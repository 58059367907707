import { Component } from 'react';

import { Subscription } from 'rxjs';
import { IOC_TYPES } from '../../context/ioc.types';
import { lazyInject } from '../../context/module';
import { FormControl } from '../../models/forms';
import { ApiConfig } from '../../services/api-config';
import { EnvironmentService } from '../../services/environment.service';
import { LightHttp } from '../../services/light-http.service';
import { LoginService } from '../../services/login.service';
import { MotivesService } from '../../services/motives.service';
import { OfflineTaskCapabilities, OfflineTaskService } from '../../services/offline-task.service';
import { ScriptLoadService } from '../../services/script-load.service';
import { SettingsService } from '../../services/settings.service';
import { UIContext } from '../../services/ui-context';
import { MotiveDisabledDate, UserPreferenceService, UserPreferenceTypes } from '../../services/user-preference.service';
import { WebSocketService } from '../../services/websocket.service';
import { PSDate } from '../../utils/date.utils';
import { IconHelper } from '../../utils/icon-helper';
import { HtmlEntityTypes } from '../../utils/icon.utils';
import { RxUtils } from '../../utils/rx-utils';
import ContactUsModal from '../ui/contact-us-modal/contact-us.modal';
import { ToggleSwitchComponent } from '../ui/toggle-switch/toggle-switch.component';
import styles from './static-footer.component.module.scss';

export class StaticFooterComponent extends Component {
    get isMotivesReadyAndEnabled() {
        return this.settingsService.motivesEnabled && this.motiveReady;
    }

    get motiveReady() {
        return this.motiveService && this.motiveService.motiveReady;
    }

    get showRating() {
        return (
            this.environment.sitename ===
            this.environment.env.constants.ps2pdfId
        );
    }

    motiveFormControl = new FormControl(false);
    offlineMode = new FormControl(false);

    wsConnected = true;

    subs: Subscription[] = [];

    @lazyInject(IOC_TYPES.WebSocketService) private wsService: WebSocketService;
    @lazyInject(IOC_TYPES.OfflineTaskService) private offlineTaskService: OfflineTaskService;
    @lazyInject(IOC_TYPES.ScriptLoadService) private scriptLoadService: ScriptLoadService;
    @lazyInject(IOC_TYPES.UIContext) private uiContext: UIContext;
    @lazyInject(IOC_TYPES.SettingsService) private settingsService: SettingsService;
    @lazyInject(IOC_TYPES.MotivesService) private motiveService: MotivesService;
    @lazyInject(IOC_TYPES.LoginService) private loginService: LoginService;
    @lazyInject(IOC_TYPES.EnvironmentService) private environment: EnvironmentService;
    @lazyInject(IOC_TYPES.LightHttp) private http: LightHttp;
    @lazyInject(IOC_TYPES.UserPreferenceService) private userPreferenceService: UserPreferenceService;

    constructor(props) {
        super(props);
        const storedValue = this.userPreferenceService.getPreference<MotiveDisabledDate>(UserPreferenceTypes.MOTIVES_DISABLED_DATE, MotiveDisabledDate);
        if (storedValue && storedValue.date) {
            if (PSDate.from(storedValue.date).isBefore(PSDate.from(new Date).subtract(1, 'hours'))) {
                this.motiveFormControl.setValue(true);
            }
        } else {
            this.motiveFormControl.setValue(true);
        }
        const motiveSub = this.motiveFormControl.valueChanges.subscribe(data =>
            this.updateMotives(data)
        );
        this.subs.push(motiveSub);

        const wsSub = this.wsService.status.subscribe({
            next: () => {
                this.wsConnected =
                    this.wsService.isConntected() &&
                    this.wsService.isServiceReady();
                this.forceUpdate();
            }
        });
        this.subs.push(wsSub);

        this.offlineTaskService.changed.subscribe(
            (state: OfflineTaskCapabilities) => {
                this.offlineMode.setValue(state.offlineModeOn, {
                    noEmit: true
                });
            }
        );

        this.offlineMode.valueChanges.subscribe(turnOnOfflineMode =>
            this.goOfflineClicked(turnOnOfflineMode)
        );
    }

    componentWillUnmount(): void {
        this.subs.forEach(sub => {
            RxUtils.safeCloseSubscriber(sub);
        })
    }

    async reConnect() {
        const mode = this.offlineTaskService.getOfflineMode()
            ? "offline"
            : null;
        try {
            await this.wsService.reinitialize();
        } catch (e) {
            if (mode === "offline") {
                await this.scriptLoadService.loadAllScriptsIfNotLoaded();
                this.offlineTaskService.setOfflineMode(false);
                this.uiContext.showErrorDialog({
                    title: "Error",
                    text: `Please make sure your are logged in and XConvert Engine is running.
                        Not sure how? please follow instruction at https://www.xconvert.com/packages before you can turn on this feature.`,
                    confirmButtonText: "Learn more",
                    successCallback: () => {
                        location.href = "packages";
                    },
                    showCancelButton: true,
                    cancelButtonText: "Dismiss"
                });
            }
        }
    }

    async goOfflineClicked(turnOnOfflineMode: boolean) {
        await this.scriptLoadService.loadAllScriptsIfNotLoaded();
        if (!this.loginService.isUserLoggedIn) {
            // this.showBuyNow();
            // Disabled buy now button for now
            this.offlineTaskService.setOfflineMode(false);
            this.uiContext.showErrorDialog({
                title: "Error",
                text: `Please make sure your are logged in and XConvert Engine is running.
                        Not sure how? please follow instruction at https://www.xconvert.com/packages 
                        before you can turn on this feature.`,
                confirmButtonText: "Learn more",
                successCallback: () => {
                    location.href = "packages";
                },
                showCancelButton: true,
                cancelButtonText: "Dismiss"
            });
            this.wsService.reinitialize();
        } else if (
            this.loginService.isUserLoggedIn &&
            !this.offlineTaskService.getCanGoOfflineMode()
        ) {
            this.uiContext.showErrorDialog({
                title: "Error",
                text:
                    "Your package does not support offline file process feature. Please visit User Portal at https://www.xconvert.com/portal to upgrade."
            });
        } else if (
            this.loginService.isUserLoggedIn &&
            this.offlineTaskService.getOfflineMode()
        ) {
            // Turn off
            this.offlineTaskService.setOfflineMode(false);
            await this.wsService.reinitialize();
        } else {
            if (
                this.loginService.isUserLoggedIn &&
                this.offlineTaskService.getCanGoOfflineMode()
            ) {
                this.http
                    .get(
                        `http://${ApiConfig.localhostDomainName}:9080/file/converter/resolve/status`
                    )
                    .subscribe(
                        data => {
                            // turn on
                            this.offlineTaskService.setOfflineMode(true);
                            this.wsService.reinitialize();
                            this.offlineMode.setValue(true, { noEmit: true });
                            return;
                        },
                        err => {
                            // Keep off
                            this.offlineTaskService.setOfflineMode(false);
                            this.uiContext.showErrorDialog({
                                title: "Error",
                                text: `Please make sure XConvert Engine is running.
                                    Not sure how? please follow instruction at https://www.xconvert.com/packages 
                                    before you can turn on this feature.`,
                                confirmButtonText: "Learn more",
                                successCallback: () => {
                                    location.href = "packages";
                                },
                                showCancelButton: true,
                                cancelButtonText: "Dismiss"
                            });
                            this.wsService.reinitialize();
                        }
                    );
            }
        }
        this.offlineMode.setValue(false, { noEmit: true });
    }

    private updateMotives(state: boolean) {
        this.settingsService.motivesEnabled = state;
        this.userPreferenceService.setPreference(UserPreferenceTypes.MOTIVES_DISABLED_DATE, state ? null : { date: new Date() });
    }

    async showContactUs() {
        await this.scriptLoadService.loadAllScriptsIfNotLoaded();
        ContactUsModal.show({});
    }

    toggleMotives(state) {
        this.motiveFormControl.setValue(state);
    }

    shouldToggle(nextValue): Promise<boolean> {
        if (!nextValue) {
            return new Promise((resolve) => {
                this.uiContext.showConfirmationWarningDialog(
                    'Support Needed!',
                    `Please consider keeping these advertisements as they help us financially. But if you must for some reason, then you may turn them off.`,
                    () => {
                        resolve(false);
                    },
                    true,
                    'Turn Ads Off',
                    'Keep Ads Running',
                    () => {
                        resolve(true);
                    }
                );
            });
        } else {
            return Promise.resolve(true);
        }
    }

    render() {
        return (
            <div className={styles['static-footer']}>
                {(false && !this.wsConnected) && (
                    <div className={styles["static-item"]}>
                        <h4>
                            {IconHelper.getIconElement(HtmlEntityTypes.ANTENNA)}
                            Lost communication with the server...
                        </h4>
                        <button
                            type="button"
                            className={`btn btn-outline-light float-right`}
                            onClick={() => this.reConnect()}
                        >
                            Reconnect
                        </button>
                    </div>
                )}
                <div className={`${styles['static-item']} ${styles['quick-actions']}`}>
                    {/* {this.showRating && (
                        <div
                            className={`${styles['quick-action-item']} ${styles['offline-mode']} ${styles['custom-control']} ${styles['custom-switch']}`}
                            data-tooltip="Offline Mode provide more privacy by processing files on your own computer."
                        >
                            <ToggleSwitchComponent
                                id='offlineModeSwitch'
                                className={styles['custom-control-input']}
                                checked={this.offlineMode.value}
                                onChange={(state) =>
                                    this.offlineMode.setValue(state)
                                }></ToggleSwitchComponent>
                            <label
                                className={styles['custom-control-label']}
                                htmlFor="offlineModeSwitch"
                            >
                                Go Offline
                            </label>
                        </div>
                    )
                    } */}
                    <div
                        id="quickActionMotive"
                        className={`${styles['quick-action-item']} ${styles['motive']} ${styles['custom-control']} ${styles['custom-switch']}`}
                        aria-describedby="quickActionMotive"
                        data-tooltip="Ads help us to keep this service free. But if it is too much of an annoyance, you may disable it."
                    >

                        <ToggleSwitchComponent
                            id='customSwitch1'
                            className={styles['custom-control-input']}
                            checked={this.motiveFormControl.value}
                            shouldToggle={(nextValue) => this.shouldToggle(nextValue)}
                            onChange={(state) =>
                                this.toggleMotives(state)
                            }></ToggleSwitchComponent>
                        <label className={styles['custom-control-label']} htmlFor="customSwitch1">
                            Ads
                        </label>
                    </div>
                    <div className={`${styles['quick-action-item']}`}>
                        <label onClick={() => this.showContactUs()}>
                            Feedback
                        </label>
                    </div>

                </div>
            </div>
        );
    }
}
