import { Container } from 'inversify';
import getDecorators from 'inversify-inject-decorators';


// Must have https://www.npmjs.com/package/babel-plugin-transform-typescript-metadata
export const container = new Container({ autoBindInjectable: true, defaultScope: 'Singleton' });
// container.bind(IOC_TYPES.EnvironmentService).to(EnvironmentService).inSingletonScope();
// container.bind(IOC_TYPES.ApiConfig).to(ApiConfig).inSingletonScope();
// container.bind(IOC_TYPES.DOMStorage).to(DOMStorage).inSingletonScope();
// container.bind(IOC_TYPES.LightHttp).to(LightHttp).inSingletonScope();

// container.bind(IOC_TYPES.OfflineTaskService).to(OfflineTaskService).inSingletonScope();
// container.bind(IOC_TYPES.StorageService).to(StorageService).inSingletonScope();
// container.bind(IOC_TYPES.WebSocketService).to(WebSocketService).inSingletonScope();
// container.bind(IOC_TYPES.SettingsService).to(SettingsService).inSingletonScope();
// container.bind(IOC_TYPES.LoginService).to(LoginService).inSingletonScope();
// container.bind(IOC_TYPES.WindowService).to(WindowService).inSingletonScope();
// container.bind(IOC_TYPES.UIContext).to(UIContext).inSingletonScope();
// container.bind(IOC_TYPES.ProcessCompleteCheckService).to(ProcessCompleteCheckService).inSingletonScope();
// container.bind(IOC_TYPES.MotivesService).to(MotivesService).inSingletonScope();
// container.bind(IOC_TYPES.AuthService).to(AuthService).inSingletonScope();
// container.bind(IOC_TYPES.ScriptLoadService).to(ScriptLoadService).inSingletonScope();
// container.bind(IOC_TYPES.PageDataProviderService).to(PageDataProviderService).inSingletonScope();
// container.bind(IOC_TYPES.GAService).to(GAService).inSingletonScope();
// container.bind(IOC_TYPES.GoogleDriverService).to(GoogleDriverService).inSingletonScope();
// container.bind(IOC_TYPES.DropboxService).to(DropboxService).inSingletonScope();
// container.bind(IOC_TYPES.SpinnerService).to(SpinnerService).inSingletonScope();
// container.bind(IOC_TYPES.FileUploadService).to(FileUploadService).inSingletonScope();
// container.bind(IOC_TYPES.HelperService).to(HelperService).inSingletonScope();
// container.bind(IOC_TYPES.FileUploadRecoveryService).to(FileUploadRecoveryService).inSingletonScope();
// container.bind(IOC_TYPES.FileManagerService).to(FileManagerService).inSingletonScope();
// container.bind(IOC_TYPES.ConnectivityService).to(ConnectivityService).inSingletonScope();
// container.bind(IOC_TYPES.ProcessorResolverService).to(ProcessorResolverService).inSingletonScope();
// container.bind(IOC_TYPES.CapabilityService).to(CapabilityService).inSingletonScope();
// container.bind(IOC_TYPES.UploadedFilesService).to(UploadedFilesService).inSingletonScope();
// container.bind(IOC_TYPES.FileProcessService).to(FileProcessService).inSingletonScope();
// container.bind(IOC_TYPES.ToastService).to(ToastService).inSingletonScope();
// container.bind(IOC_TYPES.MetadataService).to(MetadataService).inSingletonScope();
// container.bind(IOC_TYPES.MarkdownService).to(MarkdownService).inSingletonScope();
// container.bind(IOC_TYPES.ThumbnailService).to(ThumbnailService).inSingletonScope();
// container.bind(IOC_TYPES.FileDownloadService).to(FileDownloadService).inSingletonScope();
// container.bind(IOC_TYPES.PaymentService).to(PaymentService).inSingletonScope();
// container.bind(IOC_TYPES.UserPreferenceService).to(UserPreferenceService).inSingletonScope();
// container.bind(IOC_TYPES.MemeUploadService).to(MemeUploadService).inSingletonScope();
// container.bind(IOC_TYPES.FileShareService).to(FileShareService).inSingletonScope();
// container.bind(IOC_TYPES.LogService).to(LogService).inSingletonScope();
// container.bind(IOC_TYPES.FileInputButtonService).to(FileInputButtonService).inSingletonScope();

// setup the container...
let { lazyInject: originalLazyInject } = getDecorators(container);

// Additional function to make properties decorators compatible with babel.
function fixPropertyDecorator<T extends Function>(decorator: T): T {
    return ((...args: any[]) => (
        target: any,
        propertyName: any,
        ...decoratorArgs: any[]
    ) => {
        decorator(...args)(target, propertyName, ...decoratorArgs);
        return Object.getOwnPropertyDescriptor(target, propertyName);
    }) as any;
}

export const lazyInject = fixPropertyDecorator(originalLazyInject);
