import { PSFileTypes } from '../types/types';

export interface DropboxSelectedFile {
    name: string;
    link: string;
    bytes: number;
}

export class DropboxFile {
    id: string;
    name: string;
    token: string;
    lastModified: number;
    storageType = PSFileTypes.DROPBOX_FILE;
    type;
    size: number;

    constructor(token: string, id: string, name: string, mimeType: string, size: number) {
        this.id = id;
        this.name = name;
        this.token = token;
        this.type = mimeType;
        this.size = size;
        this.lastModified = Date.now();
    }

    static createFromFileList(fileList: DropboxSelectedFile[]) {
        return fileList.map(file => {
            return new DropboxFile(file.link, file.link, file.name, 'UNKNOWN', file.bytes);
        });
    }
}
