
// xType is a class (encoder/decoder) used to process the file
// we use decide the class by type
// i.e. to convert any image to jpg we use a unique method. which is same for jpeg
// so jpeg shouldnt be here, neither tif, mp4, webm.
export type FromType = 'powerpoint' |
    'excel' |
    'word' |
    'pdf' |
    'image' |
    'ps' |
    'video' |
    'audio' |
    'av' |
    'ebook' |
    'archive' |
    'files' |
    'text';

export class GenericMouseEvent {
    type: string;
    clientX: number;
    clientY: number;
    pageX: number;
    pageY: number;
    offsetX: number;
    target: any | null;
}

export class FromTypes {
    public static POWERPOINT: FromType = 'powerpoint';
    public static EXCEL: FromType = 'excel';
    public static WORD: FromType = 'word';
    public static PDF: FromType = 'pdf';
    public static IMAGE: FromType = 'image';
    public static PS: FromType = 'ps';
    public static VIDEO: FromType = 'video';
    public static AUDIO: FromType = 'audio';
    public static AV: FromType = 'av';
    public static EBOOK: FromType = 'ebook';
    public static ARCHIVE: FromType = 'archive';
    public static FILES: FromType = 'files';
    public static TEXT: FromType = 'text';
}

export enum ConvertType {
    Convert = 'convert',
    Compress = 'compress',
    Split = 'split',
    Merge = 'merge',
    All = 'all',
    Resize = 'resize',
    Meme = 'meme',
    Count = 'count',
    Encode = 'encode',
    Decode = 'decode',
    Crop = 'crop',
    Trim = 'trim',
    Cut = 'cut'
}

export type ToType = 'powerpoint' |
    'word' |
    'pdf' |
    'image' |
    'ps' |
    'png' |
    'jpg' |
    'tiff' |
    'webp' |
    'video' |
    'audio' |
    'ebook' |
    'jpeg' |
    'heic';
export class ToTypes {
    public static POWERPOINT: ToType = 'powerpoint';
    public static WORD: ToType = 'word';
    public static PDF: ToType = 'pdf';
    public static IMAGE: ToType = 'image';
    public static PS: ToType = 'ps';
    public static PNG: ToType = 'png';
    public static JPG: ToType = 'jpg';
    public static TIFF: ToType = 'tiff';
    public static WEBP: ToType = 'webp';
    public static VIDEO: ToType = 'video';
    public static AUDIO: ToType = 'audio';
    public static EBOOK: ToType = 'ebook';
    public static JPEG: ToType = 'jpeg';
    public static HEIC: ToType = 'heic';
}

export type ProcessType = 'convert' |
    'split' |
    'merge' |
    'compress' |
    'resize' |
    'url-encoder' |
    'url-decoder' |
    'base64-encode' |
    'base64-decode' |
    'word-count' |
    'meme' |
    'crop';
export class ProcessTypes {
    public static CONVERT: ProcessType = 'convert';
    public static SPLIT: ProcessType = 'split';
    public static MERGE: ProcessType = 'merge';
    public static COMPRESS: ProcessType = 'compress';
    public static RESIZ: ProcessType = 'resize';
    public static URL_ENCODER: ProcessType = 'url-encoder';
    public static URL_DECODER: ProcessType = 'url-decoder';
    public static BASE64_ENCODE: ProcessType = 'base64-encode';
    public static BASE64_DECODE: ProcessType = 'base64-decode';
    public static WORD_COUNT: ProcessType = 'word-count';
    public static MEME: ProcessType = 'meme';
    public static CROP: ProcessType = 'crop';
}

export interface ServerReply {
    error: string;
    output: string | Array<string>;
    input: string | Array<string>;
}

export type StatusType = 'completed' |
    'started' |
    'failed' |
    'undefined';
export class StatusTypes {
    public static COMPLETED: StatusType = 'completed';
    public static STARTED: StatusType = 'started';
    public static FAILED: StatusType = 'failed';
    public static UNDEFINED: StatusType = 'undefined';
}

export type UniqueProcessType = string;

export interface INchanSubscriber {
    connected: true;
    desiredTransport: string[];
    lastMessageId: string;
    reconnect: 'persist';
    reconnectTimeout: number; // millies
    running: true;
    transport: {
        emit: any;
        name: 'websocket';
        opt: any;
        listener: WebSocket
    };
    url: string;
}

export type ProcessStageSubStateType = 'started' |
    'partial' |
    'failed' |
    'unknown' |
    'completed';
export class ProcessStageSubStateTypes {
    public static STARTED: ProcessStageSubStateType = 'started';
    public static PARTIAL: ProcessStageSubStateType = 'partial';
    public static FAILED: ProcessStageSubStateType = 'failed';
    public static UNKNOWN: ProcessStageSubStateType = 'unknown';
    public static COMPLETED: ProcessStageSubStateType = 'completed';
}

export type ProcessStageType = 'upload' |
    'queued' |
    'preprocess' |
    'processing' |
    'ready' |
    'zip' |
    'completed';
export class ProcessStageTypes {
    public static UPLOAD: ProcessStageType = 'upload';
    public static QUEUED: ProcessStageType = 'queued';
    public static PREPROCESS: ProcessStageType = 'preprocess';
    public static PROCESSING: ProcessStageType = 'processing';
    public static READY: ProcessStageType = 'ready';
    public static ZIP: ProcessStageType = 'zip';
    public static COMPLETED: ProcessStageType = 'completed';
}

export type DataType = 'percentage' |
    'ratio' |
    'notification';
export class DataTypes {
    public static PERCENTAGE: DataType = 'percentage';
    public static RATIO: DataType = 'ratio';
    public static NOTIFICATION: DataType = 'notification';
}

export type WebsocketProcessType = 'convert-one-to-one' |
    'convert-one-to-many';
export class WebsocketProcessTypes {
    public static CONVERT_ONE_TO_ONE: WebsocketProcessType = 'convert-one-to-one';
    public static CONVERT_ONE_TO_MANY: WebsocketProcessType = 'convert-one-to-many';
}

export interface WebsocketResponse {
    uuid: string;
    processType: WebsocketProcessType;
    dataType: DataType;
    processStage: ProcessStageType;
    readableProcessStage: string;
    processStageSubState: ProcessStageSubStateType;
    data: string;
    result: string;
    priority: string;
    eventId: number;
}

export interface WebsocketDomainResponse {
    data: string;
    status: boolean;
}

export interface RowFileAndData {
    file: File;
    data?: any;
}

export type OriginalInputFileLocationType = 'local' |
    'gcs';
export class OriginalInputFileLocationTypes {
    public static LOCAL = 'local';
    public static GSC = 'gcs';
}

export interface OriginalInputFile {
    location: string;
    originalName: string;
    storageType: OriginalInputFileLocationType;
    metadata: any;
    gcName?: string;
    token?: string;
}

export type PSFileType = 'GOOGLE_DRIVE_FILE' |
    'DROPBOX_FILE' |
    'FILE';
export class PSFileTypes {
    public static GOOGLE_DRIVE_FILE = 'GOOGLE_DRIVE_FILE';
    public static DROPBOX_FILE = 'DROPBOX_FILE';
    public static FILE = 'FILE';
}

export type PSUploadType = 'server' | 'gcs';
export class PSUploadTypes {
    public static SERVER = 'server';
    public static GOOGLE_CLOUD_STORAGE = 'gcs';
}

export type OperationType = 'upload' |
    'process' |
    'generic';
export class OperationTypes {
    public static UPLOAD: OperationType = 'upload';
    public static PROCESS: OperationType = 'process';
    public static GENERIC: OperationType = 'generic';
}

export namespace FileMetadata {
    export function getFileIdFromSafeName(safeName: string) {
        const parts = safeName.split(/\-|\./);
        let tokenCount = parts.length - 1;
        while (tokenCount >= 0) {
            if (parts[tokenCount].length === 24) {
                return parts[tokenCount];
            }
            tokenCount--;
        }
        throw new Error('Failed to get uploaded file id from the given safe file name.');
    }
}

export interface FileMetadata {
    name: string;
    type: string;
    size: string;
    gcName: string;
    safeName: string;
}

export interface ProcessorMetadata {
    uploadDomain: string;
    processDomain: string;
    downloadDomain: string;
    thumbnailDomain: string;
    uploadType: PSUploadType;
    uploadEndpoint: string;
    processorEndpoint: string;
    uploadedFileDownloadEndpoint: string;
    downloadEndpoint: string;
    gcpTempDownloadEndpoint: string;
    emailDownloadEndpoint: string;
    imageShareEndpoint: string;
    thumbnailEndpoint: string;
    commEndpoint: string;
    zipEndpoint: string;
    fileMetadata: FileMetadata;
    postProcessMetadata: ProcessorMetadata;
}

export interface OrientationUpdateEvent {
    file: File;
    orientation: RotationAndFlip;
}

export type StringBoolean = 'true' | 'false';

export interface RotationAndFlip {
    angle: string;
    isFliped: StringBoolean; // true, false
    orientation?: string;
}