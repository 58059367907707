import { Component } from 'react';
import { createRoot } from 'react-dom/client';

import { BrowserUtils } from '../../../ui-utils/browser-utils';
import style from './toast.component.module.scss';

interface ToastProps {
    title: string;
    type?: 'primary' | 'success' | 'danger' | 'warning' | 'info';
    message: string;
    timeout?: number;
    canClose?: boolean;
    mustClose?: boolean;
    onOpen?: (modalRef: Toast) => void;
    onClose?: (modalRef: Toast) => void;
}

interface ToastState {
    shown: boolean;
}

export default class Toast extends Component<ToastProps, ToastState> {

    static showToast(request: ToastProps) {
        request.timeout = request.timeout || 3000;
        request.canClose = Object.keys(request).includes('canClose') ? request.canClose : true;
        this.createToast(<Toast {...request}>
        </Toast>, request);
    }

    static createToast(Cmp: any, props: ToastProps) {
        if (!BrowserUtils.hasDocument()) {
            console.error("Requested execution aborted since document was not avalilable");
            return;
        }
        const elementParent = document.createElement('div');
        elementParent.classList.add(`${Date.now()}`, `bg-${props.type || 'primary'}`);
        let toasts = document.querySelector('#toasts');
        if (!toasts) {
            toasts = document.createElement('div');
            toasts.id = 'toasts';
            document.body.appendChild(toasts);
        }
        if (toasts) {
            toasts.appendChild(elementParent);
            const root = createRoot(elementParent!);
            root.render(Cmp);
        } else {
            console.error('Toasts not found');
        }
    }

    readonly state: Readonly<ToastState> = {
        shown: true,
    };
    constructor(props: ToastProps) {
        super(props);
        if (!props.mustClose && props.canClose) {
            setTimeout(() => {
                this.unmount();
            }, props.timeout);
        }
    }
    parentElement;
    setParentElement(element) {
        this.parentElement = element && element.parentElement;
    }

    componentDidMount() {
        if (this.props.onOpen) {
            this.props.onOpen(this);
        }
    }

    public unmount() {
        this.setState({
            shown: false,
        });
        if (this.props.onClose) {
            this.props.onClose(this);
        }
        try {
            if (this.parentElement) {
                this.parentElement.remove();
            }
        } catch (e) {
            console.error('Error while removing modal');
        }
    }

    render() {
        return (
            <div ref={(r) => this.setParentElement(r)} className={style.toast}>
                <div className={style.content}>
                    <div className={style.head}>
                        <div className={style['header-content']}>{this.props.title}</div>
                        {
                            this.props.canClose &&
                            <div className={style.close} onClick={() => this.unmount()}>x</div>
                        }
                    </div>
                    <div className={style.body}>{this.props.message}</div>
                </div>
            </div>
        );
    }
}