import { inject, injectable } from 'inversify';

import { DropboxFile } from '../models/dropbox-file';
import { Logger } from '../models/logger';
import { UIContext } from './ui-context';

declare var Dropbox;

export interface DropboxSelectedFile {
    name: string;
    link: string;
    bytes: number;
}

@injectable()
export class DropboxService {
    private readonly logger = Logger.getLogger('DropboxService');

    readonly cid = '1002349272285-rb84b9urep0jghvnf5c04vfp2tf58quv.apps.googleusercontent.com';
    readonly scope = 'https://www.googleapis.com/auth/drive';
    initialized = false;
    authenticated = false;
    pickerLoaded = false;
    picker;
    auth = null;
    user = null;
    response = null;
    token = null;

    tryOnce = false;

    options;


    constructor(
        @inject(UIContext) private uiContext: UIContext,
    ) {

        this.options = {

            // Required. Called when a user selects an item in the Chooser.
            success: (files) => {
                this.logger.trace7('Here\'s the file link: ' + files[0].link);
            },

            // Optional. Called when the user closes the dialog without selecting a file
            // and does not include any parameters.
            cancel: () => {
                this.logger.trace7('Cancelled Here\'s the file link: ');
            },

            // Optional. "preview" (default) is a preview link to the document for sharing,
            // "direct" is an expiring link to download the contents of the file. For more
            // information about link types, see Link types below.
            linkType: 'direct', // or "direct"

            // Optional. A value of false (default) limits selection to a single file, while
            // true enables multiple file selection.
            multiselect: true, // or true

            // Optional. A value of false (default) limits selection to files,
            // while true allows the user to select both folders and files.
            // You cannot specify `linkType: "direct"` when using `folderselect: true`.
            folderselect: false, // or true
        };

    }

    showFileSelectDialog(callback: (dropboxFiles: DropboxFile[]) => void) {
        this.options.success = (files) => this.onDropboxFileOpen(files, callback);
        Dropbox.choose(this.options);
    }

    onDropboxFileOpen(data, callback: (dropboxFiles: DropboxFile[]) => void) {
        this.logger.trace7(data);
        const files: DropboxSelectedFile[] = data;

        const gdFiles = DropboxFile.createFromFileList(files);
        if (callback) {
            callback(gdFiles);
        }
    }
}
