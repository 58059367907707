import { Expose, Type } from 'class-transformer';
import { forkJoin, Observable, of, pipe, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, map, mergeMap, retry, tap, timeout } from 'rxjs/operators';

import { Packages, PackageType } from '../commons/commons';
import { Logger } from '../models/logger';
import { isEmpty } from './lodash-min';

export interface ErrorResponse {
    error: boolean;
    message: string;
}

export class NetworkError extends Error {
    static is(err: Error): boolean {
        return err instanceof NetworkError;
    }

    constructor(message) {
        super(message);
        this.name = 'NetworkError';

        Object.setPrototypeOf(this, NetworkError.prototype);
    }
}

export class ServiceError extends Error {
    static is(err: Error): boolean {
        return err instanceof ServiceError;
    }

    constructor(message) {
        super(message);
        this.name = 'ServiceError';

        Object.setPrototypeOf(this, ServiceError.prototype);
    }
}

export class ClientError extends Error {
    static is(err: Error): boolean {
        return err instanceof ClientError;
    }

    constructor(message) {
        super(message);
        this.name = 'ClientError';

        Object.setPrototypeOf(this, ClientError.prototype);
    }
}

export class UnknownHTTPError extends Error {
    static is(err: Error): boolean {
        return err instanceof UnknownHTTPError;
    }

    constructor(message) {
        super(message);
        this.name = 'UnknownHTTPError';

        Object.setPrototypeOf(this, UnknownHTTPError.prototype);
    }
}

/**
 * Enum uses java peoperty naming convension since POJO to Bson converter require this convension
 */
export type MotiveLocationType = 'appPageTop' |
    'singlePageBaseTop' |
    'singlePageBaseLeft' |
    'singlePageBaseRight' |
    'fileUploadPageTop' |
    'fileUploadPageBottom' |
    'fileDownloadPageTop' |
    'fileDownloadPageWithInCurrentDownloads' |
    'fileDownloadPageWithInRecoveredDownloads' |
    'fileDownloadPageBottom' |
    'fileDownloadPageLeft' |
    'fileDownloadPageRight' |
    'homePageTop' |
    'memePageTop' |
    'convertPageTop' |
    'convertPageBelowFeatureTitle' |
    'convertPageLeft' |
    'convertPageRight' |
    'convertPageBottom' |
    'howToPageTop';
export class MotiveLocationTypes {
    public static appPageTop: MotiveLocationType = 'appPageTop';
    public static singlePageBaseTop: MotiveLocationType = 'singlePageBaseTop';
    public static singlePageBaseLeft: MotiveLocationType = 'singlePageBaseLeft';
    public static singlePageBaseRight: MotiveLocationType = 'singlePageBaseRight';
    public static fileUploadPageTop: MotiveLocationType = 'fileUploadPageTop';
    public static fileUploadPageBottom: MotiveLocationType = 'fileUploadPageBottom';
    public static fileDownloadPageTop: MotiveLocationType = 'fileDownloadPageTop';
    public static fileDownloadPageWithInCurrentDownloads: MotiveLocationType = 'fileDownloadPageWithInCurrentDownloads';
    public static fileDownloadPageWithInRecoveredDownloads: MotiveLocationType = 'fileDownloadPageWithInRecoveredDownloads';
    public static fileDownloadPageBottom: MotiveLocationType = 'fileDownloadPageBottom';
    public static fileDownloadPageLeft: MotiveLocationType = 'fileDownloadPageLeft';
    public static fileDownloadPageRight: MotiveLocationType = 'fileDownloadPageRight';
    public static homePageTop: MotiveLocationType = 'homePageTop';
    public static memePageTop: MotiveLocationType = 'memePageTop';
    public static convertPageTop: MotiveLocationType = 'convertPageTop';
    public static convertPageBelowFeatureTitle: MotiveLocationType = 'convertPageBelowFeatureTitle';
    public static convertPageLeft: MotiveLocationType = 'convertPageLeft';
    public static convertPageRight: MotiveLocationType = 'convertPageRight';
    public static convertPageBottom: MotiveLocationType = 'convertPageBottom';
    public static howToPageTop: MotiveLocationType = 'howToPageTop';
}

export class MotiveSlotConfig {
    @Expose()
    appPageTop: boolean;
    @Expose()
    singlePageBaseTop: boolean;
    @Expose()
    singlePageBaseLeft: boolean;
    @Expose()
    singlePageBaseRight: boolean;
    @Expose()
    fileUploadPageTop: boolean;
    @Expose()
    fileUploadPageBottom: boolean;
    @Expose()
    fileDownloadPageTop: boolean;
    @Expose()
    fileDownloadPageWithInCurrentDownloads: boolean;
    @Expose()
    fileDownloadPageWithInRecoveredDownloads: boolean;
    @Expose()
    fileDownloadPageBottom: boolean;
    @Expose()
    fileDownloadPageLeft: boolean;
    @Expose()
    fileDownloadPageRight: boolean;
    @Expose()
    homePageTop: boolean;
    @Expose()
    memePageTop: boolean;
    @Expose()
    convertPageTop: boolean;
    @Expose()
    convertPageBelowFeatureTitle: string;
    @Expose()
    convertPageLeft: boolean;
    @Expose()
    convertPageRight: boolean;
    @Expose()
    convertPageBottom: boolean;
    @Expose()
    howToPageTop: boolean;
}

export class MotiveConfig extends MotiveSlotConfig {

    /** @deprecated */
    front_page: boolean;
    /** @deprecated */
    convert_page: boolean;
    /** @deprecated */
    upload_box: boolean;
    /** @deprecated */
    download_page: boolean;
    internalMotivesEnabled: boolean;
    headerPromotionToBuy: boolean;
    show7DayTrialDialog: boolean;
    externalMotiveType: 'google' | 'buysell';
    showExtensionDownloadDialog: boolean;

    rotateMotives: false;
    motiveRotationPeriodInSeconds: number;
    motiveRotationRandonOffsetRangeInSeconds: number;

    bypassMouseEvents: MotiveLocationType[];

    constructor() {
        super();
    }
}

export class MotiveSettingsResponse {
    _free: MotiveConfig;
    free: MotiveConfig;
    paid: MotiveConfig;
    /** @deprecated */
    internalMotivesEnabled: boolean;
    /** @deprecated */
    headerPromotionToBuy: boolean;
    /** @deprecated */
    show7DayTrialDialog: boolean;
    /** @deprecated */
    externalMotiveType: 'google' | 'buysell';
    /** @deprecated */
    showExtensionDownloadDialog: boolean;
}

export class FromTypeFileSizeLimitInMB {
    powerpoint: number;
    excel: number;
    word: number;
    pdf: number;
    image: number;
    ps: number;
    video: number;
    audio: number;
    av: number;
    ebook: number;
    any: number;
}

export class UserLimitsSettings extends FromTypeFileSizeLimitInMB {
    numberOfConcurrentTasks: number;
}

export interface FeatureFlags {
    showLivePreview: boolean;
    useWebsockets: boolean;
}

export class FeatureFlags {
    showLivePreview: boolean;
    useWebsockets: boolean;
}

export type PaymentStateType = 'unknown' |
    'signup' |
    'paid' |
    'cancelled';
export class PaymentStateTypes {
    static unknown = 'unknown';
    static signup = 'signup';
    static paid = 'paid';
    static cancelled = 'cancelled';
}

export type PaymentGatewayType = 'none' |
    'paypal' |
    'stripe';
export class PaymentGatewayTypes {
    static none = 'none';
    static paypal = 'paypal';
    static stripe = 'stripe';
}

export interface Rating {
    feature: string,
    rating_1: number,
    rating_2: number,
    rating_3: number,
    rating_4: number,
    rating_5: number,
}

export class AccountDetails {
    active: boolean;
    @Type(() => Date)
    createdDate: Date;
    email: string;
    @Type(() => Date)
    lastUpdatedDate: Date;
    name?: string;
    packageType: PackageType;
    paymentDetails?: any;
    paymentGateway: PaymentGatewayType;
    paymentState: PaymentStateType; // TODO type these
    verified: boolean;
    paid: boolean;
    @Type(() => Date)
    subscriptionNextBillingDate: Date;

    public isPaidUser() {
        return (this.packageType === Packages.PACKAGE_TYPE_BASIC ||
            this.packageType === Packages.PACKAGE_TYPE_PRO) &&
            (
                this.paid ||
                ((this.subscriptionNextBillingDate && this.subscriptionNextBillingDate.getTime() > new Date().getTime() - 1000 * 60 * 60 * 24 * 1)));
    }
}

export class UserSettings {
    useEdgeTTL: boolean;
    headerMessageEnabled: boolean;
    headerMessageEnabledForPaid: boolean;
    headerMessage: string;
    chunkUploadEnabled: boolean;
    chunkSize: number;
    simultaneousUploads: number;
    xhrTimeout: number;
    @Type(() => FromTypeFileSizeLimitInMB)
    highPriorityCountryFromTypeFileSizeLimitInMB: FromTypeFileSizeLimitInMB;
    @Type(() => FromTypeFileSizeLimitInMB)
    fromTypeFileSizeLimitInMB: FromTypeFileSizeLimitInMB;
    @Type(() => UserLimitsSettings)
    freeUserLimits: UserLimitsSettings;
    @Type(() => UserLimitsSettings)
    basicUserLimits: UserLimitsSettings;
    @Type(() => UserLimitsSettings)
    proUserLimits: UserLimitsSettings;
    @Type(() => FeatureFlags)
    featureFlags: FeatureFlags;
    @Type(() => MotiveSettingsResponse)
    motive: MotiveSettingsResponse;
    resolveMousemoveEventsStartup: boolean;
    featureDescriptionsDisabled: boolean;
    markdownDisabled: boolean;
}

export interface LoginResponse {
    error: boolean;
    bearer: string;
    registered: boolean;
    canGoOffline: boolean;
    motive: MotiveSettingsResponse;
    onlineWSDomains: string[];
}

export interface AuthenticateResponse {
    error: boolean;
    bearer: string;
    motive: MotiveSettingsResponse;
}

/** Only convert and rethorw know errors, else requestor should handle the error */
export const ResponsePipeForHttpInterceptor = pipe(
    catchError(response => {
        if (response instanceof ServiceError || response instanceof NetworkError) {
            throw response;
        }
        if (response.status === 0) {
            throw new NetworkError('Not connected to internet. Some services will not work.');
        } else if (response.error && response.error.error) {
            throw new ServiceError(response.error.message || response.error.errorMessage || 'Oops... Something went wrong.');
        }
        throw response;
    }),
    map((response: any) => {
        return response;
    }),
);


export const ResponsePipe = pipe(
    catchError(response => {
        if (response instanceof ServiceError || response instanceof NetworkError) {
            throw response;
        }
        if (response.status === 0) {
            throw new NetworkError('Not connected to internet. Some services will not work.');
        } else if (response.error && response.error.error) {
            throw new ServiceError(response.error.message || response.error.errorMessage || 'Oops... Something went wrong.');
        }
        console.error(response);
        if (response instanceof Error) {
            throw response;
        }
        throw new Error(`Ooops! There was error. Error: ${response.statusText}`);
    }),
    map((response: any) => {
        return response;
    }),
);

export class RequestUtils {
    private static get logger() {
        return Logger.getLogger('RequestUtils');
    };

    // Tested and working
    public static getSuccessValuesOrThrowSequential$<T>(observables$: Observable<T>[]): Observable<T> {
        const subject = new ReplaySubject<T>(1);
        const result$ = subject.asObservable();
        const shallowClonedObservables$ = observables$.map(a => a);
        const fn = (prevError) => {
            const observable = shallowClonedObservables$.pop();
            if (isEmpty(observable)) {
                subject.error(prevError);
                return;
            }
            try {
                observable.subscribe({
                    next: (result) => {
                        subject.next(result);
                        subject.complete();
                    },
                    error: (err) => {
                        fn(err);
                    }
                });
            } catch (e) {
                fn(e);
            }
        }
        setTimeout(() => fn(null), 0);
        return result$;
    }

    public static getSuccessValuesOrThrow$<T>(
        observables$: Observable<T>[],
        retryCount = 3,
        tryUntilMillies = 30000,
    ): Observable<T[]> {
        RequestUtils.logger.trace1(`Retry count: ${retryCount}, ObservableCount:${observables$.length} `);
        const subject = new Subject<T[]>();
        const results = [];
        const errors = [];
        const updateResults = () => {
            if (results.length + errors.length === observables$.length) {
                if (results.length > 0) {
                    subject.next(results);
                    subject.complete();
                } else {
                    if (errors.length > 0) {
                        subject.error(errors[0]);
                    } else {
                        subject.error(new Error('Unknown error'));
                    }
                }
            }
        }

        for (const observable$ of observables$) {
            observable$.pipe(
                tap(() => RequestUtils.logger.trace1("retry")),
                // timeout(tryUntilMillies),
                catchError(err => {
                    RequestUtils.logger.trace1("catchError")
                    return throwError(err);
                }),
                retry(retryCount),
            ).subscribe({
                next: (data) => {
                    results.push(data);
                    updateResults();
                },
                error: (err) => {
                    errors.push(err);
                    updateResults();
                }
            });
        }
        return subject.asObservable();

        // merge(observables$.map(observable$ => {
        //     return observable$.pipe(
        //         tap(() => logger.trace1("retry")),
        //         timeout(tryUntilMillies),
        //         catchError(err => {
        //             logger.trace1("catchError")
        //             return throwError(err);
        //             // timer(tryUntilMillies).pipe(
        //             //     tap(() => logger.trace1("throwError")),
        //             //     mergeMap(() => throwError(err)),
        //             // );
        //         }),
        //         retry(retryCount),
        //     );
        // })).subscribe({
        //     next: (data) => {
        //         logger.trace1(data);
        //     },
        //     error: (err) => {
        //         logger.trace1(err);
        //     }
        // })
        // return combineLatest(
        //     observables$.map(observable$ => {
        //         return observable$.pipe(
        //             retry(retryCount),
        //             timeout(tryUntilMillies),
        //             catchError(err => {
        //                 return timer(tryUntilMillies).pipe(
        //                     mergeMap(() => throwError(err)),
        //                 );
        //             }),
        //         );
        //     })
        // ).pipe(
        //     map((result: (T | null)[]) => {
        //         const successfulResults = result.filter(res => !!res);
        //         if (successfulResults.length === 0) {
        //             throw new Error('getSuccessValuesOrThrow failed to result in at least one successful result');
        //         }
        //         return successfulResults;
        //     })
        // );
    }
    /**
     * Parallelly execute all the observables and return first succeeded value.  
     * @param observables$ 
     * @param tryUntilMillies 
     * @returns 
     */
    public static getSuccessValueOrThrow$<T>(
        observables$: Observable<T>[],
        // tryForEventMillies = 10000,
        tryUntilMillies = 30000,
    ): Observable<T> {
        return forkJoin(observables$.map(observable$ => observable$.pipe(
            timeout(tryUntilMillies),
            catchError(err => {
                // console.log(`HPY =========== getSuccessValueOrThrow 1`);
                return of({
                    error: true,
                    originalError: err,
                });
            })
        ))).pipe(
            mergeMap(([...result]) => {
                // console.log(`HPY =========== getSuccessValueOrThrow 2`);
                if (result.every(r => r['error'])) {
                    // console.log(`HPY =========== getSuccessValueOrThrow 3`);
                    throw new Error('All requests failed');
                }
                const _resultT: Observable<T> = of(result.find(r => !r['originalError']) as any);
                // console.log(`HPY =========== getSuccessValueOrThrow 4`);
                return _resultT;
            })
        );

        // return race(
        //     ...observables$.map(observable$ => {
        //         return observable$.pipe(
        //             timeout(tryUntilMillies),
        //             catchError(err => {
        //                 return timer(tryUntilMillies).pipe(
        //                     mergeMap(() => throwError(err)),
        //                 );
        //             }),
        //         );
        //     })
        // );
    }

    public static async getSuccessValueOrThrow<T>(...observables$: Observable<T>[]): Promise<T> {
        return new Promise((resolve, reject) => {
            this.getSuccessValueOrThrow$(observables$).subscribe(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                },
            );
        });
    }

    public static toErrorSuppressedObservable<T>(observable$: Observable<T>) {
        return observable$.pipe(
            catchError(err => {
                return of(null);
            })
        )
    }
}
