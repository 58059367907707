import { inject, injectable } from 'inversify';
import { Observable } from 'rxjs';

import { Logger } from '../models/logger';
import { InputFileInfo } from '../types/file-upload.types';
import { ProcessorMetadata } from '../types/types';
import { ProcessorMetadataUtils } from '../utils/processor-metadata.utils';
import { ApiConfig } from './api-config';
import { HelperService } from './helper.service';
import { LightHttp } from './light-http.service';
import { StorageService } from './storage.service';


@injectable()
export class ThumbnailService {
    private readonly logger = Logger.getLogger('ThumbnailService');

    constructor(
        @inject(LightHttp) private http: LightHttp,
        @inject(StorageService) public storageService: StorageService,
        @inject(HelperService) private helperService: HelperService,
    ) { }

    getInputFileInfo(processorMetadata: ProcessorMetadata): Observable<InputFileInfo> {
        const inputFileInfoURL = `${ApiConfig.suffixDomainWithHttpsEdgeEndpoint(processorMetadata.uploadDomain)}/files/input/info`;
        return this.http.post<InputFileInfo>(inputFileInfoURL, { filename: processorMetadata.fileMetadata.safeName });
    }

    async getImageFor(token: string, processorMetadata: ProcessorMetadata) {

        const url = ProcessorMetadataUtils.getThumbnailEndpoint(processorMetadata) + '/thumbnail?token=' + token;

        await this.http.request(
            url,
            'GET',
            null,
            {
                headers: this.helperService.getCommonHttpHeaders(),
                params: { 'token': token },
            }
        ).response$.toPromise().then(data => {
            // 
        }).catch(err => {
            this.logger.error({ message: 'Error getting thumbnail.', error: err });
        });
    }
}
