import { DropboxFile } from '../models/dropbox-file';
import { GoogleDriveFile } from '../models/google-drive-file';
import { ConvertFromAudio, ConvertFromImage, ConvertFromVideo } from '../types/capability.types';

export class FilenameUtils {
    public static replaceExtension(fileName: string, extension: string) {
        const fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
        return extension && extension.startsWith('.') ? `${fileNameWithoutExtension}${extension}` : `${fileNameWithoutExtension}.${extension}`;
    }

    public static getExtension(fileName: string) {
        if (fileName.lastIndexOf('.') === -1) {
            return fileName;
        }
        const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
        return fileExtension;
    }

    public static getBasename(filePath: string) {
        const fileNameOnly = FilenameUtils.getFilename(filePath);
        if (fileNameOnly.includes('.')) {
            return fileNameOnly.replace(/\.[^/.]+$/, "");
        }
        return fileNameOnly;
    }

    public static getFilename(filePath: string) {
        return filePath.replace(/^.*[\\\/]/, '');
    }

    public static getFileMimeType(file: File | GoogleDriveFile | DropboxFile) {
        if (file.type && file.type.length > 0) {
            return file.type;
        }
        return this.getFileMimeTypeFromFilename(file.name);
    }

    public static getFileMimeTypeFromFilename(filename: string) {
        const extension = (FilenameUtils.getExtension(filename) || "").toLowerCase();
        if (ConvertFromImage.EXTENTIONS.indexOf(extension) > -1) {
            return 'image/' + extension;
        }
        if (ConvertFromVideo.EXTENTIONS.indexOf(extension) > -1) {
            return 'video/' + extension;
        }
        if (ConvertFromAudio.EXTENTIONS.indexOf(extension) > -1) {
            return 'audio/' + extension;
        }
        return extension;
    }
}
