import { EnvironmentService } from '../services/environment.service';
import { LogLevelTypes } from '../types/log.types';

export class URLType {
    public static LOCAL_RELATIVE = 'local-relative';
    public static LOCAL_ABSOLUTE = 'local-absolute';
    public static REMOTE = 'remote';
}

export type URLTypes = 'local-relative' |
    'local-absolute' |
    'remote';

export type PackageType = 'PACKAGE_TYPE_BASIC' | 'PACKAGE_TYPE_PRO';
export class Packages {
    static PACKAGE_TYPE_BASIC: PackageType = 'PACKAGE_TYPE_BASIC';
    static PACKAGE_TYPE_PRO: PackageType = 'PACKAGE_TYPE_PRO';
}

export class LoginServiceConstants {
    public static AUTH_BEARER_STORAGE_KEY = 'AUTH';
}

export class SettingsServiceConstants {
    public static MOTIVE_STORAGE_KEY = 'MOTIVE';
}

export class Commons {
    static DEBUG = EnvironmentService.getBoolEnv(process.env.NEXT_PUBLIC_DEBUG_ENABLED);
    static DEBUG_LEVEL = LogLevelTypes.INFO;

    static PAYMENT_GATEWAY_TEST_MODE = false;
    static PAYPAL_TEST_MODE = Commons.PAYMENT_GATEWAY_TEST_MODE;

    static PAYPAL_CLIENT_ID = Commons.PAYPAL_TEST_MODE ? 'AdZHSnXdmvLFV3rl5BlvsV-C69wKsI2tKFymJUy8FElT7nwhB86mN9fWfW3BuKtlyQIkcNEnTG1uxMO6' : 'AXxQSmbb22oe4ZjbHHGlTGZPP1iTePApytBxhHBeooClTimic2uX8gQwkWnVJRjKcoQ5Wyksk9Nz2pkl';

    static PAYPAL_PLAN_BACKER_MONTHLY = Commons.PAYPAL_TEST_MODE ? 'P-2LS877027G7055319L3OWXGI' : 'P-8W460836KX511983SL3SFLRY';
    static PAYPAL_PLAN_BACKER_YEARLY = Commons.PAYPAL_TEST_MODE ? 'P-0C150984PD4532505L3OWWVQ' : 'P-4BE32205WC8099423L3SFM5Y';

    static PAYPAL_PLAN_PATRON_MONTHLY = Commons.PAYPAL_TEST_MODE ? 'P-21K9973416833342TL3OWXOA' : 'P-7R9961430T230120CL3SFMDA';
    static PAYPAL_PLAN_PATRON_YEARLY = Commons.PAYPAL_TEST_MODE ? 'P-4SW19357A6322715YL3OWXVA' : 'P-1H169679XR034493GL3SFMMI';

    static STRIPE_LIVE_PUBLIC_KEY = 'pk_live_CK9WSyLfkPFmZehZljDUlyUh';
    static STRIPE_TEST_PUBLIC_KEY = 'pk_test_JX5QjY8Dpl8OhznCmkro01S8';
    static STRIPE_PUBLIC_KEY = Commons.PAYMENT_GATEWAY_TEST_MODE ? Commons.STRIPE_TEST_PUBLIC_KEY : Commons.STRIPE_LIVE_PUBLIC_KEY;

    static REMOVE_STORED_FILE_AFTER_HOURS = 6;

    static DOMAINS = {
        COMPRESSPDF_DEV: {
            key: 'COMPRESSPDF_DEV',
            url: 'https://www.compresspdf.dev',
            title: 'PDF Compressor',
            description: 'Compress PDF document files online for free',
            type: URLType.REMOTE,
        },
        JPGTOPDF_DEV: {
            key: 'JPGTOPDF_DEV',
            url: 'https://www.jpgtopdf.dev',
            title: 'JPG To PDF',
            description: 'Convert JPG to PDF online for free',
            type: URLType.REMOTE,
        },
        MERGEPDF_DEV: {
            key: 'MERGEPDF_DEV',
            url: 'https://www.mergepdf.dev',
            title: 'Merge PDF',
            description: 'Online Merge PDF document for free',
            type: URLType.REMOTE,
        },
        PDFTOJPG_DEV: {
            key: 'PDFTOJPG_DEV',
            url: 'https://www.pdftojpg.dev',
            title: 'PDF To JPG',
            description: 'Free convert PDF to JPEG/JPG online',
            type: URLType.REMOTE,
        },
        PDFTOWORD_DEV: {
            key: 'PDFTOWORD_DEV',
            url: 'https://www.pdftoword.dev',
            title: 'PDF To Word',
            description: 'Free online PDF to Word (DOCX/DOC) converter',
            type: URLType.REMOTE,
        },
        VIDEOCOMPRESSOR_DEV: {
            key: 'VIDEOCOMPRESSOR_DEV',
            url: 'https://www.videocompressor.dev',
            title: 'Compress Video',
            description: 'Free online WEBM and MP4 video compressor',
            type: URLType.REMOTE,
        },
        WORDTOPDF_DEV: {
            key: 'WORDTOPDF_DEV',
            url: 'https://www.wordtopdf.dev',
            title: 'Word To PDF',
            description: 'Convert Word/DOCX/DOC files to PDF documents for free',
            type: URLType.REMOTE,
        },
        XCONVERT_COM: {
            key: 'XCONVERT_COM',
            url: 'https://www.xconvert.com',
            title: 'File Tools',
            description: 'Online File Convert/Merge/Split and Compress Tool',
            type: URLType.REMOTE,
        },
    };

};

