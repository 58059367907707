import { Component } from 'react';

import style from './tabs.component.module.scss';

export interface TabHeaderProps {
    children?: any;
    headerFor: string;
    type: string;
    active?: boolean; // Only set by the TabsComponent
}

export class TabHeader extends Component<TabHeaderProps> {
    render() {
        return (
            <div className={`${style['tab-header']}`}>
                {
                    this.props.children
                }
            </div>
        )
    }
}


export interface TabBodyProps {
    children?: any;
    name: string;
    type: string;
}

export class TabBody extends Component<TabBodyProps> {
    render() {
        return (
            <div className={style['tab-body']}>
                {
                    this.props.children
                }
            </div>
        )
    }
}

export interface TabsComponentProps {
    children?: any;
    activeTabName?: string;
    onTabChange?: (tabName: string) => void;
}

export interface TabsComponentState {
    activeTabName?: string;
}

export class TabsComponent extends Component<TabsComponentProps, TabsComponentState> {
    readonly state: Readonly<TabsComponentState> = {
        activeTabName: this.props.activeTabName,
    };
    constructor(props) {
        super(props);
    }

    getHeaders() {
        if (Array.isArray(this.props.children)) {
            const headers = this.props.children.filter((child: any) => child && child.props && child.props['type'] === 'header');
            return headers;
        }
        return [];
    }

    getBodies() {
        if (Array.isArray(this.props.children)) {
            const bodies = this.props.children.filter((child: any) => child && child.props && child.props['type'] === 'body');
            return bodies;
        }
        return [];
    }

    tabClicked(headerFor: string) {
        if (this.state.activeTabName !== headerFor) {
            this.setState({
                activeTabName: headerFor,
            });
            setTimeout(() => {
                if (this.props.onTabChange) {
                    this.props.onTabChange(this.state.activeTabName);
                }
            }, 1);
        }
    }

    render() {
        return (
            <div className={style['tab-container']}>
                <div className={style['tab-headers']}>
                    {
                        this.getHeaders().map((item: any, index) => {
                            return <div key={index} onClick={e => this.tabClicked(item.props.headerFor)}
                                className={`${style['tab-header-container']} ${this.state.activeTabName === item.props.headerFor ? style.active : ''}`}>
                                {item}
                            </div>;
                        })
                    }
                </div>
                <div className={style['tab-bodies']}>
                    {
                        this.getBodies().map((item: any, index) => {
                            return <div key={index}
                                className={`${style['tab-body-container']} ${this.state.activeTabName === item.props.name ? style.active : ''}`}>
                                {item}
                            </div>;
                        })
                    }
                </div>
            </div>
        )
    }
}