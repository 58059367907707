import { inject, injectable } from "inversify";
import { BrowserUtils } from '../ui-utils/browser-utils';
import { HTMLUtils } from "../utils/html.utils";
import { ApiConfig } from "./api-config";
import { HttpClient } from "./http-client";
import { WebSocketService } from './websocket.service';

class TraceEvent {
    message: string;
    data: any;
    userTraceTime: string;
    userTraceOffsetSeconds: string;
    userTraceEventId: string;
    wsId: string;
    pageUrl: string;

    static newTraceEvent(message: string, data: any): TraceEvent {
        const wtrace = BrowserUtils.hasWindow() && (window as any).getUserTrace()
        const te = new TraceEvent();
        te.message = message;
        te.data = data;
        te.userTraceTime = wtrace && wtrace.userTraceTime;
        te.userTraceOffsetSeconds = wtrace && wtrace.userTraceOffsetSeconds;
        te.userTraceEventId = wtrace && wtrace.userTraceEventId;
        te.pageUrl = BrowserUtils.hasWindow() && (window as Window).location.href
        return te;
    }
}

@injectable()
export class UserTraceService {

    constructor(
        @inject(ApiConfig) private apiConfig: ApiConfig,
        @inject(HttpClient) private http: HttpClient,
        @inject(WebSocketService) private webSocketService: WebSocketService,
    ) {
        BrowserUtils.runIfWindowExists(() => {
            (window as any).emitClickEvent = (e: MouseEvent) => {
                try {
                    const element = e.target as HTMLElement;
                    const xpath = HTMLUtils.getPathTo((element));
                    this.trace("Clicked on element: " + xpath, { classList: element.classList.toString(), id: element.id, text: element.textContent })
                } catch (e) {
                    console.log(e)
                }
            }
        })
    }

    trace(message: string, data?: any) {
        try {
            // This was commented out to improve INP
            // const traceEvent = TraceEvent.newTraceEvent(message, data);
            // traceEvent.wsId = this.webSocketService.getId()
            // traceEvent.data = JSONUtils.safeStringify(data)
            // this.http.post(
            //     this.apiConfig.getUserTraceEndpoint(),
            //     traceEvent
            // );
        } catch (e) {
            // no-op
            console.error(e)
        }
    }
}