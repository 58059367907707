import { inject, injectable } from 'inversify';
import { retry } from 'rxjs/operators';

import { firstValueFrom } from 'rxjs';
import { FileUploadRequestMetadata } from '../models/file-upload-request-metadata';
import { GoogleDriveFile } from '../models/google-drive-file';
import { Logger } from '../models/logger';
import { GAContants } from '../types/ga.types';
import { OperationType, OperationTypes, ProcessorMetadata } from '../types/types';
import { BrowserUtils } from '../ui-utils/browser-utils';
import { FilenameUtils } from '../utils/filename-utils';
import { NetworkError, RequestUtils } from '../utils/request.utils';
import { UrlUtils } from '../utils/url.utils';
import { GAService } from './analytics.service';
import { ApiConfig } from './api-config';
import { ConnectivityService } from './connectivity.service';
import { EnvironmentService } from './environment.service';
import { LightHttp } from './light-http.service';
import { WebSocketService } from './websocket.service';


@injectable()
export class ProcessorResolverService {
    private readonly logger = Logger.getLogger('ProcessorResolverService');

    constructor(
        @inject(LightHttp) private http: LightHttp,
        @inject(GAService) private gaService: GAService,
        @inject(WebSocketService) private wsService: WebSocketService,
        @inject(EnvironmentService) private env: EnvironmentService,
        @inject(ApiConfig) private apiConfig: ApiConfig,
        @inject(ConnectivityService) private connectivityService: ConnectivityService,
    ) {
    }

    private getDomainToBeUsed() {
        try {
            let useDomain = null;
            let useDomainValidator = null;
            if (BrowserUtils.hasWindow()) {
                useDomain = UrlUtils.getParameterByName(window.location.href, 'useDomain') || window['useDomain'] || null;
                useDomainValidator = UrlUtils.getParameterByName(window.location.href, 'useDomainValidator') || window['useDomainValidator'] || null;
            }
            if (!useDomain) {
                return {};
            }
            return {
                useDomain,
                useDomainValidator,
            };
        } catch (e) {
            return {};
        }
    }

    async generateFileUploadRequestMetadata(
        file: File | GoogleDriveFile, fromType: string[], toType: string, operationType: OperationType,
    ): Promise<FileUploadRequestMetadata> {
        let test = 'false';
        let useGCS = 'false';
        if (BrowserUtils.hasWindow()) {
            test = UrlUtils.getParameterByName(window.location.href, 'test') || 'false';
            useGCS = UrlUtils.getParameterByName(window.location.href, 'useGCS') || 'false';
        }
        const { useDomain, useDomainValidator } = this.getDomainToBeUsed();

        this.gaService.send(
            GAContants.HitType.EVENT,
            GAContants.EventCategory['NETWORK'],
            GAContants.EventAction['FILE-PROCESSOR-RESOLVE-START'],
            GAContants.EventLabel.CONVERT,
            `${file.name}-${file.size}`,
        );
        try {
            const options = {
                params: {
                    from: fromType,
                    to: toType,
                    operation: operationType,
                    contentNames: file.name,
                    contentTypes: FilenameUtils.getFileMimeType(file),
                    contentSizes: file.size + '',
                    test,
                    useGCS,
                    wsId: this.wsService.getId(),
                    wsDomain: this.wsService.getDomain(),
                    requireActiveDownload: (file instanceof File || file instanceof Blob) ? 'false' : 'true',
                    ...(useDomain ? { useDomain, useDomainValidator } : null),
                    ...(operationType === OperationTypes.UPLOAD ? { preferedDomains: this.connectivityService.getEdgesRandomized(4).map(edge => edge.domain) } : {}),
                },
            };

            // this.http.get<ProcessorMetadata[]>(`${this.apiConfig.getUploadAndProcessEndpointResolveEndpoint()}`, options)
            //         .pipe(
            //             retry(10),
            //         ).subscribe({
            //             error: (e) => {
            //                 console.log(e)
            //             }
            //         })

            let request = RequestUtils.getSuccessValueOrThrow$<ProcessorMetadata[]>(
                [
                    this.http.get<ProcessorMetadata[]>(`${this.apiConfig.getUploadAndProcessEndpointResolveEndpoint()}`, options)
                   
                ],
                15000
            ) .pipe(
                retry(10),
            );
            const data: ProcessorMetadata[] = await firstValueFrom(request);
            this.gaService.send(
                GAContants.HitType.EVENT,
                GAContants.EventCategory['NETWORK'],
                GAContants.EventAction['FILE-PROCESSOR-RESOLVE-END'],
                GAContants.EventLabel.CONVERT,
                `${file.name}-${file.size}`,
            );
            return new FileUploadRequestMetadata(data, file);
        } catch (e) {
            this.gaService.send(
                GAContants.HitType.EVENT,
                GAContants.EventCategory['NETWORK'],
                GAContants.EventAction['FILE-PROCESSOR-RESOLVE-FAILED'],
                GAContants.EventLabel.CONVERT,
                `${file.name}-${file.size}`,
            );
            console.error(e);
            const errorSuffix = ' Error was thrown while trying to resolve edge servers.';
            if (e instanceof NetworkError) {
                throw new NetworkError(e.message + errorSuffix);
            } else {
                throw new Error(e.message + errorSuffix);
            }
        }
    }

    async generateFileUploadRequestMetadataForGenericDataProcessing(): Promise<FileUploadRequestMetadata> {
        let test = 'false';
        let useGCS = 'false';
        if (BrowserUtils.hasWindow()) {
            test = UrlUtils.getParameterByName(window.location.href, 'test') || 'false';
            useGCS = UrlUtils.getParameterByName(window.location.href, 'useGCS') || 'false';
        }
        this.gaService.send(
            GAContants.HitType.EVENT,
            GAContants.EventCategory['NETWORK'],
            GAContants.EventAction['FILE-PROCESSOR-RESOLVE-START'],
            GAContants.EventLabel.CONVERT,
            `generic-generic`,
        );
        try {
            const options = {
                params: {
                    from: 'generic',
                    to: 'generic',
                    contentNames: 'generic',
                    contentTypes: 'generic',
                    contentSizes: '0',
                    test,
                    useGCS,
                    wsId: this.wsService.getId(),
                    wsDomain: this.wsService.getDomain(),
                    requireActiveDownload: 'false',
                },
            };

            const data: ProcessorMetadata[] = await RequestUtils.getSuccessValueOrThrow<ProcessorMetadata[]>(
                this.http.get<ProcessorMetadata[]>(`${this.apiConfig.getUploadAndProcessEndpointResolveEndpoint()}`, options),
            );

            this.logger.trace1({
                message: 'ProcessMetadata',
                data: JSON.stringify(data),
            });

            this.gaService.send(
                GAContants.HitType.EVENT,
                GAContants.EventCategory['NETWORK'],
                GAContants.EventAction['FILE-PROCESSOR-RESOLVE-END'],
                GAContants.EventLabel.CONVERT,
                `generic-generic`,
            );
            return new FileUploadRequestMetadata(data, null);
        } catch (e) {
            this.logger.error({
                message: e.message || 'Error getting process metadata',
                error: e,
            });
            this.gaService.send(
                GAContants.HitType.EVENT,
                GAContants.EventCategory['NETWORK'],
                GAContants.EventAction['FILE-PROCESSOR-RESOLVE-FAILED'],
                GAContants.EventLabel.CONVERT,
                `generic-generic`,
            );
        }
    }
}
