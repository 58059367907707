import { plainToClass } from 'class-transformer';
import { inject, injectable } from 'inversify';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Logger } from '../models/logger';
import { RouteProps } from '../types/route-types';
import { RequestUtils, UserSettings } from '../utils/request.utils';
import { ApiConfig } from './api-config';
import { LightHttp } from './light-http.service';

@injectable()
export class SettingsService {
    private readonly logger = Logger.getLogger('SettingsService');

    private _motivesEnabled = true;
    private _userSettings = new ReplaySubject<UserSettings>(1);
    get userSettings(): Observable<UserSettings> {
        return this._userSettings.asObservable();
    }

    readonly userToggleShowMotives = new ReplaySubject<boolean>(1);

    constructor(
        @inject(ApiConfig) private apiConfig: ApiConfig,
        @inject(LightHttp) private http: LightHttp,
    ) {
    }

    initializeFromProps(props: RouteProps) {
        if (!props.settings) {
            // throw new Error('Requested to init settings service with null settings. Settings cannot be null.');
            // React migrate
            props.settings = new UserSettings();
        }
        const userSettings = plainToClass(UserSettings, props.settings);
        this._userSettings.next(userSettings);
    }

    public initialize(): Observable<UserSettings[]> {
        return RequestUtils.getSuccessValuesOrThrow$(
            [this.http.get<UserSettings>(this.apiConfig.getUserSettingsEndpoint())]
        ).pipe(tap({ 
            next: settings => {
                const userSettings = plainToClass(UserSettings, settings[0]);
                this._userSettings.next(userSettings);
                // We complete the observable here since this userSettings is most up to date from the server
                this._userSettings.complete();
            },
            error: err => {
                this.logger.error({ message: 'Failed to get user settings.', error: err });
                this._userSettings.error(err);
            },
        }));
    }

    get motivesEnabled() {
        return this._motivesEnabled;
    }

    set motivesEnabled(_motivesEnabled: boolean) {
        this._motivesEnabled = _motivesEnabled;
        this.userToggleShowMotives.next(this._motivesEnabled);
    }

    get onUserToggleShowMotives() {
        return this.userToggleShowMotives.asObservable();
    }

}
