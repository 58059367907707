import { PSFileTypes } from '../types/types';

export interface GoogleDriveSelectedFile {
    token: string;
    id: string;
    mimeType: string;
    name: string;
    sizeBytes: number;
    type: string;
}

export class GoogleDriveFile {
    id: string;
    name: string;
    token: string;
    lastModified: number;
    storageType = PSFileTypes.GOOGLE_DRIVE_FILE;
    type;
    size: number;

    constructor(token: string, id: string, name: string, mimeType: string, size: number) {
        this.id = id;
        this.name = name;
        this.token = token;
        this.type = mimeType;
        this.size = size;
        this.lastModified = Date.now();
    }

    static createFromFileList(fileList: GoogleDriveSelectedFile[]) {
        return fileList.map(file => {
            return new GoogleDriveFile(file.token, file.id, file.name, file.mimeType, file.sizeBytes);
        });
    }
}
