import { inject, injectable } from 'inversify';
import { firstValueFrom } from 'rxjs';
import { LogEntry, LogServiceProvider, Logger } from '../models/logger';
import { LogLevelTypes } from '../types/log.types';
import { BrowserUtils } from '../ui-utils/browser-utils';
import { PromiseUtils } from '../utils/promise.utils';
import { ApiConfig } from './api-config';
import { ConnectivityService } from './connectivity.service';
import { EnvironmentService } from './environment.service';
import { LightHttp } from './light-http.service';
import { LoginService } from './login.service';
import { WebSocketService } from './websocket.service';


export const CONSOLE_STYLE_TRACE = 'background-color: #8c8c8c; color: white';
export const CONSOLE_STYLE_INFO = 'background-color: #2196F3; color: white';
export const CONSOLE_STYLE_WARN = 'background-color: #d8a91e; color: white';
export const CONSOLE_STYLE_ERROR = 'background-color: #F44336; color: white';

export const CONSOLE_STYLE_SUCCESS = 'background-color: #4CAF50; color: white';

declare var window;

export class FileUploadMapProvider {
    getFileUploadMap: () => any;
}

@injectable()
export class LogService implements LogServiceProvider {
    private url: string;
    private wsId = 'undefined';

    constructor(
        @inject(LightHttp) private http: LightHttp,
        @inject(WebSocketService) private websocketService: WebSocketService,
        @inject(ApiConfig) private apiConfig: ApiConfig,
        @inject(LoginService) private loginService: LoginService,
        @inject(EnvironmentService) private envService: EnvironmentService,
        @inject(ConnectivityService) private connectivityService: ConnectivityService,
    ) {

    }

    public initialize() {
        this.setUp();
        this.loginService.onSessionDetails.subscribe({
            next: (sessionDetails) => {
                Logger.setSessionDetails(sessionDetails);
                this.wsId = this.websocketService.getOrCreateStoredWSId();
                Logger.setLoggerService(this);
                Logger.setWSId(this.wsId);
            }
        });
    }

    public getBrowserInfo() {
        return BrowserUtils.getBrowserInfo();
    }

    private setUp() {
        if (!BrowserUtils.hasWindow()) {
            return;
        }

        this.url = this.apiConfig.getHistoryTrackEndpoint();
        try {
            if (window.location.host.startsWith('localhost') || this.envService.env.dev) {
                this.url = this.url.replace(/https:\/\/.*?\//, '/localhost');
            }
        } catch {
            // not needed
        }
    }

    public log(logEntry: LogEntry) {
        PromiseUtils.run(async () => {
            const shouldLog = BrowserUtils.hasWindow() && LogLevelTypes.isLogTypeEnabled(LogLevelTypes.logReadableStringToLevel(logEntry.level));
            if (shouldLog) {
                try {
                    return firstValueFrom(this.http.post(this.url, logEntry));
                } catch (e) {
                    // ignore
                }
                // return this.windowsService.userEngaged.pipe(
                //     mergeMap(() => {
                //     })
                // ).toPromise();
            }
            return Promise.resolve();
        }, true).catch(console.error);
    }
}
