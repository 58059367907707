import { inject, injectable } from 'inversify';

import { PageData } from '../types/route-types';
import { BrowserUtils } from '../ui-utils/browser-utils';
import { get } from '../utils/lodash-min';
import { EnvironmentService } from './environment.service';

export interface HeaderElementUpdateRequest {
    elementType: 'title' | 'link' | 'meta';
    // Selector values should be included in attibutes list as if we create a new element, we should add selector as well
    // Since the reason for failing the get element by selector is that whatever the attr on selector was not on the element on DOM
    elementSelector: string;
    elementAttributes: {
        [key: string]: string;
    };
    innerText?: string;
}

export type HeaderElementUpdateRequests = HeaderElementUpdateRequest[];

@injectable()
export class MetadataService {

    constructor(
        @inject(EnvironmentService) private envService: EnvironmentService,
    ) { }

    // Meta data is managed by the prefender and the template itself
    private setMetaTags(value: PageData) {
        if (!value) {
            console.error(new Error('Null value was passed to metadata'))
            return;
        }
        const requests: HeaderElementUpdateRequests = [
            {
                elementType: 'link',
                elementSelector: 'link[rel="canonical"]',
                elementAttributes: {
                    rel: 'canonical',
                    href: `https://www.${this.envService.getDomainName()}/${value.canonical || value.path}`,
                },
            },
            {
                elementSelector: 'title',
                elementType: 'title',
                elementAttributes: {

                },
                innerText: value.title
            },
            {
                elementType: 'meta',
                elementSelector: 'meta[name="description"]',
                elementAttributes: {
                    name: 'description',
                    content: value.description,
                },
            },
            {
                elementType: 'meta',
                elementSelector: 'meta[property="og:title"]',
                elementAttributes: { property: 'og:title', content: value.title },
            },
            {
                elementType: 'meta',
                elementSelector: 'meta[property="og:type"]',
                elementAttributes: { property: 'og:type', content: 'website' },
            },
            // TODO Preact this should only set on XConvert.com
            {
                elementType: 'meta',
                elementSelector: 'meta[property="og:url"]',
                elementAttributes: { property: 'og:url', content: `https://www.${this.envService.getSiteName()}.com/` + value.path },
            },
            // TODO Preact this should only set on XConvert.com
            {
                elementType: 'meta',
                elementSelector: 'meta[property="og:image"]',
                elementAttributes: {
                    property: 'og:image',
                    content: "https://www.xconvert.com/img/xconvert/og_image.jpg", // get(value, 'page_seo.image') || `https://www.${this.envService.getSiteName()}.com/static/im/sm/default.png`,
                },
            },
            {
                elementType: 'meta',
                elementSelector: 'meta[property="og:site_name"]',
                elementAttributes: { property: 'og:site_name', content: `https://www.${this.envService.getSiteName()}.com` },
            },
            {
                elementType: 'meta',
                elementSelector: 'meta[property="og:description"]',
                elementAttributes: { property: 'og:description', content: value.description, },
            },
            {
                elementType: 'meta',
                elementSelector: 'meta[name="twitter:card"]',
                elementAttributes: { name: 'twitter:card', content: 'summary_large_image' },
            },
            {
                elementType: 'meta',
                elementSelector: 'meta[name="twitter:site"]',
                elementAttributes: { name: 'twitter:site', content: `@${this.envService.getSiteName()}_io` },
            },
            {
                elementType: 'meta',
                elementSelector: 'meta[name="twitter:creator"]',
                elementAttributes: { name: 'twitter:creator', content: `@${this.envService.getSiteName()}_io` },
            },
            {
                elementType: 'meta',
                elementSelector: 'meta[name="twitter:title"]',
                elementAttributes: { name: 'twitter:title', content: value.title },
            },
            {
                elementType: 'meta',
                elementSelector: 'meta[name="twitter:description"]',
                elementAttributes: { name: 'twitter:description', content: value.description, },
            },
            // TODO Preact this should only set on XConvert.com
            {
                elementType: 'meta',
                elementSelector: 'meta[name="twitter:image:src"]',
                elementAttributes: { name: 'twitter:image:src', content: get(value, 'page_seo.image') || `https://www.${this.envService.getSiteName()}.com/static/im/sm/default.png`, },
            },
        ];
        for (const request of requests) {
            this.setHeaderElementProperty(request);
        }
    }

    private setHeaderElementProperty(request: HeaderElementUpdateRequest) {
        if (!BrowserUtils.hasDocument()) {
            console.error("Requested execution aborted since document was not available");
            return;
        }
        const element: HTMLElement = this.getOrCreateHeaderElement(request.elementSelector, request.elementType);
        for (const attributeKey of Object.keys(request.elementAttributes)) {
            element.setAttribute(attributeKey, (request.elementAttributes[attributeKey] || `${attributeKey} not set`).replace(/\s+/g, ' '));
        }
        if (request.innerText) {
            element.innerText = request.innerText;
        }
    }

    private getOrCreateHeaderElement(selector, elementType) {
        let element = document.querySelector(selector);
        if (!element) {
            const head = document.querySelector('head');
            element = document.createElement(elementType);
            head.appendChild(element);
        }
        return element;
    }
}
