import { inject, injectable } from 'inversify';
import { ReplaySubject } from 'rxjs';

import { BrowserUtils } from '../ui-utils/browser-utils';
import { StorageKeyPrefixeTypes, StorageService } from './storage.service';


export const OFFLINE_FILE_PROCESS_STATUS_STORAGE_KEY = 'OFFLINE_FILE_PROCESS_STATUS_STORAGE_KEY';

export interface OfflineTaskCapabilities {
    offlineModeOn: boolean;
    canGoOfflineMode: boolean;
}

@injectable()
export class OfflineTaskService {
    private offlineModeOn = false;
    private canGoOfflineMode = false;
    public readonly changed = new ReplaySubject<OfflineTaskCapabilities>(1);

    constructor(
        @inject(StorageService) private storageService: StorageService,
    ) {
        // if (isPlatformBrowser(this.platformId)) {
        // clear stored offline mode
        if (BrowserUtils.hasWindow()) {
            const storedOfflineModeState = false;
            this.storageService.store(StorageKeyPrefixeTypes.USER_SETTING_KEY_PREFIX, OFFLINE_FILE_PROCESS_STATUS_STORAGE_KEY, storedOfflineModeState);
            this.setOfflineMode(storedOfflineModeState);
        }
        // }
    }

    getOfflineMode(): boolean {
        return this.offlineModeOn;
    }

    getCanGoOfflineMode(): boolean {
        return this.canGoOfflineMode;
    }

    setOfflineMode(turnOnOfflineMode: boolean) {
        this.offlineModeOn = !!turnOnOfflineMode;
        this.storageService.store(StorageKeyPrefixeTypes.USER_SETTING_KEY_PREFIX, OFFLINE_FILE_PROCESS_STATUS_STORAGE_KEY, this.offlineModeOn);
        this.changed.next({
            offlineModeOn: this.offlineModeOn,
            canGoOfflineMode: this.canGoOfflineMode,
        });
    }

    setCanGoOfflineMode(canGoOfflineMode: boolean) {
        this.canGoOfflineMode = !!canGoOfflineMode;
        this.storageService.store(StorageKeyPrefixeTypes.USER_SETTING_KEY_PREFIX, OFFLINE_FILE_PROCESS_STATUS_STORAGE_KEY, this.canGoOfflineMode);
        if (!canGoOfflineMode) {
            this.setOfflineMode(false);
        }
        this.changed.next({
            offlineModeOn: this.offlineModeOn,
            canGoOfflineMode: this.canGoOfflineMode,
        });
    }
}
