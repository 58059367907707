import { BrowserUtils } from "../ui-utils/browser-utils";

export class UrlUtils {
    public static getUrlParameter(name): string {
        if (!BrowserUtils.hasWindow()) {
            return null;
        }
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        let results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    public static getParameterByName(url, name): string {
        if (!BrowserUtils.hasWindow()) {
            return null;
        }
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }


    public static getUrlPathnameWithoutSlash(location: Location): string {
        if (!BrowserUtils.hasWindow()) {
            return null;
        }
        const pathname = location.pathname.split('-')[0];
        return pathname.startsWith('/') ? pathname.substring(1) : pathname;
    }

    public static getFilenameFromURL(url: string) {
        try {
            const filename = decodeURIComponent(new URL(url).pathname.split('/').pop());
            return filename;
        } catch (e) {
            console.log(url);
            console.log(e);
        }
    }

    public static isURLHasSameFileName(url1: string, url2: string) {
        return UrlUtils.getFilenameFromURL(url1) === UrlUtils.getFilenameFromURL(url2);
    }

    public static toHttpsProtocol(url: string){
        return "https://" + url.replace(/^.*?:\/\//ig, "");
    }
}
