import { inject, injectable } from 'inversify';
import { Observable, ReplaySubject, Subscription, firstValueFrom, interval, of } from 'rxjs';
import { catchError, delay, retryWhen, take, timeout } from 'rxjs/operators';
// import SockJS from 'sockjs-client';
// import { Client, Stomp } from '@stomp/stompjs';

import { Logger } from '../models/logger';
import { WebsocketDomainResponse, WebsocketResponse } from '../types/types';
import { has, shuffle } from '../utils/lodash-min';
import { RequestUtils } from '../utils/request.utils';
import { RxUtils } from '../utils/rx-utils';
import { StringUtils } from '../utils/string-utils';
import { ApiConfig } from './api-config';
import { LightHttp } from './light-http.service';
import { OfflineTaskService } from './offline-task.service';
import { SettingsService } from './settings.service';
import { SocketJSService } from './socketjs.service';
import { StorageKeyPrefixeTypes, StorageService } from './storage.service';



const DEFAULT_WS_DOMAINS = shuffle([
    'https://ws-2.xconvert.com', 'https://ws-5.xconvert.com', 'https://ws-6.xconvert.com']);

/** Websocket service does not have a single point of failure.
 *
 *  1. User session will only have 1 WS connection.
 *  2. Nchan domain provided by Nginx monitoring Tomcat
 *  3. Websocket domain selected out of 3 domain by contacting Tomcat to get a domain of an online Nchan running Nginx server
 *  4. Websocket domain is pass to the backend server when
 *      a. uploading a file
 *      b. processing a file though FormData
 *  5. Websocket server domain is stored on the Tomcat session
 */
const WS_ID_STORAGE_KEY = 'WS_ID';
const WS_DOMAIN_STORAGE_KEY = 'WS_DOMAIN';
@injectable()
export class WebSocketService {
    private readonly logger = Logger.getLogger('WebSocketService');
    // private sub: NchanSubscriber;
    private wsId: string;

    uid = StringUtils.getRandomString();

    private useWebsockets = true;

    private localhostWebsoket: WebSocket;
    private heartbeatSubscription: Subscription;

    private wsMessageSubject = new ReplaySubject<WebsocketResponse>(10);

    private wsDomain: string;
    private wsEndpoint: string;

    private opt = {
        subscriber: 'websocket',
        reconnect: 'persist',
    };

    private connectedWebSocketInfo: {
        status: boolean;
        socket: WebSocket
    } = {
            status: false,
            socket: null,
        };
    private callback: ((resp) => void)[] = [];
    private ready = false;

    private executed = false;

    private _status = new ReplaySubject<{ connected: boolean }>(1);
    public get status() {
        return this._status.asObservable();
    }

    constructor(
        @inject(ApiConfig) private apiConfig: ApiConfig,
        @inject(LightHttp) private http: LightHttp,
        @inject(SettingsService) private settingsService: SettingsService,
        @inject(StorageService) private storageService: StorageService,
        @inject(OfflineTaskService) private offlineTaskService: OfflineTaskService,
        @inject(SocketJSService) private socketJSService: SocketJSService,
    ) {
        this.wsId = this.getOrCreateStoredWSId();
        LightHttp.registerWSDetailProvider(this);
    }

    public async initWS() {
        // var socket = new SockJS(`http://localhost:8080/api/notifications/chat/${this.wsId}`);
        // var stompClient = Stomp.over(socket);
        // stompClient.connect({}, (frame) => {
        //     this.setConnected(true);
        //     console.log('Connected: ' + frame);
        //     stompClient.subscribe('/topic/messages', (messageOutput) => {
        //         this.showMessageOutput(JSON.parse(messageOutput.body));
        //     });
        // });
    }

    setConnected(a) {

    }

    showMessageOutput(a) {
        console.log(a);
    }

    public async reinitialize(wsDomains: string[] = []) {
        const mode = this.getMode();
        this.executed = false;
        // if (mode === 'offline' && this.sub) {
        //     try {
        //         this.sub.stop();
        //     } catch (e) {
        //         console.error('Failed to properly stop the remote WS. Continue to start the local WS.');
        //     }
        // } else 
        if (mode !== 'offline' && this.localhostWebsoket) {
            try {
                this.localhostWebsoket.close();
            } catch (e) {
                console.error('Failed to properly stop the local WS. Continue to start the remote WS.');
            }
        }

        this.settingsService.userSettings.subscribe(
            (userSettings) => {
                if (has(userSettings, 'featureFlags.useWebsockets')) {
                    this.useWebsockets = userSettings.featureFlags.useWebsockets;
                }
            }
        );
        if (!this.executed) {
            this.logger.trace1({ message: 'Loading WS...' });
            if (mode === 'offline') {
                await this.connectWSForOffline();
            } else {
                await this.initializeWSEndpointOutsideOfNgZone();
            }
            this.executed = true;
        }
    }

    private startSubscription() {
        // if (isPlatformBrowser(this.platformId)) {
        // try {
        //     if (!this.sub || !this.sub.connected) {
        //         this.sub.start();
        //     }
        // } catch (e) {
        //     console.error(e);
        // }
        // }
    }

    private async connectWSForOffline() {
        return new Promise<void>((resolve, reject) => {
            this.stopAndStartHeartbeat();
            const wsEndpoint = `ws://${ApiConfig.localhostDomainName}:9080/file/converter/ws`;
            this.updateWSDomain(`ws://${ApiConfig.localhostDomainName}:9080`);
            this.localhostWebsoket = new WebSocket(wsEndpoint);
            this.connectedWebSocketInfo.socket = this.localhostWebsoket;
            this.localhostWebsoket.onopen = (evt) => {
                this.onConnect(evt);
                resolve();
            };
            this.localhostWebsoket.onclose = (evt) => { this.onDisconnect(evt); };
            this.localhostWebsoket.onmessage = (evt) => { this.onMessage(evt.data); };
            this.localhostWebsoket.onerror = (evt) => {
                this.onError(evt);
                reject();
            };
        });
    }

    private async initializeWSEndpointOutsideOfNgZone() {
        let wsReadyDomainDetails: WebsocketDomainResponse;
        // if (isPlatformBrowser(this.platformId)) {
        if (!this.wsId) {
            this.wsId = this.getOrCreateStoredWSId();
        }

        // Try getting WS Domain
        const storedDomain = this.getStoredWSDomain();
        if (storedDomain && DEFAULT_WS_DOMAINS.find(domain => domain.match(storedDomain))) {
            const wsServerStatus$ = this.http.get(storedDomain + '/status', { responseType: 'text' }).pipe(
                timeout(5000),
                catchError(e => {
                    // do something on a timeout
                    console.error(e);
                    return of(null);
                }),
                retryWhen(errors => errors.pipe(delay(1000), take(2))),
            );
            const status = await firstValueFrom(wsServerStatus$);
            if (status === true || status === 'true') {
                wsReadyDomainDetails = { data: storedDomain, status: true };
                this.updateAndStoreWSEndpointData(wsReadyDomainDetails);
                return;
            }
        }

        // No stored WS Domain, try getting a one
        const wsEndpoint = this.apiConfig.getWebSocketEndpoint();
        try {
            const requestObs$ = this.http.get<WebsocketDomainResponse>(wsEndpoint);
            const request$ = RequestUtils.getSuccessValuesOrThrowSequential$([requestObs$]);
            wsReadyDomainDetails = await firstValueFrom(request$);
        } catch (err) {
            console.error(err);
            this.logger.error({ message: 'Failed to get WS supported servers.', error: err });
        }

        if (wsReadyDomainDetails && wsReadyDomainDetails.data && wsReadyDomainDetails.status) {
            this.updateAndStoreWSEndpointData(wsReadyDomainDetails);
            return;
        }

        // Nothing worked, fallback to default domain
        wsReadyDomainDetails = { data: DEFAULT_WS_DOMAINS[0], status: true };
        this.logger.error({ message: 'There was no WS domain.' });

        this.updateAndStoreWSEndpointData(wsReadyDomainDetails);
        return;
    }

    private updateAndStoreWSEndpointData(wsReadyDomainDetails: WebsocketDomainResponse) {
        this.logger.trace1({ message: `WS endpoint: ${this.wsEndpoint}` });
        this.wsEndpoint = `${wsReadyDomainDetails.data}/smq/${this.wsId}`;
        this.updateWSDomain(wsReadyDomainDetails.data);
        this.storeStoredWSDomain(wsReadyDomainDetails.data);
    }

    initialize() {
        const mode = this.getMode();
        // this.sub = new NchanSubscriber(this.wsEndpoint, this.opt);
        this.socketJSService.connect(this.wsDomain, this.wsId, (message) => { this.onMessage(message) });
        // window['ws'] = this.sub;
        // this.sub.on('transportNativeCreated', (nativeTransportObject, subscriberName) => {
        //     this.connectedWebSocketInfo.socket = nativeTransportObject;
        // });
        // this.sub.on('message', (message, messageMetadata) => {
        //     this.onMessage(message, messageMetadata);
        // });
        // this.sub.on('connect', (evt) => {
        //     this.onConnect(evt);
        // });
        // this.sub.on('disconnect', (evt) => {
        //     this.onDisconnect(evt);
        // });
        // this.sub.on('error', (errorData, errorDescription) => {
        //     this.onError(errorData, errorDescription);
        // });

        if (mode !== 'offline') {
            this.startSubscription();
        }
    }

    private updateWSDomain(domain) {
        this.wsDomain = domain;
    }

    public getWebsocketMessageObserver(): Observable<WebsocketResponse> {
        return this.wsMessageSubject.asObservable();
    }

    private onMessage(message, messageMetadata?) {
        const jmsg = typeof message === 'object' ? message : JSON.parse(message);
        this._callback(jmsg);
        try {
            // this.recoveryService.applyHistory(null, jmsg);
            this.wsMessageSubject.next(jmsg);
        } catch (e) {
            this.logger.error(e);
        }
    }

    private onError(errorData, errorDescription?) {
        this.logger.error({ message: 'WS error. msg ' + this.wsId + " " + errorDescription, error: errorData });
        this.ready = true;
        this._status.next({ connected: false });
    }

    private onDisconnect(evt) {
        this.logger.trace1({ message: 'WS Disconnected. msg ' + this.wsId });
        this.ready = true;
        this._status.next({ connected: false });
    }

    private onConnect(evt) {
        this.logger.trace1({ message: 'WS Connected. msg ' + this.wsId });
        this.ready = true;
        this._status.next({ connected: true });
    }




    /**
     * Used by templates
     */
    public isServiceReady(): boolean {
        return this.ready;
    }

    /**
     * Used by templates
     */
    public isConntected(): boolean {
        try {
            if (this.connectedWebSocketInfo.socket) {
                return this.connectedWebSocketInfo.socket.readyState === WebSocket.OPEN;
            }
        } catch (e) {
            console.error(e);
        }
        return false;
    }

    private stopHeartbeat() {
        RxUtils.safeCloseSubscriber(this.heartbeatSubscription);
    }

    private stopAndStartHeartbeat() {
        this.stopHeartbeat();
        this.heartbeatSubscription = interval(1000).subscribe(
            () => {
                try {
                    if (this.localhostWebsoket && this.localhostWebsoket.readyState === this.localhostWebsoket.OPEN) {
                        this.localhostWebsoket.send(JSON.stringify({ heart: 'beat' }));
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            () => {
                console.error('Failed to start heartbeat');
            },
        );
    }

    private _callback(resp) {
        return this.callback && this.callback.forEach(callback => callback(resp));
    }

    public getId() {
        return this.wsId;
    }

    public getDomain() {
        return this.wsDomain;
    }

    public registerCallback(callback) {
        this.callback.push(callback);
    }

    public unregisterCallback(callback) {
        const callbackIndex = this.callback.indexOf(callback);
        if (callbackIndex !== -1) {
            this.callback.splice(callbackIndex, 1);
        }
    }

    public getOrCreateStoredWSId() {
        let storedWSId = this.storageService.get<string>(
            StorageKeyPrefixeTypes.USER_SETTING_KEY_PREFIX, WS_ID_STORAGE_KEY);
        if (!storedWSId) {
            storedWSId = StringUtils.getRandomString('w');
            this.storageService.store(StorageKeyPrefixeTypes.USER_SETTING_KEY_PREFIX, WS_ID_STORAGE_KEY, storedWSId);
        }
        return storedWSId;
    }

    public getStoredWSDomain(): string {
        const wsDomainStorageKey = WS_DOMAIN_STORAGE_KEY;
        const userSettingsKeyPrefix = StorageKeyPrefixeTypes.USER_SETTING_KEY_PREFIX;
        const storedWSDomain = this.storageService.get<string>(userSettingsKeyPrefix, wsDomainStorageKey);
        return storedWSDomain;
    }

    private storeStoredWSDomain(domain) {
        this.storageService.store(
            StorageKeyPrefixeTypes.USER_SETTING_KEY_PREFIX,
            WS_DOMAIN_STORAGE_KEY,
            domain,
        );
    }

    private getMode() {
        return this.offlineTaskService.getOfflineMode() ? 'offline' : null;
    }
}
