import { injectable } from 'inversify';

import SpinnerModal from '../components/ui/spinner/spinner.modal';
import { HTMLUtils } from '../utils/html.utils';

@injectable()
export class SpinnerService {

    async show(message?: string): Promise<SpinnerModal> {
        const instance = await SpinnerModal.show({ message: message });
        // SpinnerModal.updateMessage(message || '');
        setTimeout(() => {
            // CLS Note: don't hide overflow here
            HTMLUtils.addClass(this.getElement(instance), "display-block");
            HTMLUtils.removeClass(this.getElement(instance), "display-none");
        }, 1);

        return instance;
    }

    updateMessage(instance: SpinnerModal, message: string) {
        instance.updateMessage(message);
    }

    hide(instance: SpinnerModal) {
        setTimeout(() => {
            try {
                HTMLUtils.removeClass(this.getElement(instance), "display-block");
                HTMLUtils.addClass(this.getElement(instance), "display-none");
                instance.updateMessage("");
            } catch (e) {
                console.error(e);
            }
        }, 10);
    }

    getElement(instance: SpinnerModal): HTMLElement {
        return document.querySelector<HTMLElement>(`#${instance.getContainerId()}.spinner`);
    }

    getBody(instance: SpinnerModal): HTMLElement {
        return document.querySelector<HTMLElement>(`body`);
    }

    async runWithLoader(fn: () => Promise<void>, loadMessage?: string) {
        return new Promise(async (resolve, reject) => {
            const instance = await this.show(loadMessage);
            try {
                setTimeout(async () => {
                    try {
                        await fn();
                        resolve({});
                    } catch (e) {
                        reject();
                    } finally {
                        this.hide(instance);
                    }
                }, 0);
            } catch (e) {
                console.error(e);
            }
        });
    }
}
