import { plainToClass } from 'class-transformer';
import { inject, injectable } from 'inversify';
import { Observable, ReplaySubject, of, zip } from 'rxjs';
import { catchError, map, mergeMap, tap, timeout } from 'rxjs/operators';

import { PSDate } from '../utils/date.utils';
import { isEmpty, shuffle } from '../utils/lodash-min';
import { RequestUtils } from '../utils/request.utils';
import { WindowUtils } from '../utils/window.utils';
import { ApiConfig } from './api-config';
import { LightHttp } from './light-http.service';
import { SettingsService } from './settings.service';
import { StorageService } from './storage.service';
import { WindowService } from './window.service';

export const GET_NCF_DOMAINS_ENDPOINT = '/servers/resolve/domains';
export const GET_NCF_DOMAIN_ECHO_ENDPOINT = '/servers/resolve/domains/echo';

interface RTTSummary {
    start: Date;
    end: Date;
    domain: string;
    ncfEndpoint: string;
    rtt: number;
}

@injectable()
export class ConnectivityService {

    constructor(
        @inject(ApiConfig) private apiConfig: ApiConfig,
        @inject(LightHttp) private http: LightHttp,
        @inject(StorageService) private storageService: StorageService,
        @inject(SettingsService) private settingsService: SettingsService,
        @inject(WindowService) private windowService: WindowService,
    ) {

    }

    initialize$() {
        return zip(this.settingsService.userSettings, this.windowService.userClicked).pipe(
            mergeMap(([settings]) => {
                if (settings.useEdgeTTL) {
                    this.updateRTT();
                }
                return of(settings)
            }),
        );
    }

    updateRTT() {
        this.shouldUpdateEdgesRTT().pipe(
            mergeMap((shouldUpdate) => {
                if (shouldUpdate.update) {
                    var subject = new ReplaySubject<RTTSummary[]>(1);
                    WindowUtils.runWhenIdeal(() => {
                        this.updateEdgeRTTAndSave$().subscribe({
                            next: (data) => {
                                subject.next(data);
                                subject.complete();
                            },
                            error: (err) => {
                                subject.error(err);
                            }
                        });
                    });
                    return subject.asObservable();
                }
                return of({});
            }),
        ).subscribe({
            error: (err) => {
                console.log(err);
            }
        });
    }

    /**
     * This might not send any servers at all.
     */
    getEdgesRandomized(maxNumber: number) {
        const edges = this.storageService.get<RTTSummary[]>('ConnectivityService', 'ENDPOINT_RTT');
        return shuffle((edges || []).slice(0, maxNumber));
    }

    private saveEdgesRTT(data: RTTSummary[]) {
        this.storageService.store('ConnectivityService', 'ENDPOINT_RTT', data);
        this.storageService.store('ConnectivityService', 'ENDPOINT_RTT_LAST_SAVED_DATE', new Date());
    }

    private shouldUpdateEdgesRTT(): Observable<{ update: boolean }> {
        const storedDateAsString = this.storageService.get<string>('ConnectivityService', 'ENDPOINT_RTT_LAST_SAVED_DATE');
        const edges = this.storageService.get<RTTSummary[]>('ConnectivityService', 'ENDPOINT_RTT');
        if (!storedDateAsString || isEmpty(edges)) {
            return of({ update: true });
        }
        try {
            const lastUpdatedDate = plainToClass(Date, storedDateAsString);
            return of({
                update: PSDate
                    .fromDate(lastUpdatedDate)
                    .add(1, 'hours')
                    .isBefore(PSDate.fromDate(new Date()))
            });
        } catch (e) {
            return of({ update: true });
        }
    }

    private updateEdgeRTTAndSave$() {
        const buffer = new ArrayBuffer(1024);
        return RequestUtils.getSuccessValueOrThrow$(
            [this.http.get<{ d: string, ncf: string }[]>(`${this.apiConfig.getHttpsEndpoint()}${GET_NCF_DOMAINS_ENDPOINT}`)]
        ).pipe(
            mergeMap((dList) => {
                const rtt$: Observable<RTTSummary>[] = dList.map(data => {
                    return {
                        start: new Date(),
                        end: null,
                        domain: data.d,
                        ncfEndpoint: `https://${data.ncf}${ApiConfig.edgePathPrefix}${GET_NCF_DOMAIN_ECHO_ENDPOINT}`,
                        rtt: Number.MAX_SAFE_INTEGER,
                    };
                }).map(endpointData => {
                    return this.http.post<RTTSummary>(endpointData.ncfEndpoint, buffer).pipe(
                        map(() => {
                            endpointData.end = new Date();
                            return endpointData;
                        }),
                        timeout(3500),
                        catchError((err) => {
                            return of(endpointData);
                        }),
                    );
                });
                return zip(...rtt$).pipe(
                    map(rttSummaries => {
                        return rttSummaries.filter(rttSummarty => rttSummarty.end).map(rttSummarty => {
                            rttSummarty.rtt = rttSummarty.end.getTime() - rttSummarty.start.getTime();
                            return rttSummarty;
                        }).sort((rttSummarty1, rttSummarty2) => {
                            return rttSummarty1.rtt - rttSummarty2.rtt;
                        });
                    }),
                );
            }),
            tap((data) => {
                this.saveEdgesRTT(data);
            }),
        );
    }
}
