type ColorType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'white';
export default class ColorUtils {
    public static getColor(type: ColorType) {
        switch (type) {
            case 'primary':
                return '#007bff';
            case 'secondary':
                return '#6c757d';
            case 'success':
                return '#28a745';
            case 'danger':
                return '#dc3545';
            case 'warning':
                return '#ffc107';
            case 'info':
                return '#17a2b8';
            case 'light':
                return '#f8f9fa';
            case 'dark':
                return '#343a40';
            case 'white':
                return '#fff';
            default:
                return '#007bff';
        }
    }
}