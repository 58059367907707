import { injectable } from 'inversify';

import { ApiConfig } from './api-config';
import { LightHttp } from './light-http.service';
import { WebSocketService } from './websocket.service';

@injectable()
export class ProcessCompleteCheckService {
    private endpointPostfix = '/workflows/process-complete-data/';

    constructor(
        private http: LightHttp,
        private apiConfig: ApiConfig,
        private wsService: WebSocketService,
    ) {

    }

    requestToSendFileCompleteDataThroughWS(domain: string, uuid: string): Promise<any> {
        const url = `${ApiConfig.suffixDomainWithHttpsEdgeEndpoint(domain)}${this.endpointPostfix}${uuid}/${this.wsService.getId()}`;
        return new Promise((resolve, reject) => {
            this.http.get(url).subscribe({
                next: (data: any) => {
                    if (data && data.error === false) {
                        resolve({
                            state: 'success',
                            data: data,
                        });
                    } else if (data && data.error === true) {
                        resolve({
                            state: 'error',
                            data: data,
                        });
                    } else {
                        resolve({
                            state: 'unknown',
                            data: data,
                        });
                    }
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    }
}
