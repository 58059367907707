import { FromType, FromTypes, ConvertType } from './types';

export type ToSubType = 'PostScript' |
    'Adobe PDF' |
    'E-Book' |
    'MS Office' |
    'Image Resize' |
    'Image Convert' |
    'Image Compress' |
    'Meme' |
    'Video Convert' |
    'Video Compress' |
    'Audio' |
    'Web Tools' |
    'Image Merge' |
    'Audio Compress' |
    'File Archive' |
    'Files';
export class ToSubTypes {
    public static ps: ToSubType = 'PostScript';
    public static pdf: ToSubType = 'Adobe PDF';
    public static ebook: ToSubType = 'E-Book';
    public static office: ToSubType = 'MS Office';
    public static imageResize: ToSubType = 'Image Resize';
    public static imageConvert: ToSubType = 'Image Convert';
    public static imageCompress: ToSubType = 'Image Compress';
    public static meme: ToSubType = 'Meme';
    public static videoConvert: ToSubType = 'Video Convert';
    public static videoCompress: ToSubType = 'Video Compress';
    public static audio: ToSubType = 'Audio';
    public static webtool: ToSubType = 'Web Tools';
    public static imageMerge: ToSubType = 'Image Merge';
    public static audioCompress: ToSubType = 'Audio Compress';
    public static fileArchive: ToSubType = 'File Archive';
    public static files: ToSubType = 'Files';
}


/**
* These defines types of input files we can consume.
* I.e. docx, doc belong to input type 'word', mp4, webm, avi... belongs to type 'video'
*/
export abstract class ConvertFrom {

}

export class ConvertFromWord extends ConvertFrom {
    public static TYPE: FromType = FromTypes.WORD;
    public static EXTENTIONS = ['doc', 'docx'];
}

export class ConvertFromExcel extends ConvertFrom {
    public static TYPE: FromType = FromTypes.EXCEL;
    public static EXTENTIONS = ['xls', 'xlsx'];
}

export class ConvertFromPowerPoint extends ConvertFrom {
    public static TYPE: FromType = FromTypes.POWERPOINT;
    public static EXTENTIONS = ['ppt', 'pptx'];
}

export class ConvertFromPDF extends ConvertFrom {
    public static TYPE: FromType = FromTypes.PDF;
    public static EXTENTIONS = ['pdf'];
}

export class ConvertFromPS extends ConvertFrom {
    public static TYPE: FromType = FromTypes.PS;
    public static EXTENTIONS = ['ps'];
}

export class ConvertFromImage extends ConvertFrom {
    public static TYPE: FromType = FromTypes.IMAGE;
    public static EXTENTIONS = ['jpeg', 'jpg', 'png', 'bmp', 'webp', 'gif', 'tif', 'tiff', 'cr2', 'nef', 'heic'];
}

export class ConvertFromEbook extends ConvertFrom {
    public static TYPE: FromType = FromTypes.EBOOK;
    public static EXTENTIONS = ['epub', 'mobi', 'pdf'];
}

export class ConvertFromVideo extends ConvertFrom {
    public static TYPE: FromType = FromTypes.VIDEO;
    public static EXTENTIONS = ['mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'];
}

export class OfflineFromAny extends ConvertFrom {
    public static TYPE: FromType = FromTypes.FILES;
    public static EXTENTIONS = [];
}

export class OnlineFromAny extends ConvertFrom {
    public static TYPE: FromType = FromTypes.FILES;
    public static EXTENTIONS = [];
}

export class ConvertFromArchive extends ConvertFrom {
    public static TYPE: FromType = FromTypes.ARCHIVE;
    public static EXTENTIONS = ["rar", "7z", "tar.gz", "tar.bz2", "tar.xz", "zip", "rar", "tar", "bz2", "gz", "lz4", "lzma", "xz"];
}

export class ConvertFromAudio extends ConvertFrom {
    public static TYPE: FromType = FromTypes.AUDIO;
    public static EXTENTIONS = [
        'mp3', 'aac', 'ogg', 'flac', 'aiff', 'mmf', 'm4a', 'wma', 'wav', 'm4r', 'opus',
    ];
}

export class ConvertFromAV extends ConvertFrom {
    public static TYPE: FromType = FromTypes.AV;
    public static EXTENTIONS = [
        'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov',
        'mp3', 'aac', 'ogg', 'flac', 'aiff', 'mmf', 'm4a', 'wma', 'wav', 'm4r', 'opus',
    ];
}

export interface CapabilityAvailability {
    online: boolean;
    offline: boolean;
}

export interface ConverterType {
    id: string;
    type: ConvertType | string;
    toSubType: ToSubType;
    from: FromType;
    from_syn: string[];
    to: string;
    to_syn: string[];
    link: string;
    title: string;
    description: string;
    availability?: CapabilityAvailability;
    descriptions?: {
        [sitename: string]: string;
    },
}

export interface HighLevelCapability {
    type: ConvertType | string;
    readableType: string;
    items: HighLevelCapabilityItem[];
}

export interface HighLevelCapabilityItem {
    readableName: string;
    fromType: Set<string>;
}
