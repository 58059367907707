import { ProcessorMetadata } from '../types/types';
import { assign, cloneDeep } from '../utils/lodash-min';
import { GoogleDriveFile } from './google-drive-file';

export class FileUploadRequestMetadata {
    processorMetadataList: ProcessorMetadata[];
    file: File | GoogleDriveFile;

    constructor(processorMetadataList: ProcessorMetadata[], file: File | GoogleDriveFile) {
        this.processorMetadataList = processorMetadataList;
        this.file = file;
    }

    getProcessorMetadata(index: number) {
        return this.processorMetadataList[index];
    }

    getProcessorMetadataList() {
        return this.processorMetadataList;
    }

    replceProcessMetadataWithPostProcessMetadata(index: number) {
        const metadata = this.processorMetadataList[index];
        const postProcessMetadata = cloneDeep(metadata.postProcessMetadata);
        assign(metadata, postProcessMetadata);
    }

    getFile() {
        return this.file;
    }
}
