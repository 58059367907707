import { inject, injectable } from 'inversify';
import { Observable } from 'rxjs';

import { ResponsePipe } from '../utils/request.utils';
import { ApiConfig } from './api-config';
import { LightHttp } from './light-http.service';
import { LoginService } from './login.service';

@injectable()
export class PaymentService {
    constructor(
        @inject(ApiConfig) private apiConfig: ApiConfig,
        @inject(LightHttp) private http: LightHttp,
        @inject(LoginService) private loginService: LoginService,
    ) { }

    payStripe(stripeToken: string, planName: string): Observable<any> {
        if (!this.loginService.isUserLoggedIn) {
            throw new Error('Only logged in users can make a payment');
        }
        return this.http.post(this.apiConfig.getPaymentsPayEndpoint(), {
            stripeToken,
            planName
        }).pipe(
            ResponsePipe,
        );
    }

    payPayPal(subscriptionId: string, planName: string): Observable<any> {
        if (!this.loginService.isUserLoggedIn) {
            throw new Error('Only logged in users can make a payment');
        }
        return this.http.post(this.apiConfig.getPaymentsPayPalEndpoint(), {
            subscriptionId,
            planName
        }).pipe(
            ResponsePipe,
        );
    }
}
