import { injectable } from 'inversify';
import { Observable, ReplaySubject } from 'rxjs';

import { FileUpload } from '../models/file-upload';
import { Logger } from '../models/logger';
import { FromType, OriginalInputFile } from '../types/types';
import { difference, intersection, isArray } from '../utils/lodash-min';



/** UploadedFilesService allow users to move away from a page after selecting a file by keeping track of the files being uploaded
 * NOT USED, NOT READY
 */
@injectable()
export class UploadedFilesService {
    private readonly logger = Logger.getLogger('UploadedFilesService');
    private fromType: FromType | FromType[];
    private acceptedExtensions: string;

    private files: FileUpload[] = [];
    private hidden: FileUpload[] = [];
    private onFileAdded = new ReplaySubject<FileUpload[]>(1);
    private onRequestFileReupload = new ReplaySubject<FileUpload>(1);

    initializeForCompatibility(fromType: FromType | FromType[], acceptedExtensions: string) {
        this.fromType = fromType;
        this.acceptedExtensions = acceptedExtensions;
    }

    public addFile(file: FileUpload, fileUploadResponse: { originalInputFiles: OriginalInputFile[] }): void {
        file.originalInputFiles = fileUploadResponse && fileUploadResponse.originalInputFiles;
        this.files.unshift(file);
        this.notyfyAll();
    }

    public getOnFileAddedSubscriber(): Observable<FileUpload[]> {
        return this.onFileAdded.asObservable();
    }

    public getOnRequestFileReuploadSubscriber(): Observable<FileUpload> {
        return this.onRequestFileReupload.asObservable();
    }

    public notifyReAddEvent(file: FileUpload) {
        this.onRequestFileReupload.next(file);
    }

    public removeAndNotifyReAddEvent(file: FileUpload) {
        this.onRequestFileReupload.next(file);
        this.removeFile(file);
    }

    public removeFile(file: FileUpload) {
        // this.files = this.files.filter(f => f.id !== file.id);
        // this.hidden = this.hidden.filter(f => f.id !== file.id);
        // Tell listeners that a file has been removed
        this.notyfyAll();
    }

    public removeFileFromFileList(file: FileUpload) {
        const toHideFile = this.files.find(f => f.id === file.id);
        if (toHideFile) {
            this.hidden.push(toHideFile);
        }
        // this.files = this.files.filter(f => f.id !== file.id);
        // Tell listeners that a file has been removed
        this.notyfyAll();
    }

    public getAndRemoveFile(id: string, deleteFile?: boolean): FileUpload {
        const file = this.files.find(f => f.id === id);
        if (deleteFile && file) {
            this.removeAndNotifyReAddEvent(file);
        }
        return file;
    }

    public getAndRemoveFileByFiles(files: File[], deleteFile?: boolean): FileUpload {
        try {
            const file2 = this.files.find(f => {
                if (files.length !== f.file.length) {
                    return false;
                }
                let found = true;
                for (const fileToSearch of files) {
                    found = found && !!f.file.find(_f => {
                        return _f.name === fileToSearch.name && _f.size === fileToSearch.size;
                    });
                }
                return found;
            });
            const file3 = this.hidden.find(f => {
                if (files.length !== f.file.length) {
                    return false;
                }
                let found = true;
                for (const fileToSearch of files) {
                    found = found && !!f.file.find(_f => {
                        return _f.name === fileToSearch.name && _f.size === fileToSearch.size;
                    });
                }
                return found;
            });

            const file = file2 || file3;
            this.logger.trace7({ message: `Removing file ${file.name}`, data: JSON.stringify(file) });
            if (deleteFile && file) {
                // HPY removed so that already uploaded file never get uploaded
                // this.removeAndNotifyReAddEvent(file);
            }
            return file;
        } catch (e) {
            return undefined;
        }
    }

    public getFiles(): FileUpload[];
    public getFiles(fileToType: string, deleteFile?: boolean): FileUpload[];
    public getFiles(...args: any[]): FileUpload[] {
        if (!args) {
            return this.files;
        }
        if (args.length > 0) {
            if (args[0]) {
                const files = this.files.filter(f => f.toType === args[0]);
                if (args.length > 1) {
                    if (args[1] && files.length) {
                        files.forEach((file) => this.removeAndNotifyReAddEvent(file));
                    }
                }
                return files;
            }
        }
        return this.files;
    }

    public notyfyAll() {
        if (this.fromType) {
            if (isArray(this.fromType)) {
                const typeCompatibale = this.files.filter(f => intersection(this.fromType, f.fromType).length > 0);
                const acceptSplit = this.acceptedExtensions.replace(/\./ig, '').split(',');
                const extensionAndTypeCompatible = this.files.filter(f => difference(f.extension, acceptSplit).length === 0);
                this.onFileAdded.next(intersection(typeCompatibale, extensionAndTypeCompatible));
            } else {
                const typeCompatibale = this.files.filter(f => f.fromType.indexOf(this.fromType as FromType) > -1);
                const acceptSplit = this.acceptedExtensions.replace(/\./ig, '').split(',');
                const extensionAndTypeCompatible = this.files.filter(f => difference(f.extension, acceptSplit).length === 0);
                this.onFileAdded.next(intersection(typeCompatibale, extensionAndTypeCompatible));
            }
        } else {
            this.onFileAdded.next(this.files);
        }
    }
}
