import { Component } from 'react';
import { finalize, mergeMap, debounceTime } from 'rxjs/operators';

import { Commons } from '../../../commons/commons';
import { BasicValidators, FormControl, FormGroup } from '../../../models/forms';
import { Logger } from '../../../models/logger';
import { LoginService } from '../../../services/login.service';
import { SpinnerService } from '../../../services/spinner.service';
import { UIContext } from '../../../services/ui-context';
import { IOC_TYPES } from '../../../context/ioc.types';
import { lazyInject } from '../../../context/module';
import ReCaptcha from '../recaptcha/recaptcha.component';
import { TabBody, TabHeader, TabsComponent } from '../tabs/tabs.component';
import Link from 'next/link';
import { RxUtils } from '../../../utils/rx-utils';
import { Subscription } from 'rxjs';


export type LoginDialogMode = 'login' | 'register' | 'recover-password';

export interface LoginComponentProps {
    children?: React.ReactNode;
    registerEnabled: boolean;
    passwordResetEnabled: boolean;
    mode: LoginDialogMode;
    submitBtnText: string;
    onSuccess: (data: string) => void,
}

export interface LoginComponentState {
    mode: LoginDialogMode;
}

export default class LoginComponent extends Component<LoginComponentProps, LoginComponentState> {
    private readonly logger = Logger.getLogger('LoginComponent');

    formGroup: FormGroup;
    formGroupSubcription: Subscription;
    isBrowser = false;

    @lazyInject(IOC_TYPES.LoginService) private loginService: LoginService;
    @lazyInject(IOC_TYPES.SpinnerService) private spinnerService: SpinnerService;
    @lazyInject(IOC_TYPES.UIContext) private uiContext: UIContext;

    readonly state: Readonly<LoginComponentState> = {
        mode: this.props?.mode ?? 'login',
    };
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.updateModal(this.state.mode);
    }

    // TODO Preact not fixed
    // ngOnChanges(changes: SimpleChanges): void {
    //     if (changes.mode.currentValue) {
    //         this.currentMode = changes.mode.currentValue;
    //         this.updateModal();
    //     }
    // }

    async login() {
        const instance = await this.spinnerService.show();
        this.loginService.login(this.formGroup.value.email, this.formGroup.value.password, this.formGroup.value.token)
            .pipe(
                finalize(() => {
                    this.spinnerService.hide(instance);
                }),
            ).subscribe(
                data => {
                    if (data && data.error) {
                        this.uiContext.showErrorDialog({ title: 'Error', text: 'Failed to login!' });
                    } else {
                        this.uiContext.successToast('Success!');
                        this.props.onSuccess(this.state.mode);
                    }
                },
                err => {
                    this.logger.error(err);
                    this.uiContext.handleError(err);
                },
            );
    }

    register() {
        this.spinnerService.runWithLoader(
            async () => {
                try {
                    await this.loginService.register(this.formGroup.value.email, this.formGroup.value.password, this.formGroup.value.token)
                        .pipe(
                            mergeMap(() => {
                                return this.loginService.login(
                                    this.formGroup.value.email,
                                    this.formGroup.value.password,
                                    this.formGroup.value.token,
                                );
                            }),
                        ).toPromise();
                    this.props.onSuccess(this.state.mode);

                } catch (err) {
                    this.logger.error(err);
                    this.uiContext.handleError(err);
                }

            },
            'Creating your account...'
        ).catch(err => {
            console.log(err);
        });
    }

    async recover() {
        const instance = await this.spinnerService.show();
        this.loginService.recover(this.formGroup.value.email, this.formGroup.value.token)
            .pipe(
                finalize(() => {
                    this.spinnerService.hide(instance);
                }),
            ).subscribe(
                data => {
                    this.uiContext.successToast('Success', 'E-mail dispatched with instruction to reset your password');
                    this.props.onSuccess(this.state.mode);
                },
                err => {
                    this.logger.error(err);
                    this.uiContext.handleError(err);
                },
            );
    }

    updateModal(tabname) {
        RxUtils.safeCloseSubscriber(this.formGroupSubcription);
        if (tabname === 'login') {
            this.formGroup = new FormGroup({
                email: new FormControl('', { required: BasicValidators.required }), // Validators.email, 
                password: new FormControl('', { required: BasicValidators.required }),
                token: new FormControl(undefined, { required: BasicValidators.required }),
            });

        } else if (tabname === 'register') {
            this.formGroup = new FormGroup({
                email: new FormControl('', { required: BasicValidators.required }),
                password: new FormControl('', { required: BasicValidators.required }),
                repassword: new FormControl('', { required: BasicValidators.required }),
                token: new FormControl(undefined, { required: BasicValidators.required }),
                tosAccepted: new FormControl(false, { required: BasicValidators.required, requiredTruthy: BasicValidators.requiredTruthy }),
            });
        } else if (tabname === 'recover-password') {
            this.formGroup = new FormGroup({
                email: new FormControl('', { required: BasicValidators.required }),
                token: new FormControl(undefined, { required: BasicValidators.required }),
            });
        } else {
            throw new Error('Login state is not supported');
        }

        this.formGroupSubcription = this.formGroup.valueChanges.pipe(
            debounceTime(300),
        ).subscribe({
            next: () => {
                setTimeout(() => this.forceUpdate(), 100);
            }
        });

        if (Commons.DEBUG) {
            // TODO Preact not supported yet
            // this.formGroup.get('token').disable();
        }
    }

    handleCorrectCaptcha(event) {
        this.formGroup.get('token').setValue(event);
    }

    tabChanged(mode) {
        this.setState({
            mode: mode,
        });
        this.updateModal(mode);
        this.forceUpdate();
    }

    setFromControlValue(control, value) {
        control.setValue(value);
    }

    getTOSAcceptFormControl() {
        return <div className="mt-3 mb-3" >
            <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck1"
                    onChange={(e: any) => this.formGroup.controls['tosAccepted'].setValue(e.target.checked)}
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                    I agreed to <Link href="/terms-of-service" prefetch={false} target="_blank">
                        Terms of Service
                    </Link>
                </label>
            </div>
        </div>
    }


    isValid() {
        return this.formGroup?.valid;
    }

    getHTMLForLoginTab() {
        return <div className="mt-3 mb-3" >
            <div className="form-group" >
                <label>Email address</label>
                <input type="email" className="form-control" aria-describedby="emailHelp" placeholder="Enter email"
                    onInput={(e: any) => this.setFromControlValue(this.formGroup.get('email'), e.target.value)
                    } />
            </div >
            <div className="form-group mb-3" >
                <label>Password</label>
                <input type="password" className="form-control" placeholder="Password"
                    onInput={(e: any) => this.setFromControlValue(this.formGroup.get('password'), e.target.value)} />
            </div >
            <div className="mt-3 mb-3" >
                <ReCaptcha onChange={e => this.handleCorrectCaptcha(e)}></ReCaptcha>
            </div >
            <button type="submit" className="btn btn-primary" onClick={e => this.login()}
            > {this.props.submitBtnText}</button >
        </div >;
    }


    getHTMLForRegisterTab() {
        return <div className="mt-3 mb-3" >
            <div className="form-group" >
                <label>Email address</label>
                <input type="email" className="form-control" aria-describedby="emailHelp" placeholder="Enter email"
                    onInput={(e: any) => this.setFromControlValue(this.formGroup.get('email'), e.target.value)
                    } />
            </div >
            <div className="form-group mb-2" >
                <label htmlFor="exampleInputPassword1">Password</label>
                <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password"
                    onInput={(e: any) => this.setFromControlValue(this.formGroup.get('password'), e.target.value)} />
            </div >
            <div className="form-group mb-3" >
                <label>Re-type Password</label>
                <input type="password" className="form-control" placeholder="Re-type Password"
                    onInput={(e: any) => this.setFromControlValue(this.formGroup.get('repassword'), e.target.value)} />
            </div >
            <div className="mt-3 mb-3" >
                <label>Verification</label>
                <ReCaptcha onChange={e => this.handleCorrectCaptcha(e)}></ReCaptcha>
            </div>
            {
                this.getTOSAcceptFormControl()
            }
            <button type="submit" className="btn btn-primary" onClick={e => this.register()} disabled={!this.isValid()}
            > {this.props.submitBtnText}</button >
        </div >;
    }

    getHTMLForPasswordReset() {
        return <div className="mt-3 mb-3" >
            <div className="form-group mb-3" >
                <label>Email address</label>
                <input type="email" className="form-control" aria-describedby="emailHelp" placeholder="Enter email"
                    onInput={(e: any) => this.setFromControlValue(this.formGroup.get('email'), e.target.value)
                    } />
            </div >
            <div className="mt-3 mb-3" >
                <label>Verification</label>
                <ReCaptcha onChange={e => this.handleCorrectCaptcha(e)}></ReCaptcha>
            </div >
            <button type="submit" className="btn btn-primary" onClick={e => this.recover()}
            > {this.props.submitBtnText}</button >
        </div >;
    }

    render() {
        return <TabsComponent activeTabName={this.state.mode} onTabChange={(tabname) => this.tabChanged(tabname)}>
            <TabHeader type="header" headerFor="login">
                Login
            </TabHeader>
            <TabHeader type="header" headerFor="register">
                Register
            </TabHeader>
            <TabHeader type="header" headerFor="recover-password">
                Reset Password
            </TabHeader>
            <TabBody name="login" type="body">
                {
                    this.state.mode === 'login' && this.getHTMLForLoginTab()
                }
            </TabBody>
            <TabBody name="register" type="body">
                {
                    this.state.mode === 'register' && this.getHTMLForRegisterTab()
                }
            </TabBody>
            <TabBody name="recover-password" type="body">
                {
                    this.state.mode === 'recover-password' && this.getHTMLForPasswordReset()
                }
            </TabBody>
        </TabsComponent>
    }
}
