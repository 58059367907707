import { Dimension2D } from '../types/geo.types';
import { Millisecond } from '../ui-utils/browser-utils';
import { UIUtils } from './dom-utils';

export type Corner = 'topleft' | 'topright' | 'bottomleft' | 'bottomright';

export class HTMLUtils {

    public static css(element: HTMLElement, key: string, value: string) {
        element.style[key] = value;
    }

    public static addClass(element: HTMLElement, ...classNames: string[]) {
        if (classNames) {
            classNames.forEach(className => {
                element.classList.add(className);
            });
        }
    }

    public static removeClass(element: HTMLElement, className: string) {
        element.classList.remove(className);
    }

    public static attr(element: HTMLElement, key: string, value: string) {
        element.setAttribute(key, value);
    }

    public static getParent(element: Element): HTMLElement {
        return element.parentElement;
    }

    public static getParentBoundingRectangle(element: Element, relaveToDocument?: boolean) {
        const rect = HTMLUtils.getParent(element).getBoundingClientRect();
        const scrollY = relaveToDocument ? window.scrollY : 0;
        const scrollX = relaveToDocument ? window.scrollX : 0;
        return {
            top: rect.top + scrollY,
            left: rect.left + scrollX,
            height: rect.height,
            width: rect.width,
        };
    }

    public static getImageNatualDimension(image: HTMLImageElement): Dimension2D {
        return {
            height: image.naturalHeight,
            width: image.naturalWidth,
        };
    }

    public static getCanvasNatualDimension(canvas: HTMLCanvasElement): Dimension2D {
        return {
            height: canvas.height,
            width: canvas.width,
        };
    }

    public static getElementBoundingRectangle(element: Element, relaveToDocument?: boolean) {
        const rect = element.getBoundingClientRect();
        const scrollY = relaveToDocument ? window.scrollY : 0;
        const scrollX = relaveToDocument ? window.scrollX : 0;
        return {
            top: rect.top + scrollY,
            left: rect.left + scrollX,
            height: rect.height,
            width: rect.width,
        };
    }

    public static getMouseOffset(element: HTMLElement, ev: MouseEvent, relativeTo: Corner) {
        const elementRect = HTMLUtils.getElementBoundingRectangle(element, true);
        let dx = 0;
        let dy = 0;

        if (relativeTo === 'topright') {
            dx = elementRect.width;
        } else if (relativeTo === 'bottomleft') {
            dy = elementRect.height;
        } else if (relativeTo === 'bottomright') {
            dx = elementRect.width;
            dy = elementRect.height;
        }

        const e = UIUtils.toGenericMouseEvent(ev);

        return {
            dx: dx + elementRect.left - e.pageX,
            dy: dy + elementRect.top - e.pageY,
        };
    }

    public static getElementOffsetTop(parent: Element, element: Element) {
        return HTMLUtils.getElementBoundingRectangle(element).top - HTMLUtils.getElementBoundingRectangle(parent).top;
    }

    public static getElementOffsetLeft(parent: Element, element: Element) {
        return HTMLUtils.getElementBoundingRectangle(element).left - HTMLUtils.getElementBoundingRectangle(parent).left;
    }

    public static waitForElement(selector: string, tryFor: Millisecond) {
        return new Promise((resolve, reject) => {
            let count = tryFor;
            const fn = () => {
                const el = document.querySelector(selector);
                if (el) {
                    resolve(el);
                } else {
                    if (count <= 0) {
                        reject();
                        return;
                    } else {
                        count = count - 100;
                        setTimeout(fn, 100);
                    }
                }
            };
            count--;
            setTimeout(fn, 100);
        });
    }

    public static getPathTo(element) {
        if (element.tagName == 'HTML')
            return '/HTML[1]';
        if (element === document.body)
            return '/HTML[1]/BODY[1]';

        var ix = 0;
        var siblings = element.parentNode.childNodes;
        for (var i = 0; i < siblings.length; i++) {
            var sibling = siblings[i];
            if (sibling === element)
                return HTMLUtils.getPathTo(element.parentNode) + '/' + element.tagName + '[' + (ix + 1) + ']';
            if (sibling.nodeType === 1 && sibling.tagName === element.tagName)
                ix++;
        }
    }

    public static getElementByXpath(path) {
        return document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
      }
}
