import { Observable, pipe, ReplaySubject, UnaryFunction } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Logger } from '../models/logger';


interface UntilDestroyedComponent {
  componentWillUnmount?: (...args: any) => void;
  __hasUnmountWrapper: boolean;
  __originalFn: (...args: any) => void;
  __untilDestroySubject: ReplaySubject<void>;
}

export function untilDestroyed<T>(_component: any): UnaryFunction<Observable<T>, Observable<T>> {
  const logger = Logger.getLogger('UntilDestroyedComponent');
  const component: UntilDestroyedComponent = _component;
  let count = 0;
  let isUnmounted = false;
  if (!component.componentWillUnmount) {
    // Assign null fn
    component.componentWillUnmount = () => {
      logger.trace1({ message: "Fake unmount called" });
    };
  }

  if (component.__untilDestroySubject) {
    return pipe<Observable<T>, Observable<T>>(takeUntil<T>(component.__untilDestroySubject));
  }
  component.__untilDestroySubject = new ReplaySubject();
  component.__hasUnmountWrapper = true;
  component.__originalFn = component.componentWillUnmount;
  const unmountWrapper = function (...args) {
    component.__originalFn.apply(component, args);
    component.__untilDestroySubject.next();
    component.__untilDestroySubject.complete();
  };
  component.componentWillUnmount = unmountWrapper;

  return pipe<Observable<T>, Observable<T>>(takeUntil<T>(component.__untilDestroySubject));
}