import { Component } from 'react';
import ContactUs from '../../contact-us/contact-us';
import Modal from '../modal/modal.component';


export default class ContactUsModal extends Component<any> {
    static show(props: any) {
        Modal.createModal(ContactUsModal, props);
    }

    render() {
        return <Modal>
            <div data-modalheader>
                <h2>Contact Us</h2>
            </div>
            <div data-modalbody>
                <ContactUs></ContactUs>
            </div>
        </Modal>
    }
}