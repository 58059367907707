import { Type } from 'class-transformer';

import { FromTypeFileSizeLimitInMB, UserLimitsSettings } from '../utils/request.utils';

export class SessionDetails {
    ip: string;
    ipv4: string;
    ipv6: string;
    time: number;
    country: string;
    isHighPriorityUser: boolean;
    isPaidUser: boolean;
    @Type(() => FromTypeFileSizeLimitInMB)
    fileSizeLimitsInMB: FromTypeFileSizeLimitInMB;
    @Type(() => UserLimitsSettings)
    userLimits: UserLimitsSettings;

    /** Need to takecare of high priority users */
    get fileSizeLimitMB() {
        return (this.userLimits && this.userLimits.av) || 400;
    }

    get numberOfConcurrentProcessLimit() {
        return (this.userLimits && this.userLimits.numberOfConcurrentTasks) || 4;
    }
}