import { plainToClass } from 'class-transformer';
import { injectable } from 'inversify';

import { StringUtils } from '../utils/string-utils';

export type StorageKeyPrefixeType = 'USER_SETTING_PREFIX' |
'USER_PREFERENCE_PREFIX' |
'FILE_UPLOAD';
export class StorageKeyPrefixeTypes {
    public static USER_SETTING_KEY_PREFIX = 'USER_SETTING_PREFIX';
    public static USER_PREFERENCE_KEY_PREFIX = 'USER_PREFERENCE_PREFIX';
    public static FILE_UPLOAD_KEY_PREFIX = 'FILE_UPLOAD';
}

let fakeStorage = {};

@injectable()
export class StorageService {
    private storage: Storage;

    constructor() {
        // if (isPlatformBrowser(this.platformId)) {
        try {
            this.storage = localStorage;
        } catch {
            this.storage = {
                length: 0,
                clear: () => { fakeStorage = {}; },
                getItem: (key: string) => {
                    return fakeStorage[key];
                },
                key: (index: number) => {
                    // Not used
                    return null;
                },
                removeItem: (key: string) => {
                    delete fakeStorage[key];
                },
                setItem: (key: string, value: string) => {
                    fakeStorage[key] = value;
                },
            };
        }
        // }
    }

    store(prefix: string, key: string, value: any) {
        try {
            return this.storage.setItem(`${prefix}-${key}`, JSON.stringify(value));
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    /**
     * TODO make it better.
     * @param prefix key prefix
     * @param key key
     * 
     * Note: type is set to any but it shoud be ClassType<T>
     * We cannot import import { ClassType } from 'class-transformer/ClassTransformer';
     */
    get<T>(prefix: string, key: string, type?: any, deserializer?: (json) => T): T {
        let item = null;
        try {
            item = this.storage.getItem(`${prefix}-${key}`);
            if (item) {
                item = JSON.parse(item); // this should not fail for any type
            }
            item = this.storage[`${prefix}-${key}`];
            if (item) {
                item = JSON.parse(item); // this should not fail for any type
            }
            try {
                if (type) {
                    item = plainToClass(type, item);
                }
            } catch (e) {
                console.error(e);
            }
            try {
                if (deserializer) {
                    item = deserializer(item);
                }
            } catch (e) {
                console.error(e);
            }
        } catch (e) {
            try {
                return StringUtils.parseString(item);
            }catch(e){
                console.error(e);
            }
        }
        return item;
    }

    remove(prefix: string, key: string) {
        try {
            return this.storage.removeItem(`${prefix}-${key}`);
        } catch (e) {
            console.error(e);
        }
    }

    keySet(keyPrefix?: string): Set<string> {
        try {
            const keys = new Set<string>();
            for (const key in this.storage) {
                if (keyPrefix) {
                    if (key.startsWith(keyPrefix)) {
                        keys.add(key);
                    }
                } else {
                    keys.add(key);
                }
            }
            return keys;
        } catch (e) {
            console.error(e);
        }
    }

    timestampValue(value) {
        return {
            updatedTime: Date.now(),
            value: value,
        };
    }

    clearAll() {
        localStorage.clear();
    }
}
