import { inject, injectable } from 'inversify';
import { Observable, ReplaySubject } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import { Logger } from '../models/logger';
import { ConvertOptionCategories } from '../types/dropdown.type';
import { PageData, RouteProps } from '../types/route-types';
import { JSONUtils } from '../utils/json.utils';
import { isString } from '../utils/lodash-min';
import { RouteUtils } from '../utils/route.utils';
import { EnvironmentService } from './environment.service';
import { LightHttp } from './light-http.service';
import { UIContext } from './ui-context';


@injectable()
export class PageDataProviderService {
    private readonly logger = Logger.getLogger('PageDataProviderService');

    private cache: { [feature: string]: Observable<PageData> } = {};
    constructor(
        @inject(LightHttp) private http: LightHttp,
        @inject(EnvironmentService) private env: EnvironmentService,
        @inject(UIContext) private uiContext: UIContext,
    ) {

    }

    loadConvertOptions(propsOrPath: RouteProps | string): Observable<ConvertOptionCategories> {
        const pathname = isString(propsOrPath) ? RouteUtils.pathTextOnly(propsOrPath) : RouteUtils.getPathFromProps(propsOrPath);
        return this.http.get<string>(`https://www.xconvert.com/convert-options/${pathname}.txt`).pipe(
            map((data) => {
                return JSONUtils.parse(data);
            }),
        );
    }

    loadFeaturePageDataForProps(props: RouteProps): Observable<PageData> {
        const path = RouteUtils.getPathFromProps(props);
        return this.loadFeaturePageDataOrDefaults(path);
    }

    loadFeaturePageDataOrDefaults(featurePagePath: string, isRequried: boolean = true): Observable<PageData> {
        // const isValidCapability = this.capabilityServie.hasCapability(featurePagePath);
        // if (!isValidCapability) {
        //     return of(null);
        // }

        if (!this.cache[featurePagePath]) { // To enable SSR Caching
            const subject = new ReplaySubject<PageData>(1);
            this.cache[featurePagePath] = subject.asObservable();
            const url = `https://www.${this.env.domainname}/data/${this.env.sitename}/routes/${featurePagePath}.txt`;
            this.http.get<string>(url, {
                responseType: 'text',
            }).pipe(
                retry(5),
            ).subscribe({
                next: (pageDataString) => {
                    try {
                        const pageData = eval(pageDataString);
                        if (Object.keys(pageData).length > 0) {
                            subject.next(pageData);
                            subject.complete();
                            return;
                        }
                    } catch (e) {
                    }
                    if (isRequried) {
                        this.uiContext.showErrorDialog({
                            title: 'Error',
                            text: 'Something went wrong. Product may not work as intended.'
                        });
                        this.logger.error({
                            message: `Failed to decode loaded page data for ${featurePagePath}.`
                        });
                    }
                    subject.error(new Error(`Failed to decoded loaded page data for ${featurePagePath}.`));
                },
                error: (err) => {
                    if (isRequried) {
                        this.uiContext.showErrorDialog({
                            title: 'Error',
                            text: 'Something went wrong. Product may not work as intended.'
                        });
                        this.logger.error({
                            message: `Failed to load page data for ${featurePagePath} ${url}.`
                        });
                    }
                    subject.error(new Error(`Failed to load page data for ${featurePagePath} ${url}.`));
                },
            });
        }
        return this.cache[featurePagePath];
    }

    /**
     * TODO not sure what this does in Preact. Migrated from angular
     */
    async resolveRouteDataRecursively(appRoutes: any[], path: string) {
        const routePath: PageData[] = [];
        try {
            let pathSplit: string[] = [];
            if (path === '/') {
                pathSplit.push('/');
            } else {
                const qIndex = path.indexOf('?');
                if (qIndex > -1) {
                    path = path.substring(0, qIndex);
                }
                pathSplit = path.split('/');
                if (pathSplit[0] === '') {
                    pathSplit[0] = '/';
                }
            }
            let currentPath = '/';

            for (const pathStr of pathSplit) {
                let route: PageData = null;
                for (const _route of appRoutes) {
                    if (_route.data && _route.data.path) {
                        if (_route.data.path === pathStr) {
                            route = _route.data as PageData;
                            break;
                        }
                    }
                }
                if (!route) {
                    const pageData = await this.loadFeaturePageDataOrDefaults(pathStr).toPromise();
                    if (pageData) {
                        route = pageData;
                    }
                }
                if (route) {
                    routePath.push(route);
                } else {
                    console.warn(`Found a URL part without pageData for path ${path}`);
                }
            }
            routePath.forEach(route => currentPath += (route && route.path) || '');
        } catch (e) {
            console.error(e);
        }

        return routePath;
    }
}
