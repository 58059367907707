import { injectable } from 'inversify';

@injectable()
export class MemeUploadService {

    canvastoFile(canvas: HTMLCanvasElement): File {
        const dataURI = canvas.toDataURL('image/jpeg');
        // convert the data URL to a byte string
        const byteString = atob(dataURI.split(',')[1]);

        // pull out the mime type from the data URL
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // Convert to byte array
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // Create a blob that looks like a file.
        const blob: any = new Blob([ab], { 'type': mimeString });
        blob['lastModifiedDate'] = (new Date()).toISOString();
        blob['name'] = 'file';

        // Figure out what extension the file should have
        switch (blob.type) {
            case 'image/jpeg':
                blob['name'] += '.jpg';
                break;
            case 'image/png':
                blob['name'] += '.png';
                break;
        }
        // cast to a File
        return <File>blob;
    }
}
