import { inject, injectable } from 'inversify';
import { Observable, ReplaySubject, catchError, filter, map, tap, throwError } from 'rxjs';
import { AjaxConfig, AjaxError, AjaxResponse, ajax } from 'rxjs/ajax';

import { HttpRequestBody, HttpRequestOptions, HttpRequestOptionsInternal } from '../types/http.types';
import { get, parseInt } from '../utils/lodash-min';
import { ClientError, ServiceError, UnknownHTTPError } from '../utils/request.utils';
import { ApiConfig } from './api-config';
import { DOMStorage } from './dom-storage.service';
import { EnvironmentService } from './environment.service';
import { ILightHttpService } from './service-interfaces';

export const UNAUTHENTICATED_URLS = [
    new RegExp('^https://storage.googleapis.com.*', 'i'),
    new RegExp('^https://checkip.amazonaws.com.*', 'i'),
    new RegExp('.*style.*', 'i'),
];


export type HttpProgressEventType = 'upload_loadstart' |
    'upload_load' |
    'upload_progress' |
    'download_loadstart' |
    'download_load' |
    'download_progress';
export class HttpProgressEventTypes {
    public static UPLOAD_LOADSTART: HttpProgressEventType = 'upload_loadstart';
    public static UPLOAD_LOAD: HttpProgressEventType = 'upload_load';
    public static UPLOAD_PROGRESS: HttpProgressEventType = 'upload_progress';
    public static DOWNLOAD_LOADSTART: HttpProgressEventType = 'download_loadstart';
    public static DOWNLOAD_LOAD: HttpProgressEventType = 'download_load';
    public static DOWNLOAD_PROGRESS: HttpProgressEventType = 'download_progress';
}

export interface HttpProgressEvent {
    type: HttpProgressEventType;
    event: {
        total: number;
        loaded: number;
    };
}

export interface HttpResponseEvent<T> {
    response$: Observable<T>;
    events$: Observable<HttpProgressEvent>;
}

@injectable()
export class LightHttp implements ILightHttpService {

    private static wsDataProvider = {
        getOrCreateStoredWSId: (): string | null => null,
        getStoredWSDomain: (): string | null => null,
    };
    static loginUserDetailsProvider: { userIpAddress: string | null, userContry: string | null } = { userIpAddress: null, userContry: null };
    static commonHttpHeadersProvider: { getCommonHttpHeaders: () => any }
        = { getCommonHttpHeaders: () => { return {}; } };
    static authTokenProvider: { getToken: () => string | null } = { getToken: () => null };

    constructor(
        @inject(ApiConfig) private apiConfig: ApiConfig,
        @inject(EnvironmentService) private env: EnvironmentService,
        @inject(DOMStorage) private domStorage: DOMStorage,
    ) {
    }

    public post<T>(
        url: string,
        body: HttpRequestBody,
        options?: HttpRequestOptions,
    ) {
        return this.request<T>(url, 'POST', body, options).response$;
    }

    public put<T>(
        url: string,
        body: HttpRequestBody,
        options?: HttpRequestOptions,
    ) {
        return this.request<T>(url, 'PUT', body, options).response$;
    }

    public patch<T>(
        url: string,
        body: HttpRequestBody,
        options?: HttpRequestOptions,
    ) {
        return this.request<T>(url, 'PATCH', body, options).response$;
    }

    public get<T>(
        url: string,
        options?: HttpRequestOptions,
    ) {
        return this.request<T>(url, 'GET', null, options).response$;
    }

    public delete<T>(
        url: string,
        options?: HttpRequestOptions,
    ) {
        return this.request<T>(url, 'DELETE', null, options).response$;
    }

    /**
     * Default content type to json,
     * requestGeneratorFn allow retry() on rxjs
     * @param url 
     * @param method 
     * @param body 
     * @param options 
     */
    public request<T>(
        url: string,
        method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'HEAD' | 'PATCH',
        body: HttpRequestBody | null,
        options: HttpRequestOptions = {},
    ): HttpResponseEvent<T> {
        this.mergeUserRequestedHeadersWithDefaultHeaders(url, options);
        const config: AjaxConfig = {
            url: url,
            method: method,
            includeDownloadProgress: true,
            includeUploadProgress: true,
            body: body,
            ...(options.params && { queryParams: options.params }),
            ...(options.headers && { headers: options.headers }),
            ...(options.withCredentials && { withCredentials: options.withCredentials }),
            ...(options.responseType && { responseType: options.responseType }),
            ...(options.timeout && { timeout: options.timeout }),
        };
        const eventsListner = new ReplaySubject<HttpProgressEvent>(1);
        // const requestEvents = new ReplaySubject<any>(1);
        const requestEvents = ajax<T>(config)
        .pipe(
            tap((data) => {
                if (data.type != 'download_load') {
                    eventsListner.next(this.convertToProgressEvent(data));
                }
            }),
            catchError(error => {
                return throwError(() => this.convertErrorResponse(error, { requestUrl: url }))
            }),
            filter((data) => {
                return data.type == 'download_load'
            }),
            map(data => data.response)
        )
        /*
        .subscribe({
            next: (data) => {
                if (data.type == 'download_load') {
                    requestEvents.next(data.response);
                    requestEvents.complete();
                } else {
                    eventsListner.next(this.convertToProgressEvent(data));
                }
            },
            error: (error) => {
                requestEvents.error(this.convertErrorResponse(error, { requestUrl: url }));
            }
        });
        */
        return {
            response$: requestEvents,
            events$: eventsListner.asObservable(),
        };
    }

    private convertToProgressEvent(event: AjaxResponse<any>): HttpProgressEvent {
        if (event.type === 'upload_progress' || event.type === 'download_progress') {
            return {
                event: {
                    loaded: event.loaded,
                    total: event.total,
                },
                type: event.type === 'upload_progress' ? 'upload_progress' : 'download_progress',
            }
        }

        return {
            event,
            type: event.type,
        };
    }

    private convertErrorResponse(error: AjaxError, options?: { requestUrl: string }) {
        if (error.status == 0) {
            let url = get(error || {}, 'xhr.responseURL', options?.requestUrl);
            if ((!url || url.length == 0) && options?.requestUrl) {
                url = options?.requestUrl;
            }
            return new UnknownHTTPError(`Unable to reach the server. Possible causes could be server is offline, your connection to the server is blocked or you are offline. ${url}`);
        }

        const status = parseInt(error.status / 100 + '');
        if (status === 4) {
            return new ClientError(this.getErrorMessage(error, options));
        } else if (status === 5) {
            return new ServiceError(this.getErrorMessage(error, options));
        } else {
            return new UnknownHTTPError(this.getErrorMessage(error, options));
        }
    }

    private getErrorMessage(error: AjaxError, options?: { requestUrl?: string }): string {
        let message = get(error, 'response.message');
        const url = get(error, 'xhr.responseURL');
        if (message) {
            return message;
        }
        if (error.status || error.status === 0) {
            if (error.response) {
                return `${error.status} - ${error.response}`;
            }
            if (url) {
                return `${error.status} - unknow error while connecting to ${url}`;
            }
            return `${error.status} - unknow error`;
        }
        var resolvedUrl = url || options?.requestUrl;
        if (resolvedUrl) {
            return `Unknow error while connecting to ${resolvedUrl}`;
        }
        return `Unknow error`;
    }

    private mergeUserRequestedHeadersWithDefaultHeaders(url: string, options: HttpRequestOptionsInternal) {
        if (!options.headers) {
            options.headers = {};
        }
        const commonHeaders = this.getCommonHeaders(url)
        let allHeaders = Object.assign({}, commonHeaders, options.headers);
        options.headers = allHeaders;
    }

    public getCommonHeaders(url: string) {
        let headers: any = {};
        const token = LightHttp.authTokenProvider.getToken();
        if (!UNAUTHENTICATED_URLS.find(regex => regex.test(url))) {
            headers = LightHttp.commonHttpHeadersProvider.getCommonHttpHeaders();
            // IMPORTANT: Need to add GCP, blob://, sockjs-node to skip url list
            headers = Object.assign(
                {},
                headers,
                {
                    /** API Domain name.
                     * //TODO please rename sitename to apiEndpointDomain
                     */
                    sitename: `${this.apiConfig.apiDomainName}`,
                    /** Domain name of the requesting website  */
                    domainname: `${this.env.domainname}`,
                    ...(LightHttp.loginUserDetailsProvider.userIpAddress ? { 'ip-address': LightHttp.loginUserDetailsProvider.userIpAddress } : null),
                    ...(LightHttp.loginUserDetailsProvider.userContry ? { 'country': LightHttp.loginUserDetailsProvider.userContry } : null),
                    wsId: `${LightHttp.wsDataProvider.getOrCreateStoredWSId()}`,
                    wsDomain: `${LightHttp.wsDataProvider.getStoredWSDomain()}`,
                }
            );

            if (token) {
                headers.Authorization = `Bearer ${token}`;
            }
        }
        return headers;
    }

    public static registerWSDetailProvider(provider: {
        getOrCreateStoredWSId: () => string | null,
        getStoredWSDomain: () => string | null,
    }) {
        LightHttp.wsDataProvider = provider;
    }

    public static registerLoginUserDetailsProvider(provider: { userIpAddress: string, userContry: string }) {
        LightHttp.loginUserDetailsProvider = provider;
    }

    public static registerCommonHttpHeadersProvider(provider: { getCommonHttpHeaders: () => any }) {
        LightHttp.commonHttpHeadersProvider = provider;
    }

    public static registerAuthTokenProvider(provider: { getToken: () => string }) {
        LightHttp.authTokenProvider = provider;
    }
}