import { ApiConfig } from "../services/api-config"
import { ProcessorMetadata } from "../types/types"

export namespace ProcessorMetadataUtils {

    export function getUploadEndpoint(metadata: ProcessorMetadata): string {
        return `${ApiConfig.suffixDomainWithHttpsEdgeEndpoint(metadata.uploadDomain)}${metadata.uploadEndpoint}`
    } 
    export function getProcessorEndpoint(metadata: ProcessorMetadata): string {
        return `${ApiConfig.suffixDomainWithHttpsEdgeEndpoint(metadata.processDomain)}${metadata.processorEndpoint}`
    } 
    export function getUploadedFileDownloadEndpoint(metadata: ProcessorMetadata): string {
        if(metadata.uploadDomain.indexOf('googleapis') !== -1) {
            return `${metadata.uploadDomain}${metadata.uploadedFileDownloadEndpoint}`
        }
        return `${ApiConfig.suffixDomainWithHttpsEdgeEndpoint(metadata.uploadDomain)}${metadata.uploadedFileDownloadEndpoint}`
    } 
    export function getDownloadEndpoint(metadata: ProcessorMetadata): string {
        if(metadata.downloadDomain.indexOf('googleapis') !== -1) {
            return `${metadata.downloadDomain}${metadata.downloadEndpoint}`
        }
        return `${ApiConfig.suffixDomainWithHttpsEdgeEndpoint(metadata.downloadDomain)}${metadata.downloadEndpoint}`
    } 
    export function getGcpTempDownloadEndpoint(metadata: ProcessorMetadata): string {
        return `${ApiConfig.suffixDomainWithHttpsEdgeEndpoint(metadata.downloadDomain)}${metadata.gcpTempDownloadEndpoint}`
    } 
    export function getEmailDownloadEndpoint(metadata: ProcessorMetadata): string {
        return `${ApiConfig.suffixDomainWithHttpsEdgeEndpoint(metadata.downloadDomain)}${metadata.emailDownloadEndpoint}`
    } 
    export function getImageShareEndpoint(metadata: ProcessorMetadata): string {
        return `${ApiConfig.suffixDomainWithHttpsEdgeEndpoint(metadata.processDomain)}${metadata.imageShareEndpoint}`
    } 
    export function getThumbnailEndpoint(metadata: ProcessorMetadata): string {
        return `${ApiConfig.suffixDomainWithHttpsEdgeEndpoint(metadata.thumbnailDomain)}${metadata.thumbnailEndpoint}`
    } 
    export function getUploadCommEndpoint(metadata: ProcessorMetadata): string {
        return `${ApiConfig.suffixDomainWithHttpsEdgeEndpoint(metadata.uploadDomain)}${metadata.commEndpoint}`
    } 
    export function getProcessCommEndpoint(metadata: ProcessorMetadata): string {
        return `${ApiConfig.suffixDomainWithHttpsEdgeEndpoint(metadata.processDomain)}${metadata.commEndpoint}`
    } 
    export function getZipEndpoint(metadata: ProcessorMetadata): string {
        return `${ApiConfig.suffixDomainWithHttpsEdgeEndpoint(metadata.processDomain)}${metadata.zipEndpoint}`
    } 
}
