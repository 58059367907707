import { inject, injectable } from 'inversify';

import { BrowserUtils } from '../ui-utils/browser-utils';
import { UrlUtils } from '../utils/url.utils';
import { EnvironmentService } from './environment.service';

export type DomainType = 'offline';

@injectable()
export class ApiConfig {
    static apiPathPrefix = '/v1/api';
    static edgePathPrefix = '/v1/edge';

    static localhostDomainName = '127.0.0.1';

    get isNgrokOrLocalTunnel() {
        if (BrowserUtils.hasWindow()) {
            if (location.host.endsWith('ngrok.io') || location.host.endsWith('loca.lt')) {
                return true; // true
            }
        }
        return false;
    }

    get isLocalTunnel() {
        if (BrowserUtils.hasWindow()) {
            if (location.host.endsWith('loca.lt')) {
                return true;
            }
        }
        return false;
    }

    get isNgrok() {
        if (BrowserUtils.hasWindow()) {
            if (location.host.endsWith('ngrok.io')) {
                return true;
            }
        }
        return false;
    }


    get ngrokHost() {
        return location.host;
    }

    get apiDomainName() {
        if (this.isNgrokOrLocalTunnel) {
            return this.ngrokHost;
        }
        return this.envService && this.envService.apiDomainname();
    }

    get podDomainName() {
        if (this.isNgrokOrLocalTunnel) {
            return this.ngrokHost;
        }
        return this.envService && this.envService.podDomainname();
    }

    get apiSubdomain() {
        if (this.isNgrokOrLocalTunnel) {
            return '';
        }
        return 'api-v1';
    }

    get subdomainPrefixForNCF() {
        return 'ncf-'
    }

    get podSubdomain() {
        return 'pod'
    }

    // TODO change to readonly
    private get QA_API_ENDPOINT_1() { return `https://qa-1.${this.apiDomainName}`; }

    private get QA_WS_ENDPOINT_3() { return `https://qa-2.${this.apiDomainName}`; }

    private get OFFLINE_API_ENDPOINT_1() { return this.isNgrokOrLocalTunnel ? `https://${this.ngrokHost}` : `http://${ApiConfig.localhostDomainName}:9080`; }
    private get OFFLINE_WS_ENDPOINT() { return this.isNgrokOrLocalTunnel ? `https://${this.ngrokHost}` : `https://${ApiConfig.localhostDomainName}:9080`; }

    apiEndpoint: string | null = null;
    podEndpoint: string | null = null;
    // TODO enable this

    constructor(
        @inject(EnvironmentService) private envService: EnvironmentService,
    ) {
        this.apiEndpoint = this.isNgrokOrLocalTunnel ? `https://${this.ngrokHost}` : `https://${this.subdomainPrefixForNCF}${this.apiSubdomain}.${this.apiDomainName}`;
        this.podEndpoint = this.isNgrokOrLocalTunnel ? `https://${this.ngrokHost}` : `https://${this.subdomainPrefixForNCF}${this.podSubdomain}.${this.podDomainName}`;
        try {
            if (this.isLocalhost() || this.envService.env.dev) {
                this.apiEndpoint = `//${this.envService.env.localhostAPIDomain}`;
                this.podEndpoint = `//${this.envService.env.localhostPODDomain}`;
            } else {
            }
        } catch (e) {
            console.error('Suppressed ApiConfig constructor error: ' + e.message);
        }
    }

    getLogoutEndpoint() {
        return this.apiEndpoint + `${ApiConfig.apiPathPrefix}/auth/logout`;
    }

    getLoginEndpoint() {
        return this.apiEndpoint + `${ApiConfig.apiPathPrefix}/auth/login`;
    }

    getAuthenticateEndpoint(): string {
        return `${this.getApiHttpsDomain()}${ApiConfig.apiPathPrefix}/auth/authenticate`;
    }

    getWebSocketEndpoint(mode?: string): string {
        if (mode === 'offline') {
            return `${this.getApiWSOfflineDomains()}${ApiConfig.apiPathPrefix}/ws`;
        }
        if (this.isLocalhost() || this.envService.env.dev || this.envService.env.qa) {
            return `${this.getApiWSLocalDomains()}${ApiConfig.apiPathPrefix}/comm/ws-domain`;
        }

        return `${this.getApiWSProductionDomains()}${ApiConfig.apiPathPrefix}/servers/resolve/ws-domain`;
    }

    getHttpsEndpoint(mode?: DomainType): string {
        if (mode === 'offline') {
            return this.getApiHttpsOfflineDomain();
        }
        if (this.envService.env.qa) {
            return this.getQAApiHttpsDomain();
        }
        return `${this.getApiHttpsDomain()}${ApiConfig.apiPathPrefix}`;
    }

    static suffixDomainWithHttpsEdgeEndpoint(domain: string) {
        return `${UrlUtils.toHttpsProtocol(domain)}${ApiConfig.edgePathPrefix}`;
    }

    private isLocalhost(): boolean {
        try {
            return window.location.host.startsWith('localhost');
        } catch {
            return false;
        }
    }

    private getApiHttpsDomain(): string {
        return this.apiEndpoint;
    }

    private getQAApiHttpsDomain(): string {
        return this.QA_API_ENDPOINT_1;
    }

    private getApiHttpsOfflineDomain(): string {
        return this.OFFLINE_API_ENDPOINT_1;
    }

    private getApiWSLocalDomains(): string {
        return this.apiEndpoint;
    }

    private getApiWSProductionDomains(): string {
        return this.apiEndpoint;
    }

    private getQAApiWSDomains(): string {
        return this.QA_WS_ENDPOINT_3;
    }

    private getApiWSOfflineDomains(): string {
        return this.OFFLINE_WS_ENDPOINT;
    }

    getPaymentsPayEndpoint() {
        return this.apiEndpoint + `${ApiConfig.apiPathPrefix}/payments/pay`;
    }

    getPaymentsPayPalEndpoint() {
        return this.apiEndpoint + `${ApiConfig.apiPathPrefix}/payments/pay-paypal`;
    }

    getCancelPaymentsEndpoint() {
        return this.apiEndpoint + `${ApiConfig.apiPathPrefix}/payments/cancel`;
    }

    getRegisterEndpoint() {
        return this.apiEndpoint + `${ApiConfig.apiPathPrefix}/auth/register`;
    }

    getEmailValidateEndpoint() {
        return this.apiEndpoint + `${ApiConfig.apiPathPrefix}/auth/validate-email`;
    }

    getUserDetailsEndpoint() {
        return this.apiEndpoint + `${ApiConfig.apiPathPrefix}/auth/user-details`;
    }

    getRecoverPasswordEndpoint() {
        return this.apiEndpoint + `${ApiConfig.apiPathPrefix}/auth/recover-password`;
    }

    getResetPasswordEndpoint() {
        return this.apiEndpoint + `${ApiConfig.apiPathPrefix}/auth/reset-password`;
    }

    getAccountVerifyEndpoint() {
        return this.apiEndpoint + `${ApiConfig.apiPathPrefix}/auth/verify`;
    }

    getProcessAuthEndpoint() {
        return this.apiEndpoint + `${ApiConfig.apiPathPrefix}/auth/authorize-request`;
    }

    getSupportEmailEndpoint() {
        return this.podEndpoint + `${ApiConfig.edgePathPrefix}/comm/captcha_validate`;
    }

    getHistoryTrackEndpoint() {
        return this.podEndpoint + `${ApiConfig.edgePathPrefix}/comm/history-track`;
    }

    getUserTraceEndpoint() {
        return this.podEndpoint + `${ApiConfig.edgePathPrefix}/users/trace`;
    }

    getVersionCheckEndpoint() {
        return this.apiEndpoint + `${ApiConfig.apiPathPrefix}/settings/web_version`;
    }

    getUploadAndProcessEndpointResolveEndpoint() {
        return this.apiEndpoint + `${ApiConfig.apiPathPrefix}/servers/resolve`;
    }

    generateFileId() {
        return this.apiEndpoint + `${ApiConfig.apiPathPrefix}/uploads/new-file-id`;
    }

    getUserSettingsEndpoint() {
        return this.apiEndpoint + `${ApiConfig.apiPathPrefix}/settings`;
    }

    getUserAnalyticsSaveEndpoint() {
        return this.apiEndpoint + `${ApiConfig.apiPathPrefix}/comm/analytics`;
    }

    getMotiveStatEndpoint() {
        return this.apiEndpoint + `${ApiConfig.apiPathPrefix}/comm/motive`;
    }
}
