import { inject, injectable } from 'inversify';
import Resumable from 'resumablejs';
import { Observer, ReplaySubject, of, retry, throwError } from 'rxjs';

import { delay, mergeMap } from 'rxjs/operators';
import { DropboxFile } from '../models/dropbox-file';
import { FileUploadRequestMetadata } from '../models/file-upload-request-metadata';
import { GoogleDriveFile } from '../models/google-drive-file';
import { Logger } from '../models/logger';
import { UploadInProgressFile } from '../models/upload-in-progress-file';
import { GAContants } from '../types/ga.types';
import { OriginalInputFile, PSUploadTypes, WebsocketResponse } from '../types/types';
import { FilenameUtils } from '../utils/filename-utils';
import { get, isString, parseInt } from '../utils/lodash-min';
import { ProcessorMetadataUtils } from '../utils/processor-metadata.utils';
import { StringUtils } from '../utils/string-utils';
import { GAService } from './analytics.service';
import { AuthService } from './auth.service';
import { HelperService } from './helper.service';
import { HttpProgressEvent, LightHttp } from './light-http.service';
import { LoginService } from './login.service';
import { SettingsService } from './settings.service';
import { UIContext } from './ui-context';
import { WebSocketService } from './websocket.service';



/**
 * IMPORTANT!: All files that are uploaded but be saved to fileUploadMap.<route_path> so that when WS recieves a message,
 * We can update corresponding FileUpload with the data.
 */
@injectable()
export class FileUploadService {
    logger = Logger.getLogger('FileUploadService');

    maxFileSizeInBytes = 600 * 1000 * 1000;
    maxFileSizeInMB = 600;
    concurrentProcessLimit = 4;
    chunkSize = 1 * 1024 * 1024 + 1;
    simultaneousUploads = 6;
    chunkUploadEnabled = true

    private _onFileUploadStart = new ReplaySubject<{ dispatchedAt: Date }>(1);
    get onFileUploadStart() {
        return this._onFileUploadStart;
    }

    private _onFileSelected = new ReplaySubject<{ dispatchedAt: Date }>(1);
    get onFileSelected() {
        return this._onFileSelected;
    }

    public fileSelected() {
        this._onFileSelected.next({ dispatchedAt: new Date() });
    }

    // private fileUploadMap: Map<UniqueProcessType, FileUpload[]> = new Map();
    // private fileUploadsChanged = new Subject<FileUpload>();
    // get onFileUploadChanged() {
    //     return this.fileUploadsChanged.asObservable();
    // }


    constructor(
        @inject(LightHttp) private http: LightHttp,
        @inject(WebSocketService) private wsService: WebSocketService,
        @inject(GAService) private gaService: GAService,
        @inject(UIContext) private uiContext: UIContext,
        @inject(AuthService) private authService: AuthService,
        @inject(HelperService) private helperService: HelperService,
        @inject(SettingsService) private settingsService: SettingsService,
        @inject(LoginService) private loginService: LoginService,
    ) {

    }

    initialize() {
        this.loginService.onSessionDetails.subscribe({
            next: (sessionDetails) => {
                this.maxFileSizeInBytes = ((sessionDetails && sessionDetails.fileSizeLimitMB) || 400) * 1000 * 1000;
                this.maxFileSizeInMB = (sessionDetails && sessionDetails.fileSizeLimitMB) || 400;
                this.concurrentProcessLimit = (sessionDetails && sessionDetails.numberOfConcurrentProcessLimit) || 4;
            }
        });
        this.settingsService.userSettings.subscribe({
            next: (userSettings) => {
                this.chunkSize = get(userSettings, 'chunkSize', 40 * 1024 * 1024 + 1);
                this.chunkUploadEnabled = get(userSettings, 'chunkUploadEnabled', true);
                this.simultaneousUploads = get(userSettings, 'simultaneousUploads', 2);
            }
        });

        // this.fileManagerService.onFileUploadRemove()
        //     .subscribe((fileUpload) => {
        //         this.fileUploadMap.forEach(fileUploads => {
        //             const index = fileUploads.findIndex(f => f.id === fileUpload.id);
        //             if (index > -1) {
        //                 fileUploads.splice(index, 1);
        //             }
        //         });
        //         this.notify();
        //     });

        // this.fileManagerService.registerActiveFileUploadsProvider(this);
    }

    // public notify(fileUpload?: FileUpload) {
    //     this.fileUploadsChanged.next(fileUpload);
    // }

    // public getFileUploadArray(processType: UniqueProcessType) {
    //     if (!this.fileUploadMap.get(processType)) {
    //         this.fileUploadMap.set(processType, new Array());
    //     }
    //     return this.fileUploadMap.get(processType);
    // }

    // public getFileUploadMap() {
    //     return this.fileUploadMap;
    // }

    // public getFileUploads(): FileUpload[] {
    //     const fileArray = [];
    //     const fileMap: Map<UniqueProcessType, FileUpload[]> = this.getFileUploadMap();
    //     fileMap.forEach((fileUploads, uniqueProcessType) => {
    //         fileUploads.forEach(fileUpload => {
    //             fileArray.push(fileUpload);
    //         });
    //     });
    //     return fileArray;
    // }

    // public getFileUpload(_uuid: string): FileUpload {
    //     let item;
    //     this.fileUploadMap.forEach((fileUploads, key) => {
    //         fileUploads.forEach(fileUpload => {
    //             if (fileUpload.id === _uuid) {
    //                 item = fileUpload;
    //             }
    //         });
    //     });
    //     return item;
    // }

    private notifyFileUploadStart() {
        this._onFileUploadStart.next({ dispatchedAt: new Date() });
    }

    public async uploadFileFromURL(
        fileWithMetadata: UploadInProgressFile<DropboxFile | GoogleDriveFile | File>,
        progressObserver: Observer<any>,
    ): Promise<OriginalInputFile> {
        this.notifyFileUploadStart();
        const uploadRequestMetadata: FileUploadRequestMetadata = fileWithMetadata.getMetadata();
        if (uploadRequestMetadata.processorMetadataList[0].uploadDomain.indexOf('localhost') !== -1) {
            this.uiContext.errorToast('Error', 'Running in Test Mode.', false, false);
        }
        this.gaService.send(
            GAContants.HitType.EVENT,
            GAContants.EventCategory.NETWORK,
            GAContants.EventAction['FILE-UPLOAD-START'],
            GAContants.EventLabel.CONVERT,
            `${fileWithMetadata.metadata.file.name}`,
        );
        if (uploadRequestMetadata.processorMetadataList[0].uploadType === PSUploadTypes.SERVER) {
            const progressCallback = (_fileWithMetadata) => {
                let recievedSize = 0;
                const localFileWithMetadata: UploadInProgressFile<any> = _fileWithMetadata;
                return (data) => {
                    this.logger.trace7({ message: 'URL file upload progress response', data: data });
                    if (data.uuid === localFileWithMetadata.getFile().name) {
                        recievedSize = parseFloat(data.data) > recievedSize ? parseFloat(data.data) : recievedSize;
                        const progress = 100 * recievedSize / localFileWithMetadata.getFile().size;

                        if (recievedSize === localFileWithMetadata.getFile().size || parseInt(progress + '', 10) === 100) {
                            localFileWithMetadata.setComplete();
                        }

                        if (data.processStageSubState === 'completed') {
                            localFileWithMetadata.setComplete();
                            this.gaService.send(
                                GAContants.HitType.EVENT,
                                GAContants.EventCategory.NETWORK,
                                GAContants.EventAction['FILE-UPLOAD-END'],
                                GAContants.EventLabel.CONVERT,
                                `${localFileWithMetadata.metadata.file.name}`,
                            );
                            this.wsService.unregisterCallback(removableCallback);
                        }

                        if (!localFileWithMetadata.getComplete()) {
                            localFileWithMetadata.setProgress(parseFloat(parseFloat(progress + '').toFixed(2)));
                        } else {
                            localFileWithMetadata.setProgress(parseFloat(parseFloat('100').toFixed(2)));
                        }
                    }
                };
            };
            const removableCallback = (data) => progressCallback(fileWithMetadata)(data);
            this.wsService.registerCallback(removableCallback);
            const urlUploadEndpoint = ProcessorMetadataUtils.getUploadEndpoint(uploadRequestMetadata.processorMetadataList[0]) + '/url';
            const result: any = await this.http.post(urlUploadEndpoint, fileWithMetadata.getFile(), {
                params: {
                    wsId: this.wsService.getId(),
                    wsDomain: this.wsService.getDomain(),
                    safeFilename: fileWithMetadata.metadata.processorMetadataList[0].fileMetadata.safeName,
                },
            }).toPromise();

            const info = {
                ftype: FilenameUtils.getFileMimeType(fileWithMetadata.getFile()),
                fname: fileWithMetadata.getFile().name,
                fsize: fileWithMetadata.getFile().size,
                flastModified: fileWithMetadata.getFile().lastModified,
            };
            const originalInputFiles = result.originalInputFiles[0];
            originalInputFiles.metadata = info;
            return originalInputFiles;
        }
    }

    public async uploadFileFromGooleDrive(
        fileWithMetadata: UploadInProgressFile<File>,
        progressObserver: Observer<any>,
    ): Promise<OriginalInputFile> {
        this.notifyFileUploadStart();
        const uploadRequestMetadata: FileUploadRequestMetadata = fileWithMetadata.getMetadata();
        if (uploadRequestMetadata.processorMetadataList[0].uploadDomain.indexOf('localhost') !== -1) {
            this.uiContext.errorToast('Error', 'Running in Test Mode.', false, false);
        }
        this.gaService.send(
            GAContants.HitType.EVENT,
            GAContants.EventCategory.NETWORK,
            GAContants.EventAction['FILE-UPLOAD-START'],
            GAContants.EventLabel.CONVERT,
            `${fileWithMetadata.metadata.file.name}`,
        );
        if (uploadRequestMetadata.processorMetadataList[0].uploadType === PSUploadTypes.SERVER) {
            const progressCallback = (_fileWithMetadata) => {
                let recievedSize = 0;
                const localFileWithMetadata: UploadInProgressFile<any> = _fileWithMetadata;
                return (data: WebsocketResponse) => {
                    this.logger.trace7({ message: 'GoogleDrive file upload progress response', data: data });
                    if (data.uuid === localFileWithMetadata.getFile().name) {
                        recievedSize = parseFloat(data.data) > recievedSize ? parseFloat(data.data) : recievedSize;
                        const progress = 100 * recievedSize / localFileWithMetadata.getFile().size;

                        if (recievedSize === localFileWithMetadata.getFile().size || parseInt(progress + '', 10) === 100) {
                            localFileWithMetadata.setComplete();
                        }

                        if (data.processStageSubState === 'completed') {
                            localFileWithMetadata.setComplete();
                            this.gaService.send(
                                GAContants.HitType.EVENT,
                                GAContants.EventCategory.NETWORK,
                                GAContants.EventAction['FILE-UPLOAD-END'],
                                GAContants.EventLabel.CONVERT,
                                `${localFileWithMetadata.metadata.file.name}`,
                            );
                            this.wsService.unregisterCallback(removableCallback);
                        }

                        if (!localFileWithMetadata.getComplete()) {
                            localFileWithMetadata.setProgress(parseFloat(parseFloat(progress + '').toFixed(2)));
                        } else {
                            localFileWithMetadata.setProgress(parseFloat(parseFloat('100').toFixed(2)));
                        }
                    }
                };
            };
            const removableCallback = (data) => progressCallback(fileWithMetadata)(data);
            this.wsService.registerCallback(removableCallback);
            const url = ProcessorMetadataUtils.getUploadEndpoint(uploadRequestMetadata.processorMetadataList[0]) + '/google-drive';
            const result: any = await this.http.post(url, fileWithMetadata.getFile(), {
                params: {
                    wsId: this.wsService.getId(),
                    wsDomain: this.wsService.getDomain(),
                    safeFilename: fileWithMetadata.metadata.processorMetadataList[0].fileMetadata.safeName,
                },
            }).toPromise();

            const info = {
                ftype: FilenameUtils.getFileMimeType(fileWithMetadata.getFile()),
                fname: fileWithMetadata.getFile().name,
                fsize: fileWithMetadata.getFile().size,
                flastModified: fileWithMetadata.getFile().lastModified,
            };
            const originalInputFiles = result.originalInputFiles[0];
            originalInputFiles.metadata = info;
            return originalInputFiles;
        }
    }

    private async uploadGCSingleFile(fileWithMetadata: UploadInProgressFile<File>, progressObserver: Observer<any>): Promise<any> {
        const file: File = fileWithMetadata.getFile();
        const total = file.size;
        const processorMetadata = fileWithMetadata.getMetadata().processorMetadataList[0];
        return await new Promise((resolve, reject) => {
            const url = ProcessorMetadataUtils.getUploadEndpoint(processorMetadata);
            const { response$, events$ } = this.http.request(
                url,
                'PUT',
                file,
                {
                    headers: this.http.getCommonHeaders(url),
                }
            );
            events$.subscribe((event: HttpProgressEvent) => {
                if (event.type === 'upload_progress') {
                    // progress = Math.round(100 * event.loaded / event.total);
                    const progress = Math.round(100 * event.event.loaded / total);
                    progressObserver.next(progress);
                }
            });
            response$.subscribe({
                next: () => {
                    this.logger.trace7({
                        message: 'Successful GSC single file upload: ' + processorMetadata.fileMetadata.name,
                        data: processorMetadata,
                    });
                    const postProcessorMetadata = processorMetadata.postProcessMetadata;
                    if (postProcessorMetadata) {
                        const fileUploadEndpoint = ProcessorMetadataUtils.getUploadEndpoint(postProcessorMetadata) + '/store-gcs-file';
                        const formData = new FormData();
                        formData.append('originalInputFiles', JSON.stringify({
                            // Change this from event.url in an effort to remove HttpClient
                            location: url,
                            storageType: 'gcs',
                            originalName: processorMetadata.fileMetadata.name,
                            metadata: {
                                ftype: FilenameUtils.getFileMimeType(file),
                                fname: file.name,
                                fsize: file.size,
                                flastModified: file.lastModified,
                            },
                            gcName: processorMetadata.fileMetadata.gcName,
                        } as OriginalInputFile));
                        this.http.post<{ originalInputFiles: OriginalInputFile[] }>(fileUploadEndpoint, formData).subscribe(
                            (resp: { originalInputFiles: OriginalInputFile[] }) => {
                                fileWithMetadata.getMetadata().replceProcessMetadataWithPostProcessMetadata(0);
                                const info = {
                                    ftype: FilenameUtils.getFileMimeType(file),
                                    fname: file.name,
                                    fsize: file.size,
                                    flastModified: file.lastModified,
                                };
                                resolve(Object.assign(resp.originalInputFiles[0], { metadata: info }));
                                progressObserver.complete();
                            },
                            (err) => {
                                this.logger.error(err);
                            },
                        );

                    } else {
                        resolve({
                            location: url,
                            storageType: 'gcs',
                            originalName: processorMetadata.fileMetadata.name,
                            metadata: {
                                ftype: FilenameUtils.getFileMimeType(file),
                                fname: file.name,
                                fsize: file.size,
                                flastModified: file.lastModified,
                            },
                            gcName: processorMetadata.fileMetadata.gcName,
                        } as OriginalInputFile);
                        progressObserver.complete();
                    }
                },
                error: (err) => {
                    this.logger.error({
                        message: 'Error GSC single file upload: ' + processorMetadata.fileMetadata.name,
                        data: processorMetadata,
                    });
                    reject({
                        location: url,
                        storageType: 'gcs',
                        originalName: processorMetadata.fileMetadata.name,
                        metadata: {
                            ftype: FilenameUtils.getFileMimeType(file),
                            fname: file.name,
                            fsize: file.size,
                            flastModified: file.lastModified,
                        },
                        gcName: processorMetadata.fileMetadata.gcName,
                    } as OriginalInputFile);
                }
            });
        });
    }

    // This is a single file
    public async uploadFileFromLocalDisk(
        fileWithMetadata: UploadInProgressFile<File>,
        progressObserver: Observer<any>,
    ): Promise<OriginalInputFile> {
        this.notifyFileUploadStart();
        const uploadRequestMetadata: FileUploadRequestMetadata = fileWithMetadata.getMetadata();
        this.logger.trace7({ message: 'File upload from disk', data: uploadRequestMetadata });
        if (uploadRequestMetadata.processorMetadataList[0].uploadDomain.indexOf('localhost') !== -1) {
            this.uiContext.errorToast('Error', 'Running in Test Mode.', false, false);
        }
        this.gaService.send(
            GAContants.HitType.EVENT,
            GAContants.EventCategory.NETWORK,
            GAContants.EventAction['FILE-UPLOAD-START'],
            GAContants.EventLabel.CONVERT,
            `${fileWithMetadata.metadata.file.name}`,
        );
        if (uploadRequestMetadata.processorMetadataList[0].uploadType === PSUploadTypes.SERVER) {
            const endpointSuffix = this.chunkUploadEnabled ? "res": "file"
            const fileUploadEndpoint = ProcessorMetadataUtils.getUploadEndpoint(uploadRequestMetadata.processorMetadataList[0]) + `/${endpointSuffix}`;
            return this.uploadSingleFile(fileUploadEndpoint, fileWithMetadata.getMetadata(), fileWithMetadata.getFile(), progressObserver)
                .then((uploadResponse: OriginalInputFile) => {
                    this.gaService.send(
                        GAContants.HitType.EVENT,
                        GAContants.EventCategory.NETWORK,
                        GAContants.EventAction['FILE-UPLOAD-END'],
                        GAContants.EventLabel.CONVERT,
                        `${fileWithMetadata.metadata.file.name}`,
                    );
                    return uploadResponse;
                })
                .catch(err => {
                    this.logger.error({ message: `Disk file upload failed due to ${err.message}`, error: err });
                    this.gaService.send(
                        GAContants.HitType.EVENT,
                        GAContants.EventCategory.MESSAGE,
                        GAContants.EventAction['FILE-UPLOAD-FAILED'],
                        GAContants.EventLabel.CONVERT,
                        '',
                    );
                    this.uiContext.showErrorDialog({ title: 'Error', text: 'Could not update the file' });
                    throw new Error('Could not update the file');
                });
        } else {
            return this.uploadGCSingleFile(fileWithMetadata, progressObserver)
                .then(uploadResponse => {
                    this.gaService.send(
                        GAContants.HitType.EVENT,
                        GAContants.EventCategory.NETWORK,
                        GAContants.EventAction['FILE-UPLOAD-END'],
                        GAContants.EventLabel.CONVERT,
                        `${fileWithMetadata.metadata.file.name}`,
                    );
                    return uploadResponse;
                })
                .catch(err => {
                    this.logger.error({ message: `Disk file upload to GSC failed due to ${err.message}`, error: err });
                    this.gaService.send(
                        GAContants.HitType.EVENT,
                        GAContants.EventCategory.MESSAGE,
                        GAContants.EventAction['FILE-UPLOAD-FAILED'],
                        GAContants.EventLabel.CONVERT,
                        '',
                    );
                    this.uiContext.showErrorDialog({ title: 'Error', text: 'Could not update the file' });
                    throw new Error('Could not update the file to GCP');
                });
        }
    }

    private uploadSingleFile(
        url: string, fileMetadata: FileUploadRequestMetadata, file: File, progressObserver: Observer<any>,
    ): Promise<any> {
        if(!this.chunkUploadEnabled) {
            return this.directUploadSingleFile(url, fileMetadata, file, progressObserver)
        }
        const fn = () => {
            return (resolve, reject) => {
                // HPY NOTE uploaded files should not come here, this service only to upload files
                const info = {
                    ftype: FilenameUtils.getFileMimeType(file),
                    fname: file.name,
                    fsize: file.size,
                    flastModified: file.lastModified,
                };
                this.chunkUploadSingleFile(url, fileMetadata, file, progressObserver)
                    .then(accuReply => {
                        resolve(Object.assign(accuReply.originalInputFiles[0], { metadata: info }));
                    }).catch(reject);
            };
        };
        return new Promise(fn());
    }

    private directUploadSingleFile(
        url: string,
        fileMetadata: FileUploadRequestMetadata,
        file: File,
        progressObserver: Observer<any>,
    ) {
        const safeFilename = fileMetadata.processorMetadataList[0].fileMetadata.safeName;
        const uploadUUID = StringUtils.uuid();
        const formData = new FormData();
        formData.append('files', file);

        const info = {
            ftype: FilenameUtils.getFileMimeType(file),
            fname: file.name,
            fsize: file.size,
            flastModified: file.lastModified,
        };

        return new Promise((resolve, reject) => {
            const total = file.size
            const { response$, events$ } = this.http.request(
                url,
                'POST',
                formData,
                {
                    headers: this.http.getCommonHeaders(url),
                        params: {
                            safeName: safeFilename,
                            uuid: uploadUUID,
                    }
                },
                
            );
            events$.subscribe((event: HttpProgressEvent) => {
                if (event.type === 'upload_progress') {
                    const progress = Math.round(100 * event.event.loaded / total);
                    progressObserver.next(progress);
                }
            });
            response$.subscribe({
                next: (accuReply: any) => {
                    resolve(Object.assign(accuReply.originalInputFiles[0], { metadata: info }));
                    progressObserver.complete()
                },
                error: (e) => {
                    progressObserver.error(e)
                }
            })
        })

    }

    private async chunkUploadSingleFile(
        url: string,
        fileMetadata: FileUploadRequestMetadata,
        file: File,
        progressObserver: Observer<any>,
    ): Promise<any> {
        const fn = () => {
            const safeFilename = fileMetadata.processorMetadataList[0].fileMetadata.safeName;
            return (resolve: (data) => void, reject: (error) => void) => {
                const uploadEndpoint = url;
                const accuReply = {
                    originalInputFiles: [],
                };
                const uploadUUID = StringUtils.uuid();
                let errorOccured = false;
                const r = new Resumable({
                    target: uploadEndpoint,
                    chunkSize: this.chunkSize, // 1 MB 40 * 1024
                    simultaneousUploads: this.simultaneousUploads,
                    testChunks: false,
                    method: 'octet',
                    prioritizeFirstAndLastChunk: true,
                    query: {
                        safeName: safeFilename,
                        uuid: uploadUUID,
                    },
                    maxFileSize: this.maxFileSizeInBytes as any,
                    headers: this.http.getCommonHeaders(uploadEndpoint)
                });
                if (!r.support) {
                    alert('Your browser does not support this feature');
                    this.logger.error({ message: 'Your browser does not support this feature', data: { url, } });
                } // TODO: do better error handling

                r.on('fileProgress', (f, message) => {
                    const progress = r.progress();
                    progressObserver.next(parseFloat(parseFloat((progress * 100) + '').toFixed(2)));
                });

                r.on('fileSuccess', (f, message) => {
                    this.logger.trace7({
                        message: `Chunk file upload succcessfully ended. ${message}`,
                    });
                    try {
                        if (isString(message)) {
                            message = JSON.parse(message);
                            accuReply.originalInputFiles.push(...message.originalInputFiles);
                        } else {
                            errorOccured = true;
                            this.logger.fatal({ message: 'Fatal since fileSuccess couldnt be added to accuReply.originalInputFiles: ' + message, data: { url, } });
                            // alert('File upload failed. Please kindly let us know about this at support@xconvert.com');
                        }
                    } catch (e) {
                        this.logger.fatal({
                            message: 'Error thrown while generating accuReply.originalInputFiles: ' + message + ` ${uploadUUID}`,
                            error: e,
                            data: { url, uploadUUID }
                        });
                        errorOccured = true;
                    }
                });

                r.on('complete', (f, message) => {
                    if (errorOccured) {
                        this.updateFileUploadFileOriginalInputFilesAndResolve(url, uploadUUID, safeFilename, accuReply, progressObserver, resolve);
                    } else {
                        this.logger.trace7({
                            message: `Chunk file upload completed. ${message}`,
                        });
                        progressObserver.complete();
                        resolve(accuReply);
                    }
                });

                r.on('catchAll', (event, ...args) => {
                    //console.log(event, args);
                });

                r.on('cancel', () => {
                    //this.logger.log('cancel');
                });

                r.on('fileAdded', (f, event) => {
                    r.upload();
                });

                r.on('error', (e, rfile) => {
                    this.logger.error({ error: new Error(e), message: ` Error thrown by file uploader: ${e} ${uploadUUID}`, data: { uploadUUID } });
                    errorOccured = true;
                    reject(e);
                });

                r.addFile(file);
            };
        };
        return new Promise(fn());
    }

    private updateFileUploadFileOriginalInputFilesAndResolve(url, uploadUUID, safeFilename, accuReply, progressObserver, resolve) {
        this.http.get<{ [key: string]: [] }>(url + '/orignal-input-files', {
            params: {
                uuid: uploadUUID,
                safeName: safeFilename
            },
        }).pipe(
            mergeMap((resp) => {
                if (!resp || !resp.originalInputFiles) {
                    return of({}).pipe(
                        delay(2000),
                        mergeMap(() => {
                            return throwError(() => {
                                const error = new Error(`Request to get original uploaded file data returned null. ${uploadUUID} ${resp} ${url}`);
                                return error;
                            });
                        }),
                    );
                }
                return of(resp)
            }),
            retry(3),
        ).subscribe({
            next: (resp: any) => {
                this.logger.trace7({ message: `Chunk upload completed ${uploadUUID}`, data: { url, resp } });
                accuReply.originalInputFiles.push(...resp.originalInputFiles);
                progressObserver.complete();
                resolve(accuReply);
            },
            error: (err) => {
                progressObserver.error(err);
                this.logger.error({ message: `Error file upload through chunk upload.${uploadUUID}`, error: err, data: { url, } });
                this.uiContext.showErrorDialog({ title: 'Error', text: 'File upload failed. Please refresh page and try gain.' });
            },
        });
    }
}
