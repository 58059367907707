import { plainToClass } from 'class-transformer';
import { inject, injectable } from 'inversify';
import { ReplaySubject } from 'rxjs';
import { delay, filter, mergeMap, retryWhen, take } from 'rxjs/operators';

import { BrowserUtils } from '../ui-utils/browser-utils';
import { PSDate } from '../utils/date.utils';
import { MotiveConfig, MotiveSettingsResponse, MotiveSlotConfig } from '../utils/request.utils';
import { ApiConfig } from './api-config';
import { LightHttp } from './light-http.service';
import { LoginService } from './login.service';
import { SettingsService } from './settings.service';
import { UIContext } from './ui-context';
import { MotiveDisabledDate, UserPreferenceService, UserPreferenceTypes } from './user-preference.service';
import { WindowService } from './window.service';


export declare namespace GoogleMotive {
    export const DOWNLOAD_PAGE_TOP = '4411354276';
    export const DOWNLOAD_PAGE_LEFT = '1772269566';
    export const DOWNLOAD_PAGE_RIGHT = '4206861217';
    export const DOWNLOAD_PAGE_BOTTOM = '5895347409';
}

@injectable()
export class MotivesService {
    private _initialMotives: MotiveSettingsResponse;
    get initialMotives(): MotiveSettingsResponse {
        return this._initialMotives;
    }
    set initialMotives(_initialMotives: MotiveSettingsResponse) {
        this._initialMotives = _initialMotives;
        this.setMotives(this._initialMotives);
        this.updateCurrentUserMotiveConfig();
    }

    get freeUserMotives(): MotiveConfig {
        return this.initialMotives ? this.initialMotives.free : {} as any;
    }

    private motivesEnabled = true;
    private forceNoMotives = false;
    canDisplayAdsense = false;
    motiveSourceLoadStarted = false;


    get internalAdsEnabled() {
        // TODO need to check if a user is a paid user
        return this.motives && this.motives && this.motives.free.internalMotivesEnabled;
    }

    get motiveReady(): boolean {
        return !this.forceNoMotives && this.canDisplayAdsense;
    }

    readonly _onMotiveSettingsChange = new ReplaySubject<MotiveSettingsResponse>(1);
    get onMotiveSettingsChange() {
        return this._onMotiveSettingsChange.asObservable();
    }

    readonly _onEffectiveMotiveSettingsChange = new ReplaySubject<MotiveConfig>(1);
    get onEffectiveMotiveSettingsChange() {
        return this._onEffectiveMotiveSettingsChange.asObservable();
    }

    private _effectiveMotives: MotiveConfig;
    get effectiveMotives(): MotiveConfig {
        return this._effectiveMotives;
    }

    private _motiveConfig: MotiveConfig;
    get currentUserMotiveConfig() {
        return this._motiveConfig;
    }

    private motives: MotiveSettingsResponse;

    constructor(
        @inject(SettingsService) private settingsService: SettingsService,
        @inject(UserPreferenceService) private userPreferenceService: UserPreferenceService,
        @inject(LoginService) private loginService: LoginService,
        @inject(UIContext) private uiContext: UIContext,
        @inject(LightHttp) private http: LightHttp,
        @inject(ApiConfig) private apiConfig: ApiConfig,
        @inject(WindowService) private windowService: WindowService,
    ) {
        const disabledDate = this.userPreferenceService.getPreference<MotiveDisabledDate>(UserPreferenceTypes.MOTIVES_DISABLED_DATE, MotiveDisabledDate);
        if (disabledDate && disabledDate.date) {
            if (PSDate.from(disabledDate.date).isAfter(PSDate.from(new Date).subtract(1, 'hours'))) {
                this.motivesEnabled = false;
            }
        }
        this.loginService.onUserDetailsChanged.subscribe(
            () => {
                this.updateCurrentUserMotiveConfig();
                this.calculateEffictiveMotives();
            },
        );

        this.settingsService.userSettings.subscribe({
            next: (userSettings) => {
                this.setMotives(userSettings.motive);
                this.updateCurrentUserMotiveConfig();
                this.calculateEffictiveMotives();
            },
        });

        this.userPreferenceService.preferenceChange.pipe(
            filter(val => val.preferenceKey === UserPreferenceTypes.MOTIVES_DISABLED_DATE)
        ).subscribe({
            next: (preference) => {
                const disabledDate: MotiveDisabledDate = preference.value;
                if (disabledDate && disabledDate.date && PSDate.from(disabledDate.date).isAfter(PSDate.from(new Date).subtract(1, 'hours'))) {
                    this.motivesEnabled = false;
                } else {
                    this.motivesEnabled = true;
                }
                this.calculateEffictiveMotives();
            },
        });
    }

    initialize() {
        this.loadMotiveSource();
        try {
            if (!BrowserUtils.hasDocument()) {
                console.error("Requested execution aborted since document was not avalilable");
                return;
            }
            this.forceNoMotives = !!document.location.search.match(/noMotives/);
        } catch {
            // skip
        }
    }

    private loadMotiveSource() {
        try {
            if (!this.motiveSourceLoadStarted) {
                this.motiveSourceLoadStarted = true;
                // Enable this if adsense script removed from the header
                // await this.scriptLoadService.loadAdsense();
                const w = window as any;
                w.adsbygoogle = w.adsbygoogle || [];
                this.canDisplayAdsense = true;
                try {
                    this.saveMotiveStatics(true);
                } catch (e) {
                    // Too much to handle. calling log here will case recursve call to ths fn
                }
            }
        } catch (e) {
            // if (isPlatformBrowser(this.platformId)) {
            this.uiContext.errorToast(
                `Please consider whitelisting xconvert.com on your AdBlocker.`,
                'Hi Friend',
                false,
                false
            );
            try {
                this.saveMotiveStatics(false);
            } catch (e) {
                // Too much to handle. calling log here will case recursve call to ths fn
            }
            // }
        }
    }

    public saveMotiveStatics(enabled: boolean) {
        try {
            const userEndpoint = '/users';
            const sessionDataEndPoint = `${userEndpoint}/session/info`;
            this.windowService.userClicked.pipe(
                mergeMap(() => {
                    return this.http.post(
                        `${this.apiConfig.getHttpsEndpoint()}${sessionDataEndPoint}`,
                        { enabled },
                        {
                            headers: { 'Content-Type': 'application/json' },
                        });
                }),
                retryWhen(errors => errors.pipe(delay(1000), take(2))),
            ).subscribe({
                error: (err) => {
                    // skip
                }
            });
        } catch (e) {
            // not need
        }
    }

    private calculateEffictiveMotives() {
        const currentUserMotiveConfig = plainToClass(MotiveSlotConfig, this.currentUserMotiveConfig || {}, { strategy: 'excludeAll' });
        const _effectiveMotives = Object.assign({}, this.currentUserMotiveConfig, currentUserMotiveConfig);
        Object.keys(currentUserMotiveConfig).forEach(key => {
            _effectiveMotives[key] = this.currentUserMotiveConfig[key] && this.motivesEnabled;
        });

        this._effectiveMotives = _effectiveMotives;
        this._onEffectiveMotiveSettingsChange.next(this.effectiveMotives);
    }

    private updateCurrentUserMotiveConfig() {
        if (this.motives) {
            if (this.loginService.userDetails && this.loginService.userDetails.isPaidUser()) {
                this._motiveConfig = this.motives.paid;
            } else {
                this._motiveConfig = this.motives.free;
            }
        } else {
            this._motiveConfig = {} as any;
        }
    }

    private setMotives(motives: MotiveSettingsResponse) {
        this.motives = motives;
        this._onMotiveSettingsChange.next(motives);
    }
}
