import { Component, createRef } from 'react';

import { ScriptLoadService } from '../../../services/script-load.service';
import { WindowService } from '../../../services/window.service';
import { IOC_TYPES } from '../../../context/ioc.types';
import { lazyInject } from '../../../context/module';

declare var grecaptcha;

export interface ReCaptchaProps {
    onChange: (data) => void;
}

export default class ReCaptcha extends Component<ReCaptchaProps> {
    elementRef = createRef<HTMLDivElement>();
    @lazyInject(IOC_TYPES.WindowService) private windowService: WindowService;
    @lazyInject(IOC_TYPES.ScriptLoadService) private scriptLoaderService: ScriptLoadService;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.windowService.userEngaged.subscribe({
            next: async () => {
                await this.scriptLoaderService.loadAllScriptsIfNotLoaded();
                const render = () => {
                    try {
                        grecaptcha.render(this.elementRef.current, {
                            'sitekey': '6LdusUYUAAAAAKBS86tYOM5qjp-MaFs6I_ZwPLCq',
                            'callback': (data) => {
                                if (this.props.onChange) {
                                    this.props.onChange(data);
                                }
                            },
                            'expired-callback': (data) => {
                                if (this.props.onChange) {
                                    this.props.onChange(null);
                                }
                            },
                            'error-callback': (data) => {
                                if (this.props.onChange) {
                                    this.props.onChange(null);
                                }
                            },
                        });
                    } catch {
                        setTimeout(() => render(), 1000);
                    }
                }
                render();
            }
        });
    }

    render() {
        return <div >
            <div ref={this.elementRef}></div>
        </div>
    }
}