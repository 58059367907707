import { inject, injectable } from 'inversify';
import { Observable } from 'rxjs';

import { ApiConfig } from './api-config';
import { LightHttp } from './light-http.service';

@injectable()
export class MarkdownService {
    constructor(
        @inject(LightHttp) private http: LightHttp,
        @inject(ApiConfig) private apiConfig: ApiConfig,
    ) { }

    // convertMarkdownToHtmlString(markdownFileUrl: String) {
    //     const converterEndpoint = this.apiConfig.apiEndpoint + `${this.apiConfig.pathPrefix}/converter/comm/markdown-to-html`;
    //     return this.http.post(converterEndpoint, { markdownFileUrl }, { responseType: 'text' });
    // }

    convertMarkdownToHtmlString(markdownFileUrl: string): Observable<string> {
        const converterEndpoint = this.apiConfig.getHttpsEndpoint() + `/markdown/markdown-to-html`;
        return this.http.get<string>(converterEndpoint, { responseType: 'text', headers: { 'get-data': markdownFileUrl } });
    }
}