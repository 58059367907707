import { CompatClient, IMessage, Stomp } from '@stomp/stompjs';
import { inject, injectable } from 'inversify';
import castArray from 'lodash/castArray';
import isEqual from 'lodash/isEqual';
import SockJS from 'sockjs-client';
import { Logger } from '../models/logger';
import { StringUtils } from '../utils/string-utils';
import { StorageKeyPrefixeTypes, StorageService } from './storage.service';

class Message {
    createdDate: string;
    id: string;
    jsonStringifiedMessageContent: string;
    messageId: string;
    sessionAckMap: { [sessionId: string]: boolean };
    wsId: string;
}

const LAST_SAVED_WEBSOCKET_MESSAGE_ID_STORAGE_KEY = 'LAST_SAVED_WEBSOCKET_MESSAGE_ID';

@injectable()
export class SocketJSService {
    private readonly logger = Logger.getLogger('SocketJSService');
    private wsId;
    private wsDomain;
    private socket: WebSocket;
    private stompClient: CompatClient;
    private recInterval;
    private sessionId = StringUtils.getRandomString();

    private lastMessageIds: string[];
    private lastMessages: Message[];
    private messagesData: any;
    private lastMessageReceivedTime = new Date();
    private messageReceiver: (message) => void;

    autoResponseInterval;
    isProcessingMessages = false;
    constructor(
        @inject(StorageService) private storageService: StorageService,
    ) { }

    private setupAutoClientResponse(): void {
        this.autoResponseInterval = setInterval(() => {
            if (this.isProcessingMessages) {
                return;
            }
            if (Date.now() - this.lastMessageReceivedTime.getTime() > 2000) {
                this.sendNextMessage();
            }
        }, 2000);
    }


    /**
     * Sending a custom message
     * @param message 
     * @returns 
     */
    public sendMessage(message: any) {
        this.lastMessageReceivedTime = new Date();
        if (!this.stompClient?.connected) {
            return;
        }
        this.stompClient.publish({
            destination: `/app/${this.wsId}/client-responses`,
            body: JSON.stringify(message),
        })
    }

    private sendNextMessage() {
        this.lastMessageReceivedTime = new Date();
        if (!this.stompClient?.connected) {
            return;
        }
        this.stompClient.publish({
            destination: `/app/${this.wsId}/client-responses`,
            body: JSON.stringify({
                latestMessageIds: this.lastMessageIds,
                wsId: this.wsId,
                sessionId: this.sessionId
            }),
        })
    }

    public connect(wsDomain, wsId: string, messageReceiver: (message) => void) {
        if (this.isConnected) {
            return;
        }
        this.restoreLastReceivedMessageIdsFromStorage();
        this.logger.debug("SockJS connect called.");
        this.disconnect();
        this.messageReceiver = messageReceiver;
        this.wsId = wsId;
        this.wsDomain = wsDomain;
        this.initializeStopClientWithSockJS();
        this.setupAutoClientResponse();
    }

    private initializeStopClientWithSockJS() {
        const url = `${this.wsDomain}/api/notifications/user`
        this.stompClient = Stomp.over(() => {
            this.socket = new SockJS(
                url,
                null,
                { transports: ['websocket', 'xhr-polling'] } // websocket
            );
            return this.socket;
        });
        this.stompClient.debug = () => { };
        this.stompClient.reconnectDelay = 10000;

        this.connectStompClient();

        this.stompClient.onDisconnect = () => {
            this.disconnect();
            this.logger.debug('SockJS onDisconnect called. Reconnecting');
            this.recInterval = setInterval(function () {
                this.connectStompClient();
            }, 2000);
        };
    }

    private connectStompClient() {
        // Secure this by sending clients Access Token for server-side validation of it
        this.stompClient.connect({}, (frame) => {
            this.logger.debug('Stomp client is connected: ' + frame);
            this.stompClient.subscribe(`/topic/messages/${this.wsId}/${this.sessionId}`, (messageOutput: IMessage) => {
                this.processReceivedMessage(messageOutput);
                this.sendNextMessage();
            });
        });
        clearInterval(this.recInterval);
        clearInterval(this.autoResponseInterval);
    }

    private processReceivedMessage(messageOutput: IMessage) {
        this.isProcessingMessages = true;
        try {
            const messages: Message[] = JSON.parse(messageOutput.body);
            this.lastMessages = (messages || []);
            this.saveLastMessageIds(this.lastMessages);
            this.messagesData = (messages || []).map(message => JSON.parse(message?.jsonStringifiedMessageContent)).filter(i => i);
            this.saveLastReceivedMessageId();
            this.messagesData.forEach(messageData => this.messageReceiver(messageData));
        } catch (e) {
            this.logger.error({
                message: "Error was thrown while decoding Stomp client message.",
                error: e
            });
        }
        this.isProcessingMessages = false;
    }

    private saveLastMessageIds(lastMessages: Message[]) {
        const sortedMessagesByDateDescending = lastMessages.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
        const messageIds = sortedMessagesByDateDescending.map(i => i.messageId).filter(i => i);
        if (isEqual(messageIds, this.lastMessageIds)) {
            // messageId list was same as before. Only save the latest message's id to improve network and DB performance
            const lastMessageId = sortedMessagesByDateDescending[0].messageId;
            this.lastMessageIds = [lastMessageId];
        } else {
            this.lastMessageIds = messageIds;
        }
    }

    private get isConnected(): boolean {
        return this.stompClient?.connected ?? false;
    }

    private disconnect() {
        this.logger.debug("SockJS disconnect called");
        this.stompClient?.disconnect();
        this.socket?.close();
    }

    private restoreLastReceivedMessageIdsFromStorage(): void {
        const wsDomainStorageKey = LAST_SAVED_WEBSOCKET_MESSAGE_ID_STORAGE_KEY;
        const userSettingsKeyPrefix = StorageKeyPrefixeTypes.USER_SETTING_KEY_PREFIX;
        const storedWSDomain = this.storageService.get<string[]>(userSettingsKeyPrefix, wsDomainStorageKey) ?? undefined;
        this.lastMessageIds = castArray(storedWSDomain).filter(i => i);
    }

    private saveLastReceivedMessageId() {
        this.storageService.store(
            StorageKeyPrefixeTypes.USER_SETTING_KEY_PREFIX,
            LAST_SAVED_WEBSOCKET_MESSAGE_ID_STORAGE_KEY,
            this.lastMessageIds,
        );
    }
}