
// https://www.w3schools.com/charsets/ref_utf_dingbats.asp
// https://www.toptal.com/designers/htmlarrows/symbols/information-source/
// https://www.compart.com/en/unicode/U+1F5A5
export type HtmlEntityType = string;
export class HtmlEntityTypes {
    public static WARNING = '&#9888;';
    public static CROSS = '&#10540;';
    public static CHECK = '&#10003;';
    public static INFO = '&#8505;';
    public static INFO_WITH_CIRCLE = '&#128712;';
    public static TRICOLON = '&#8285;';
    public static STAR = '&#9733;';
    public static HALF_START = '&#10029;';
    public static NO_STAR = '&#9734;';
    public static UNDO = '&#8634;';
    public static REDO = '&#8635;';
    public static ARROW_UP = '&#x2bc5;';
    public static ARROW_DOWN = '&#x2bc6;';
    public static ARROW_FORWARD = '&#x2bc8;';
    public static ARROW_BACKWARD = '&#x2bc7;';
    public static PAUSE = '&#9208;';
    public static CLOUD = '&#9729;';
    public static AIRPLANE = '&#9992;';
    public static BULB = '&#x1f4a1;';
    public static PADLOCK_CLOSE = '&#x1F512;';
    public static PADLOCK_OPEN = '&#x1F513;';
    public static DESKTOP_COMPUTER = '&#x1F5A5;';
    public static ANTENNA = '&#x1F4E1;';
    public static ARROW_LEFT_RIGHT = '&#8644;';
    public static ARROW_UP_DOWN = '&#8645;';
    public static PENSIL = '&#9998;';
    public static TRASH_BIN = '&#128465;';
    public static PLUS = '&#43;';
    public static MINUS ='&#8722;';
    public static PLUS_BOLD = '&#10133;';
    public static ARROW_CROSS_HEAD_NORTH_AND_EAST = '&#10542;';
    public static ARROW_CROSS_HEAD_SOUTH_AND_WEST = '&#10538;';
    public static EDIT_EMOJO='&#128221';
    public static UPLOAD_TRAY = '&#128228;';
    public static DOWNLOAD_TRAY = '&#128229;';
    public static SEARCH = '&#128269;';
    public static CHECK_MARK = '&#10003;';
    public static CHECK_MARK_BOLD = '&#10004;';
    public static X = '&#10005;';
    public static X_BOLD = '&#10006;';
    public static RESIZE_EXPAND = '&#10021;';
    public static RESIZE_CONTRACT = '&#10016;';
}

