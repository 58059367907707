import { Subscription } from 'rxjs';

export class RxUtils {
    public static safeCloseSubscriber(sub: Subscription) {
        if (sub && !sub.closed) {
            sub.unsubscribe();
        }
    }

    public static safeCloseSubscribers(subs: Subscription[]) {
        (subs || []).forEach(this.safeCloseSubscriber)
    }
}
