import { Commons } from '../commons/commons';

export type LogLevel = 1300 |
    1200 |
    1100 |
    1000 |
    900 |
    800 |
    700 |
    600 |
    500 |
    400 |
    300 |
    200 |
    100;

export class LogLevelTypes {
    public static TRACE_7: LogLevel = 1300;
    public static TRACE_6: LogLevel = 1200;
    public static TRACE_5: LogLevel = 1100;
    public static TRACE_4: LogLevel = 1000;
    public static TRACE_3: LogLevel = 900;
    public static TRACE_2: LogLevel = 800;
    public static TRACE_1: LogLevel = 700;
    public static TRACE: LogLevel = 600;
    public static DEBUG: LogLevel = 500;
    public static INFO: LogLevel = 400;
    public static WARN: LogLevel = 300;
    public static ERROR: LogLevel = 200;
    public static FATAL: LogLevel = 100;

    private static logTypeOrder = {
        [LogLevelTypes.TRACE_7]: 1,
        [LogLevelTypes.TRACE_6]: 2,
        [LogLevelTypes.TRACE_5]: 3,
        [LogLevelTypes.TRACE_4]: 4,
        [LogLevelTypes.TRACE_3]: 5,
        [LogLevelTypes.TRACE_2]: 6,
        [LogLevelTypes.TRACE_1]: 7,
        [LogLevelTypes.TRACE]: 8,
        [LogLevelTypes.DEBUG]: 9,
        [LogLevelTypes.INFO]: 10,
        [LogLevelTypes.WARN]: 11,
        [LogLevelTypes.ERROR]: 12,
        [LogLevelTypes.FATAL]: 13,
    };

    public static logLevelToReadableString(level: LogLevel): string {
        switch (level) {
            case LogLevelTypes.TRACE_7: return 'TRACE_7';
            case LogLevelTypes.TRACE_6: return 'TRACE_6';
            case LogLevelTypes.TRACE_5: return 'TRACE_5';
            case LogLevelTypes.TRACE_4: return 'TRACE_4';
            case LogLevelTypes.TRACE_3: return 'TRACE_3';
            case LogLevelTypes.TRACE_2: return 'TRACE_2';
            case LogLevelTypes.TRACE_1: return 'TRACE_1';
            case LogLevelTypes.TRACE: return 'TRACE';
            case LogLevelTypes.DEBUG: return 'DEBUG';
            case LogLevelTypes.INFO: return 'INFO';
            case LogLevelTypes.WARN: return 'WARN';
            case LogLevelTypes.ERROR: return 'ERROR';
            case LogLevelTypes.FATAL: return 'FATAL';
            default: return 'UNKNOWN';
        }
    }

    public static logReadableStringToLevel(readableLevel: string): LogLevel {
        switch (readableLevel) {
            case 'TRACE_7': return LogLevelTypes.TRACE_7;
            case 'TRACE_6': return LogLevelTypes.TRACE_6;
            case 'TRACE_5': return LogLevelTypes.TRACE_5;
            case 'TRACE_4': return LogLevelTypes.TRACE_4;
            case 'TRACE_3': return LogLevelTypes.TRACE_3;
            case 'TRACE_2': return LogLevelTypes.TRACE_2;
            case 'TRACE_1': return LogLevelTypes.TRACE_1;
            case 'TRACE': return LogLevelTypes.TRACE;
            case 'DEBUG': return LogLevelTypes.DEBUG;
            case 'INFO': return LogLevelTypes.INFO;
            case 'WARN': return LogLevelTypes.WARN;
            case 'ERROR': return LogLevelTypes.ERROR;
            case 'FATAL': return LogLevelTypes.FATAL;
            default: LogLevelTypes.DEBUG;
        }
    }

    public static isLogTypeEnabled(level: LogLevel) {
        const result = this.logTypeOrder[level] >= this.logTypeOrder[Commons.DEBUG_LEVEL];
        return result;
    }
}