import { inject, injectable } from 'inversify';

import { GAType } from '../types/ga.types';
import { BrowserUtils } from '../ui-utils/browser-utils';
import { includes } from '../utils/lodash-min';
import { UserTraceService } from './user-trace.service';
import { WebSocketService } from './websocket.service';

declare var gtag;

@injectable()
export class GAService {
    tracker: any;

    constructor(
        @inject(WebSocketService) private websockerService: WebSocketService,
        @inject(UserTraceService) private userTraceService: UserTraceService,
    ) {
        if (BrowserUtils.hasWindow()) {
            try {
                gtag('set', { 'user_id': this.websockerService.getOrCreateStoredWSId() });
            } catch (e) {
                // TODO ignore
            }
        }
    }

    pageview(pageUrl: string) {
        if (BrowserUtils.hasWindow() && pageUrl) {
            try {
                if ('gtag' in window) {
                    gtag('config', 'G-SJCXH1QF9N', { 'page_path': pageUrl });
                }
            } catch (e) {
                console.error(e);
            }
        }
    }

    send(
        hitType: GAType.HitType,
        eventCategory: GAType.EventCategory,
        eventAction: GAType.EventAction,
        eventLabel: GAType.EventLabel,
        eventValue: any,
        tries = 0,
    ) {
        this.sendAsync(hitType, eventCategory, eventAction, eventLabel, eventValue, tries);
    }

    private async sendAsync(
        hitType: GAType.HitType,
        eventCategory: GAType.EventCategory,
        eventAction: GAType.EventAction,
        eventLabel: GAType.EventLabel,
        eventValue: any,
        tries = 0,
    ) {
        if (BrowserUtils.hasWindow()) {
            if ('gtag' in window) {
                gtag = window['gtag'];
                if (gtag && this.shouldSendEvent(eventAction)) {
                    gtag(
                        hitType,
                        eventAction,
                        {
                            event_category: eventCategory,
                            event_label: eventLabel,
                            value: eventValue,
                        },
                    );
                }
            } else {
                setTimeout(() => {
                    if (tries < 100) {
                        this.sendAsync(hitType, eventCategory, eventAction, eventLabel, eventValue, tries++);
                    }
                }, 100);
            }

            this.userTraceService.trace(
                eventAction,
                {
                    event_category: eventCategory,
                    event_label: eventLabel,
                    value: eventValue,
                    hitType: hitType,
                    eventAction: eventAction,
                },
            )
        }
    }

    private shouldSendEvent(eventAction: GAType.EventAction) {
        const ignoreActions = [
            // 'file-selected',
            // 'file-upload-start',
            // 'file-upload-end',
            // 'file-convert-end',
            'offline-engine-downloaded',
            'model-open',
            'file-select-dialog-opened',
            // 'file-processor-resolve-start',
            // 'file-processor-resolve-end',
            // 'file-processor-resolve-failed',
        ];
        return !includes(ignoreActions, eventAction);
    }
}

