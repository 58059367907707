
export class GAContants {
    static EventType: { [key in EventType]: GAType.EventType } = {
        'SEND': 'send',
    };
    static HitType: { [key in HitType]: GAType.HitType } = {
        'EVENT': 'event',
        'PAGEVIEW': 'pageview',
    };
    static EventCategory: { [key in EventCategory]: GAType.EventCategory } = {
        'ANCHOR': 'anchor',
        'BUTTON': 'button',
        'MESSAGE': 'message',
        'SELECT-DIALOG': 'select-dialog',
        'NETWORK': 'network',
        'AUTO': 'auto',
    };

    // TODO Convert these to enums
    static EventAction: { [key in EventAction]: GAType.EventAction } = {
        'PAGE-CHANGE': 'page-change',
        'FILE-UPLOAD-START': 'file-upload-start',
        'FILE-UPLOAD-END': 'file-upload-end',
        'FILE-UPLOAD-READY': 'file-upload-ready',
        'FILE-UPLOAD-CLICKED': 'file-upload-clicked',
        'FILE-DOWNLOAD': 'file-download',
        'MODEL-OPEN': 'model-open',
        'FILE-DOWNLOAD-ZIP': 'file-download-zip',
        'FILE-SELECT-DIALOG-OPENED': 'file-select-dialog-opened',
        'FILE-SELECTED': 'file-selected',
        'FILE-CONVERT-START': 'file-convert-start',
        'FILE-CONVERT-END': 'file-convert-end',
        'OFFLINE-ENGINE-DOWNLOADED': 'offline-engine-downloaded',
        'FILE-PROCESSOR-RESOLVE-START': 'file-processor-resolve-start',
        'FILE-PROCESSOR-RESOLVE-END': 'file-processor-resolve-end',
        'FILE-PROCESSOR-RESOLVE-FAILED': 'file-processor-resolve-failed',
    };
    static EventLabel: { [key in EventLabel]: GAType.EventLabel } = {
        'NAVIGATE': 'navigate',
        'DOWNLOAD': 'download',
        'UPLOAD': 'upload',
        'SELECT': 'select',
        'CONVERT': 'convert',
        'ERROR': 'error',
        'COMPLETE': 'complete',
        'PACKAGE_DOWNLOAD': 'package_download',
    };
    static EventValue = {};
}

export declare namespace GAType {
    export type EventType = 'send';
    export type HitType = 'event' | 'pageview';
    export type EventCategory = 'anchor' | 'button' | 'select-dialog' | 'message' | 'network' | 'auto';
    export type EventAction = 'page-change' | 'file-upload-start' | 'file-upload-end' | 'file-upload-ready' | 'file-upload-clicked' | 'file-selected'
        | 'file-convert-start' | 'file-convert-end'
        | 'offline-engine-downloaded'
        | 'file-download' | 'model-open' | 'file-download-zip'
        | 'file-select-dialog-opened'
        | 'file-processor-resolve-start'
        | 'file-processor-resolve-end'
        | 'file-processor-resolve-failed';
    export type EventLabel = 'navigate' | 'download' | 'upload' | 'select' | 'convert' | 'error' |
        'complete' | 'package_download';
    export type EventValue = any;
}

export type EventType = 'SEND';
export type HitType = 'EVENT' | 'PAGEVIEW';
export type EventCategory = 'ANCHOR'
    | 'BUTTON'
    | 'MESSAGE'
    | 'SELECT-DIALOG' | 'NETWORK' | 'AUTO';
export type EventAction = 'PAGE-CHANGE'

    | 'FILE-UPLOAD-START'
    | 'FILE-UPLOAD-END'
    | 'FILE-UPLOAD-READY'
    | 'FILE-UPLOAD-CLICKED'

    | 'FILE-SELECT-DIALOG-OPENED'
    | 'FILE-SELECTED'

    | 'FILE-CONVERT-START'
    | 'FILE-CONVERT-END'

    | 'FILE-DOWNLOAD'
    | 'MODEL-OPEN'
    | 'FILE-DOWNLOAD-ZIP'

    | 'FILE-PROCESSOR-RESOLVE-START'
    | 'FILE-PROCESSOR-RESOLVE-END'
    | 'FILE-PROCESSOR-RESOLVE-FAILED'

    | 'OFFLINE-ENGINE-DOWNLOADED';

export type EventLabel = 'NAVIGATE'
    | 'DOWNLOAD'
    | 'UPLOAD'
    | 'SELECT'
    | 'CONVERT'
    | 'ERROR'
    | 'COMPLETE'
    | 'PACKAGE_DOWNLOAD';
export type EventValue = any;
