import { Component, createRef } from 'react';

import { UIContext } from '../../../services/ui-context';
import { IOC_TYPES } from '../../../context/ioc.types';
import { lazyInject } from '../../../context/module';
import Modal from '../modal/modal.component';
import LoginComponent from './login.component';
import { LoginComponentProps } from './login.component';

export type LoginDialogMode = 'login' | 'register' | 'recover-password';

export class LoginModalComponent extends Component<LoginComponentProps> {
    modalRef = createRef<Modal>();

    static show(props: LoginComponentProps) {
        Modal.createModal(LoginModalComponent, props);
    }


    @lazyInject(IOC_TYPES.UIContext) private uiContext: UIContext;

    constructor(props: LoginComponentProps) {
        super(props);
    }

    hide() {
        this.modalRef.current.unmount();
    }

    success(data) {
        if (this.props.onSuccess) {
            this.props.onSuccess(data);
        }
        this.modalRef.current.unmount();
    }

    render() {
        return <Modal ref={this.modalRef}>
            <div data-modalheader>
                <h2></h2>
            </div>
            <div data-modalbody>
                <LoginComponent {...this.props} onSuccess={(data) => this.success(data)}></LoginComponent>
            </div>
        </Modal>
    }
}
