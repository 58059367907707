import { Component } from 'react';
import { createRoot } from 'react-dom/client';
import { Logger } from '../../../models/logger';
import { OptionalChildrenProps } from '../../../types/react.types';
import { BrowserUtils } from '../../../ui-utils/browser-utils';
import style from './modal.component.module.scss';

export interface ModalProps extends OptionalChildrenProps {
    modalClass?: string;
    onOpen?: (modalRef: Modal) => void;
    onClose?: (modalRef: Modal) => void;
    disableCloseButton?: boolean;
}

export interface ModalState {
    shown: boolean;
}

export default class Modal extends Component<ModalProps, ModalState> {
    private static readonly logger = Logger.getLogger('Modal');

    static createModal<T>(Comp: any, props: any): any {
        if (!BrowserUtils.hasDocument()) {
            Modal.logger.error("Requested execution aborted since document was not avalilable");
            return;
        }
        const modalPortal = document.createElement('div');
        modalPortal.className = `${Date.now()}`;
        modalPortal.style.position = 'relative';
        modalPortal.style.zIndex = '9999';
        document.body.appendChild(modalPortal);

        const child = <Comp {...props}></Comp>;
        const root = createRoot(modalPortal!);
        root.render(child);
        return child;
    }
    parentElement;
    setParentElement = (element) => {
        this.parentElement = element && element.parentElement;
    }

    readonly state: Readonly<ModalState> = {
        shown: true,
    };
    constructor(props: ModalProps) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onOpen) {
            this.props.onOpen(this);
        }
    }

    private getHeader() {
        if (Array.isArray(this.props.children)) {
            return this.props.children.find((child: any) => child && child.props && child.props['data-modalheader']);
        }
        return '';
    }

    private getBody() {
        if (Array.isArray(this.props.children)) {
            return this.props.children.find((child: any) => child && child.props && child.props['data-modalbody']);
        }
        return '';
    }

    private getFooter() {
        if (Array.isArray(this.props.children)) {
            return this.props.children.find((child: any) => child && child.props && child.props['data-modalfooter']);
        }
        return '';
    }

    public unmount() {
        this.setState({
            shown: false,
        });
        if (this.props.onClose) {
            this.props.onClose(this);
        }
        try {
            if (this.parentElement) {
                this.parentElement.remove();
            }
        } catch (e) {
            Modal.logger.error({ message: 'Error while removing modal', error: e });
        }
    }

    render() {
        return (this.state.shown &&
            <div ref={(ref) => this.setParentElement(ref)} className={`${this.props.modalClass} modal ${style.modal}`}>
                <div className={`content ${style.content}`}>
                    <div className={`head ${style.head}`}>
                        <div className={`header-content ${style['header-content']}`}>{this.getHeader()}</div>
                        {
                            !this.props.disableCloseButton &&
                            <button className={`close ${style.close}`} onClick={() => this.unmount()}
                                onTouchEnd={() => this.unmount()}>&#x2716;</button>
                        }
                    </div>
                    <div className={`body ${style.body}`}>{this.getBody()}</div>
                    <div className={`footer ${style.footer}`}>{this.getFooter()}</div>
                </div>
            </div>
        );
    }
}