import { Component } from 'react';

import { IOC_TYPES } from '../../../context/ioc.types';
import { lazyInject } from '../../../context/module';
import { UserPreferenceService, UserPreferenceTypes } from '../../../services/user-preference.service';
import { BrowserUtils } from '../../../ui-utils/browser-utils';
import { PSDate } from '../../../utils/date.utils';
import { IconHelper } from '../../../utils/icon-helper';
import { HtmlEntityTypes } from '../../../utils/icon.utils';
import { isNil } from '../../../utils/lodash-min';
import styles from './mobile-app-prompt.component.module.scss';

// import styles from './mobile-app-prompt.component.scss';


interface MobileAppPromptComponentState {
    isShown: boolean;
    isIOS: boolean;
    isAndroid: boolean;
}

export default class MobileAppPromptComponent extends Component {
    @lazyInject(IOC_TYPES.UserPreferenceService) private userPreferenceService: UserPreferenceService;

    readonly state: Readonly<MobileAppPromptComponentState> = { isShown: false, isIOS: false, isAndroid: false };
    constructor(props) {
        super(props);
    }

    componentDidMount(): void {
        const isIOS = BrowserUtils.isIOS();
        const isAndroid = BrowserUtils.isAndroid();
        console.log(isIOS, isAndroid, this.userClosedPrompt())
        this.setState({
            isShown: (isIOS || isAndroid) && !this.userClosedPrompt(),
            isIOS: isIOS,
            isAndroid: isAndroid,
        });
    }

    userClosedPrompt() {
        const lastClosedDate = this.userPreferenceService.getPreference<Date>(UserPreferenceTypes.MOBILE_APP_PROMO_CLOSED_DATE, Date);
        if (!isNil(lastClosedDate) && PSDate.from(lastClosedDate).isAfter(PSDate.from().subtract(3, 'days'))) {
            return true;
        }
        return false;
    }

    close() {
        this.userPreferenceService.setPreference(UserPreferenceTypes.MOBILE_APP_PROMO_CLOSED_DATE, new Date());
        this.setState({ isShown: false })
    }

    render() {
        return <div className={`mobile-prompt ${styles['app-links']} ${styles['mobile-prompt']} ${this.state.isShown ? '' : 'hidden'}`}>
            <div className={`mobile-prompt-android ${styles['app-popup']}`}>
                <div className={`${styles['popup-content']}`}>
                    <a className={`${styles['close-btn']}`} onClick={() => this.close()}>
                        {IconHelper.getIconElement(HtmlEntityTypes.X)}
                    </a> <div className={`${styles['hero']}`}>
                        <img src="/img/xconvert/app-icon.png" />
                        <div>
                            <h2>ShrinkVid Video Compressor</h2>
                            <p>By xconvert.com</p>
                            <div className={`${styles['stars']}`}>
                                {IconHelper.getIconElement(HtmlEntityTypes.STAR)}
                                {IconHelper.getIconElement(HtmlEntityTypes.STAR)}
                                {IconHelper.getIconElement(HtmlEntityTypes.STAR)}
                                {IconHelper.getIconElement(HtmlEntityTypes.STAR)}
                                {IconHelper.getIconElement(HtmlEntityTypes.STAR)}
                            </div>
                        </div>
                    </div>
                    <div className={`${styles['popup-bottom']}`}>
                        <div style={{ width: '120px' }}>
                            <img style={{ maxWidth: '120px' }} src={
                                this.state.isIOS ?
                                    "/img/apple-app-store.svg" :
                                    "/img/google-play-badge.png"} />
                        </div>
                        <a href={this.state.isIOS ?
                            'https://apps.apple.com/us/app/video-compressor-shrinkvid/id1560444951' :
                            'https://play.google.com/store/apps/details?id=com.freeconvert.video_compressor'}
                            className={`${styles['confirm']}`}>
                            YES, INSTALL
                        </a>
                    </div>
                </div>
            </div>
        </div>
    }
}