import { inject, injectable } from 'inversify';

import AlertDialog from '../components/ui/dialogs/alert-dialog.component';
import { Logger } from '../models/logger';
import { ToastService } from './toast.service';

declare var window;
declare var Swal;

export type Millisecond = number;

export interface ShowErrorDialogRequest {
    title: string;
    html?: string;
    text?: string;
    confirmButtonText?: string;
    successCallback?: () => void;
    showCancelButton?: boolean;
    cancelButtonText?: string;
    cancelCallback?: () => void;
}

@injectable()
export class UIContext {
    private readonly logger = Logger.getLogger('UIContext');

    constructor(
        @inject(ToastService) private toastr: ToastService,
    ) { }

    successToast(title?: string, message?: string, timeout: Millisecond = 5000) {
        this.toastr.success(message, title, {
            timeout: timeout,
        });
    }

    errorToast(title: string, message: string, isImportant?: boolean, shouldErrorForSentry?: boolean) {
        // this.logToSentry(title, message, isImportant, shouldErrorForSentry);
        this.logger.error({message: message });
        this.toastr.error(title, message);
    }

    warnToast(title: string, message: string, isImportant: boolean, shouldErrorForSentry: boolean) {
        // this.logToSentry(title, message, isImportant, shouldErrorForSentry);
        this.logger.warn({message: message });
        this.toastr.warning(title, message);
    }

    handleError(err: Error, options?: {
        confirmButtonText?: string,
        confirmButtonCallback?: () => void
    }) {
        this.logger.error(err);
        const errorTitle = err && err.name || 'Error';
        const errorMessage = err && err.message || 'Something went wrong!';
        AlertDialog.create({
            title: errorTitle,
            text: errorMessage,
            type: 'danger',
            showCancelButton: false,
            confirmButtonText: options?.confirmButtonText || 'Ok!',
            confirmButtonCallback: options?.confirmButtonCallback
        });
    }

    private logToSentry(title: string, data: any, isImportant = true, shouldErrorForSentry = true) {
        
    }

    private stackTrace() {
        const err = new Error();
        return err.stack;
    }

    showErrorDialog(request: ShowErrorDialogRequest) {
       try {
        this.logger.error({ message: `${request.title} ${request.text}`, data: request});
       }catch(e) {
        // prevent circular ref errors
        this.logger.error({ message: `${request.title} ${request.text}`});
       }
        AlertDialog.create({
            title: request.title,
            text: request.text,
            html: request.html,
            type: 'danger',
            showCancelButton: request.showCancelButton || false,
            confirmButtonText: request.confirmButtonText || 'Ok!',
            cancelButtonText: request.cancelButtonText || 'Cancel',
            confirmButtonCallback: request.successCallback,
            cancelButtonCallback: request.cancelCallback
        });
    }

    showSuccessDialog(title: string, text: string, html?: string) {
        AlertDialog.create({
            title: title,
            text: text,
            html: html,
            type: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok!',
        });
    }

    showConfirmationWarningDialog(
        title: string,
        message: string,
        onConfirmationFn: () => void,
        showCancelButton = true,
        cancelButtonText = 'No',
        confirmButtonText = 'Yes',
        cancelButtonCallback = () => null,
    ) {
        AlertDialog.create({
            title: title,
            text: message,
            type: 'warning',
            showCancelButton: showCancelButton,
            cancelButtonText: cancelButtonText,
            confirmButtonText: confirmButtonText,
            confirmButtonCallback: onConfirmationFn,
            cancelButtonCallback: cancelButtonCallback,
        });
    }

    showDialogWithInput(title: string, text: string, placeholder: string): Promise<string> {
        return Promise.reject('Not implemented!')
        // return new Promise((resolve, reject) => {
        //     Swal({
        //         title: title,
        //         text: text,
        //         input: 'email',
        //         inputPlaceholder: placeholder,
        //         showCancelButton: true,
        //         inputValidator: (value) => {
        //             return !value && 'Please enter your e-mail!';
        //         },
        //     })
        //         .then(data => {
        //             if (data.dismiss) {
        //                 reject(data.dismiss);
        //             } else if (data.value) {
        //                 resolve(data.value);
        //             }
        //             reject(data);
        //         })
        //         .catch(err => reject(err));
        // });
    }
}
