import { GAService } from "../services/analytics.service";
import { ApiConfig } from "../services/api-config";
import { AuthService } from "../services/auth.service";
import { CapabilityService } from "../services/capability.service";
import { ConnectivityService } from "../services/connectivity.service";
import { DOMStorage } from "../services/dom-storage.service";
import { DropboxService } from "../services/dropbox.service";
import { EnvironmentService } from "../services/environment.service";
import { FileDownloadService } from "../services/file-download.service";
import { FileInputButtonService } from "../services/file-input-button.service";
import { FileManagerService } from "../services/file-manager.service";
import { FileProcessService } from "../services/file-process.service";
import { FileShareService } from "../services/file-share.service";
import { FileUploadRecoveryService } from "../services/file-upload-recovery.service";
import { FileUploadService } from "../services/file-upload.service";
import { GoogleDriverService } from "../services/google-driver.service";
import { HelperService } from "../services/helper.service";
import { HttpClient } from "../services/http-client";
import { LightHttp } from "../services/light-http.service";
import { LogService } from "../services/log.service";
import { LoginService } from "../services/login.service";
import { MarkdownService } from "../services/markdown.service";
import { MemeUploadService } from "../services/meme-upload.service";
import { MetadataService } from "../services/metadata.service";
import { MotivesService } from "../services/motives.service";
import { OfflineTaskService } from "../services/offline-task.service";
import { PageDataProviderService } from "../services/page-data-provider.service";
import { PaymentService } from "../services/payments.service";
import { ProcessCompleteCheckService } from "../services/process-complete-check.service";
import { ProcessorResolverService } from "../services/processor-resolver.service";
import { ScriptLoadService } from "../services/script-load.service";
import { SettingsService } from "../services/settings.service";
import { SocketJSService } from "../services/socketjs.service";
import { SpinnerService } from "../services/spinner.service";
import { StorageService } from "../services/storage.service";
import { ThumbnailService } from "../services/thumbnail.service";
import { ToastService } from "../services/toast.service";
import { UIContext } from "../services/ui-context";
import { UploadedFilesService } from "../services/uploaded-files.service";
import { UserPreferenceService } from "../services/user-preference.service";
import { UserTraceService } from "../services/user-trace.service";
import { WebSocketService } from "../services/websocket.service";
import { WindowService } from "../services/window.service";

export const IOC_TYPES = {
    EnvironmentService: EnvironmentService,
    ApiConfig: ApiConfig,
    LightHttp: LightHttp,
    HttpClient: HttpClient,
    DOMStorage: DOMStorage,
    OfflineTaskService: OfflineTaskService,
    StorageService: StorageService,
    WebSocketService: WebSocketService,
    SettingsService: SettingsService,
    UIContext: UIContext,
    ProcessCompleteCheckService: ProcessCompleteCheckService,
    WindowService: WindowService,
    AuthService: AuthService,
    ScriptLoadService: ScriptLoadService,
    LoginService: LoginService,
    MotivesService: MotivesService,
    PageDataProviderService: PageDataProviderService,
    GAService: GAService,
    GoogleDriverService: GoogleDriverService,
    DropboxService: DropboxService,
    SpinnerService: SpinnerService,
    FileManagerService: FileManagerService,
    FileUploadService: FileUploadService,
    HelperService: HelperService,
    FileUploadRecoveryService: FileUploadRecoveryService,
    ConnectivityService: ConnectivityService,
    ProcessorResolverService: ProcessorResolverService,
    CapabilityService: CapabilityService,
    UploadedFilesService: UploadedFilesService,
    FileProcessService: FileProcessService,
    ToastService: ToastService,
    MetadataService: MetadataService,
    MarkdownService: MarkdownService,
    ThumbnailService: ThumbnailService,
    FileDownloadService: FileDownloadService,
    PaymentService: PaymentService,
    UserPreferenceService: UserPreferenceService,
    MemeUploadService: MemeUploadService,
    FileShareService: FileShareService,
    LogService: LogService,
    FileInputButtonService: FileInputButtonService,
    SocketJSService: SocketJSService,
    UserTraceService: UserTraceService
}