import { plainToClass } from 'class-transformer';
import { inject, injectable } from 'inversify';
import { Observable, ReplaySubject, concat, from, of } from 'rxjs';
import { catchError, first, map, mergeMap, retry, switchMap, tap } from 'rxjs/operators';

import { SettingsServiceConstants } from '../commons/commons';
import { Logger } from '../models/logger';
import { SessionDetails } from '../models/session-details';
import { BrowserUtils } from '../ui-utils/browser-utils';
import {
    AccountDetails,
    AuthenticateResponse,
    ErrorResponse,
    LoginResponse,
    ResponsePipe,
} from '../utils/request.utils';
import { ApiConfig } from './api-config';
import { AuthService } from './auth.service';
import { LightHttp } from './light-http.service';
import { OfflineTaskService } from './offline-task.service';
import { ScriptLoadService } from './script-load.service';
import { StorageKeyPrefixeTypes, StorageService } from './storage.service';
import { UIContext } from './ui-context';
import { WindowService } from './window.service';


async function sha256(message) {
    if (!message) {
        return message;
    }
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string                  
    const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
    return hashHex;
}

@injectable()
export class LoginService {
    private readonly logger = Logger.getLogger('LoginService');

    public isUserLoggedIn = false;
    public canGoOffline = false;
    public isUserAuthenticated = false;
    private loginRequestDispatched = false;

    private _userDetails: AccountDetails = null;
    get userDetails() {
        return this._userDetails;
    }
    private readonly _onUserDetailsChanged = new ReplaySubject<AccountDetails>(1);
    get onUserDetailsChanged() {
        return this._onUserDetailsChanged.asObservable();
    }

    private _userIpAddress: string = null;
    get userIpAddress() {
        return this._userIpAddress;
    }

    private _userContry: string = null;
    get userContry() {
        return this._userContry;
    }

    private _onSessionDetails = new ReplaySubject<SessionDetails>(1);
    get onSessionDetails(): Observable<SessionDetails> {
        return this._onSessionDetails.asObservable();
    }


    constructor(
        @inject(ApiConfig) private apiConfig: ApiConfig,
        @inject(AuthService) private authService: AuthService,
        @inject(OfflineTaskService) private offlineTaskService: OfflineTaskService,
        @inject(StorageService) private storageService: StorageService,
        @inject(ScriptLoadService) private scriptLoadService: ScriptLoadService,
        @inject(UIContext) private uiContext: UIContext,
        @inject(WindowService) private windowService: WindowService,
        @inject(LightHttp) private lightHttp: LightHttp,
    ) {
        LightHttp.registerLoginUserDetailsProvider(this);
    }

    private loadSessionInfo() {
        const apiDomain = this.apiConfig.getHttpsEndpoint();
        // This has to go though ws domain to get users actual IP and country
        const initializer$ = of({} as any); // location.href.indexOf('product') > -1 ? of({} as any) : this.windowService.userClicked;
        return initializer$.pipe(
            first(),
            mergeMap(() => {
                return this.lightHttp.post<SessionDetails>(
                    `${apiDomain}/users/session/settings`, {});
            })
        ).pipe(
            tap({
                next: (sessionDetailsObj) => {
                    const sessionDetails = plainToClass(SessionDetails, sessionDetailsObj);
                    this._userIpAddress = sessionDetails && (sessionDetails.ipv4 || sessionDetails.ip);
                    this._userContry = sessionDetails && sessionDetails.country;
                    this._onSessionDetails.next(sessionDetails);
                }
            }),
            catchError(err => {
                this.scriptLoadService.loadAllScriptsIfNotLoaded(true).then(
                    () => {
                        this.uiContext.showErrorDialog({
                            title: 'Error', text: 'Failed to get session info. Please let us know about this issue @ support@xconvert.com. Please reload the page and try again.'
                        });
                    }
                ).catch(console.error);
                this.logger.error({ message: `Failed to get SessionDetails using ${apiDomain} domain.`, error: err });
                return of({} as SessionDetails);
            }),
        );
    }

    logout(): Observable<any> {
        this.loginRequestDispatched = false;
        return concat(
            this.lightHttp.post(this.apiConfig.getLogoutEndpoint(), {}).pipe(
                ResponsePipe,
            ).pipe(
                mergeMap((a) => {
                    this.authService.unsetAuthToken();
                    this.canGoOffline = false;
                    this.offlineTaskService.setCanGoOfflineMode(false);
                    this.isUserLoggedIn = false;
                    this.isUserAuthenticated = false;
                    return this.authenticate();
                }),
                tap(() => { this.updateUserDetails(null); }),
                mergeMap((res) => {
                    return this.login(null, null, null).pipe(
                        map(() => {
                            return res;
                        }),
                    );
                }),
            ),
        );
    }

    /**
     * If user already logged in and has a token, this method does nothing.
     */
    authenticate(): Observable<any> {
        if (this.authService.getToken()) {
            this.isUserAuthenticated = true;
            this.logger.trace7(`%cUser already has a valid token. Authentication skipped. Token state: ${!!this.authService.getToken()}`);
            return of({});
        }

        const request$ = this.lightHttp.post<AuthenticateResponse>(
            this.apiConfig.getAuthenticateEndpoint(),
            { authToken: this.authService.getToken(), },
            { withCredentials: true, },
        ).pipe(
            ResponsePipe,
            catchError(err => of(err)),
        );

        const subject = new ReplaySubject(1);
        if (!this.isUserAuthenticated && !this.loginRequestDispatched) {
            request$.subscribe({
                next: (result: AuthenticateResponse) => {
                    const token = result.bearer;
                    if (token) {
                        this.authService.setAuthToken(token);

                        this.storageService.store(StorageKeyPrefixeTypes.USER_SETTING_KEY_PREFIX,
                            SettingsServiceConstants.MOTIVE_STORAGE_KEY, result.motive);
                        // this.motivesService.setMotives(plainToClass(MotiveSettingsResponse, result.motive));

                        this.isUserAuthenticated = true;
                        this.logger.trace7(`%cUser authentication successful. Token updated!`);
                    } else {
                        this.logger.trace7(`%cSkipped respond from auth service. setAuthToken was already called. Token state: ${!!this.authService.getToken()} and PSToken: ${!!token}`);
                    }
                    subject.next(result);
                    subject.complete();
                },
                error: (err) => {
                    subject.error(err);
                },
            });
        }

        return subject.asObservable();
    }

    resetPassword(email: string, password: string, captchaToken: string, k: string): Observable<any> {
        return from(sha256(password || '')).pipe(
            mergeMap((passwordHash) => {
                return this.lightHttp.post(this.apiConfig.getResetPasswordEndpoint(), {
                    email,
                    password: passwordHash,
                    token: captchaToken,
                    k,
                },
                    {
                        withCredentials: true,
                    },
                ).pipe(
                    ResponsePipe,
                );
            })
        )
    }

    verifyAccount(k: string): Observable<any> {
        return this.lightHttp.post(
            this.apiConfig.getAccountVerifyEndpoint(),
            {
                k,
            },
            {
                withCredentials: true,
            },
        ).pipe(
            ResponsePipe,
        );
    }

    private getDefaultHeaders(): { location?: string } {
        return {
            ...(BrowserUtils.hasWindow() ? { 'location': window.location.href } : undefined),
        }
    }

    login(email: string, password: string, captchaToken: string): Observable<LoginResponse> {
        this.loginRequestDispatched = true;
        return from(sha256(password)).pipe(
            mergeMap((passwordHash) => {
                return this.lightHttp.post(this.apiConfig.getLoginEndpoint(), {
                    email,
                    password: passwordHash,
                    token: captchaToken,
                    ...this.getDefaultHeaders(),
                },
                    {
                        withCredentials: true,
                    },
                ).pipe(
                    retry({count: 2, delay: 3 * 1000}),
                    ResponsePipe,
                    mergeMap((res: LoginResponse) => {
                        if (res.bearer) {
                            this.authService.setAuthToken(res.bearer);
                        }
                        if (res.registered) {
                            this.offlineTaskService.setCanGoOfflineMode(res.canGoOffline);
                            this.isUserLoggedIn = true;
                            this.canGoOffline = res.canGoOffline;
                        } else {
                            this.offlineTaskService.setCanGoOfflineMode(!!res.canGoOffline);
                            this.canGoOffline = !!res.canGoOffline;
                            this.isUserLoggedIn = false;
                        }

                        this.storageService.store(
                            StorageKeyPrefixeTypes.USER_SETTING_KEY_PREFIX,
                            SettingsServiceConstants.MOTIVE_STORAGE_KEY, res.motive
                            );
                        // this.motivesService.setMotives(plainToClass(MotiveSettingsResponse, res.motive));

                        this.loginRequestDispatched = false;
                        if (res.registered) {
                            return this.getUserDetailsOnce$().pipe(
                                switchMap(
                                    (userDetails) => {
                                        this.updateUserDetails(plainToClass(AccountDetails, userDetails));
                                        return of(res);
                                    },
                                ),
                            );
                        } else {
                            try {
                                this.updateUserDetails(null);
                            } catch (e) {
                                //
                            }
                        }
                        return of(res);
                    }),
                    mergeMap((res) => {
                        return this.loadSessionInfo().pipe(
                            map(() => {
                                return res;
                            }),
                        );
                    }),
                );
            })
        )

    }

    private updateUserDetails(userDetails: AccountDetails) {
        this._userDetails = userDetails;
        this._onUserDetailsChanged.next(userDetails);
    }

    register(email: string, password: string, captchaToken: string): Observable<any> {
        return from(sha256(password)).pipe(
            mergeMap((passwordHash) => {
                return this.lightHttp.post(this.apiConfig.getRegisterEndpoint(), {
                    email,
                    password: passwordHash,
                    token: captchaToken,
                }).pipe(
                    ResponsePipe,
                );
            })
        )
    }

    validateEmail(email: string): Observable<ErrorResponse> {
        return this.lightHttp.post(this.apiConfig.getEmailValidateEndpoint(), {
            email,
        }).pipe(
            ResponsePipe,
        );
    }

    getUserDetailsOnce$(): Observable<AccountDetails> {
        return this.lightHttp.post(this.apiConfig.getUserDetailsEndpoint(), {}).pipe(
            ResponsePipe,
            map(resp => plainToClass(AccountDetails, resp))
        );
    }

    recover(email: string, captchaToken: string) {
        return this.lightHttp.post(this.apiConfig.getRecoverPasswordEndpoint(), {
            email,
            token: captchaToken,
        }).pipe(
            ResponsePipe,
        );
    }

    authorizeFileProcessRequest() {
        return this.lightHttp.post(this.apiConfig.getProcessAuthEndpoint(), {}).pipe(
            ResponsePipe,
        );
    }
}
