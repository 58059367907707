import { Component, createRef } from 'react';

import { FormControl, FormGroup } from '../../models/forms';
import { Logger } from '../../models/logger';
import { ApiConfig } from '../../services/api-config';
import { EnvironmentService } from '../../services/environment.service';
import { FileManagerService } from '../../services/file-manager.service';
import { HelperService } from '../../services/helper.service';
import { HttpProgressEvent, LightHttp } from '../../services/light-http.service';
import { UIContext } from '../../services/ui-context';
import { IOC_TYPES } from '../../context/ioc.types';
import { lazyInject } from '../../context/module';
import FormInputComponent from '../ui/forms/form-input/form-input.component';
import ReCaptcha from '../ui/recaptcha/recaptcha.component';

interface ContactUsState {
    uploaded: number;
}
export default class ContactUs extends Component {
    logger = Logger.getLogger('ContactUs');

    fileRef = createRef<HTMLInputElement>();
    @lazyInject(IOC_TYPES.EnvironmentService) env: EnvironmentService;
    @lazyInject(IOC_TYPES.LightHttp) http: LightHttp;
    @lazyInject(IOC_TYPES.ApiConfig) apiConfig: ApiConfig;
    @lazyInject(IOC_TYPES.UIContext) uiContext: UIContext;
    @lazyInject(IOC_TYPES.FileManagerService) fileManagerService: FileManagerService;
    @lazyInject(IOC_TYPES.HelperService) helperService: HelperService;

    private emailSentSuccess = false;
    private emailSentFailed = false;
    private token = null;

    formGroup = new FormGroup({
        email: new FormControl(undefined),
        message: new FormControl(undefined),
        token: new FormControl(undefined),
    });

    readonly state: Readonly<ContactUsState> = {
        uploaded: 0,
    };
    constructor(props) {
        super(props);
    }

    handleCorrectCaptcha(event) {
        this.token = event;
        this.formGroup.setValue({ 'token': event });
    }

    onSubmit(e) {
        if (this.formGroup.valid) {
            const formData = new FormData();
            formData.append('email', this.formGroup.controls.email.value);
            let message = this.formGroup.value.message;
            message += '  File uploads: ' + this.fileManagerService.stringifyAllUploadedFileUploads();
            formData.append('message', message);
            if (this.fileRef.current.files) {
                for (const file of Array.from(this.fileRef.current.files)) {
                    formData.append('file', file);
                }
            }
            formData.append('token', this.formGroup.value.token);
            const { response$, events$ } = this.http.request<{ error: boolean, msg: any }>(
                this.apiConfig.getSupportEmailEndpoint(),
                'POST',
                formData,
                {
                    headers: this.helperService.getCommonHttpHeaders(),
                },
            );
            response$.subscribe({
                next: (result) => {
                    if (!result.error) {
                        this.emailSentSuccess = true;
                        this.emailSentFailed = false;
                        this.uiContext.successToast('Success', `Email sent to support@${this.env.domainname}.com`);
                    } else {
                        this.emailSentFailed = true;
                        this.uiContext.errorToast('Error', 'Could not send your E-mail.' + result.msg, false, false);
                    }
                    this.forceUpdate();
                }
            })
            events$.subscribe({
                next: (result: HttpProgressEvent) => {
                    if (result.type === 'upload_progress') {
                        this.setState({
                            uploaded: parseFloat(parseFloat((100 * result.event.loaded / result.event.total) + '').toFixed(2)),
                        });
                    }
                },
                error: (err) => {
                    this.logger.error({
                        message: 'Invalid E-mail form data.',
                        error: err,
                    });
                    this.uiContext.errorToast('Error', `Failed to send E-mail. Contact us at support@${this.env.domainname}.com`, false, false);
                }
            });
        } else {
            this.emailSentFailed = true;

            this.uiContext.errorToast('Error', 'Invalid E-mail form data.', false, true);
        }
    }

    render() {
        return (<div>
            <form className="contact-us-email-form mt-3">
                <div className="form-group mb-1">
                    <label htmlFor="exampleFormControlInput1">Email:</label>
                    <FormInputComponent control={this.formGroup.get('email')} type="email" className="form-control" placeholder="name@example.com"></FormInputComponent>
                    <div>
                        <small className="text-danger">Email is not required. If you are reporting a problem and okay with us asking you a
                            follow up question,
                            please enter an email address where we can reach you.</small>
                    </div>
                </div>
                <div className="form-group mb-1">
                    <label htmlFor="exampleFormControlTextarea1">Message*:</label>
                    <textarea className="form-control" rows={3} required onInput={(e: any) => this.formGroup.setValue({ 'message': e.target.value })}></textarea>
                </div>
                <div className="form-group mb-1">
                    <label htmlFor="exampleFormControlFile1">File</label>
                    <input ref={this.fileRef} type="file" className="form-control-file" id="exampleFormControlFile1" />
                    <div>
                        <small className="text-danger">If you face a technical problem, we appreciate it a lot if you could attach file(s)
                            you were trying to
                            process. This help us a lot to fix issue.</small>
                    </div>
                </div>
                <div className="form-group mb-1">
                    <ReCaptcha onChange={e => this.handleCorrectCaptcha(e)}></ReCaptcha>
                </div>
                <button type="button" className="x-btn x-btn-primary" onClick={(e) => this.onSubmit(e)}>Submit</button>
                {this.state.uploaded != 0 && <span>File uploaded {this.state.uploaded}%</span>}

            </form>
            <br />
            {
                this.emailSentSuccess && <div className="alert alert-success">Your email sent.</div>
            }
            {
                this.emailSentFailed && <div className="alert alert-danger">Failed to send your email. Please contact us directly.</div>
            }
            <br />
            <div>You may also contact us directly at support@{this.env.domainname}</div>
        </div>
        )
    }
}