import { inject, injectable } from 'inversify';
import { ReplaySubject, Subject } from 'rxjs';
import { GAContants } from '../types/ga.types';
import { GAService } from './analytics.service';

interface FileSelectEvent {
    requestId: string;
    fileSelectEvent: any;
}

@injectable()
export class FileInputButtonService {
    private fileInputEl: HTMLInputElement;
    private _onFileSelect = new Subject<FileSelectEvent>();
    private attributes;
    private _canClick = new ReplaySubject(1);
    private fileSelectRequestId;
    get canClick() {
        return this._canClick.asObservable();
    }

    get onFileSelect() {
        return this._onFileSelect.asObservable();
    }

    constructor(
        @inject(GAService) private gaService: GAService,
    ) { }

    public registerFileInputRef(fileInputEl: HTMLInputElement) {
        if (!this.fileInputEl && fileInputEl) {
            this.fileInputEl = fileInputEl;
            this.setFileInputAttributes();
            this.fileInputEl?.addEventListener('change', (evnt) => {
                // const files = Array.from(this.fileInputEl.files);
                this._onFileSelect.next({
                    requestId: this.fileSelectRequestId,
                    fileSelectEvent: evnt,
                });
            });
            this._canClick.next({});
            this._canClick.complete();
            this.gaService.send(
                GAContants.HitType.EVENT,
                GAContants.EventCategory.NETWORK,
                GAContants.EventAction['FILE-UPLOAD-READY'],
                GAContants.EventLabel.UPLOAD,
                '',
            );
        }
    }

    public setAttributes(attributes: {
        multiple: any,
        hidden: any,
        accept: any
    }) {
        this.attributes = attributes;
        this.setFileInputAttributes();
    }

    private setFileInputAttributes() {
        if (this.fileInputEl && this.attributes) {
            Object.assign(this.fileInputEl, this.attributes);
        }
    }

    public click(fileSelectRequestId: string) {
        this.fileSelectRequestId = fileSelectRequestId;
        this.fileInputEl?.click();
        this.gaService.send(
            GAContants.HitType.EVENT,
            GAContants.EventCategory.NETWORK,
            GAContants.EventAction['FILE-UPLOAD-CLICKED'],
            GAContants.EventLabel.UPLOAD,
            '',
        );
    }

    public clearInput() {
        this.fileInputEl.value = '';
        this.fileInputEl.type = 'text';
        this.fileInputEl.type = 'file';
    }
}
