import { inject, injectable } from 'inversify';

import { getConvertersForDomain } from '../commons/converters';
import { FileUpload } from '../models/file-upload';
import { ConverterType, HighLevelCapability } from '../types/capability.types';
import { includes } from '../utils/lodash-min';
import { EnvironmentService } from './environment.service';

@injectable()
export class CapabilityService {

    constructor(
        @inject(EnvironmentService) private envService: EnvironmentService,
    ) {

    }

    public getConverters() {
        return getConvertersForDomain(this.envService.getDomainName());
    }

    private hasCapability(urlPathOrCapability: string) {
        if (!urlPathOrCapability.startsWith('/')) {
            urlPathOrCapability = `/${urlPathOrCapability}`;
        }
        return includes(this.getConverters().map(a => a.link), urlPathOrCapability);
    }

    public highLevelCapabilities: HighLevelCapability[] = [
        {
            type: 'convert',
            readableType: 'Convert',
            items: [
                {
                    readableName: 'All',
                    fromType: new Set(['*']),
                },
                {
                    readableName: 'PDF',
                    fromType: new Set(['pdf', 'pdf/a']),
                },
                {
                    readableName: 'Image',
                    fromType: new Set(['jpg', 'jpeg', 'png', 'tiff', 'tif', 'webp', 'heic', 'cr2', 'nef']),
                },
                {
                    readableName: 'Video',
                    fromType: new Set(['mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov']),
                },
                {
                    readableName: 'Word',
                    fromType: new Set(['doc', 'docx']),
                },
                {
                    readableName: 'Excel',
                    fromType: new Set(['xls', 'xlsx']),
                },
                {
                    readableName: 'Audio',
                    fromType: new Set([
                        'mp3', 'aac', 'ogg', 'flac', 'aiff', 'mmf', 'm4a', 'wma', 'wav', 'm4r', 'opus',
                    ]),
                },
                {
                    readableName: 'E-book',
                    fromType: new Set([
                        'epub', 'mobi', 'pdf',
                    ]),
                },
                {
                    readableName: 'AV',
                    fromType: new Set([
                        'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov',
                        'mp3', 'aac', 'ogg', 'flac', 'aiff', 'mmf', 'm4a', 'wma', 'wav', 'm4r', 'opus',
                    ]),
                },
            ],
        },
        {
            type: 'compress',
            readableType: 'Compress',
            items: [
                {
                    readableName: 'Image',
                    fromType: new Set(['jpg', 'jpeg', 'png', 'tiff', 'tif', 'webp', 'heic', 'gif']),
                },
                {
                    readableName: 'PDF',
                    fromType: new Set(['pdf']),
                },
                {
                    readableName: 'Video',
                    fromType: new Set(['webm', 'mp4', 'video', 'mts', 'mkv', 'mov', 'm4v', 'mpg', , 'mp3', 'wav', 'm4r']),
                },
                {
                    readableName: 'Audio',
                    fromType: new Set(['audio', 'mp3', 'wav', 'm4r']),
                },
                {
                    readableName: 'All',
                    fromType: new Set(['*']),
                },
            ],
        },
        {
            type: 'resize',
            readableType: 'Resize',
            items: [
                {
                    readableName: 'Image',
                    fromType: new Set(['jpg', 'jpeg', 'png', 'tiff', 'tif', 'webp']),
                },
                {
                    readableName: 'Video',
                    fromType: new Set(['webm', 'mp4', 'mov', 'm4v', 'mpg']),
                },
                {
                    readableName: 'All',
                    fromType: new Set(['*']),
                },
            ],
        },
        {
            type: 'merge',
            readableType: 'Merge / Archive',
            items: [
                {
                    readableName: 'PDF',
                    fromType: new Set(['pdf']),
                },
                {
                    readableName: 'Archive',
                    fromType: new Set(['zip', 'tar', 'tar.gz', 'tar.xz', 'tar.bz2', '7z', 'rar']),
                },
                {
                    readableName: 'All',
                    fromType: new Set(['*']),
                },
            ],
        },
        {
            type: 'split',
            readableType: 'Split / Unzip',
            items: [
                {
                    readableName: 'PDF',
                    fromType: new Set(['pdf']),
                },
                {
                    readableName: 'Archive',
                    fromType: new Set(['zip', 'tar', 'tar.gz', 'tar.xz', 'tar.bz2', '7z', 'rar']),
                },
                {
                    readableName: 'All',
                    fromType: new Set(['*']),
                },
            ],
        },
        {
            type: 'cut',
            readableType: 'Cut/Trim',
            items: [
                {
                    readableName: 'Video',
                    fromType: new Set(['webm', 'mp4', 'video', 'mts', 'mkv', 'mov', 'm4v', 'mpg', , 'mp3', 'wav', 'm4r']),
                },
                {
                    readableName: 'Audio',
                    fromType: new Set(['audio', 'mp3', 'wav', 'm4r']),
                },
                {
                    readableName: 'All',
                    fromType: new Set(['*']),
                },
            ],
        },
        {
            type: 'meme',
            readableType: 'Meme',
            items: [
                {
                    readableName: 'All',
                    fromType: new Set(['*']),
                },
            ],
        },
        {
            type: 'all',
            readableType: 'All',
            items: [
                {
                    readableName: 'All',
                    fromType: new Set(['*']),
                },
            ],
        },
    ];

    public getHighLevelCapabilities() {
        return this.highLevelCapabilities;
    }

    public isCompatible(converterType: ConverterType, fileUpload: FileUpload): boolean {
        if (fileUpload.fromType.indexOf(converterType.from) > -1) {
            return true;
        }
        return false;
    }
}
