import { inject, injectable } from 'inversify';

import { LoginServiceConstants } from '../commons/commons';
import { LightHttp } from './light-http.service';
import { StorageKeyPrefixeTypes, StorageService } from './storage.service';

@injectable()
export class AuthService {

    authToken: string;

    constructor(
        @inject(StorageService) private storageService: StorageService,
    ) {
        LightHttp.registerAuthTokenProvider(this);
    }

    public getToken(): string {
        return this.authToken || this.storageService.get<string>(StorageKeyPrefixeTypes.USER_SETTING_KEY_PREFIX, LoginServiceConstants.AUTH_BEARER_STORAGE_KEY);
    }

    /**
     * Setting it to undefined or null is considered as no valid token
     * @param token
     */
    public setAuthToken(token: string) {
        this.storageService.store(StorageKeyPrefixeTypes.USER_SETTING_KEY_PREFIX, LoginServiceConstants.AUTH_BEARER_STORAGE_KEY, token);
        this.authToken = token;
    }

    public unsetAuthToken() {
        this.storageService.remove(StorageKeyPrefixeTypes.USER_SETTING_KEY_PREFIX, LoginServiceConstants.AUTH_BEARER_STORAGE_KEY);
        this.authToken = null;
    }
}
