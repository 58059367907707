import { inject, injectable } from 'inversify';

import { FileUpload } from '../models/file-upload';
import { Logger } from '../models/logger';
import { GAContants } from '../types/ga.types';
import { ProcessorMetadataUtils } from '../utils/processor-metadata.utils';
import { GAService } from './analytics.service';
import { ApiConfig } from './api-config';
import { LightHttp } from './light-http.service';


@injectable()
export class FileDownloadService {
    private readonly logger = Logger.getLogger('FileDownloadService');

    constructor(
        @inject(GAService) private gaService: GAService,
        @inject(LightHttp) private http: LightHttp,
    ) {
    }

    emailDownload(fileInfo: FileUpload, email: string) {
        /*this.http.post(fileInfo.endpointData.domain + fileInfo.endpointData.emailDownload, {
            email: email
        }).subscribe(data => {
            console.log(data);
        });
        */
    }

    /** Only for file-list-items */
    getDirectDownloadLink(fileInfo: FileUpload, filename: string) {
        const fileDownloadDomain = fileInfo.endpointData.processorMetadataList[0].downloadDomain;
        const downloadUrl = ProcessorMetadataUtils.getDownloadEndpoint(fileInfo.endpointData.processorMetadataList[0]) +
            ((fileDownloadDomain.indexOf('googleapis') !== -1) ? '' : '/') + filename;

        return `${downloadUrl}?uuid=${fileInfo.id}`;
    }

    getRangeSupportedDirectDownloadLink(fileInfo: FileUpload, filename: string) {
        const fileDownloadDomain = fileInfo.endpointData.processorMetadataList[0].downloadDomain;
        const downloadUrl = ApiConfig.suffixDomainWithHttpsEdgeEndpoint(fileDownloadDomain)
            + '/downloadable-files' +
            ((fileDownloadDomain.indexOf('googleapis') !== -1) ? '' : '/') + filename;

        return `${downloadUrl}?uuid=${fileInfo.id}`;
    }

    downloadFile(fileInfo: FileUpload, filename: string) {
        const downloadUrl = this.getDirectDownloadLink(fileInfo, filename);

        // if(fileDownloadDomain.indexOf('googleapis') !== -1){
        //     const commEndpoint = fileInfo.endpointData.processorMetadataList[0].commEndpoint;
        //     const commOutputExistEndpoint = fileDownloadDomain + commEndpoint + `/output-file-exists`;
        //     this.http.post(commOutputExistEndpoint, {
        //         filename
        //     });

        // }
        // July 18, 2020 - Disabled downloadng file though www.xconvert.com. Instead navigate user to file URL
        // const openedWindow: Window = window.open('/download?url=' + encodeURIComponent(downloadUrl));
        const openedWindow: Window = window.open(downloadUrl);
        if (downloadUrl.endsWith('zip')) {
            this.gaService.send(
                GAContants.HitType.EVENT,
                GAContants.EventCategory.BUTTON,
                GAContants.EventAction['FILE-DOWNLOAD-ZIP'],
                GAContants.EventLabel.DOWNLOAD,
                fileInfo.name,
            );
        } else {
            this.gaService.send(
                GAContants.HitType.EVENT,
                GAContants.EventCategory.BUTTON,
                GAContants.EventAction['FILE-DOWNLOAD'],
                GAContants.EventLabel.DOWNLOAD,
                '',
            );
        }
        if (!openedWindow || openedWindow.closed || typeof openedWindow.closed === 'undefined') {
            return false;
        }
        return true;
    }

    downloadFileInputFile(fileInfo: FileUpload, localFilename: string) {
        const downloadUrl = fileInfo.endpointData.processorMetadataList[0].uploadDomain
            + fileInfo.endpointData.processorMetadataList[0].uploadedFileDownloadEndpoint + '/' +
            localFilename;
        // const openedWindow: Window = window.open('/download?url=' + encodeURIComponent(downloadUrl));
        const openedWindow: Window = window.open(downloadUrl);
        if (downloadUrl.endsWith('zip')) {
            this.gaService.send(
                GAContants.HitType.EVENT,
                GAContants.EventCategory.BUTTON,
                GAContants.EventAction['FILE-DOWNLOAD-ZIP'],
                GAContants.EventLabel.DOWNLOAD,
                fileInfo.name,
            );
        } else {
            this.gaService.send(
                GAContants.HitType.EVENT,
                GAContants.EventCategory.BUTTON,
                GAContants.EventAction['FILE-DOWNLOAD'],
                GAContants.EventLabel.DOWNLOAD,
                '',
            );
        }
        if (!openedWindow || openedWindow.closed || typeof openedWindow.closed === 'undefined') {
            return false;
        }
        return true;
    }

    downloadGCSTempFile(fileInfo: FileUpload) {
        const domain = fileInfo.endpointData.processorMetadataList[0].processDomain || fileInfo.endpointData.processorMetadataList[0].uploadDomain;
        const downloadUrl = domain
            + fileInfo.endpointData.processorMetadataList[0].gcpTempDownloadEndpoint + '/' +
            fileInfo.endpointData.processorMetadataList[0].fileMetadata.gcName;
        // const _window: Window = window.open('/download?url=' + encodeURIComponent(downloadUrl));
        // const openedWindow = '/download?url=' + encodeURIComponent(downloadUrl);
        // window.open(openedWindow);
        const openedWindow: Window = window.open(downloadUrl);

        this.logger.trace7(downloadUrl);
        if (downloadUrl.endsWith('zip')) {
            this.gaService.send(
                GAContants.HitType.EVENT,
                GAContants.EventCategory.BUTTON,
                GAContants.EventAction['FILE-DOWNLOAD-ZIP'],
                GAContants.EventLabel.DOWNLOAD,
                fileInfo.name,
            );
        } else {
            this.gaService.send(
                GAContants.HitType.EVENT,
                GAContants.EventCategory.BUTTON,
                GAContants.EventAction['FILE-DOWNLOAD'],
                GAContants.EventLabel.DOWNLOAD,
                '',
            );
        }
    }

    getDownloadURL(fileInfo: FileUpload, filename: string) {
        const domain = fileInfo.endpointData.processorMetadataList[0].downloadDomain;
        const downloadUrl = ProcessorMetadataUtils.getDownloadEndpoint(fileInfo.endpointData.processorMetadataList[0]) +
            ((domain.indexOf('googleapis') !== -1) ? '' : '/') + filename;
        return `${downloadUrl}?uuid=${fileInfo.id}`;
    }

    /**
     * Not used and not tested
     * Google stored files are not handled
     * @param fileInfo 
     * @param filename 
     * @returns 
     */
    getDownloadURLWithFilenameAsAQueryParam(fileInfo: FileUpload, filename: string) {
        const domain = fileInfo.endpointData.processorMetadataList[0].downloadDomain;
        const downloadUrl = ProcessorMetadataUtils.getDownloadEndpoint(fileInfo.endpointData.processorMetadataList[0]) +
            ((domain.indexOf('googleapis') !== -1) ? '' : '/?file_name=') + filename;
        return downloadUrl;
    }
}
