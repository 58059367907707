import { Container, interfaces } from 'inversify';
import { Component, createContext, useContext } from 'react';

import { container } from './module';
import { OptionalChildrenProps } from '../types/react.types';

const InversifyContext = createContext<{ container: Container | null }>({ container: container });

interface Props extends OptionalChildrenProps {
    container: Container;
};

export class DIProvider extends Component<Props> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <InversifyContext.Provider value={{ container: this.props.container }}>
                {this.props.children}
            </InversifyContext.Provider>
        );
    }
}

export function useInjection<T>(identifier: interfaces.ServiceIdentifier<T>) {
    const { container } = useContext(InversifyContext);
    if (!container) { throw new Error(); }
    return container.get<T>(identifier);
};