import {
    ConverterType,
    ConvertFromArchive,
    ConvertFromAudio,
    ConvertFromAV,
    ConvertFromEbook,
    ConvertFromImage,
    ConvertFromPDF,
    ConvertFromPowerPoint,
    ConvertFromPS,
    ConvertFromVideo,
    ConvertFromWord,
    OfflineFromAny,
    OnlineFromAny,
    ToSubTypes,
} from '../types/capability.types';

export function getConvertersForDomain(domainname?: string): ConverterType[] {
    return converters;
}

export const converters: Array<ConverterType> = [
    {
        id: 'convert-ps-to-pdf',
        type: 'convert',
        toSubType: ToSubTypes.ps,
        from: ConvertFromPS.TYPE,
        from_syn: ['ps'],
        to: 'pdf',
        to_syn: ['pdf'],
        link: '/convert-ps-to-pdf',
        title: 'Convert PS to PDF',
        description: 'Free Online Converter to convert Microst Office PS documents to PDF',
        descriptions: {
            xconvert: 'Free Online Converter to convert Microst Office PS documents to PDF',
        },
        availability: {
            online: true,
            offline: true,
        }
    },
    {
        id: 'convert-png-to-pdf',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['png'],
        to: 'pdf',
        to_syn: ['pdf'],
        link: '/convert-png-to-pdf',
        title: 'Convert PNG to PDF',
        description: 'Best online easy PNG to PDF converter',
        descriptions: {
            xconvert: 'Best online easy PNG to PDF converter',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-tiff-to-pdf',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['tiff', 'tif'],
        to: 'pdf',
        to_syn: ['pdf'],
        link: '/convert-tiff-to-pdf',
        title: 'Convert TIFF to PDF',
        description: 'Best online free TIFF to PDF converter',
        descriptions: {
            xconvert: 'Best online free TIFF to PDF converter',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'split-pdf',
        type: 'split',
        toSubType: ToSubTypes.pdf,
        from: ConvertFromPDF.TYPE,
        from_syn: ['pdf'],
        to: 'pdf',
        to_syn: ['pdf'],
        link: '/split-pdf',
        title: 'Split PDF files',
        description: 'Free Online PDF splitter.',
        descriptions: {
            xconvert: 'Free Online PDF splitter.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-jpg-to-pdf',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['jpg'],
        to: 'pdf',
        to_syn: ['pdf'],
        link: '/convert-jpg-to-pdf',
        title: 'Convert JPG/JPEG to PDF',
        description: 'Convert JPEG/JPG files to PDF with heighest quality',
        descriptions: {
            xconvert: 'Convert JPEG/JPG files to PDF with heighest quality',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-pdf-to-word',
        type: 'convert',
        toSubType: ToSubTypes.pdf,
        from: ConvertFromPDF.TYPE,
        from_syn: ['pdf', 'pdf/a'],
        to: 'word',
        to_syn: ['docx', 'doc'],
        link: '/convert-pdf-to-word',
        title: 'Convert PDF to Word',
        description: 'Turn your PDF to Word without loosing quality or format',
        descriptions: {
            xconvert: 'Turn your PDF to Word without loosing quality or format',
        },
        availability: {
            online: true,
            offline: false,
        },
    },
    {
        id: 'convert-word-to-pdf',
        type: 'convert',
        toSubType: ToSubTypes.office,
        from: ConvertFromWord.TYPE,
        from_syn: ['docx', 'doc'],
        to: 'pdf',
        to_syn: ['pdf'],
        link: '/convert-word-to-pdf',
        title: 'Convert Word to PDF',
        description: 'Free Online Converter to convert Microst Office Word documents to PDF',
        descriptions: {
            xconvert: 'Free Online Converter to convert Microst Office Word documents to PDF',
        },
        availability: {
            online: true,
            offline: false,
        },
    },
    /*{
        id: 'convert-pdf-to-powerpoint',
        type: 'convert',
        toSubType: ToSubTypes.webtool,
        from: ConvertFromPDF.TYPE,
        from_syn: ['pdf/a'],
        to: 'powerpoint',
        to_syn: ['pptx', 'ppt'],
        link: '/convert-pdf-to-powerpoint',
        title: 'Convert PDF to PowerPoint',
        dxxxescription: 'Turn your PDF to PowerPoint without loosing quality or format',
        availability : {
            online: true,
            offline: true,
        }
    },*/
    {
        id: 'convert-powerpoint-to-pdf',
        type: 'convert',
        toSubType: ToSubTypes.office,
        from: ConvertFromPowerPoint.TYPE,
        from_syn: ['pptx', 'ppt'],
        to: 'pdf',
        to_syn: ['pdf'],
        link: '/convert-powerpoint-to-pdf',
        title: 'Convert PowerPoint to PDF',
        description: 'Free Online Converter to convert Microst Office PowerPoint documents to PDF',
        descriptions: {
            xconvert: 'Free Online Converter to convert Microst Office PowerPoint documents to PDF',
        },
        availability: {
            online: true,
            offline: false,
        },
    },
    {
        id: 'convert-pdf-to-image',
        type: 'convert',
        toSubType: ToSubTypes.pdf,
        from: ConvertFromPDF.TYPE,
        from_syn: ['pdf'],
        to: 'image',
        to_syn: ['jpg', 'png'],
        link: '/convert-pdf-to-image',
        title: 'Convert PDF to Image',
        description: 'Free Online Converter to convert PDF Files to Images',
        descriptions: {
            xconvert: 'Free Online Converter to convert PDF Files to Images',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-pdf-to-ps',
        type: 'convert',
        toSubType: ToSubTypes.pdf,
        from: ConvertFromPDF.TYPE,
        from_syn: ['pdf', 'pdf/a'],
        to: 'ps',
        to_syn: ['ps'],
        link: '/convert-pdf-to-ps',
        title: 'Convert PDF to PS',
        description: 'Turn your PDF to PS without loosing quality or format',
        descriptions: {
            xconvert: 'Turn your PDF to PS without loosing quality or format',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-pdf-to-png',
        type: 'convert',
        toSubType: ToSubTypes.pdf,
        from: ConvertFromPDF.TYPE,
        from_syn: ['pdf'],
        to: 'image',
        to_syn: ['png'],
        link: '/convert-pdf-to-png',
        title: 'Convert PDF to png',
        description: 'Free Online Converter to convert PDF Files to PNG',
        descriptions: {
            xconvert: 'Free Online Converter to convert PDF Files to PNG',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-pdf-to-jpg',
        type: 'convert',
        toSubType: ToSubTypes.pdf,
        from: ConvertFromPDF.TYPE,
        from_syn: ['pdf'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/convert-pdf-to-jpg',
        title: 'Convert PDF to jpg',
        description: 'Free Online Converter to convert PDF Files to JPEG/JPG',
        descriptions: {
            xconvert: 'Free Online Converter to convert PDF Files to JPEG/JPG',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-image-to-pdf',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['image', 'jpg', 'png'],
        to: 'pdf',
        to_syn: ['pdf'],
        link: '/convert-image-to-pdf',
        title: 'Convert Image to PDF',
        description: 'Free Online Converter to convert Image Files to PDF',
        descriptions: {
            xconvert: 'Free Online Converter to convert Image Files to PDF',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'merge-pdf',
        type: 'merge',
        toSubType: ToSubTypes.pdf,
        from: ConvertFromPDF.TYPE,
        from_syn: ['pdf'], // , 'jpg', 'png' not supported yet since dimension issue
        to: 'pdf',
        to_syn: ['pdf'],
        link: '/merge-pdf',
        title: 'Merge PDF',
        description: 'Merge Images and PDF files to single PDF',
        descriptions: {
            xconvert: 'Merge Images and PDF files to single PDF',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'combine-pdf',
        type: 'merge',
        toSubType: ToSubTypes.pdf,
        from: ConvertFromPDF.TYPE,
        from_syn: ['pdf'], // , 'jpg', 'png' not supported yet since dimension issue
        to: 'pdf',
        to_syn: ['pdf'],
        link: '/combine-pdf',
        title: 'Combine PDF',
        description: 'Combine Images and PDF files to single PDF',
        descriptions: {
            xconvert: 'Combine Images and PDF files to single PDF',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'pdf-combiner',
        type: 'merge',
        toSubType: ToSubTypes.pdf,
        from: ConvertFromPDF.TYPE,
        from_syn: ['pdf'], // , 'jpg', 'png' not supported yet since dimension issue
        to: 'pdf',
        to_syn: ['pdf'],
        link: '/pdf-combiner',
        title: 'PDF Combiner',
        description: 'PDF/Image combiner to create single PDF booklet',
        descriptions: {
            xconvert: 'PDF/Image combiner to create single PDF booklet',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'pdf-merger',
        type: 'merge',
        toSubType: ToSubTypes.pdf,
        from: ConvertFromPDF.TYPE,
        from_syn: ['pdf'], // , 'jpg', 'png' not supported yet since dimension issue
        to: 'pdf',
        to_syn: ['pdf'],
        link: '/pdf-merger',
        title: 'PDF Merger',
        description: 'Images and PDF merger to create single PDF',
        descriptions: {
            xconvert: 'Images and PDF merger to create single PDF',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'merge-image-to-pdf',
        type: 'merge',
        toSubType: ToSubTypes.imageMerge,
        from: ConvertFromPDF.TYPE,
        from_syn: ['image'], // , 'jpg', 'png' not supported yet since dimension issue
        to: 'pdf',
        to_syn: ['pdf'],
        link: '/merge-image-to-pdf',
        title: 'Merge Images To PDF',
        description: 'Merge Image files to single PDF',
        descriptions: {
            xconvert: 'Merge Image files to single PDF',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-jpg-to-png',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['jpg'],
        to: 'image',
        to_syn: ['png'],
        link: '/convert-jpg-to-png',
        title: 'Convert JPG to PNG',
        description: 'Best online JPG to PNG converter',
        descriptions: {
            xconvert: 'Best online JPG to PNG converter',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-png-to-jpg',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['png'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/convert-png-to-jpg',
        title: 'Convert PNG to JPG',
        description: 'Best online PNG to JPG converter',
        descriptions: {
            xconvert: 'Best online PNG to JPG converter',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-jpg-to-webp',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['jpg'],
        to: 'image',
        to_syn: ['webp'],
        link: '/convert-jpg-to-webp',
        title: 'Convert JPG to WEBP',
        description: 'Best online JPG to WEBP converter',
        descriptions: {
            xconvert: 'Best online JPG to WEBP converter',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-webp-to-jpg',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['webp'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/convert-webp-to-jpg',
        title: 'Convert WEBP to JPG',
        description: 'Best online WEBP to JPG converter',
        descriptions: {
            xconvert: 'Best online WEBP to JPG converter',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-jpg-to-tiff',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['jpg'],
        to: 'image',
        to_syn: ['tiff'],
        link: '/convert-jpg-to-tiff',
        title: 'Convert JPG to TIFF',
        description: 'Best online JPG to TIFF converter',
        descriptions: {
            xconvert: 'Best online JPG to TIFF converter',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-tiff-to-jpg',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['tiff'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/convert-tiff-to-jpg',
        title: 'Convert TIFF to JPG',
        description: 'Best online TIFF to JPG converter',
        descriptions: {
            xconvert: 'Best online TIFF to JPG converter',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-png-to-webp',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['png'],
        to: 'image',
        to_syn: ['webp'],
        link: '/convert-png-to-webp',
        title: 'Convert PNG to WEBP',
        description: 'Best online PNG to WEBP converter',
        descriptions: {
            xconvert: 'Best online PNG to WEBP converter',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-webp-to-png',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['webp'],
        to: 'image',
        to_syn: ['png'],
        link: '/convert-webp-to-png',
        title: 'Convert WEBP to PNG',
        description: 'Best online WEBP to PNG converter',
        descriptions: {
            xconvert: 'Best online WEBP to PNG converter',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-png-to-tiff',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['png'],
        to: 'image',
        to_syn: ['tiff'],
        link: '/convert-png-to-tiff',
        title: 'Convert PNG to TIFF',
        description: 'Best online PNG to TIFF converter',
        descriptions: {
            xconvert: 'Best online PNG to TIFF converter',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-tiff-to-png',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['tiff'],
        to: 'image',
        to_syn: ['png'],
        link: '/convert-tiff-to-png',
        title: 'Convert TIFF to PNG',
        description: 'Best online TIFF to PNG converter',
        descriptions: {
            xconvert: 'Best online TIFF to PNG converter',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-jpg-to-heic',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['jpg'],
        to: 'image',
        to_syn: ['heic'],
        link: '/convert-jpg-to-heic',
        title: 'Convert JPG to HEIC',
        description: 'Best online JPG to HEIC converter',
        descriptions: {
            xconvert: 'Best online JPG to HEIC converter',
        },
    },
    {
        id: 'convert-heic-to-jpg',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['heic'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/convert-heic-to-jpg',
        title: 'Convert HEIC to JPG',
        description: 'Best online HEIC to JPG converter',
        descriptions: {
            xconvert: 'Best online HEIC to JPG converter',
        },
    },
    {
        id: 'convert-webp-to-heic',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['webp'],
        to: 'image',
        to_syn: ['heic'],
        link: '/convert-webp-to-heic',
        title: 'Convert WEBP to HEIC',
        description: 'Best online WEBP to HEIC converter',
        descriptions: {
            xconvert: 'Best online WEBP to HEIC converter',
        },
    },
    {
        id: 'convert-heic-to-webp',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['heic'],
        to: 'image',
        to_syn: ['webp'],
        link: '/convert-heic-to-webp',
        title: 'Convert HEIC to WEBP',
        description: 'Best online HEIC to WEBP converter',
        descriptions: {
            xconvert: 'Best online HEIC to WEBP converter',
        },
    },
    {
        id: 'convert-png-to-heic',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['png'],
        to: 'image',
        to_syn: ['heic'],
        link: '/convert-png-to-heic',
        title: 'Convert PNG to HEIC',
        description: 'Best online PNG to HEIC converter',
        descriptions: {
            xconvert: 'Best online PNG to HEIC converter',
        },
    },
    {
        id: 'convert-heic-to-png',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['heic'],
        to: 'image',
        to_syn: ['png'],
        link: '/convert-heic-to-png',
        title: 'Convert HEIC to PNG',
        description: 'Best online HEIC to PNG converter',
        descriptions: {
            xconvert: 'Best online HEIC to PNG converter',
        },
    },
    {
        id: 'convert-tiff-to-heic',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['tiff'],
        to: 'image',
        to_syn: ['heic'],
        link: '/convert-tiff-to-heic',
        title: 'Convert TIFF to HEIC',
        description: 'Best online TIFF to HEIC converter',
        descriptions: {
            xconvert: 'Best online TIFF to HEIC converter',
        },
    },
    {
        id: 'convert-heic-to-tiff',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['heic'],
        to: 'image',
        to_syn: ['tiff'],
        link: '/convert-heic-to-tiff',
        title: 'Convert HEIC to TIFF',
        description: 'Best online HEIC to TIFF converter',
        descriptions: {
            xconvert: 'Best online HEIC to TIFF converter',
        },
    },
    {
        id: 'convert-cr2-to-jpg',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['cr2'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/convert-cr2-to-jpg',
        title: 'Convert CR2 to JPG',
        description: 'Best online CR2 to JPG converter',
        descriptions: {
            xconvert: 'Best online CR2 to JPG converter',
        },
    },
    {
        id: 'convert-nef-to-jpg',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['nef'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/convert-nef-to-jpg',
        title: 'Convert NEF to JPG',
        description: 'Best online NEF to JPG converter',
        descriptions: {
            xconvert: 'Best online NEF to JPG converter',
        },
    },
    {
        id: 'meme-generator',
        type: 'meme',
        toSubType: ToSubTypes.meme,
        from: ConvertFromImage.TYPE,
        from_syn: ['meme'],
        to: 'image',
        to_syn: ['meme'],
        link: '/meme-generator',
        title: 'Meme Generator',
        description: 'Create Memes Online',
        descriptions: {
            xconvert: 'Create Memes Online',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'edit-image',
        type: 'resize',
        toSubType: ToSubTypes.imageResize,
        from: ConvertFromImage.TYPE,
        from_syn: ['image', 'jpg', 'jpeg', 'png', 'webp', 'tiff', 'tif', 'bmp'],
        to: 'image',
        to_syn: ['jpg', 'jpeg', 'png', 'webp', 'tiff', 'tif', 'bmp'],
        link: '/edit-image',
        title: 'Edit Image Online',
        description: 'Crop, Resize, Shrink, Rotate and Flip Any Photo Type',
        descriptions: {
            xconvert: 'Crop, Resize, Shrink, Rotate and Flip Any Photo Type',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'resize-image',
        type: 'resize',
        toSubType: ToSubTypes.imageResize,
        from: ConvertFromImage.TYPE,
        from_syn: ['image', 'jpg', 'jpeg', 'png', 'webp', 'tiff', 'tif', 'bmp'],
        to: 'image',
        to_syn: ['jpg', 'jpeg', 'png', 'webp', 'tiff', 'tif', 'bmp'],
        link: '/resize-image',
        title: 'Resize Image Online',
        description: 'Resize JPG/JPEG, PNG, WebP, BPM, TIF/TIFF Photos',
        descriptions: {
            xconvert: 'Resize JPG/JPEG, PNG, WebP, BPM, TIF/TIFF Photos',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'image-resizer',
        type: 'resize',
        toSubType: ToSubTypes.imageResize,
        from: ConvertFromImage.TYPE,
        from_syn: ['image', 'jpg', 'jpeg', 'png', 'webp', 'tiff', 'tif', 'bmp'],
        to: 'image',
        to_syn: ['jpg', 'jpeg', 'png', 'webp', 'tiff', 'tif', 'bmp'],
        link: '/image-resizer',
        title: 'Resize Image Online',
        description: 'Resize JPG/JPEG, PNG, WebP, BPM, TIF/TIFF Photos',
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'resize-jpg',
        type: 'resize',
        toSubType: ToSubTypes.imageResize,
        from: ConvertFromImage.TYPE,
        from_syn: ['jpg', 'jpeg'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/resize-jpg',
        title: 'Resize JPG image online',
        description: 'Online JPG files batch resizer',
        descriptions: {
            xconvert: 'Online JPG files batch resizer',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'resize-png',
        type: 'resize',
        toSubType: ToSubTypes.imageResize,
        from: ConvertFromImage.TYPE,
        from_syn: ['png'],
        to: 'image',
        to_syn: ['png'],
        link: '/resize-png',
        title: 'Resize PNG image online',
        description: 'Best online batch PNG file resizer',
        descriptions: {
            xconvert: 'Best online batch PNG file resizer',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'resize-webp',
        type: 'resize',
        toSubType: ToSubTypes.imageResize,
        from: ConvertFromImage.TYPE,
        from_syn: ['webp'],
        to: 'image',
        to_syn: ['webp'],
        link: '/resize-webp',
        title: 'Resize WEBP file online',
        description: 'Online WEBP image batch resizer',
        descriptions: {
            xconvert: 'Online WEBP image batch resizer',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'resize-tiff',
        type: 'resize',
        toSubType: ToSubTypes.imageResize,
        from: ConvertFromImage.TYPE,
        from_syn: ['tiff'],
        to: 'image',
        to_syn: ['tiff'],
        link: '/resize-tiff',
        title: 'Resize TIFF image online',
        description: 'Resize multiple TIFF files online',
        descriptions: {
            xconvert: 'Resize multiple TIFF files online',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'crop-image',
        type: 'crop',
        toSubType: ToSubTypes.imageResize,
        from: ConvertFromImage.TYPE,
        from_syn: ['image', 'jpg', 'jpeg', 'png', 'webp', 'tiff', 'tif', 'bmp'],
        to: 'image',
        to_syn: ['jpg', 'jpeg', 'png', 'webp', 'tiff', 'tif', 'bmp'],
        link: '/crop-image',
        title: 'Crop Image Online',
        description: 'Crop JPG/JPEG, PNG, WebP, BPM, TIF/TIFF Photos',
        descriptions: {
            xconvert: 'Crop JPG/JPEG, PNG, WebP, BPM, TIF/TIFF Photos',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'crop-jpg',
        type: 'crop',
        toSubType: ToSubTypes.imageResize,
        from: ConvertFromImage.TYPE,
        from_syn: ['jpg', 'jpeg'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/crop-jpg',
        title: 'Crop JPG image online',
        description: 'Online JPG files batch cropper',
        descriptions: {
            xconvert: 'Online JPG files batch cropper',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'crop-png',
        type: 'crop',
        toSubType: ToSubTypes.imageResize,
        from: ConvertFromImage.TYPE,
        from_syn: ['png'],
        to: 'image',
        to_syn: ['png'],
        link: '/crop-png',
        title: 'Crop PNG image online',
        description: 'Best online batch PNG file cropper',
        descriptions: {
            xconvert: 'Best online batch PNG file cropper',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'crop-webp',
        type: 'crop',
        toSubType: ToSubTypes.imageResize,
        from: ConvertFromImage.TYPE,
        from_syn: ['webp'],
        to: 'image',
        to_syn: ['webp'],
        link: '/crop-webp',
        title: 'Crop WEBP file online',
        description: 'Online WEBP image batch cropper',
        descriptions: {
            xconvert: 'Online WEBP image batch cropper',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'crop-tiff',
        type: 'crop',
        toSubType: ToSubTypes.imageResize,
        from: ConvertFromImage.TYPE,
        from_syn: ['tiff'],
        to: 'image',
        to_syn: ['tiff'],
        link: '/crop-tiff',
        title: 'Crop TIFF image online',
        description: 'Crop multiple TIFF files online',
        descriptions: {
            xconvert: 'Crop multiple TIFF files online',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'compress-image',
        type: 'compress',
        toSubType: ToSubTypes.imageCompress,
        from: ConvertFromImage.TYPE,
        from_syn: ['image', 'jpg', 'jpeg'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/compress-image',
        title: 'Compress Image online',
        description: 'Online Compress TIFF/TIFF PNG, JPG/JPEG image',
        descriptions: {
            xconvert: 'Online Compress TIFF/TIFF PNG, JPG/JPEG image',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'image-compressor',
        type: 'compress',
        toSubType: ToSubTypes.imageCompress,
        from: ConvertFromImage.TYPE,
        from_syn: ['image', 'jpg', 'jpeg', 'png', 'tiff', 'tif', 'webp'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/image-compressor',
        title: 'Image Compressor online',
        description: 'Compress any image online for free',
        descriptions: {
            xconvert: 'Compress any image online for free',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'compress-jpg',
        type: 'compress',
        toSubType: ToSubTypes.imageCompress,
        from: ConvertFromImage.TYPE,
        from_syn: ['jpg', 'jpeg'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/compress-jpg',
        title: 'Compress JPG/JPEG image online',
        description: 'Online JPG/JPEG files batch compressor',
        descriptions: {
            xconvert: 'Online JPG/JPEG files batch compressor',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'compress-jpeg',
        type: 'compress',
        toSubType: ToSubTypes.imageCompress,
        from: ConvertFromImage.TYPE,
        from_syn: ['jpeg', 'jpg'],
        to: 'image',
        to_syn: ['jpeg'],
        link: '/compress-jpeg',
        title: 'Compress JPEG image online',
        description: 'Online JPEG files batch compressor',
        descriptions: {
            xconvert: 'Online JPEG files batch compressor',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'compress-gif',
        type: 'compress',
        toSubType: ToSubTypes.imageCompress,
        from: ConvertFromImage.TYPE,
        from_syn: ['gif'],
        to: 'image',
        to_syn: ['gif'],
        link: '/compress-gif',
        title: 'Compress GIF image online',
        description: 'Online animated GIF compressor',
        descriptions: {
            xconvert: 'Online animated GIF compressor',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'compress-png',
        type: 'compress',
        toSubType: ToSubTypes.imageCompress,
        from: ConvertFromImage.TYPE,
        from_syn: ['png'],
        to: 'image',
        to_syn: ['png'],
        link: '/compress-png',
        title: 'Compress PNG image online',
        description: 'Best online batch PNG file compressor',
        descriptions: {
            xconvert: 'Best online batch PNG file compressor',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'compress-webp',
        type: 'compress',
        toSubType: ToSubTypes.imageCompress,
        from: ConvertFromImage.TYPE,
        from_syn: ['webp'],
        to: 'image',
        to_syn: ['webp'],
        link: '/compress-webp',
        title: 'Compress WEBP file online',
        description: 'Online WEBP image batch compressor',
        descriptions: {
            xconvert: 'Online WEBP image batch compressor',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'compress-tiff',
        type: 'compress',
        toSubType: ToSubTypes.imageCompress,
        from: ConvertFromImage.TYPE,
        from_syn: ['tiff'],
        to: 'image',
        to_syn: ['tiff'],
        link: '/compress-tiff',
        title: 'Compress TIFF image online',
        description: 'Compress multiple TIFF files online',
        descriptions: {
            xconvert: 'Compress multiple TIFF files online',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'compress-heic',
        type: 'compress',
        toSubType: ToSubTypes.imageCompress,
        from: ConvertFromImage.TYPE,
        from_syn: ['heic'],
        to: 'image',
        to_syn: ['heic'],
        link: '/compress-heic',
        title: 'Compress HEIC image online',
        description: 'Compress multiple HEIC files online',
        descriptions: {
            xconvert: 'Compress multiple HEIC files online',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'compress-jpg-image-online',
        type: 'compress',
        toSubType: ToSubTypes.imageCompress,
        from: ConvertFromImage.TYPE,
        from_syn: ['jpg', 'jpeg'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/compress-jpg-image-online',
        title: 'HTML5 JPG compressor',
        description: 'Online HTML5 JPG file compressor',
        descriptions: {
            xconvert: 'Online HTML5 JPG file compressor',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'compress-pdf',
        type: 'compress',
        toSubType: ToSubTypes.pdf,
        from: ConvertFromPDF.TYPE,
        from_syn: ['pdf', 'pdf/a'],
        to: 'pdf',
        to_syn: ['pdf'],
        link: '/compress-pdf',
        title: 'Compress PDF',
        description: 'Best online PDF compressor',
        descriptions: {
            xconvert: 'Best online PDF compressor',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'compress-mp4',
        type: 'compress',
        toSubType: ToSubTypes.videoCompress,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mp4'],
        to: 'video',
        to_syn: ['mp4'],
        link: '/compress-mp4',
        title: 'MP4 video compressor',
        description: 'Online MP4 video file compressor',
        descriptions: {
            xconvert: 'Online MP4 video file compressor',
            videocompressor: 'MP4 Compressor to reduce video file size.'
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'compress-mkv',
        type: 'compress',
        toSubType: ToSubTypes.videoCompress,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mkv'],
        to: 'video',
        to_syn: ['mkv'],
        link: '/compress-mkv',
        title: 'MKV video compressor',
        description: 'Online MKV video file compressor',
        descriptions: {
            xconvert: 'Online MKV video file compressor',
            videocompressor: 'Free online MKV Compressor to reduce video file size.'
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    // AVI, WMV, and FLV doesnt seem to work well with ffmpeg -b:v and -maxrate to compress properly
    // {
    //     id: 'compress-avi',
    //     type: 'compress',
    //     toSubType: ToSubTypes.videoCompress,
    //     from: ConvertFromVideo.TYPE,
    //     from_syn: ['avi'],
    //     to: 'video',
    //     to_syn: ['avi'],
    //     link: '/compress-avi',
    //     title: 'AVI video compressor',
    //     dxxxescription: 'Online AVI video file compressor',
    //     availability: {
    //         online: true,
    //         offline: true,
    //     },
    // },
    // {
    //     id: 'compress-flv',
    //     type: 'compress',
    //     toSubType: ToSubTypes.videoCompress,
    //     from: ConvertFromVideo.TYPE,
    //     from_syn: ['flv'],
    //     to: 'video',
    //     to_syn: ['flv'],
    //     link: '/compress-flv',
    //     title: 'FLV video compressor',
    //     dxxxescription: 'Online FLV video file compressor',
    //     availability: {
    //         online: true,
    //         offline: true,
    //     },
    // },
    // {
    //     id: 'compress-wmv',
    //     type: 'compress',
    //     toSubType: ToSubTypes.videoCompress,
    //     from: ConvertFromVideo.TYPE,
    //     from_syn: ['wmv'],
    //     to: 'video',
    //     to_syn: ['wmv'],
    //     link: '/compress-wmv',
    //     title: 'WMV video compressor',
    //     dxxxescription: 'Online WMV video file compressor',
    //     availability: {
    //         online: true,
    //         offline: true,
    //     },
    // },
    {
        id: 'compress-mov',
        type: 'compress',
        toSubType: ToSubTypes.videoCompress,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mov'],
        to: 'video',
        to_syn: ['mov'],
        link: '/compress-mov',
        title: 'MOV video compressor',
        description: 'Online MOV video file compressor',
        descriptions: {
            xconvert: 'Online MOV video file compressor',
            videocompressor: 'Compres Apple .MOV videos make video smaller.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'compress-m4v',
        type: 'compress',
        toSubType: ToSubTypes.videoCompress,
        from: ConvertFromVideo.TYPE,
        from_syn: ['m4v'],
        to: 'video',
        to_syn: ['m4v'],
        link: '/compress-m4v',
        title: 'M4V video compressor',
        description: 'Online M4V video file compressor',
        descriptions: {
            xconvert: 'Online M4V video file compressor',
            videocompressor: 'Free M4V compressor to make video file size smaller.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'compress-mts',
        type: 'compress',
        toSubType: ToSubTypes.videoCompress,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mts'],
        to: 'video',
        to_syn: ['mts'],
        link: '/compress-mts',
        title: 'MTS video compressor',
        description: 'Online MTS video file compressor',
        descriptions: {
            xconvert: 'Online MTS video file compressor',
            videocompressor: 'Online Blu-ray Disc Audio-Video (BDAV) MPEG-2 Transport Stream (M2TS) MTS video compressor.'
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'compress-mpg',
        type: 'compress',
        toSubType: ToSubTypes.videoCompress,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mpg'],
        to: 'video',
        to_syn: ['mpg'],
        link: '/compress-mpg',
        title: 'MPG video compressor',
        description: 'Online MPG video file compressor',
        descriptions: {
            xconvert: 'Online MPG video file compressor',
            videocompressor: 'Moving Picture Experts Group (MPEG) MPG video file compressor to reduce video file size.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'compress-webm',
        type: 'compress',
        toSubType: ToSubTypes.videoCompress,
        from: ConvertFromVideo.TYPE,
        from_syn: ['webm'],
        to: 'video',
        to_syn: ['webm'],
        link: '/compress-webm',
        title: 'WebM Video compressor',
        description: 'Online WebM video file compressor',
        descriptions: {
            xconvert: 'Online WebM video file compressor',
            videocompressor: 'Compress WebM with VP8/VP9/AV1 encoding to reduce video file size.'
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'video-compressor',
        type: 'compress',
        toSubType: ToSubTypes.videoCompress,
        from: ConvertFromVideo.TYPE,
        from_syn: ['video', 'mp4', 'webm'],
        to: 'video',
        to_syn: ['video', 'mp4', 'webm'],
        link: '/video-compressor',
        title: 'Video Compressor',
        description: 'Free online video file compressor',
        descriptions: {
            xconvert: 'Free online video file compressor',
            videocompressor: 'Compress MP4, WebM, MTS, MKV and more to a desired video file size.'
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'audio-compressor',
        type: 'compress',
        toSubType: ToSubTypes.audioCompress,
        from: ConvertFromAV.TYPE,
        from_syn: ['audio', 'mp3'],
        to: 'audio',
        to_syn: ['mp3'],
        link: '/audio-compressor',
        title: 'Audio compressor',
        description: 'Online audio file compressor',
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'compress-mp3',
        type: 'compress',
        toSubType: ToSubTypes.audioCompress,
        from: ConvertFromAV.TYPE,
        from_syn: ['mp3', 'audio'],
        to: 'audio',
        to_syn: ['mp3'],
        link: '/compress-mp3',
        title: 'MP3 audio compressor',
        description: 'Online MP3 audio file compressor',
        descriptions: {
            xconvert: 'Online MP3 audio file compressor',
            videocompressor: 'MP3 (formally MPEG-1 Audio Layer III or MPEG-2 Audio Layer III) compressor to change bitrate and reduce audio file size.'
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'compress-wav',
        type: 'compress',
        toSubType: ToSubTypes.audioCompress,
        from: ConvertFromAV.TYPE,
        from_syn: ['wav', 'audio'],
        to: 'audio',
        to_syn: ['wav'],
        link: '/compress-wav',
        title: 'WAV audio compressor',
        description: 'Online WAV audio file compressor',
        descriptions: {
            xconvert: 'Online WAV audio file compressor',
            videocompressor: 'Reduce Microsoft WAV (Waveform Audio File Format) audio file size by compressing.'
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'compress-m4r',
        type: 'compress',
        toSubType: ToSubTypes.audioCompress,
        from: ConvertFromAV.TYPE,
        from_syn: ['m4r', 'audio'],
        to: 'audio',
        to_syn: ['m4r'],
        link: '/compress-m4r',
        title: 'M4R audio compressor',
        description: 'Online M4R audio file compressor',
        descriptions: {
            xconvert: 'Online M4R audio file compressor',
            videocompressor: 'M4R (Apple iPhone rington format) audio compressor to reduce audio file size.'
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'video-converter',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['video', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['video', 'mp4', 'webm'],
        link: '/video-converter',
        title: 'Video converter',
        description: 'Free online video file converter',
        descriptions: {
            xconvert: 'Free online video file converter',
            videocompressor: 'Convert MP4, WebM, AVI and other video formats.'
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'mp4-converter',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mp4', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['video', 'mp4', 'webm'],
        link: '/mp4-converter',
        title: 'mp4 converter',
        description: 'Free online MP4 file converter',
        descriptions: {
            xconvert: 'Free online MP4 file converter',
            videocompressor: 'Convert MP4, WebM, AVI and other video formats.'
        },
    },
    {
        id: 'convert-video-to-webm',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['video', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['webm'],
        link: '/convert-video-to-webm',
        title: 'Convert video to WebM',
        description: 'Online WebM video file converter',
        descriptions: {
            xconvert: 'Online WebM video file converter',
            videocompressor: 'Faster way to convert any video format to WebM video format.'
        },
        availability: {
            online: true,
            offline: true,
        },
    }, {
        id: 'convert-video-to-mp4',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['video', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['mp4'],
        link: '/convert-video-to-mp4',
        title: 'Convert video to MP4',
        description: 'Online MP4 video file converter',
        descriptions: {
            xconvert: 'Online MP4 video file converter',
            videocompressor: 'Best way to convert WebM, AVI, WMV and any other video format to MP4 video format.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },








    {
        id: 'convert-mkv-to-mp4',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mkv', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['mp4'],
        link: '/convert-mkv-to-mp4',
        title: 'Convert MKV to MP4',
        description: 'Online MKV to MP4 converter',
        descriptions: {
            xconvert: 'Online MKV to MP4 converter',
            videocompressor: 'Fast online MKV video to MP4 converter.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'convert-avi-to-mp4',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['avi', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['mp4'],
        link: '/convert-avi-to-mp4',
        title: 'Convert AVI to MP4',
        description: 'Online AVI to MP4 converter',
        descriptions: {
            xconvert: 'Online AVI to MP4 converter',
            videocompressor: 'Convert AVI (Microsft Audio Video Interleave) files to MP4 videos.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'convert-mov-to-mp4',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mov', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['mp4'],
        link: '/convert-mov-to-mp4',
        title: 'Convert MOV to MP4',
        description: 'Online MOV to MP4 converter',
        descriptions: {
            xconvert: 'Online MOV to MP4 converter',
            videocompressor: 'Online MOV (Apple QuickTime Video) to MP4 converter for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'convert-webm-to-mp4',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['webm', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['mp4'],
        link: '/convert-webm-to-mp4',
        title: 'Convert WEBM to MP4',
        description: 'Online WEBM to MP4 converter',
        descriptions: {
            xconvert: 'Online WEBM to MP4 converter',
            videocompressor: 'Best online (Google Open Source) WebM to MP4 video converter.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'convert-flv-to-mp4',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['flv', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['mp4'],
        link: '/convert-flv-to-mp4',
        title: 'Convert FLV to MP4',
        description: 'Online FLV to MP4 converter',
        descriptions: {
            xconvert: 'Online FLV to MP4 converter',
            videocompressor: 'Convert Adobe FLV flash video to MP4 videos.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'convert-m4v-to-mp4',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['m4v', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['mp4'],
        link: '/convert-m4v-to-mp4',
        title: 'Convert M4V to MP4',
        description: 'Online M4V to MP4 converter',
        descriptions: {
            xconvert: 'Online M4V to MP4 converter',
            videocompressor: 'Online M4V to MP4 converter.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'convert-wmv-to-mp4',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['wmv', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['mp4'],
        link: '/convert-wmv-to-mp4',
        title: 'Convert WMV to MP4',
        description: 'Online WMV to MP4 converter',
        descriptions: {
            xconvert: 'Online WMV to MP4 converter',
            videocompressor: 'Convert Microsft WMV videos to MP4 format.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'convert-mts-to-mp4',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mts', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['mp4'],
        link: '/convert-mts-to-mp4',
        title: 'Convert MTS to MP4',
        description: 'Online MTS to MP4 converter',
        descriptions: {
            xconvert: 'Online MTS to MP4 converter',
            videocompressor: 'Best online MTS (BDAV MPEG-2 Transport Stream) to MP4 converter.'
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'convert-mpg-to-mp4',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mpg', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['mp4'],
        link: '/convert-mpg-to-mp4',
        title: 'Convert MPG to MP4',
        description: 'Online MPG to MP4 converter',
        descriptions: {
            xconvert: 'Online MPG to MP4 converter.',
            videocompressor: 'Free MPG or MPEG video to MP4 converter.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },


    {
        id: 'convert-mkv-to-webm',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mkv', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['webm'],
        link: '/convert-mkv-to-webm',
        title: 'Convert MKV to WebM',
        description: 'Online MKV to WebM converter',
        descriptions: {
            xconvert: 'Online MKV to WebM converter',
            videocompressor: 'Convert and compress MKV video to WebM format.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'convert-avi-to-webm',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['avi', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['webm'],
        link: '/convert-avi-to-webm',
        title: 'Convert AVI to WebM',
        description: 'Online AVI to WebM converter',
        descriptions: {
            xconvert: 'Online AVI to WebM converter',
            videocompressor: 'Free online Microsft AVI video to WebM converter.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'convert-mov-to-webm',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mov', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['webm'],
        link: '/convert-mov-to-webm',
        title: 'Convert MOV to WebM',
        description: 'Online MOV to WebM converter',
        descriptions: {
            xconvert: 'Online MOV to WebM converter',
            videocompressor: 'Free online Apple QuickTime video MOV to WebM converter.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'convert-webm-to-webm',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['webm', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['webm'],
        link: '/convert-webm-to-webm',
        title: 'Convert WEBM to WebM',
        description: 'Online WEBM to WebM converter',
        descriptions: {
            xconvert: 'Online WEBM to WebM converter',
            videocompressor: 'Re-encode WebM format with different settings.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'convert-flv-to-webm',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['flv', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['webm'],
        link: '/convert-flv-to-webm',
        title: 'Convert FLV to WebM',
        description: 'Online FLV to WebM converter',
        descriptions: {
            xconvert: 'Online FLV to WebM converter',
            videocompressor: 'Convert Adobe Flash video FLV to WebM online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'convert-m4v-to-webm',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['m4v', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['webm'],
        link: '/convert-m4v-to-webm',
        title: 'Convert M4V to WebM',
        description: 'Online M4V to WebM converter',
        descriptions: {
            xconvert: 'Online M4V to WebM converter',
            videocompressor: 'Best M4V/MP4 video to WebM converter for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'convert-wmv-to-webm',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['wmv', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['webm'],
        link: '/convert-wmv-to-webm',
        title: 'Convert WMV to WebM',
        description: 'Online WMV to WebM converter',
        descriptions: {
            xconvert: 'Online WMV to WebM converter',
            videocompressor: 'Convert Microsoft video format WMV to WebM format.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'convert-mts-to-webm',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mts', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['webm'],
        link: '/convert-mts-to-webm',
        title: 'Convert MTS to WebM',
        description: 'Online MTS to WebM converter',
        descriptions: {
            xconvert: 'Online MTS to WebM converter',
            videocompressor: 'Fast online MTS to WebM converter.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'convert-mpg-to-webm',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mpg', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['webm'],
        link: '/convert-mpg-to-webm',
        title: 'Convert MPG to WebM',
        description: 'Online MPG to WebM converter',
        descriptions: {
            xconvert: 'Online MPG to WebM converter',
            videocompressor: 'Convert MPG and MPEG video files to WebM format.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },







    {
        id: 'convert-mov-to-webm',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mov', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'video',
        to_syn: ['webm'],
        link: '/convert-mov-to-webm',
        title: 'Convert MOV to WebM',
        description: 'Online MOV to WebM converter',
        descriptions: {
            xconvert: 'Online MOV to WebM converter',
            videocompressor: 'Best online MOV (Apple QuickTime Video format) to WebM video.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'convert-gif-to-video',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['gif'],
        to: 'mp4',
        to_syn: ['video'],
        link: '/convert-gif-to-video',
        title: 'Convert GIF to Video',
        description: 'Online GIF to Video file converter',
        descriptions: {
            xconvert: 'Online GIF to Video file converter',
            videocompressor: 'Online GIF (Graphics Interchange Format) image to Video converter.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-gif-to-mp4',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['gif'],
        to: 'mp4',
        to_syn: ['mp4'],
        link: '/convert-gif-to-mp4',
        title: 'Convert GIF to MP4',
        description: 'Online GIF to MP4 converter',
        descriptions: {
            xconvert: 'Online GIF to MP4 converter',
            videocompressor: 'Convert GIF images to MP4 video format for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-gif-to-webm',
        type: 'convert',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['gif'],
        to: 'webm',
        to_syn: ['webm'],
        link: '/convert-gif-to-webm',
        title: 'Convert GIF to WebM',
        description: 'Online GIF to WebM converter',
        descriptions: {
            xconvert: 'Online GIF to WebM converter',
            videocompressor: 'Convert GIF images to WebM online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'convert-video-to-gif',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['video', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'gif',
        to_syn: ['gif'],
        link: '/convert-video-to-gif',
        title: 'Convert Video to GIF',
        description: 'Online Video to animated GIF converter',
        descriptions: {
            xconvert: 'Online Video to animated GIF converter',
            videocompressor: 'Convert any short video clip to GIF image for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-mp4-to-gif',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mp4', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'gif',
        to_syn: ['gif'],
        link: '/convert-mp4-to-gif',
        title: 'Convert MP4 to GIF',
        description: 'Online MP4 to GIF converter',
        descriptions: {
            xconvert: 'Online MP4 to GIF converter',
            videocompressor: 'Convert and resize MP4 video to GIF image.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-webm-to-gif',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['webm', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'wmv', 'mov'],
        to: 'gif',
        to_syn: ['gif'],
        link: '/convert-webm-to-gif',
        title: 'Convert WebM to GIF',
        description: 'Online WebM to GIF converter',
        descriptions: {
            xconvert: 'Online WebM to GIF converter',
            videocompressor: 'Convert WebM to GIF image online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-mov-to-gif',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['webm', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'wmv', 'mov'],
        to: 'gif',
        to_syn: ['gif'],
        link: '/convert-mov-to-gif',
        title: 'Convert MOV to GIF',
        description: 'Online MOV to GIF converter',
        availability: {
            online: true,
            offline: true,
        },
    },

    /**/
    {
        id: 'convert-video-to-mp3',
        type: 'convert',
        toSubType: ToSubTypes.audio,
        from: ConvertFromAV.TYPE,
        from_syn: ['video', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'audio',
        to_syn: ['mp3'],
        link: '/convert-video-to-mp3',
        title: 'Convert Videos to MP3',
        description: 'Online Video to MP3 converter',
        descriptions: {
            xconvert: 'Online Video to MP3 converter',
            videocompressor: 'Convert any video format to MP3 audio file.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'audio-converter',
        type: 'convert',
        toSubType: ToSubTypes.audio,
        from: ConvertFromAV.TYPE,
        from_syn: ['audio', 'video', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'audio',
        to_syn: ['audio'],
        link: '/audio-converter',
        title: 'Convert Media to Audio',
        description: 'Online Video/Audio to Audio converter',
        descriptions: {
            xconvert: 'Online Video/Audio to Audio converter',
            videocompressor: 'Convert audio format between MP3, WMA, FLA, FLAC and more.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-mp4-to-mp3',
        type: 'convert',
        toSubType: ToSubTypes.audio,
        from: ConvertFromAV.TYPE,
        from_syn: ['video', 'mts', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mp4', 'mpeg', 'mpg', 'vob', 'webm', 'wmv', 'mov'],
        to: 'audio',
        to_syn: ['mp3'],
        link: '/convert-mp4-to-mp3',
        title: 'Convert Videos to MP3',
        description: 'Online MP4 to MP3 converter',
        descriptions: {
            xconvert: 'Online MP4 to MP3 converter',
            videocompressor: 'Fasterst way to extract audio track from a MP4 file.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-pdf-to-epub',
        type: 'convert',
        toSubType: ToSubTypes.ebook,
        from: ConvertFromEbook.TYPE,
        from_syn: ['pdf'],
        to: 'epub',
        to_syn: ['epub'],
        link: '/convert-pdf-to-epub',
        title: 'Convert PDF to EPUB',
        description: 'Best online PDF to EPUB converter',
        descriptions: {
            xconvert: 'Best online PDF to EPUB converter',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-pdf-to-mobi',
        type: 'convert',
        toSubType: ToSubTypes.ebook,
        from: ConvertFromEbook.TYPE,
        from_syn: ['pdf'],
        to: 'epub',
        to_syn: ['mobi', 'epub'],
        link: '/convert-pdf-to-mobi',
        title: 'Convert PDF to MOBI',
        description: 'Best online PDF to MOBI converter',
        descriptions: {
            xconvert: 'Best online PDF to MOBI converter',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-epub-to-mobi',
        type: 'convert',
        toSubType: ToSubTypes.ebook,
        from: ConvertFromEbook.TYPE,
        from_syn: ['epub', 'pdf'],
        to: 'epub',
        to_syn: ['mobi', 'epub'],
        link: '/convert-epub-to-mobi',
        title: 'Convert EPUB to MOBI',
        description: 'Best online EPUB to MOBI converter',
        descriptions: {
            xconvert: 'Best online EPUB to MOBI converter',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-mobi-to-epub',
        type: 'convert',
        toSubType: ToSubTypes.ebook,
        from: ConvertFromEbook.TYPE,
        from_syn: ['mobi', 'pdf'],
        to: 'epub',
        to_syn: ['epub'],
        link: '/convert-mobi-to-epub',
        title: 'Convert MOBI to EPUB',
        description: 'Best online MOBI to EPUB converter',
        descriptions: {
            xconvert: 'Best online MOBI to EPUB converter',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'url-encoder',
        type: 'encode',
        toSubType: ToSubTypes.webtool,
        from: OfflineFromAny.TYPE,
        from_syn: ['url'],
        to: 'text',
        to_syn: ['url'],
        link: '/url-encoder',
        title: 'URL Encode',
        description: 'Best online URL Encoder',
        descriptions: {
            xconvert: 'Best online URL Encoder',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'url-decoder',
        type: 'decode',
        toSubType: ToSubTypes.webtool,
        from: OfflineFromAny.TYPE,
        from_syn: ['url'],
        to: 'text',
        to_syn: ['url'],
        link: '/url-decoder',
        title: 'URL Decode',
        description: 'Best online URL Decoder',
        descriptions: {
            xconvert: 'Best online URL Decoder',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'word-count',
        type: 'count',
        toSubType: ToSubTypes.webtool,
        from: OfflineFromAny.TYPE,
        from_syn: ['text'],
        to: 'text',
        to_syn: ['text'],
        link: '/word-count',
        title: 'Online Word Count',
        description: 'Best online word counter',
        descriptions: {
            xconvert: 'Best online word counter',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'base64-encode',
        type: 'convert',
        toSubType: ToSubTypes.webtool,
        from: OfflineFromAny.TYPE,
        from_syn: ['text'],
        to: 'text',
        to_syn: ['base64'],
        link: '/base64-encode',
        title: 'Base64 Encoder',
        description: 'Best online Base64 encode tool',
        descriptions: {
            xconvert: 'Best online Base64 encode tool',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'base64-decode',
        type: 'convert',
        toSubType: ToSubTypes.webtool,
        from: OfflineFromAny.TYPE,
        from_syn: ['base64'],
        to: 'text',
        to_syn: ['text'],
        link: '/base64-decode',
        title: 'Base64 Decoder',
        description: 'Best online Base64 decode tool',
        descriptions: {
            xconvert: 'Best online Base64 decode tool',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-ogg-to-mp3',
        type: 'convert',
        toSubType: ToSubTypes.audio,
        from: ConvertFromAudio.TYPE,
        from_syn: ['ogg'],
        to: 'audio',
        to_syn: ['mp3'],
        link: '/convert-ogg-to-mp3',
        title: 'Convert OGG to MP3',
        description: 'Best online OGG to MP3 converter.',
        descriptions: {
            xconvert: 'Best online OGG to MP3 converter.',
            videocompressor: 'Online OGG (by Xiph.Org Foundation) to MP3 converter.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-m4a-to-mp3',
        type: 'convert',
        toSubType: ToSubTypes.audio,
        from: ConvertFromAudio.TYPE,
        from_syn: ['m4a'],
        to: 'audio',
        to_syn: ['mp3'],
        link: '/convert-m4a-to-mp3',
        title: 'Convert M4A to MP3',
        description: 'Best online M4A to MP3 converter.',
        descriptions: {
            xconvert: 'Best online M4A to MP3 converter.',
            videocompressor: 'Convert M4A audio to MP3 format.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-m4a-to-wav',
        type: 'convert',
        toSubType: ToSubTypes.audio,
        from: ConvertFromAudio.TYPE,
        from_syn: ['m4a'],
        to: 'audio',
        to_syn: ['wav'],
        link: '/convert-m4a-to-wav',
        title: 'Convert M4A to WAV',
        description: 'Best online M4A to WAV converter.',
        descriptions: {
            xconvert: 'Best online M4A to WAV converter.',
            videocompressor: 'Convert M4A audio tracks to Microsft WAV format.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-wav-to-mp3',
        type: 'convert',
        toSubType: ToSubTypes.audio,
        from: ConvertFromAudio.TYPE,
        from_syn: ['wav'],
        to: 'audio',
        to_syn: ['mp3'],
        link: '/convert-wav-to-mp3',
        title: 'Convert WAV to MP3',
        description: 'Best online WAV to MP3 converter.',
        descriptions: {
            xconvert: 'Best online WAV to MP3 converter.',
            videocompressor: 'Online free Microsoft WAV to MP3 audio converter.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-mp3-to-wav',
        type: 'convert',
        toSubType: ToSubTypes.audio,
        from: ConvertFromAudio.TYPE,
        from_syn: ['mp3'],
        to: 'audio',
        to_syn: ['wav'],
        link: '/convert-mp3-to-wav',
        title: 'Convert MP3 to WAV',
        description: 'Best online MP3 to WAV converter.',
        descriptions: {
            xconvert: 'Best online MP3 to WAV converter.',
            videocompressor: 'Best online MP3 audio to WMV converter.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-flac-to-mp3',
        type: 'convert',
        toSubType: ToSubTypes.audio,
        from: ConvertFromAudio.TYPE,
        from_syn: ['flac'],
        to: 'audio',
        to_syn: ['mp3'],
        link: '/convert-flac-to-mp3',
        title: 'Convert FLAC to MP3',
        description: 'Best online FLAC to MP3 converter.',
        descriptions: {
            xconvert: 'Best online FLAC to MP3 converter.',
            videocompressor: 'Convert high quality FLAC to MP3 audio format.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-wma-to-mp3',
        type: 'convert',
        toSubType: ToSubTypes.audio,
        from: ConvertFromAudio.TYPE,
        from_syn: ['wma'],
        to: 'audio',
        to_syn: ['mp3'],
        link: '/convert-wma-to-mp3',
        title: 'Convert WMA to MP3',
        description: 'Best online WMA to MP3 converter.',
        descriptions: {
            xconvert: 'Best online WMA to MP3 converter.',
            videocompressor: 'Convert Microsoft WMA audio files to Portable MP3 audio files.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-aiff-to-mp3',
        type: 'convert',
        toSubType: ToSubTypes.audio,
        from: ConvertFromAudio.TYPE,
        from_syn: ['aiff'],
        to: 'audio',
        to_syn: ['mp3'],
        link: '/convert-aiff-to-mp3',
        title: 'Convert AIFF to MP3',
        description: 'Best online AIFF to MP3 converter.',
        descriptions: {
            xconvert: 'Best online AIFF to MP3 converter.',
            videocompressor: 'Free online AIFF to MP3 audio converter.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-aac-to-mp3',
        type: 'convert',
        toSubType: ToSubTypes.audio,
        from: ConvertFromAudio.TYPE,
        from_syn: ['aac'],
        to: 'audio',
        to_syn: ['mp3'],
        link: '/convert-aac-to-mp3',
        title: 'Convert AAC to MP3',
        description: 'Best online AAC to MP3 converter.',
        descriptions: {
            xconvert: 'Best online AAC to MP3 converter.',
            videocompressor: 'Convert Advanced Audio Coding (AAC) audio files to MP3 format.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-flac-to-wav',
        type: 'convert',
        toSubType: ToSubTypes.audio,
        from: ConvertFromAudio.TYPE,
        from_syn: ['flac'],
        to: 'audio',
        to_syn: ['wav'],
        link: '/convert-flac-to-wav',
        title: 'Convert FLAC to WAV',
        description: 'Best online FLAC to WAV converter.',
        descriptions: {
            xconvert: 'Best online FLAC to WAV converter.',
            videocompressor: 'Online Free Lossless Audio Codec (FLAC) to WAV converter.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-mp3-to-m4a',
        type: 'convert',
        toSubType: ToSubTypes.audio,
        from: ConvertFromAudio.TYPE,
        from_syn: ['mp3'],
        to: 'audio',
        to_syn: ['m4a'],
        link: '/convert-mp3-to-m4a',
        title: 'Convert MP3 to M4A',
        description: 'Best online MP3 to M4A converter.',
        descriptions: {
            xconvert: 'Best online MP3 to M4A converter.',
            videocompressor: 'Convert portable MP3 audio files to .m4a format.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-opus-to-mp3',
        type: 'convert',
        toSubType: ToSubTypes.audio,
        from: ConvertFromAudio.TYPE,
        from_syn: ['opus'],
        to: 'audio',
        to_syn: ['mp3'],
        link: '/convert-opus-to-mp3',
        title: 'Convert OPUS to MP3',
        description: 'Best online OPUS to MP3 converter.',
        descriptions: {
            xconvert: 'Best online OPUS to MP3 converter.',
            videocompressor: 'Online OPUS (lossy audio coding format) to MP3 converter.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-mp3-to-m4r',
        type: 'convert',
        toSubType: ToSubTypes.audio,
        from: ConvertFromAudio.TYPE,
        from_syn: ['mp3'],
        to: 'audio',
        to_syn: ['m4r'],
        link: '/convert-mp3-to-m4r',
        title: 'Convert MP3 to M4R',
        description: 'Best online MP3 to M4R converter.',
        descriptions: {
            xconvert: 'Best online MP3 to M4R converter.',
            videocompressor: 'Convert MP3 audio files to M4R format.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-mp4-to-wav',
        type: 'convert',
        toSubType: ToSubTypes.audio,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mp4'],
        to: 'audio',
        to_syn: ['wav'],
        link: '/convert-mp4-to-wav',
        title: 'Convert MP4 to WAV',
        description: 'Best online MP4 to WAV converter.',
        descriptions: {
            xconvert: 'Best online MP4 to WAV converter.',
            videocompressor: 'Extract and convert audio from MP4 to Microsft WAV format.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'convert-webm-to-mp3',
        type: 'convert',
        toSubType: ToSubTypes.audio,
        from: ConvertFromVideo.TYPE,
        from_syn: ['webm'],
        to: 'audio',
        to_syn: ['mp3'],
        link: '/convert-webm-to-mp3',
        title: 'Convert WebM to MP3',
        description: 'Best online WebM to MP3 converter.',
        descriptions: {
            xconvert: 'Best online WebM to MP3 converter.',
            videocompressor: 'Extract audio from a WebM video as MP3 audi file.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    /**
     * Duplicated to route without convert keyword
     */
    {
        id: 'jpg-to-png',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['jpg'],
        to: 'image',
        to_syn: ['png'],
        link: '/jpg-to-png',
        title: 'JPG to PNG',
        description: 'Convert JPG to PNG online for free.',
        descriptions: {
            xconvert: 'Convert JPG to PNG online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'png-to-jpg',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['png'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/png-to-jpg',
        title: 'PNG to JPG',
        description: 'Convert PNG to JPG online for free.',
        descriptions: {
            xconvert: 'Convert PNG to JPG online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'jpg-to-webp',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['jpg'],
        to: 'image',
        to_syn: ['webp'],
        link: '/jpg-to-webp',
        title: 'JPG to WEBP',
        description: 'Convert JPG to WEBP online for free.',
        descriptions: {
            xconvert: 'Convert JPG to WEBP online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'webp-to-jpg',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['webp'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/webp-to-jpg',
        title: 'WEBP to JPG',
        description: 'Convert WEBP to JPG online for free.',
        descriptions: {
            xconvert: 'Convert WEBP to JPG online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'jpg-to-tiff',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['jpg'],
        to: 'image',
        to_syn: ['tiff'],
        link: '/jpg-to-tiff',
        title: 'JPG to TIFF',
        description: 'Convert JPG to TIFF online for free.',
        descriptions: {
            xconvert: 'Convert JPG to TIFF online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'tiff-to-jpg',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['tiff'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/tiff-to-jpg',
        title: 'TIFF to JPG',
        description: 'Convert TIFF to JPG online for free.',
        descriptions: {
            xconvert: 'Convert TIFF to JPG online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'png-to-webp',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['png'],
        to: 'image',
        to_syn: ['webp'],
        link: '/png-to-webp',
        title: 'PNG to WEBP',
        description: 'Convert PNG to WEBP online for free.',
        descriptions: {
            xconvert: 'Convert PNG to WEBP online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'webp-to-png',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['webp'],
        to: 'image',
        to_syn: ['png'],
        link: '/webp-to-png',
        title: 'WEBP to PNG',
        description: 'Convert WEBP to PNG online for free.',
        descriptions: {
            xconvert: 'Convert WEBP to PNG online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'png-to-tiff',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['png'],
        to: 'image',
        to_syn: ['tiff'],
        link: '/png-to-tiff',
        title: 'PNG to TIFF',
        description: 'Convert PNG to TIFF online for free.',
        descriptions: {
            xconvert: 'Convert PNG to TIFF online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'tiff-to-png',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['tiff'],
        to: 'image',
        to_syn: ['png'],
        link: '/tiff-to-png',
        title: 'TIFF to PNG',
        description: 'Convert TIFF to PNG online for free.',
        descriptions: {
            xconvert: 'Convert TIFF to PNG online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'jpg-to-heic',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['jpg'],
        to: 'image',
        to_syn: ['heic'],
        link: '/jpg-to-heic',
        title: 'Convert JPG to HEIC',
        description: 'Convert JPG to HEIC online for free.',
        descriptions: {
            xconvert: 'Convert JPG to HEIC online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'heic-to-jpg',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['heic'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/heic-to-jpg',
        title: 'Convert HEIC to JPG',
        description: 'Convert HEIC to HEIC online for free.',
        descriptions: {
            xconvert: 'Convert HEIC to HEIC online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'webp-to-heic',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['webp'],
        to: 'image',
        to_syn: ['heic'],
        link: '/webp-to-heic',
        title: 'Convert WebP to HEIC',
        description: 'Convert WebP to HEIC online for free.',
        descriptions: {
            xconvert: 'Convert WebP to HEIC online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'heic-to-webp',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['heic'],
        to: 'image',
        to_syn: ['webp'],
        link: '/heic-to-webp',
        title: 'Convert HEIC to WebP',
        description: 'Convert HEIC to HEIC online for free.',
        descriptions: {
            xconvert: 'Convert HEIC to HEIC online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'png-to-heic',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['png'],
        to: 'image',
        to_syn: ['heic'],
        link: '/png-to-heic',
        title: 'Convert PNG to HEIC',
        description: 'Convert PNG to HEIC online for free.',
        descriptions: {
            xconvert: 'Convert PNG to HEIC online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'heic-to-png',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['heic'],
        to: 'image',
        to_syn: ['png'],
        link: '/heic-to-png',
        title: 'Convert HEIC to PNG',
        description: 'Convert HEIC to HEIC online for free.',
        descriptions: {
            xconvert: 'Convert HEIC to HEIC online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'tiff-to-heic',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['tiff'],
        to: 'image',
        to_syn: ['heic'],
        link: '/tiff-to-heic',
        title: 'Convert TIFF to HEIC',
        description: 'Convert TIFF to HEIC online for free.',
        descriptions: {
            xconvert: 'Convert TIFF to HEIC online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'heic-to-tiff',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['heic'],
        to: 'image',
        to_syn: ['tiff'],
        link: '/heic-to-tiff',
        title: 'Convert HEIC to TIFF',
        description: 'Convert HEIC to HEIC online for free.',
        descriptions: {
            xconvert: 'Convert HEIC to HEIC online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'cr2-to-jpg',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['cr2'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/cr2-to-jpg',
        title: 'CR2 to JPG',
        description: 'Convert CR2 (Canon Raw Version 2) to JPG online for free.',
        descriptions: {
            xconvert: 'Convert CR2 (Canon Raw Version 2) to JPG online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'nef-to-jpg',
        type: 'convert',
        toSubType: ToSubTypes.imageConvert,
        from: ConvertFromImage.TYPE,
        from_syn: ['nef'],
        to: 'image',
        to_syn: ['jpg'],
        link: '/nef-to-jpg',
        title: 'NEF to JPG',
        description: 'Convert NEF (Nikon Electronic Format) to JPG online for free.',
        descriptions: {
            xconvert: 'Convert NEF (Nikon Electronic Format) to JPG online for free.',
        },
        availability: {
            online: true,
            offline: true,
        },
    },


    {
        id: 'video-cutter',
        type: 'cut',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mp4'],
        to: 'video',
        to_syn: ['mp4'],
        link: '/video-cutter',
        title: 'Video Cutter',
        description: 'Online Video Cutter - Cut Video, Cut MP4, AVI, MPG, WebM and more...',
        descriptions: {
            xconvert: 'Online Video Cutter - Cut Video, Cut MP4, AVI, MPG, MTS, MKV, WebM and more...',
            videocompressor: 'Online Video Cutter - Cut Video, Cut MP4, MTS, MKV, AVI, MPG, WebM and more....'
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'video-trimmer',
        type: 'trim',
        toSubType: ToSubTypes.videoConvert,
        from: ConvertFromVideo.TYPE,
        from_syn: ['mp4'],
        to: 'video',
        to_syn: ['mp4'],
        link: '/video-trimmer',
        title: 'Video Trimmer',
        description: 'Online Video Trimmer - Trim any video type from MP4, AVI, MPG, WebM and more...',
        descriptions: {
            xconvert: 'Online Video Trimmer - Trim any video type from MP4, AVI, MPG, MTS, MKV, WebM and more...',
            videocompressor: 'Online Video Trimmer - Trim any video type from MP4, MTS, MKV, AVI, MPG, WebM and more....'
        },
        availability: {
            online: true,
            offline: true,
        },
    },

    {
        id: 'audio-trimmer',
        type: 'trim',
        toSubType: ToSubTypes.audioCompress,
        from: ConvertFromAudio.TYPE,
        from_syn: ['mp3', 'aac', 'flac', 'wav', 'm4a', 'opus', 'm4r'],
        to: 'audio',
        to_syn: ['mp3', 'aac', 'flac', 'wav', 'm4a', 'opus', 'm4r'],
        link: '/audio-trimmer',
        title: 'Audio Trimmer',
        description: 'Online Audio Trimmer - Trim/cut .mp3, .wma, ...',
        descriptions: {
            xconvert: 'Online Audio Trimmer - Cut Audio, Cut MP4, AVI, MPG, MTS, MKV, WebM and more...',
            videocompressor: 'Online Audio Trimmer - Cut Audio, Cut MP4, MTS, MKV, AVI, MPG, WebM and more....'
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'audio-cutter',
        type: 'cut',
        toSubType: ToSubTypes.audioCompress,
        from: ConvertFromAudio.TYPE,
        from_syn: ['mp3', 'aac', 'flac', 'wav', 'm4a', 'opus', 'm4r'],
        to: 'audio',
        to_syn: ['mp3', 'aac', 'flac', 'wav', 'm4a', 'opus', 'm4r'],
        link: '/audio-cutter',
        title: 'Audio Cutter',
        description: 'Online Audio Cutter - Trim/cut .mp3, .wma, ...',
        descriptions: {
            xconvert: 'Online Audio Cutter - Cut Audio, Cut MP4, AVI, MPG, MTS, MKV, WebM and more...',
            videocompressor: 'Online Audio Cutter - Cut Audio, Cut MP4, MTS, MKV, AVI, MPG, WebM and more....'
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'mp3-cutter',
        type: 'trim',
        toSubType: ToSubTypes.audioCompress,
        from: ConvertFromAudio.TYPE,
        from_syn: ['mp3', 'aac', 'flac', 'wav', 'm4a', 'opus', 'm4r'],
        to: 'audio',
        to_syn: ['mp3'],
        link: '/mp3-cutter',
        title: 'MP3 Cutter',
        description: 'Online MP3 Cutter - Cut/Trim MP3 audio files.',
        descriptions: {
            xconvert: 'Online MP3 Cutter - Cut/Trim MP3 audio files.',
            videocompressor: 'Online MP3 Cutter - Cut/Trim MP3 audio files.'
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'mp3-trimmer',
        type: 'cut',
        toSubType: ToSubTypes.audioCompress,
        from: ConvertFromAudio.TYPE,
        from_syn: ['mp3'],
        to: 'audio',
        to_syn: ['mp3'],
        link: '/mp3-trimmer',
        title: 'MP3 Trimmer',
        description: 'Online MP3 Trimmer - Trim or cut MP3 audio files online.',
        descriptions: {
            xconvert: 'Online MP3 Trimmer - Trim or cut MP3 audio files online.',
            videocompressor: 'Online MP3 Trimmer - Trim or cut MP3 audio files online.'
        },
        availability: {
            online: true,
            offline: true,
        },
    },
    {
        id: 'rar-to-zip',
        type: 'convert',
        toSubType: ToSubTypes.fileArchive,
        from: ConvertFromArchive.TYPE,
        from_syn: ['rar'],
        to: 'archive',
        to_syn: ['zip'],
        link: '/rar-to-zip',
        title: 'RAR to Zip',
        description: 'Convert RAR to Zip archive',
    },
    {
        id: 'zip-to-rar',
        type: 'convert',
        toSubType: ToSubTypes.fileArchive,
        from: ConvertFromArchive.TYPE,
        from_syn: ['zip'],
        to: 'archive',
        to_syn: ['rar'],
        link: '/zip-to-rar',
        title: 'Zip to RAR',
        description: 'Convert Zip to RAR archive',
    },
    {
        id: 'tar-to-zip',
        type: 'convert',
        toSubType: ToSubTypes.fileArchive,
        from: ConvertFromArchive.TYPE,
        from_syn: ['tar'],
        to: 'archive',
        to_syn: ['zip'],
        link: '/tar-to-zip',
        title: 'TAR to Zip',
        description: 'Convert TAR to Zip archive',
    },
    {
        id: 'zip-to-tar',
        type: 'convert',
        toSubType: ToSubTypes.fileArchive,
        from: ConvertFromArchive.TYPE,
        from_syn: ['zip'],
        to: 'archive',
        to_syn: ['tar'],
        link: '/zip-to-tar',
        title: 'Zip to TAR',
        description: 'Convert Zip to TAR archive',
    },
    {
        id: '7z-to-zip',
        type: 'convert',
        toSubType: ToSubTypes.fileArchive,
        from: ConvertFromArchive.TYPE,
        from_syn: ['7z'],
        to: 'archive',
        to_syn: ['zip'],
        link: '/7z-to-zip',
        title: '7z to Zip',
        description: 'Convert 7Z to Zip archive',
    },
    {
        id: 'unzip-files',
        type: 'split',
        toSubType: ToSubTypes.files,
        from: ConvertFromArchive.TYPE,
        from_syn: ['zip'],
        to: 'files',
        to_syn: ['file'],
        link: '/unzip-files',
        title: 'Unzip Files',
        description: 'Unzip .zip files online.',
    },
    {
        id: 'unzip-tar.gz',
        type: 'split',
        toSubType: ToSubTypes.files,
        from: ConvertFromArchive.TYPE,
        from_syn: ['tar.gz'],
        to: 'files',
        to_syn: ['files'],
        link: '/unzip-tar.gz',
        title: 'UNZIP tar.GZ',
        description: 'Untar tar.gz files online',
    },
    {
        id: 'zip-extractor',
        type: 'split',
        toSubType: ToSubTypes.files,
        from: ConvertFromArchive.TYPE,
        from_syn: ['zip'],
        to: 'files',
        to_syn: ['files'],
        link: '/zip-extractor',
        title: 'Zip Extractor',
        description: 'Extract zip files online',
    },
    {
        id: 'rar-extractor',
        type: 'split',
        toSubType: ToSubTypes.files,
        from: ConvertFromArchive.TYPE,
        from_syn: ['rar'],
        to: 'files',
        to_syn: ['files'],
        link: '/rar-extractor',
        title: 'RAR Extractor',
        description: 'Extract RAR files online',
    },
    {
        id: 'rar-files',
        type: 'merge',
        toSubType: ToSubTypes.fileArchive,
        from: OnlineFromAny.TYPE,
        from_syn: ['files', 'rar'],
        to: 'archive',
        to_syn: ['rar'],
        link: '/rar-files',
        title: 'RAR Files',
        description: 'RAR files online',
    },
    {
        id: '7zip',
        type: 'merge',
        toSubType: ToSubTypes.fileArchive,
        from: OnlineFromAny.TYPE,
        from_syn: ['files', '7z'],
        to: 'archive',
        to_syn: ['7z'],
        link: '/7zip',
        title: '7ZIP',
        description: 'Combine files to .7z online',
    },
];

const converterIdToConverter = new Map<string, ConverterType>();
export const getConverter = (id) => {
    if (!converterIdToConverter.has(id)) {
        converterIdToConverter.set(id, converters.find(c => c.id === id))
    }
    return converterIdToConverter.get(id);
}
