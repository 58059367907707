import { get, pick } from './lodash-min';

import { Point } from '../types/geo.types';
import { GenericMouseEvent } from '../types/types';

export class UIUtils {
    static getElementScrollPosition(e: HTMLElement): Point {
        if (e.parentElement) {
            const parentScroll = UIUtils.getElementScrollPosition(e.parentElement);
            return {
                x: (e.scrollLeft || 0) + parentScroll.x,
                y: (e.scrollTop || 0) + parentScroll.y,
            };
        }
        return {
            x: e.scrollLeft || 0,
            y: e.scrollTop || 0,
        };
    }

    static getElementWindowPosition(e: HTMLElement): Point {
        return {
            x: window.scrollX || 0,
            y: window.scrollY || 0,
        };
    }

    static toGenericMouseEvent(event: MouseEvent | any): GenericMouseEvent | null {
        const targetTouches = get(event, 'targetTouches');
        const isTouchEvent = targetTouches && targetTouches[0];
        let firstTouch = isTouchEvent ? event.targetTouches[0] : event;
        if (firstTouch.nativeEvent) {
            firstTouch = firstTouch.nativeEvent;
        }
        let offsetX = (event as any).offsetX || firstTouch.offsetX;
        if (isTouchEvent) {
            try {
                offsetX = event.targetTouches[0].pageX - (event.target as any).getBoundingClientRect().left;
            } catch (e) {
                return null;
            }
        }
        return {
            offsetX,
            type: event.type,
            ...pick(firstTouch, [
                'clientX',
                'clientY',
                'pageX',
                'pageY',
                'target',
            ]),
        }
    }

    public static hasParentWithCSSSelector(element: HTMLElement, parentCSSSelector: string) {
        return !!this.getParentWithCSSSelector(element, parentCSSSelector);
    }

    public static getParentWithCSSSelector(element: HTMLElement, parentCSSSelector: string) {
        return element.closest(parentCSSSelector);
    }

    public static isElementParentOrSelfOf(element, parent): boolean {
        if (!element || !parent) {
            return false;
        }
        try {
            let ancester: HTMLElement = element;
            while (parent && parent.tagName !== document.body.tagName) {
                if (parent === ancester) {
                    return true;
                }
                ancester = ancester.parentElement;
            }
        } catch (e) {
            console.error('Error on getClosestElements supressed. ' + e.message);
        }
        return false;
    }

    /**
     * Go up the ancester tree and tries to find an ancester that has a child for given selector.
     * @param elment
     * @param elementsSelector
     *
     * @returns HTMLElement first element that matches selector
     */
    public static getClosestElements(elment: HTMLElement, elementsSelector: string) {
        try {
            let parent: HTMLElement = elment;
            while (parent && parent.tagName !== document.body.tagName) {
                const elements = parent.querySelectorAll<HTMLElement>(elementsSelector);
                if (elements.length > 0) {
                    return elements;
                }
                parent = parent.parentElement;
            }
        } catch (e) {
            console.error('Error on getClosestElements supressed. ' + e.message);
        }
        return null;
    }

    public static scrollToTop() {
        if (navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./) || navigator.userAgent.match(/Edge\/12\./)) {
            window.scrollTo && window.scrollTo(0, 0);
        } else {
            window.scroll && window.scroll({
                left: 0,
                top: 0,
                behavior: 'smooth',
            });
        }
    }

    public static scrollToNav() {
        const nav = document.querySelector('nav');
        const navRect = nav.getBoundingClientRect();
        if (navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./) || navigator.userAgent.match(/Edge\/12\./)) {
            window.scrollTo && window.scrollTo(0, navRect.top);
        } else {
            window.scroll && window.scroll({
                left: 0,
                top: navRect.top,
                behavior: 'smooth',
            });
        }
    }

    public static alignNavToWindowTop() {
        try {
            const nav = document.querySelector('nav');
            const navRect = nav.getBoundingClientRect();
            if (navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./) || navigator.userAgent.match(/Edge\/12\./)) {
                window.scrollTo(0, navRect.top);
            } else {
                window.scroll({
                    left: 0,
                    top: navRect.top,
                    behavior: 'smooth',
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    public static setAttribute(element: HTMLElement, attribute: string, value: any) {
        element.setAttribute(attribute, value);
    }

    public static setStyle(element: HTMLElement, property: string, value: any) {
        element.style[property] = value;
    }
}
