import { inject, injectable } from 'inversify';

import { ApiConfig } from './api-config';
import { AuthService } from './auth.service';
import { EnvironmentService } from './environment.service';
import { LightHttp } from './light-http.service';
import { LoginService } from './login.service';

@injectable()
export class HelperService {

    constructor(
        @inject(EnvironmentService) private env: EnvironmentService,
        @inject(ApiConfig) private apiConfig: ApiConfig,
        @inject(AuthService) private authService: AuthService,
        @inject(LoginService) private loginService: LoginService,
    ) {
        LightHttp.registerCommonHttpHeadersProvider(this);
    }

    public getCommonHttpHeaders(): { [key: string]: any } {
        const headers = {};
        headers['sitename'], `${this.apiConfig.apiDomainName}`;
        headers['domainname'], `${this.env.domainname}`;

        if (this.loginService.userIpAddress) {
            headers['ip-address'], `${this.loginService.userIpAddress}`;
        }
        if (this.loginService.userContry) {
            headers['country'], `${this.loginService.userContry}`;
        }
        const token = this.authService.getToken();
        if (token) {
            headers['Authorization'], `Bearer ${token}`;
        }
        return headers;
    }
}
