import { Type } from 'class-transformer';
import { inject, injectable } from 'inversify';
import { ReplaySubject } from 'rxjs';

import { StorageKeyPrefixeTypes, StorageService } from './storage.service';

export type UserPreferenceType = 'MOTIVES_DISABLED_DATE' | 'MOBILE_APP_PROMO_CLOSED_DATE';
export class UserPreferenceTypes {
    public static MOTIVES_DISABLED_DATE: UserPreferenceType = 'MOTIVES_DISABLED_DATE';
    public static MOBILE_APP_PROMO_CLOSED_DATE: UserPreferenceType = 'MOBILE_APP_PROMO_CLOSED_DATE';
}

export class MotiveDisabledDate {
    @Type(() => Date)
    date: Date;
}

@injectable()
export class UserPreferenceService {
    _preferenceChange = new ReplaySubject<{ preferenceKey: UserPreferenceType, value: any }>();
    get preferenceChange() {
        return this._preferenceChange.asObservable();
    }

    constructor(
        @inject(StorageService) private storage: StorageService,
    ) { }

    setPreference(preferenceKey: UserPreferenceType, value) {
        this.storage.store(StorageKeyPrefixeTypes.USER_PREFERENCE_KEY_PREFIX, preferenceKey, value);
        this._preferenceChange.next({
            preferenceKey: preferenceKey,
            value: value,
        });
    }

    /**
     * 
     * @param preferenceKey 
     * @param type 
     * Note: type is set to any but it shoud be ClassType<T>
     * We cannot import import { ClassType } from 'class-transformer/ClassTransformer';
     */
    getPreference<T>(preferenceKey: UserPreferenceType, type?: any): T {
        return this.storage.get<T>(StorageKeyPrefixeTypes.USER_PREFERENCE_KEY_PREFIX, preferenceKey, type);
    }
}