import { plainToClass, Type } from 'class-transformer';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IFileManagerService, ILightHttpService, IWebSocketService } from '../services/service-interfaces';
import { ConvertOptionCategories } from '../types/dropdown.type';
import {
    FromType,
    OperationTypes,
    OriginalInputFile,
    ProcessorMetadata,
    ProcessStageSubStateTypes,
    ProcessStageTypes,
    ProcessType,
    StatusType,
    ToType,
    WebsocketResponse,
} from '../types/types';
import { FilenameUtils } from '../utils/filename-utils';
import { castArray, cloneDeep, get } from '../utils/lodash-min';
import { ProcessorMetadataUtils } from '../utils/processor-metadata.utils';
import { RequestUtils } from '../utils/request.utils';
import { FileUploadRequestMetadata } from './file-upload-request-metadata';
import { Logger } from './logger';


export class FileUpload {
    /**
     * id/UUID used in FileUpload cannot be file upload's ObjectId since we use the same FileUpload for re-edit functionality
     */
    id: string;
    wsId: string;
    @Type(() => Date)
    uploadStartTime: Date;
    @Type(() => Date)
    processEndTime?: Date;
    progress: number;
    result: any;
    zipResult: WebsocketResponse;
    status: StatusType;
    name: string;
    size: number;

    /** @deprecated When get to file-list-item this is not a list. resolve that issue */
    file: Array<File>;
    readableMessage: string;
    ws: WebsocketResponse;
    processType: ProcessType;
    fromType: Array<FromType>;
    extension: string[];
    toType: ToType; // TODO this should be just ToType
    options: Array<any>;
    discartWSReply: boolean;
    endpointData?: FileUploadRequestMetadata;
    /**
     *  Filed is generated by the UploadedFileService when a file added to it.
     * Contains list of files separated by ', ' that came from uploading this file to the server
     */
    originalInputFiles?: OriginalInputFile[];
    orientation?: string[];
    settings = {
        email: '',
    };
    @Type(() => Date)
    processStartTime: Date;
    permanentlyDeleted: boolean;
    optionsSnapshot: ConvertOptionCategories;
    metadata: FileUploadRequestMetadata;
    /**
     * Need this list to inflate merged fileUpload and display thumbnails.
     * To do that, originalInputFiles is not enough as safeFilename is required to download the
     * file to server storage from GCP
     */
    metadataList?: FileUploadRequestMetadata[];

    uniqueProcessType: string;
    recoveredFileUpload?: boolean;
    pageData: import("/home/user/sites/ps2pdf/ps2pdf-web/types/route-types").PageData;

    /**
     * 
     * Only used on when recovering files since plainToClass is really heavy and drag down the page speed
     * @param json json file upload recovered from storage
     */
    public static fastDeserialize(json) {
        const instant = new FileUpload();
        const fileUpload: FileUpload = Object.assign(instant, json);

        if (fileUpload.uploadStartTime) {
            fileUpload.uploadStartTime = new Date(fileUpload.uploadStartTime);
        }
        if (fileUpload.processEndTime) {
            fileUpload.processEndTime = new Date(fileUpload.processEndTime);
        }
        if (fileUpload.processStartTime) {
            fileUpload.processStartTime = new Date(fileUpload.processStartTime);
        }


        if (fileUpload.endpointData) {
            const fum = new FileUploadRequestMetadata(fileUpload.endpointData.processorMetadataList, fileUpload.endpointData.file);
            fileUpload.endpointData = fum;
        }
        if (fileUpload.metadata) {
            const fum = new FileUploadRequestMetadata(fileUpload.metadata.processorMetadataList, fileUpload.metadata.file);
            fileUpload.metadata = fum;
        }
        if (fileUpload.metadataList && fileUpload.metadataList.length > 0) {
            const list = [];
            fileUpload.metadataList.forEach(item => {
                const fum = new FileUploadRequestMetadata(item.processorMetadataList, item.file);
                list.push(fum);
            })
            fileUpload.metadataList = list;
        }
        return fileUpload;
    }

    public static isUploadedFile(fileUpload: FileUpload) {
        if (fileUpload.originalInputFiles && fileUpload.originalInputFiles.length) {
            return true;
        }
        return false;
    }

    public static toLoggableObject(fileUpload: FileUpload): any {
        if (FileUpload.isUploadedFile(fileUpload)) {
            try {
                const fileUploadObject: any = cloneDeep(fileUpload);
                delete fileUploadObject.endpointData;
                delete fileUploadObject.originalInputFiles;
                delete fileUploadObject.optionsSnapshot;
                delete fileUploadObject.metadata;
                delete fileUploadObject.metadataList;
                delete fileUploadObject.optionsSnapshot;
                try {
                    fileUploadObject.inputDownloadLinks = fileUpload.getInputDownloadURLsIfAvailable();
                } catch { }
                try {
                    fileUploadObject.outputDownloadLinks = fileUpload.getOutputDownloadURLsIfAvailable();
                } catch { }

                return fileUploadObject;
            } catch (e) {
                Logger.getLogger('FileUpload').error({ message: 'Failed to convert FileUpload to loggable object..', error: e });
            }
        }
        return {};
    }

    public static fromPlain(plain: any) {
        return plainToClass(FileUpload, plain);
    }

    private getDomainToBeUsed() {
        try {
            const useDomain = window['useDomain'] || null;
            const useDomainValidator = window['useDomainValidator'] || null;
            if (!useDomain) {
                return {};
            }
            return {
                useDomain,
                useDomainValidator,
            }
        } catch (e) {
            return {};
        }
    }

    updateProcessDomain(
        http: ILightHttpService,
        fileManagerService: IFileManagerService,
        urlEndpoint: string,
        wsService: IWebSocketService,
    ): Observable<ProcessorMetadata[]> {
        const processorMetadata = this.getProcessorMetadata();
        const { useDomain, useDomainValidator } = this.getDomainToBeUsed();
        const file = castArray(this.file);
        const options = {
            timeout: 10000,
            params: {
                from: this.fromType,
                to: this.toType,
                operation: OperationTypes.PROCESS,
                contentNames: file[0].name,
                contentTypes: FilenameUtils.getFileMimeType(file[0]),
                contentSizes: file[0].size + '',
                test: 'false',
                useGCS: 'false',
                wsId: wsService.getId(),
                wsDomain: wsService.getDomain(),
                requireActiveDownload: 'false',
                currentFileHostDomain: processorMetadata.uploadDomain,
                ...(useDomain ? { useDomain, useDomainValidator } : null)
            },
        };
        // console.log(`HPY =========== calling ${urlEndpoint}`);
        return RequestUtils.getSuccessValueOrThrow$<ProcessorMetadata[]>(
            [http.get<ProcessorMetadata[]>(`${urlEndpoint}`, options)],
        ).pipe(
            tap((data) => {
                // console.log(`HPY =========== getSuccessValueOrThrow ${data[0].processDomain}`);
                // TODO IMPORTANT File tracker need to recompute since requests are bucketized by process domain
                processorMetadata.processDomain = data[0].processDomain;
                processorMetadata.downloadDomain = data[0].downloadDomain;
                this.persistFileUpload(fileManagerService);
            }),
        );
    }

    persistFileUpload(fileManagerService: IFileManagerService) {
        fileManagerService.saveFileUploadToStorage(this);
    }

    getProcessorMetadata(): ProcessorMetadata {
        return this.endpointData.processorMetadataList[0];
    }

    /** Server completed to request */
    isCompleted() {
        const processStage = get(this, 'ws.processStage');
        const processStageSubState = get(this, 'ws.processStageSubState');
        if (processStage === ProcessStageTypes.COMPLETED && processStageSubState === ProcessStageSubStateTypes.COMPLETED) {
            return true;
        }
        return false;
    }

    isFailed() {
        const processStageSubState = get(this, 'ws.processStageSubState');
        if (processStageSubState === ProcessStageSubStateTypes.FAILED) {
            return true;
        }
        return false;
    }

    /** File ready to download if downloadable file */
    isFileResultReady(fileInfo: FileUpload) {
        if (!fileInfo.result || (fileInfo.result && Object.keys(fileInfo.result).length === 0)) {
            return false;
        }
        return true;
    }

    getOutputDownloadURLsIfAvailable(): string[] {
        const downloadLinkFn = (resultOutput: string) => {
            const tokens = resultOutput.split('\\');
            const filename = tokens[tokens.length - 1];
            const domain = this.endpointData.processorMetadataList[0].downloadDomain;
            const downloadUrl = ProcessorMetadataUtils.getDownloadEndpoint(this.endpointData.processorMetadataList[0]) +
                ((domain.indexOf('googleapis') !== -1) ? '' : '/') + filename;
            return downloadUrl;
        }

        try {
            if (!this.isFileResultReady(this)) {
            } else if (this.result.error === true) {
            } else {
                if (Array.isArray(this.result.output)) {
                    return this.result.output.map(output => downloadLinkFn(output));
                } else {
                    return [downloadLinkFn(this.result.output)];
                }
            }
        } catch (e) {
            console.error(e);
        }
        return null;
    }

    getInputDownloadURLsIfAvailable(): string[] {
        const downloadLinkFn = (resultOutput: string) => {
            const tokens = resultOutput.split('\\');
            const filename = tokens[tokens.length - 1];
            const domain = this.endpointData.processorMetadataList[0].uploadDomain;
            const uploadFileDownloadUrl = ProcessorMetadataUtils.getUploadedFileDownloadEndpoint(this.endpointData.processorMetadataList[0]) +
                ((domain.indexOf('googleapis') !== -1) ? '' : '/') + filename;
            return uploadFileDownloadUrl;
        }

        try {
            if (Array.isArray(this.result.input)) {
                return this.result.input.map(input => downloadLinkFn(input));
            } else {
                return [downloadLinkFn(this.result.input)];
            }
        } catch { }
        return null;
    }
}
