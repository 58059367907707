import { isString } from '../utils/lodash-min';

import { JSONUtils } from '../utils/json.utils';
import { Rating, UserSettings } from '../utils/request.utils';
import { RelatedLink } from './site-urls';
import { FromType, ProcessType, ToType } from './types';

export interface RouteProps {
    // ususally, window.location.pathname. Use RouteUtils to clean it up
    url: string;
    settings: UserSettings,
    environment: any;
    path?: string;
    convertOptionCategories?: any;
    relatedLinks: RelatedLink[];
    pageData?: any;
    markdown?: any;
    markdownHtml?: any;
    rating?: Rating;
    extensionSEOData?: ExtensionSEOData[];
    seoHeaderTags?: string;
}

export interface ExtensionSEODataDetails {
    text: string;
    link: string;
}

export interface ExtensionSEOData {
    extension: ExtensionSEODataDetails[];
    category: ExtensionSEODataDetails[];
    description: ExtensionSEODataDetails[];
    actions: ExtensionSEODataDetails[];
    details: ExtensionSEODataDetails[];
    programs: ExtensionSEODataDetails[];
    developer: ExtensionSEODataDetails[];
    mime: ExtensionSEODataDetails[];
    links: ExtensionSEODataDetails[];
}

export interface AppProps {
    // ususally, window.location.pathname. Use RouteUtils to clean it up
    pageProps: {
        url: string;
        path: string;
        environment: any;
        relatedLinks: RelatedLink[];
        convertOptionCategories: any;
        /** Should use as the source to generate convertOptionCategories */
        convertOptionCategoriesOriginal?: string;
        pageData?: any;
        markdown?: any;
        settings: UserSettings;
        extensionSEOData?: any[];
        seoHeaderTags?: string;
    }
}

export function appPropsToRouteProps(props: AppProps): RouteProps {
    if (props?.pageProps) {
        const routeData = props?.pageProps;
        if (routeData.convertOptionCategoriesOriginal && isString(routeData.convertOptionCategoriesOriginal)) {
            const json = JSON.parse(routeData.convertOptionCategoriesOriginal);
            const decoded = JSONUtils.parse(json);
            routeData.convertOptionCategories = decoded;
        }

        if (routeData.extensionSEOData && Array.isArray(routeData.extensionSEOData)) {
            routeData.extensionSEOData = routeData.extensionSEOData.map(data => eval(data));
        }
        return routeData;
    }
    console.error(`Null props were fed to the component`);
    return {} as any;
}


export interface SeoFeature {
    icon: string;
    title: string;
    description: string;
}

export interface PageSeo {
    title: string;
    description: string;
    image: string;
    feature?: Array<SeoFeature>;
}

export interface PageData {
    title: string;
    path: string;
    canonical?: string;
    /**
     * Used to create a bucket for upload image for each unique route
     * Generally used path itself since paths are unique and contains info regarding
     * type of process
     * */
    uniqueProcessType: string;
    hasHowToGuide?: boolean;
    description: string;
    file_uload_button_text: string;
    /**
     * This is important. This will decide if the files will be processed individually
     * or as single batch and combine them to form one single output.
    */
    processType: ProcessType;
    fromType: Array<FromType>;
    toType: ToType;
    accept: string;
    btnText: string;
    page_seo: PageSeo;
    breadcrumbText: string;
    // Markdown page
    page?: string;
}

export interface RouteData {
    path: string;
    loadChildren?: any;
    component?: any;
    data?: PageData;
}

export interface HowToRouteData {
    path: string;
    component: any;
    data: HowToPageData;
}

export interface HowToPageData {
    title: string;
    path: string;
    description: string;
    breadcrumbText: string;
    howToGuide?: HowToGuide;
}

export interface HowToGuide {
    title: string;
    description: string;
    steps: HowToGuideStep[];
    footer?: string;
    totalTime?: number;
}

export interface HowToGuideStep {
    title: string;
    description: string;
    imageURL?: string;
}
