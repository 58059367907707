import { inject, injectable } from 'inversify';
import { ReplaySubject, from, of } from 'rxjs';
import { catchError, mergeMap, retry } from 'rxjs/operators';

import { Commons } from '../commons/commons';
import { Logger } from '../models/logger';
import { BrowserUtils } from '../ui-utils/browser-utils';
import { get } from '../utils/lodash-min';
import { UrlUtils } from '../utils/url.utils';
import { ConnectivityService } from './connectivity.service';
import { EnvironmentService } from './environment.service';
import { WindowService } from './window.service';


declare var window;

export interface ValidatableScript {
    url: string;
    readableName: string;
    loadFn: (stateObjectIdentifier: string) => Promise<void>;
}

export interface ScriptState {
    readableName: string;
    executed: boolean;
    checkCount: number;
    errorLogged: boolean;
    loaded: boolean;
    error: boolean;
    required: boolean;
}

export type ScriptStateMap = Map<string, ScriptState>;

class HeaderElementLoader {

}

@injectable()
export class ScriptLoadService {
    private readonly logger = Logger.getLogger('ScriptLoadService');

    readonly scriptStateMap: ScriptStateMap = new Map<string, ScriptState>();

    _dropboxApiReady = true;
    get dropboxApiReady() {
        return this._dropboxApiReady;
    }

    _googleApiReady = true;
    get googleApiReady() {
        return this._googleApiReady;
    }

    _saveToGoogleDriveReady = new ReplaySubject(1);
    get saveToGoogleDriveReady() {
        return this._saveToGoogleDriveReady.asObservable();
    }

    scriptsWithValidations: ValidatableScript[] = [
        {
            url: 'https://storage.googleapis.com/ps2pdf-static/gzip-libs/js/resumable.min.js',
            readableName: 'File Upload Service',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'Resumable');
            },
        },
        {
            url: 'https://storage.googleapis.com/ps2pdf-static/gzip-libs/js/html2canvas.min.js',
            readableName: 'HTML Canvas Utils',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'html2canvas');
            },
        },
        {
            url: 'https://storage.googleapis.com/ps2pdf-static/gzip-libs/js/load-image.all.min.js',
            readableName: 'Image Load Service',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'loadImage');
            },
        },
        {
            url: 'https://storage.googleapis.com/ps2pdf-static/gzip-libs/js/canvas-to-blob.min.js',
            readableName: 'Blob Service',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'dataURLtoBlob');
            },
        },
        {
            url: 'https://storage.googleapis.com/ps2pdf-static/gzip-libs/js/lib/download.js',
            readableName: 'Download Service',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'download');
            },
        },
        {
            url: 'https://storage.googleapis.com/ps2pdf-static/gzip-libs/js/lib/glfx.6.min.js',
            readableName: 'WebGL Service Service',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'fx');
            },
        },
        {
            url: 'https://storage.googleapis.com/ps2pdf-static/gzip-libs/js/lib/libwebp-0.1.min.js',
            readableName: 'WebP Encoder Service',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'WebPEncoder');
            },
        },
        {
            url: 'https://storage.googleapis.com/ps2pdf-static/gzip-libs/js/lib/UTIF.min.min.js',
            readableName: 'TIFF Service',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'UTIF');
            },
        },
        {
            url: 'https://storage.googleapis.com/ps2pdf-static/gzip-libs/js/marked.min.js',
            readableName: 'Markdown Service',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'marked');
            },
        },
        {
            url: 'https://storage.googleapis.com/ps2pdf-static/gzip-libs/js/lib/canvastotiff.min.js',
            readableName: 'TIF Service',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'CanvasToTIFF');
            },
        },
        {
            url: 'https://storage.googleapis.com/ps2pdf-static/gzip-libs/js/hammer.min.js',
            readableName: 'Touch Event Service',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'Hammer');
            },
        },
        {
            url: 'https://storage.googleapis.com/ps2pdf-static/gzip-libs/js/hammer.min.js',
            readableName: 'Touch Event Service',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'Hammer');
            },
        },
        /*{
            url: 'assets/js/popper.min.js',
            readableName: 'Tooltip Service',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'Popper');
            }
        }*/
    ];

    executed = false;

    loadedPomise: Promise<any>;
    private loadedPomiseResolve: () => void;
    private loadedPomiseReject: () => void;
    constructor(
        @inject(WindowService) private windowService: WindowService,
        @inject(EnvironmentService) private env: EnvironmentService,
        @inject(ConnectivityService) private connectivityService: ConnectivityService,
    ) {
        this.loadedPomise = new Promise<void>((resolve, reject) => {
            this.loadedPomiseResolve = resolve;
            this.loadedPomiseReject = reject;
        });
    }

    runScriptAtFirstMouseEvent() {
        // if (isPlatformBrowser(this.platformId)) {
        //     this.loadAdsense();
        // }
        this.lazyLoadCSS();
        this.loadMaterialIcons();
    }

    async loadAllScriptsIfNotLoaded(silently = false) {
        if(true) {
            // All of these scripts are loaded on the _document.tsx file
            return true;
        }
        if (!BrowserUtils.hasWindow()) {
            console.log('Exit loadAllScriptsIfNotLoaded fn. No window.');
            return;
        }
        if (BrowserUtils.isSafari()) {
            return true;
        }

        if (!this.executed) {
            this.executed = true;
            const promiseArray: Promise<any>[] = [];
            // Load varifiable and required scripts
            promiseArray.push(
                this.connectivityService.initialize$().pipe(catchError((err) => of(err))).toPromise(),
                ...this.loadRequiredVarifiableScripts(),
                this.loadDropbox(),
                this.loadGoogleApi(),
                this.loadReCaptcha(),
            );
            let modalRef = null;
            if (!silently) {
                // TODO Fix this in preact
                // modalRef = ScriptLoadModalComponent.show(this.modalService, {
                //     scriptStateMap: this.scriptStateMap,
                // });
            }
            try {
                await Promise.all([...promiseArray.map(p => p.catch(() => Promise.resolve()))])
                    .then(() => {
                        this.loadedPomiseResolve();
                        let allRequiredServicesLoaded = true;
                        const scriptedFailedToLoad = [];
                        this.scriptStateMap.forEach(v => {
                            allRequiredServicesLoaded = allRequiredServicesLoaded && (v.required ? v.loaded : true);
                            if (v.required && !v.loaded) {
                                scriptedFailedToLoad.push(v.readableName);
                            }
                        });
                        if (allRequiredServicesLoaded) {
                            if (modalRef) {
                                modalRef.hide();
                            }
                        } else {
                            this.logger.error({
                                message: 'Following scripts failed to load: ' + scriptedFailedToLoad.join(', ')
                            });
                        }
                    }).catch((err) => {
                        this.logger.error({
                            message: 'Filed to load some of the scripts. This is a fatal error. Please let us know at support@xconvert.com',
                            error: err,
                        });
                        this.loadedPomiseReject();
                    });
            } catch (e) {
                this.logger.error({
                    message: 'Filed to load some of the scripts. Promise array threw error.',
                    error: e,
                });
                this.loadedPomiseReject();
            }
        }
        return this.loadedPomise;
    }

    /**
     * returns a list of promises that resolves when item is on the browser window object
     */
    private loadRequiredVarifiableScripts() {
        const promises: Promise<any>[] = [];
        this.scriptsWithValidations.forEach(script => {
            this.scriptStateMap.set(script.url, this.createScriptStateObjectFor(script.readableName, true));
            promises.push(script.loadFn(script.url));
        });
        this.scriptsWithValidations.forEach(script => {
            this.loadScript(script.url).catch(console.log);
        });
        return promises;
    }

    /**
     * Load a script and create a state object for it.
     */
    private loadScript(url: string, options?: { attributes: { [key: string]: string } }): Promise<any> {
        if (!BrowserUtils.hasDocument()) {
            console.log('Request to load the script ignored. globalThis.document is missing.')
            return Promise.resolve();
        }
        this.uploadStateObject(url, false);
        return new Promise<void>((gResolve, gReject) => {
            of({}).pipe(
                mergeMap(() => {
                    const promise = new Promise<void>((resolve, reject) => {
                        const currentScripts = Array.from(document.querySelectorAll<HTMLScriptElement>('head script')).map(a => a.src).filter(url => !!url && url.length > 4);
                        const scriptExists = currentScripts.find(currentScript => UrlUtils.isURLHasSameFileName(url, currentScript));
                        if (scriptExists) {
                            resolve();
                            return;
                        }
                        try {
                            const node = document.createElement('script');
                            node.src = url;
                            node.type = 'text/javascript';
                            // node.charset = 'utf-8';
                            if (options && options.attributes) {
                                for (const attr in options.attributes) {
                                    if (options.attributes[attr]) {
                                        node.setAttribute(attr, options.attributes[attr]);
                                    }
                                }
                            }
                            node.onload = () => {
                                resolve();
                            };
                            node.onerror = (e) => {
                                reject(e);
                            };
                            document.getElementsByTagName('head')[0].appendChild(node);
                        } catch (e) {
                            reject(e);
                        }
                    });
                    return from(promise);
                }),
                retry(6),
            ).subscribe({
                next: () => {
                    this.getValidatableScriptStateObject(url).error = false;
                    gResolve();
                },
                error: (err) => {
                    this.getValidatableScriptStateObject(url).error = true;
                    this.logger.error({ message: `Failed to load script ${url}.`, error: err });
                    gReject(err);
                }
            });
        });
    }

    private uploadStateObject(url: string, track: boolean) {
        let stateObject = this.getValidatableScriptStateObject(url);
        if (stateObject && stateObject.executed) {
            return;
        } else if (!stateObject) {
            stateObject = this.createScriptStateObjectFor(url, track);
            this.scriptStateMap.set(url, stateObject);
            stateObject.executed = true;
        } else {
            stateObject.executed = true;
        }
    }

    public loadMaterialIcons() {
        // if (isPlatformBrowser(this.platformId)) {
        //     if (!window['materialLoaded']) {
        //         window['materialLoaded'] = true;
        //         const a = document.createElement('link');
        //         a.rel = 'stylesheet';
        //         a.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
        //         document.head.appendChild(a);
        //     }
        // } else {
        //     const a = document.createElement('link');
        //     a.rel = 'stylesheet';
        //     a.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
        //     document.head.appendChild(a);
        // }
    }

    public loadStyleSheet(link: string) {
        if (!BrowserUtils.hasDocument()) {
            console.log("Requested execution aborted since document was not avalilable");
            return;
        }
        return new Promise<void>((resolve, reject) => {
            if (!this.windowService.runOnWindow((window) => window[link])) {
                this.windowService.runOnWindow((window) => window[link] = true);
                const a = document.createElement('link');
                a.rel = 'stylesheet';
                a.href = link;
                document.head.appendChild(a);
                a.onload = () => {
                    resolve();
                };
                a.onerror = () => {
                    reject();
                };
            } else {
                const runUntilTrue = (count) => {
                    if (!this.windowService.runOnWindow((window) => window[link]) && count > 0) {
                        setTimeout(() => runUntilTrue(count--), 1000);
                    } else {
                        if (count <= 0 && !this.windowService.runOnWindow((window) => window[link])) {
                            reject();
                        }
                        resolve();
                    }
                };
                runUntilTrue(20);
            }
        });
    }

    public lazyLoadCSS() {
        // July 18, 2020 - disabled as it is included with the styles on angular.json
        // this.loadBootstrap();
        // this.loadToastr();
        // this.loadSwal2();
    }

    /**
     * Wait for given windowItem to appear on browser window object.
     */
    private waitForWindowItem(stateObjectIdentifier: string, windowItem: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const validatorFn = () => {
                const stateObj = this.scriptStateMap.get(stateObjectIdentifier);
                if (!BrowserUtils.hasWindow()) {
                    // TODO this is falsly resolving since window is not available
                    resolve();
                } else if (stateObj.error) {
                    reject();
                } else if (window && get(window, windowItem)) {
                    stateObj.error = false;
                    stateObj.loaded = true;
                    stateObj.checkCount++;
                    resolve();
                } else {
                    stateObj.checkCount++;
                    if (stateObj.checkCount <= 5) {
                        this.logger.trace1({
                            message: `Script, ${windowItem} is not ready yet after ${stateObj.checkCount}s!. If you see this message repeat for more than 20s then there was a fatal error loading this page. Please let us know at support@xconvert.com`,
                        });
                    } else {
                        if (!stateObj.errorLogged) {
                            stateObj.errorLogged = true;
                            this.logger.error(`Script, ${windowItem} is not ready yet after ${stateObj.checkCount}s!. If you see this message repeat for more than 20s then there was a fatal error loading this page. Please let us know at support@xconvert.com`);
                        }
                    }
                    setTimeout(() => validatorFn(), 1000);
                }
            };
            setTimeout(() => validatorFn(), 1000);
        });
    }

    private getValidatableScriptStateObject(url: string): ScriptState {
        return this.scriptStateMap.get(url);
    }

    private createScriptStateObjectFor(readableName, track, required = true) {
        return {
            checkCount: 0,
            loaded: false,
            readableName,
            error: false,
            errorLogged: false,
            executed: false,
            tracked: track,
            required,
        };
    }

    public async loadDropbox() {
        const script = {
            url: 'https://www.dropbox.com/static/api/2/dropins.js',
            readableName: 'Dropbox Service',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'Dropbox');
            },
        };
        const state = this.scriptStateMap.get(script.url);
        if (state && state.loaded) {
            return;
        }

        this.scriptStateMap.set(script.url, this.createScriptStateObjectFor(script.readableName, true, false));
        this.loadScript(script.url, {
            attributes: {
                id: 'dropboxjs',
                'data-app-key': 'crbrplpove29sb2'
            }
        }).then(
            () => this._dropboxApiReady = true,
            () => this._dropboxApiReady = false,
        );
        return script.loadFn(script.url);
    }

    /**
     * Window service must load this
     */
    public async loadAdsense() {
        return;
        if (!BrowserUtils.hasWindow()) {
            console.log('Exit loadAllScriptsIfNotLoaded fn. No window.');
            return;
        }
        const script = {
            url: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
            readableName: 'Google Mono',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'adsbygoogle');
            },
        };
        const state = this.scriptStateMap.get(script.url);
        if (state && state.loaded) {
            return;
        }

        this.scriptStateMap.set(script.url, this.createScriptStateObjectFor(script.readableName, true, false));
        // tslint:disable-next-line: object-literal-key-quotes
        this.loadScript(script.url, { attributes: { 'data-ad-client': this.env.env.gadId, 'async': 'true' } }).catch(console.log);
        return script.loadFn(script.url);
    }

    /** Only App component call this based on the route */
    public loadGoogleDriveSave() {
        const script = {
            url: 'https://apis.google.com/js/platform.js',
            readableName: 'Google API Service',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'gapi.platform');
            },
        };
        const state = this.scriptStateMap.get(script.url);
        if (state && state.loaded) {
            return;
        }

        this.scriptStateMap.set(script.url, this.createScriptStateObjectFor(script.readableName, false, false));
        this.loadScript(script.url).then(
            () => this._saveToGoogleDriveReady.next(true),
            (err) => {
                console.error(err);
            },
        );
        return script.loadFn(script.url);
    }

    public loadGoogleApi() {
        const script = {
            url: 'https://apis.google.com/js/api.js',
            readableName: 'Google API Service',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'gapi');
            },
        };
        const state = this.scriptStateMap.get(script.url);
        if (state && state.loaded) {
            return;
        }

        this.scriptStateMap.set(script.url, this.createScriptStateObjectFor(script.readableName, true, false));
        this.loadScript(script.url).then(
            () => this._googleApiReady = true,
            () => this._googleApiReady = false,
        );
        return script.loadFn(script.url);
        // return new Promise((resolve, reject) => {
        //     if (window['googleapis']) {
        //         resolve();
        //     } else {
        //         const node = document.createElement('script');
        //         node.src = 'https://apis.google.com/js/api.js';
        //         node.type = 'text/javascript';
        //         node.charset = 'utf-8';
        //         node.onload = resolve;
        //         node.onerror = reject;
        //         document.getElementsByTagName('head')[0].appendChild(node);
        //     }
        // });
    }

    public async loadStripe() {
        const script = {
            url: 'https://js.stripe.com/v3/',
            readableName: 'Stripe Service',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'Stripe');
            },
        };
        const state = this.scriptStateMap.get(script.url);
        if (state && state.loaded) {
            return;
        }
        this.scriptStateMap.set(script.url, this.createScriptStateObjectFor(script.readableName, true));
        await this.loadScript(script.url);
        return script.loadFn(script.url);
    }

    public async loadReCaptcha() {
        const script = {
            url: 'https://www.google.com/recaptcha/api.js',
            readableName: 'Google Recaptcha Service',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'grecaptcha');
            },
        };
        const state = this.scriptStateMap.get(script.url);
        if (state && state.loaded) {
            return;
        }
        this.scriptStateMap.set(script.url, this.createScriptStateObjectFor(script.readableName, true));
        await this.loadScript(script.url);
        return script.loadFn(script.url);
    }

    public async loadPayPal() {
        const url = `https://www.paypal.com/sdk/js?client-id=${Commons.PAYPAL_CLIENT_ID}&vault=true`;
        const script = {
            url,
            readableName: 'PayPal Service',
            loadFn: (url: string) => {
                return this.waitForWindowItem(url, 'paypal');
            },
        };
        const state = this.scriptStateMap.get(script.url);
        if (state && state.loaded) {
            return;
        }
        this.scriptStateMap.set(script.url, this.createScriptStateObjectFor(script.readableName, true));
        await this.loadScript(script.url);
        return script.loadFn(script.url);
    }

    private loadFacebookSDK() {
        (function (d, s, id) {
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2&appId=1700010840091346&autoLogAppEvents=1';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    public getFailedScripts() {
        const errors: string[] = [];
        this.scriptStateMap.forEach(stateObject => {
            if (stateObject.error) {
                errors.push(stateObject.readableName + (stateObject.required ? ' (Required)' : ' (Optional)'));
            }
        });
        return errors;
    }

}
