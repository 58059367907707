import { RouteProps } from '../types/route-types';

export class RouteUtils {
    public static getPathFromProps(routeProps: RouteProps) {
        return RouteUtils.pathTextOnly(routeProps.url);
    }

    public static pathTextOnly(pathname: string) {
        if (pathname.startsWith('/')) {
            pathname = pathname.substring(1);
        }
        if (pathname.endsWith('/')) {
            pathname = pathname.substring(0, pathname.length - 1);
        }
        return pathname;
    }

    public static getPageDataFromProps(routeProps: RouteProps) {
        return routeProps.pageData;
    }
}