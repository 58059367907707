const millisPerHour = 3600000;

export class PSRedableDuration {
    constructor(private date: PSDate) {

    }

    fromNow() {
        return this.fromDate(new Date());
    }

    fromDate(date: PSDate | Date | string) {
        return this.durationToReadable(Math.abs(PSDate.from(date).getTime() - this.date.getTime()));
    }

    private durationToReadable(milliseconds: number) {
        // TIP: to find current time in milliseconds, use:
        // var  current_time_milliseconds = new Date().getTime();

        function numberEnding(number) {
            return (number > 1) ? 's' : '';
        }

        var temp = Math.floor(milliseconds / 1000);
        var years = Math.floor(temp / 31536000);
        if (years) {
            return years + ' year' + numberEnding(years);
        }
        //TODO: Months! Maybe weeks? 
        var days = Math.floor((temp %= 31536000) / 86400);
        if (days) {
            return days + ' day' + numberEnding(days);
        }
        var hours = Math.floor((temp %= 86400) / 3600);
        if (hours) {
            return hours + ' hour' + numberEnding(hours);
        }
        var minutes = Math.floor((temp %= 3600) / 60);
        if (minutes) {
            return minutes + ' minute' + numberEnding(minutes);
        }
        var seconds = temp % 60;
        if (seconds) {
            return seconds + ' second' + numberEnding(seconds);
        }
        return 'less than a second'; //'just now' //or other string you like;
    }
}

export class PSDate {
    private time: number;
    private constructor(private date: Date) {
        this.time = date.getTime();
    }

    public static fromString(date: string) {
        return new PSDate(new Date(date));
    }

    public static fromDate(date: Date) {
        return new PSDate(new Date(date));
    }

    public static from(date?: PSDate | Date | string) {
        if (!date) {
            date = new Date();
        }
        return date instanceof PSDate ? date : (date instanceof Date ? PSDate.fromDate(date) : PSDate.fromString(date));
    }

    add(value: number, unit: 'days' | 'hours' | 'ms' = 'hours') {
        let millies = value;
        if (unit === 'hours') {
            millies = value * millisPerHour;
        } else {
            millies = value * 24 * millisPerHour;
        }
        this.time = this.time + (millies);
        return this;
    }

    subtract(value: number, unit: 'days' | 'hours' | 'ms' = 'hours') {
        return this.add(-1 * value, unit);
    }

    toDate() {
        return new Date(this.time);
    }

    getTime() {
        return this.time;
    }

    isBefore(date: PSDate | Date | string) {
        return this.time < PSDate.from(date).getTime();
    }

    isAfter(date: PSDate | Date | string) {
        return this.time > PSDate.from(date).getTime();
    }

    isValid() {
        try {
            return this.date instanceof Date && !isNaN(this.date.getTime());
        } catch (e) {

        }
        return false;
    }
}