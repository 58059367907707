import { isNaN, isNil, toNumber } from './lodash-min';

import { BrowserUtils } from '../ui-utils/browser-utils';

export class StringUtils {
    public static removeNewLineAndMultipleContigeousWhiteSpaces(str: string | undefined | null) {
        if (!str) {
            return str;
        }
        str = str.trim();
        str = str.replace(/\n/g, ' ');
        str = str.replace(/[ ]+/g, ' ');
        return str;
    }

    public static capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    public static toTitleCase(str) {
        if (isNil(str)) {
            return str;
        }
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    public static toBase64(str: string) {
        return btoa(str);
    }

    public static parseString<T>(item: string): T {
        if (item === 'undefined' || isNil(item)) {
            return undefined;
        } else if (item === 'null') {
            return null;
        } else if (!isNaN(toNumber(item))) {
            return toNumber(item) as any;
        } else {
            return item as any;
        }

    }

    /**
     * First char can be a number so pass a letter prefix when using for a HTML element id or WS id
     * @param prefix 
     */
    public static getRandomString(prefix: string = '') {
        return prefix + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    public static uuid() {
        try {
            var ID = () => {
                let array = new Uint32Array(8)
                window.crypto.getRandomValues(array)
                let str = ''
                for (let i = 0; i < array.length; i++) {
                    str += (i < 2 || i > 5 ? '' : '-') + array[i].toString(16).slice(-4)
                }
                return str
            }

            if (BrowserUtils.hasWindow()) {
                if (window && window.crypto && window.crypto.getRandomValues) {
                    return ID();
                }
            }
        } catch (e) {
            // skip
        }
        const chr4 = () => {
            return Math.random().toString(16).slice(-4);
        }
        return chr4() + chr4() +
            '-' + chr4() +
            '-' + chr4() +
            '-' + chr4() +
            '-' + chr4() + chr4() + chr4();
    }

    static async hash(message: string) {
        const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
        const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
        return hashHex;
    }

    public static supportedTextEncoding() {
        return [
            ['UTF-8', 'UTF-8'],
            ['ASCII', 'US-ASCII'],
            ['ISO-8859-1', 'ISO-8859-1'],
            ['ISO-8859-15', 'ISO-8859-15'],
            ['ISO-8859-2', 'ISO-8859-2'],
            ['ISO-8859-6', 'ISO-8859-6'],
            ['windows-1252', 'windows-1252'],
            ['Big5', 'Big5'],
            ['Big5-HKSCS', 'Big5-HKSCS'],
            ['CESU-8', 'CESU-8'],
            ['EUC-JP', 'EUC-JP'],
            ['EUC-KR', 'EUC-KR'],
            ['GB18030', 'GB18030'],
            ['GB2312', 'GB2312'],
            ['GBK', 'GBK'],
            ['IBM-Thai', 'IBM-Thai'],
            ['IBM00858', 'IBM00858'],
            ['IBM01140', 'IBM01140'],
            ['IBM01141', 'IBM01141'],
            ['IBM01142', 'IBM01142'],
            ['IBM01143', 'IBM01143'],
            ['IBM01144', 'IBM01144'],
            ['IBM01145', 'IBM01145'],
            ['IBM01146', 'IBM01146'],
            ['IBM01147', 'IBM01147'],
            ['IBM01148', 'IBM01148'],
            ['IBM01149', 'IBM01149'],
            ['IBM037', 'IBM037'],
            ['IBM1026', 'IBM1026'],
            ['IBM1047', 'IBM1047'],
            ['IBM273', 'IBM273'],
            ['IBM277', 'IBM277'],
            ['IBM278', 'IBM278'],
            ['IBM280', 'IBM280'],
            ['IBM284', 'IBM284'],
            ['IBM285', 'IBM285'],
            ['IBM290', 'IBM290'],
            ['IBM297', 'IBM297'],
            ['IBM420', 'IBM420'],
            ['IBM424', 'IBM424'],
            ['IBM437', 'IBM437'],
            ['IBM500', 'IBM500'],
            ['IBM775', 'IBM775'],
            ['IBM850', 'IBM850'],
            ['IBM852', 'IBM852'],
            ['IBM855', 'IBM855'],
            ['IBM857', 'IBM857'],
            ['IBM860', 'IBM860'],
            ['IBM861', 'IBM861'],
            ['IBM862', 'IBM862'],
            ['IBM863', 'IBM863'],
            ['IBM864', 'IBM864'],
            ['IBM865', 'IBM865'],
            ['IBM866', 'IBM866'],
            ['IBM868', 'IBM868'],
            ['IBM869', 'IBM869'],
            ['IBM870', 'IBM870'],
            ['IBM871', 'IBM871'],
            ['IBM918', 'IBM918'],
            ['ISO-2022-CN', 'ISO-2022-CN'],
            ['ISO-2022-JP', 'ISO-2022-JP'],
            ['ISO-2022-JP-2', 'ISO-2022-JP-2'],
            ['ISO-2022-KR', 'ISO-2022-KR'],
            ['ISO-8859-13', 'ISO-8859-13'],
            ['ISO-8859-16', 'ISO-8859-16'],
            ['ISO-8859-3', 'ISO-8859-3'],
            ['ISO-8859-4', 'ISO-8859-4'],
            ['ISO-8859-5', 'ISO-8859-5'],
            ['ISO-8859-7', 'ISO-8859-7'],
            ['ISO-8859-8', 'ISO-8859-8'],
            ['ISO-8859-9', 'ISO-8859-9'],
            ['JIS_X0201', 'JIS_X0201'],
            ['JIS_X0212-1990', 'JIS_X0212-1990'],
            ['KOI8-R', 'KOI8-R'],
            ['KOI8-U', 'KOI8-U'],
            ['Shift_JIS', 'Shift_JIS'],
            ['TIS-620', 'TIS-620'],
            ['UTF-16', 'UTF-16'],
            ['UTF-16BE', 'UTF-16BE'],
            ['UTF-16LE', 'UTF-16LE'],
            ['UTF-32', 'UTF-32'],
            ['UTF-32BE', 'UTF-32BE'],
            ['UTF-32LE', 'UTF-32LE'],
            ['windows-1250', 'windows-1250'],
            ['windows-1251', 'windows-1251'],
            ['windows-1253', 'windows-1253'],
            ['windows-1254', 'windows-1254'],
            ['windows-1255', 'windows-1255'],
            ['windows-1256', 'windows-1256'],
            ['windows-1257', 'windows-1257'],
            ['windows-1258', 'windows-1258'],
            ['windows-31j', 'windows-31j'],
            ['Big5-HKSCS-2001', 'x-Big5-HKSCS-2001'],
            ['Big5-Solaris', 'x-Big5-Solaris'],
            ['euc-jp-linux', 'x-euc-jp-linux'],
            ['EUC-TW', 'x-EUC-TW'],
            ['eucJP-Open', 'x-eucJP-Open'],
            ['IBM1006', 'x-IBM1006'],
            ['IBM1025', 'x-IBM1025'],
            ['IBM1046', 'x-IBM1046'],
            ['IBM1097', 'x-IBM1097'],
            ['IBM1098', 'x-IBM1098'],
            ['IBM1112', 'x-IBM1112'],
            ['IBM1122', 'x-IBM1122'],
            ['IBM1123', 'x-IBM1123'],
            ['IBM1124', 'x-IBM1124'],
            ['IBM1166', 'x-IBM1166'],
            ['IBM1364', 'x-IBM1364'],
            ['IBM1381', 'x-IBM1381'],
            ['IBM1383', 'x-IBM1383'],
            ['IBM300', 'x-IBM300'],
            ['IBM33722', 'x-IBM33722'],
            ['IBM737', 'x-IBM737'],
            ['IBM833', 'x-IBM833'],
            ['IBM834', 'x-IBM834'],
            ['IBM856', 'x-IBM856'],
            ['IBM874', 'x-IBM874'],
            ['IBM875', 'x-IBM875'],
            ['IBM921', 'x-IBM921'],
            ['IBM922', 'x-IBM922'],
            ['IBM930', 'x-IBM930'],
            ['IBM933', 'x-IBM933'],
            ['IBM935', 'x-IBM935'],
            ['IBM937', 'x-IBM937'],
            ['IBM939', 'x-IBM939'],
            ['IBM942', 'x-IBM942'],
            ['IBM942C', 'x-IBM942C'],
            ['IBM943', 'x-IBM943'],
            ['IBM943C', 'x-IBM943C'],
            ['IBM948', 'x-IBM948'],
            ['IBM949', 'x-IBM949'],
            ['IBM949C', 'x-IBM949C'],
            ['IBM950', 'x-IBM950'],
            ['IBM964', 'x-IBM964'],
            ['IBM970', 'x-IBM970'],
            ['ISCII91', 'x-ISCII91'],
            ['ISO-2022-CN-CNS', 'x-ISO-2022-CN-CNS'],
            ['ISO-2022-CN-GB', 'x-ISO-2022-CN-GB'],
            ['iso-8859-11', 'x-iso-8859-11'],
            ['JIS0208', 'x-JIS0208'],
            ['JISAutoDetect', 'x-JISAutoDetect'],
            ['Johab', 'x-Johab'],
            ['MacArabic', 'x-MacArabic'],
            ['MacCentralEurope', 'x-MacCentralEurope'],
            ['MacCroatian', 'x-MacCroatian'],
            ['MacCyrillic', 'x-MacCyrillic'],
            ['MacDingbat', 'x-MacDingbat'],
            ['MacGreek', 'x-MacGreek'],
            ['MacHebrew', 'x-MacHebrew'],
            ['MacIceland', 'x-MacIceland'],
            ['MacRoman', 'x-MacRoman'],
            ['MacRomania', 'x-MacRomania'],
            ['MacSymbol', 'x-MacSymbol'],
            ['MacThai', 'x-MacThai'],
            ['MacTurkish', 'x-MacTurkish'],
            ['MacUkraine', 'x-MacUkraine'],
            ['MS932_0213', 'x-MS932_0213'],
            ['MS950-HKSCS', 'x-MS950-HKSCS'],
            ['MS950-HKSCS-XP', 'x-MS950-HKSCS-XP'],
            ['mswin-936', 'x-mswin-936'],
            ['PCK', 'x-PCK'],
            ['SJIS_0213', 'x-SJIS_0213'],
            ['UTF-16LE-BOM', 'x-UTF-16LE-BOM'],
            ['UTF-32BE-BOM', 'X-UTF-32BE-BOM'],
            ['UTF-32LE-BOM', 'X-UTF-32LE-BOM'],
            ['windows-50220', 'x-windows-50220'],
            ['windows-50221', 'x-windows-50221'],
            ['windows-874', 'x-windows-874'],
            ['windows-949', 'x-windows-949'],
            ['windows-950', 'x-windows-950'],
            ['windows-iso2022jp', 'x-windows-iso2022jp'],
        ];
    }
}
