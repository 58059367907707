import { injectable } from 'inversify';

import { BrowserUtils } from '../ui-utils/browser-utils';

declare var document;

@injectable()
export class DOMStorage {
    static TYPE = 'DOMStorage';
    content: any;

    appComponentReady = false;
    baseComponentReady = false;

    constructor() {

    }

    setAppComponentReadyAndFreezeContentIfNeeded() {
        this.appComponentReady = true;
        // this.freezeIfNeeded();
    }

    setBaseComponentReadyAndFreezeContentIfNeeded() {
        this.baseComponentReady = true;
        // this.freezeIfNeeded();
    }

    private freezeIfNeeded() {
        if (this.appComponentReady && this.baseComponentReady) {
            this.load();
            this.content.frozen = true;
            this.persist();
        }
    }

    memStore(key: string, serializableContent: any) {
        this.load();
        if (!this.content) {
            this.content = {};
        } else if (this.content.frozen) {
            console.error(`Content is frozen. Not storing key: ${key}`);
            return;
        }
        this.content[key] = serializableContent;
        this.persist();
    }

    get(key: string) {
        this.load();
        return this.content && this.content[key];
    }

    private persist() {
        const serializedContent = encodeURIComponent(JSON.stringify(this.content));
        if (!BrowserUtils.hasDocument()) {
            console.error("Requested execution aborted since document was not avalilable");
            return;
        }
        const _document: Document = document;
        let dataStorage = _document.querySelector<HTMLScriptElement>('script[type="__PS_DATA__"]');
        if (!dataStorage) {
            dataStorage = _document.createElement('script');
            dataStorage.type = "__PS_DATA__";
            _document.body.appendChild(dataStorage);
        }
        dataStorage.textContent = serializedContent;

    }

    private load() {
        if (!BrowserUtils.hasDocument()) {
            console.error("Requested execution aborted since document was not avalilable");
            return;
        }
        const _document: Document = document;
        let dataStorage = _document.querySelector<HTMLScriptElement>('script[type="__PS_DATA__"]');
        if (!dataStorage) {
            dataStorage = _document.createElement('script');
            dataStorage.type = "__PS_DATA__";
            _document.body.appendChild(dataStorage);
        }

        const textContent = dataStorage.textContent;
        try {
            if (textContent && textContent.length > 0) {
                this.content = JSON.parse(decodeURIComponent(textContent));
            } else {
                this.content = {};
            }
        } catch (e) {
            this.content = {};
        }

    }
}