import { Component, Fragment } from 'react';

import { EnvironmentService } from '../../services/environment.service';
import { IOC_TYPES } from '../../context/ioc.types';
import { lazyInject } from '../../context/module';
import { StaticFooterComponent } from '../static-footer/static-footer.component';
import styles from './footer.component.module.scss';
import MobileAppPromptComponent from './mobile-app-prompt/mobile-app-prompt.component';
import { RouteProps } from '../../types/route-types';



interface FooterComponentProps extends RouteProps {
    fileSelectBtnClicked: boolean;
}
interface FooterComponentState {
    shouldShowAppPromo: boolean;
}
export class FooterComponent extends Component<FooterComponentProps, FooterComponentState> {
    @lazyInject(IOC_TYPES.EnvironmentService) private environmentService: EnvironmentService;

    static getDerivedStateFromProps(props) {
        let shouldShowAppPromo = false;
        if (props?.url) {
            const url = props.url;
            // shouldShowAppPromo = url.endsWith('video-compressor') || url.endsWith('compress-mp4') || url.endsWith('compress-webm');
        }
        return {
            shouldShowAppPromo
        };
    }
    get getIsXConvert() {
        return this.environmentService.isXConvert();
    }

    readonly state: Readonly<FooterComponentState> = {
        shouldShowAppPromo: false
    }
    constructor(props: FooterComponentProps) {
        super(props);
    }

    render() {
        if (this.props.fileSelectBtnClicked) {
            return <Fragment>
                <StaticFooterComponent></StaticFooterComponent>
                {
                    this.state.shouldShowAppPromo && <MobileAppPromptComponent></MobileAppPromptComponent>
                }
            </Fragment>
        }
        return <div className={`${styles.footer}`}>
            <div className={`${styles['link-sections-container']}`}>
                <div className={`${styles['link-sections']}`}>
                    {
                        this.getIsXConvert && <div className={`${styles['link-section']}`}>
                            <p className="text-left mt-2 h5">Convert</p>
                            <ul>
                                <li>
                                    <a href="/convert-pdf-to-ps" >
                                        PDF to PS</a>
                                </li>
                                <li>
                                    <a href="/convert-ps-to-pdf" >
                                        PS to PDF</a>
                                </li>
                                <li>
                                    <button id="pmLink" className={styles['pmLink']}>Privacy Manager</button>
                                </li>
                                
                            </ul>
                        </div>
                    }

                    {
                        this.getIsXConvert && <div className={`${styles['link-section']}`}>
                            <p className="text-left mt-2 h5">Image</p>
                            <ul>
                                <li>
                                    <a href="/convert-pdf-to-jpg" >
                                        PDF to JPG
                                    </a>
                                </li>
                                <li>
                                    <a href="/convert-jpg-to-pdf" >
                                        JPG to PDF
                                    </a>
                                </li>
                                <li>
                                    <a href="/convert-png-to-pdf" >
                                        PNG to PDF
                                    </a>
                                </li>
                                <li>
                                    <a href="/convert-pdf-to-png" >
                                        PDF to PNG
                                    </a>
                                </li>
                            </ul>
                        </div>
                    }

                    {
                        this.getIsXConvert && <div className={`${styles['link-section']}`}>
                            <p className="text-left mt-2 h5">Split &amp; Merge</p>
                            <ul>
                                <li>
                                    <a href="/split-pdf" >
                                        Split PDF
                                    </a>
                                </li>
                                <li>
                                    <a href="/merge-pdf" >
                                        Merge PDF
                                    </a>
                                </li>
                                <li>
                                    <a href="/merge-image-to-pdf" >
                                        Merge Image to PDF</a>
                                </li>
                            </ul>
                        </div>
                    }

                    <div className={`${styles['link-section']}`}>
                        <p className="text-left mt-2 h5">{this.environmentService?.env.siteDisplayName}</p>
                        <ul>
                            <li>
                                <a href="/about-us" >
                                    About Us
                                </a>
                            </li>
                            <li>
                                <a href="/privacy-policy" >
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a href="/terms-of-service" >
                                    Terms of Service
                                </a>
                            </li>
                            <li>
                                <a href="/contact-us" >
                                    Contact Us
                                </a>
                            </li>
                            <li>
                                {
                                    this.getIsXConvert && <a href="/blog/" >
                                        Blog
                                    </a>
                                }

                            </li>
                            <li>
                                <a href="/link-us" >
                                    Link Us
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <StaticFooterComponent></StaticFooterComponent>
                {
                    this.state.shouldShowAppPromo && <MobileAppPromptComponent></MobileAppPromptComponent>
                }
            </div>

        </div>;
    }
}