import { Commons } from '../commons/commons';
import { LogLevel, LogLevelTypes } from '../types/log.types';

export interface LogErrorRequest {
    message: string;
    data?: any;
    error?: Error;
}

export interface LogFatalRequest {
    message: string;
    data?: any;
    error?: Error;
}

export interface LogDebugRequest {
    message: string;
    data?: any;
}

export interface LogServiceProvider {
    log: (logEntry: LogEntry) => void;
    getBrowserInfo: () => any,
}

export interface LogEntry {
    message: string;
    level: string;
    loggerName?: string;
    data?: any;
    error?: Error;
    errorName?: string;
    errorMessage?: string;
    errorStack?: string;
    sessionDetails?: string;
    browserDetails?: string;
    url?: string;
    wsId: string;
    date: Date;
}


export function dataLogger(loggerName: string) {
    return function (target: Object, propertyKey: string) {
        let value: any = Logger.getLogger(loggerName);;
        const descriptor = {
            get(this: any) {
                return value;
            },
            set(value: any) {
                value = Logger.getLogger(loggerName);
            },
            enumerable: true,
            configurable: true,
        };
        const getter = function () {
            console.log('getter called');
            return value;
        };
        const setter = function (newVal: string) {
            console.log('setter called');
            value = Logger.getLogger(loggerName);
        };
        Object.defineProperty(target, propertyKey, descriptor);
    };
}

export class Logger {
    private static loggerService: LogServiceProvider;
    private static sessionDetails: any; // SessionDetails
    private static wsId;

    public static getLogger(name: string): Logger {
        return new Logger(name);
    }

    constructor(
        public name: string,
    ) { }

    public static setLoggerService(loggerService: LogServiceProvider) {
        return Logger.loggerService = loggerService;
    }

    public static setWSId(wsId: any) {
        return Logger.wsId = wsId;
    }

    public static setSessionDetails(sessionDetails: any) {
        return Logger.sessionDetails = sessionDetails;
    }

    private _log(message: string, level: LogLevel, data?: any, err?: Error) {
        if (Commons.DEBUG) {
            console.log(message);
            if (err) {
                console.error(err);
            }
            if (data) {
                console.log(data);
            }
        }
        if (Logger.loggerService) {
            const logEntry: LogEntry = Object.assign(
                {},
                this.getBaseObject(),
                {
                    message,
                    version: "1.0.2",
                    errorName: err ? err.name : null,
                    errorMessage: err ? err.message : null,
                    errorStack: err ? err.stack : null,
                    loggerName: this.name,
                    level: LogLevelTypes.logLevelToReadableString(level),
                    sessionDetails: Logger.sessionDetails,
                    browserDetails: Logger.loggerService.getBrowserInfo(),
                    url: window?.location?.href,
                    userTimestamp: `${(new Date()).toISOString()}`,
                },
                (data ? { data: data } : {}),
            );
            Logger.loggerService.log(logEntry);
        } else {
            console.log('LogService is not ready!', {
                message: message,
                level: level,
                data: data,
                error: err,
            });
        }
    }

    private log(level: LogLevel, request: LogErrorRequest | Error | string | String) {
        if (typeof request === 'string' || request instanceof String) {
            this._log(request.toString(), level, null);
        } else if (request instanceof Error) {
            this._log(request.message, level, null, request);
        } else {
            this._log(request.message, level, request.data, request.error);
        }
    }

    public trace1(request: LogDebugRequest | string) {
        const level = LogLevelTypes.TRACE_1;
        this.log(level, request);
    }

    public trace7(request: LogDebugRequest | string | String) {
        const level = LogLevelTypes.TRACE_7;
        this.log(level, request);
    }

    public debug(request: LogDebugRequest | string | String) {
        const level = LogLevelTypes.DEBUG;
        this.log(level, request);
    }

    public warn(request: LogErrorRequest | Error | string | String) {
        const level = LogLevelTypes.WARN;
        this.log(level, request);
    }

    public error(request: LogErrorRequest | Error | string | String) {
        const level = LogLevelTypes.ERROR;
        this.log(level, request);
    }

    public fatal(request: LogFatalRequest | Error | string | String) {
        const level = LogLevelTypes.FATAL;
        this.log(level, request);
    }

    private getBaseObject() {
        return {
            wsId: Logger.wsId,
            date: new Date(),
        };
    }
}
