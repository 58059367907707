import { inject, injectable } from 'inversify';

import { Commons } from '../commons/commons';
import { FileUpload } from '../models/file-upload';
import { Logger } from '../models/logger';
import { PSDate } from '../utils/date.utils';
import { FileManagerService } from './file-manager.service';
import { LightHttp } from './light-http.service';
import { StorageKeyPrefixeTypes, StorageService } from './storage.service';


@injectable()
export class FileUploadRecoveryService {
    private readonly logger = Logger.getLogger('FileUploadRecoveryService');

    constructor(
        @inject(StorageService) private storageService: StorageService,
        @inject(LightHttp) private httpClient: LightHttp,
        @inject(FileManagerService) private fileManagerService: FileManagerService,
    ) {

    }

    initialize() {
        // if (isPlatformBrowser(this.platformId)) {
        this.recoverFileUploadFromStorage();
        // }
    }

    private recoverFileUploadFromStorage() {
        // this.httpClient.get('https://ncf-api-v1.xconvert.com/file/converter/workflows/list/w8i6kueulgoix5a4tfx63qm').subscribe({
        //     next: (workflows) => {
        //         console.log(workflows)
        //         const fileUpload = FileUpload.fastDeserialize(workflows[0].processArguments);
        //         console.log(fileUpload);
        //     }
        // })

        const keys = this.storageService.keySet(StorageKeyPrefixeTypes.FILE_UPLOAD_KEY_PREFIX);
        const toRemoveIds = [];
        const toRecoverFileUploads = [];
        for (const key of Array.from(keys)) {
            const _key = key.replace(`${StorageKeyPrefixeTypes.FILE_UPLOAD_KEY_PREFIX}-`, '');
            const fileUpload = this.storageService.get<FileUpload>(
                StorageKeyPrefixeTypes.FILE_UPLOAD_KEY_PREFIX,
                _key,
                null,
                FileUpload.fastDeserialize,
            );
            // Remove old fileUploads
            if (fileUpload.uploadStartTime) {
                const uploadStartDate = PSDate.fromDate(fileUpload.uploadStartTime);
                if (uploadStartDate.isValid() &&
                    uploadStartDate.isBefore(PSDate.fromDate(new Date()).subtract(Commons.REMOVE_STORED_FILE_AFTER_HOURS, 'hours'))) {
                    toRemoveIds.push(_key);
                    continue;
                }
            }
            fileUpload.recoveredFileUpload = true;
            toRecoverFileUploads.push(fileUpload);
        }
        this.fileManagerService.addFileUploads(toRecoverFileUploads);
        toRemoveIds.forEach(key => {
            try {
                this.storageService.remove(StorageKeyPrefixeTypes.FILE_UPLOAD_KEY_PREFIX, key);
            } catch (error) {
                this.logger.error({ error, message: 'Failed to remove file: ' + key });
            }
        });
    }
}
