import { inject, injectable } from 'inversify';

import { GoogleDriveFile, GoogleDriveSelectedFile } from '../models/google-drive-file';
import { Logger } from '../models/logger';
import { UIContext } from './ui-context';

declare var gapi;
declare var google;

@injectable()
export class GoogleDriverService {
    private readonly logger = Logger.getLogger('GoogleDriverService');

    readonly cid = '1002349272285-rb84b9urep0jghvnf5c04vfp2tf58quv.apps.googleusercontent.com';
    readonly scope = 'https://www.googleapis.com/auth/drive.file';
    initialized = false;
    authenticated = false;
    pickerLoaded = false;
    picker;
    auth = null;
    user = null;
    response = null;
    token = null;
    appId = '1002349272285';
    tryOnce = false;

    constructor(
        @inject(UIContext) private uiContext: UIContext,
    ) {

    }

    showFileSelectDialog(callback: (googleDriveFiles: GoogleDriveFile[]) => void) {
        gapi.load('client:auth2', () => this.initClient(callback));
        // gapi.auth2.getAuthInstance().signIn();
    }

    initClient(callback: (googleDriveFiles: GoogleDriveFile[]) => void) {

        gapi.client.init({
            clientId: this.cid,
            scope: this.scope,
        }).then(
            () => {

                // Check if we are logged in.
                try {
                    this.auth = gapi.auth2.getAuthInstance();
                    this.auth.isSignedIn.listen((data) => this.onStatusChange(data));
                    this.authenticated = this.auth.isSignedIn.get();

                    if (this.authenticated) {
                        this.user = this.auth.currentUser.get();
                        this.response = this.user.getAuthResponse(true);
                        this.token = this.response.access_token;
                        gapi.load('picker', (data) => this.onPickerLoad(data, callback));
                    } else {
                        gapi.auth2.getAuthInstance().signIn()
                            .then(() => {
                                // this.authenticated = this.auth.isSignedIn.get();
                                // gapi.load('picker', (data) => this.onPickerLoad(data, callback));
                                if (!this.tryOnce) {
                                    this.tryOnce = true;
                                    this.initClient(callback);
                                } else {
                                    return;
                                }

                            }).catch(e => {
                                if (e.error === 'popup_blocked_by_browser') {
                                    this.logger.trace1({
                                        message: 'Error opening Google Drive file selector due to popup blocker',
                                    });
                                    this.uiContext.showErrorDialog(
                                        {
                                            title: 'Popup Blocked', text: `Google Drive could't open file select dialog due to popups been blocked.
                                         Please enable popups and try again.` },
                                    );
                                } else {
                                    this.logger.trace1({
                                        message: `Error while selecting file from google drive ${e && e.error}`,
                                    });
                                }
                            });
                    }
                } catch (e) {
                    this.logger.error(e);
                }
            }, (e) => {
                this.logger.error(e);
                if (e.details) {
                    this.uiContext.showErrorDialog({ title: 'Error', text: e.details });
                }
            });
    }

    onStatusChange(data) {
        this.logger.trace7({ message: 'Google drive status change.', data: data });
    }

    onPickerLoad(data, callback: (googleDriveFiles: GoogleDriveFile[]) => void) {
        this.logger.trace7({ message: 'Google drive picker loaded.', data: data });
        this.pickerLoaded = true;
        this.showPicker(callback);
    }

    showPicker(callback: (googleDriveFiles: GoogleDriveFile[]) => void) {
        if (this.pickerLoaded) { //  && this.authenticated
            const view = new google.picker.View(google.picker.ViewId.DOCS);
            let picker = new google.picker.PickerBuilder();
            picker.addView(view);
            picker.enableFeature(google.picker.Feature.MULTISELECT_ENABLED);
            picker.setOAuthToken(this.token);
            picker.setAppId(this.appId);
            picker.setCallback((data) => this.onDriveFileOpen(data, callback));
            picker = picker.build();
            picker.setVisible(true);
        }
    }

    onDriveFileOpen(data, callback: (googleDriveFiles: GoogleDriveFile[]) => void) {
        if (data.action === google.picker.Action.PICKED) {
            const files: GoogleDriveSelectedFile[] = data.docs;
            files.forEach(f => f.token = this.token);
            const gdFiles = GoogleDriveFile.createFromFileList(files);
            if (callback) {
                callback(gdFiles);
            }
        }
    }
}
