import { inject, injectable } from 'inversify';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, mergeMap, retryWhen, take, tap } from 'rxjs/operators';

import { FileUpload } from '../models/file-upload';
import { Logger } from '../models/logger';
import { GAContants } from '../types/ga.types';
import { ProcessorMetadata } from '../types/types';
import { castArray, isString } from '../utils/lodash-min';
import { ProcessorMetadataUtils } from '../utils/processor-metadata.utils';
import { GAService } from './analytics.service';
import { ApiConfig } from './api-config';
import { FileManagerService } from './file-manager.service';
import { HelperService } from './helper.service';
import { LightHttp } from './light-http.service';
import { OfflineTaskService } from './offline-task.service';
import { WebSocketService } from './websocket.service';


export interface ProbedData {
    approxTime: number;
    approxSize: number;
}

export interface FileProcessResponse {
    originalInputFiles: string[];
}

@injectable()
export class FileProcessService {
    private readonly logger = Logger.getLogger('FileProcessService');

    constructor(
        @inject(LightHttp) private http: LightHttp,
        @inject(WebSocketService) private wsService: WebSocketService,
        @inject(FileManagerService) private fileManagerService: FileManagerService,
        @inject(GAService) private gaService: GAService,
        @inject(OfflineTaskService) private offlineTaskService: OfflineTaskService,
        @inject(HelperService) private helperService: HelperService,
        @inject(ApiConfig) private apiConfig: ApiConfig,
    ) { }

    processFile(fileUploads: FileUpload): Observable<FileProcessResponse[] | any> {
        this.gaService.send(
            GAContants.HitType.EVENT,
            GAContants.EventCategory.BUTTON,
            GAContants.EventAction['FILE-CONVERT-START'],
            GAContants.EventLabel.CONVERT,
            '',
        );
        this.fileManagerService.saveFileUploadToStorage(fileUploads);
        this.logger.trace7({ message: 'FileUpload sent for processing', data: FileUpload.toLoggableObject(fileUploads) });
        const processorMetadata = fileUploads.endpointData.processorMetadataList[0];
        // console.log('HPY =========== calling updateProcessDomain', processorMetadata);
        const updateProcessDomain$ = fileUploads.updateProcessDomain(
            this.http,
            this.fileManagerService,
            this.apiConfig.getUploadAndProcessEndpointResolveEndpoint(),
            this.wsService,
        );
        return updateProcessDomain$.pipe(
            mergeMap(() => {
                return this._processFile(fileUploads, processorMetadata);
            })
        );
        // return new Promise(async (resolve, reject) => {
        //     if (!processorMetadata.processDomain) {
        //         await fileUploads.updateProcessDomain(this.http, this.storageService, this.urlEndpoints, this.wsService);
        //     }

        // });
    }


    private _processFile(fileUploads: FileUpload, processorMetadata: ProcessorMetadata,) {
        // console.log('HPY =========== processFile mergeMap');
        const url = ProcessorMetadataUtils.getProcessorEndpoint(processorMetadata);
        const formData: FormData = new FormData();
        const i = 0;
        if (fileUploads.file && !FileUpload.isUploadedFile(fileUploads)) {
            fileUploads.file.forEach(file => {
                formData.append('files', file, file.name); // [' + (i++) + ']
            });
        } else {
            // this is a re add event
            if (fileUploads.originalInputFiles) {
                formData.append('originalInputFiles', JSON.stringify(fileUploads.originalInputFiles));
            }
        }

        formData.append('id', fileUploads.id);
        formData.append('processType', fileUploads.processType);
        formData.append('fromType', fileUploads.fromType.toString());
        formData.append('toType', fileUploads.toType);
        formData.append('wsId', this.wsService.getId());
        formData.append('wsDomain', this.wsService.getDomain());
        formData.append('email', fileUploads.settings.email);

        fileUploads.metadataList.map(m => m.processorMetadataList[0]).forEach(pmdl => {
            formData.append(
                'fileUploadedServerEndpointList',
                ProcessorMetadataUtils.getUploadedFileDownloadEndpoint(pmdl)
            );
        });

        formData.append(
            'metadataList',
            JSON.stringify(fileUploads.metadataList),
        );
        // HPY IMP this is needed to dispatch the email. it is used in the email
        // formData.append('downloadURLPrefix', fileUploads.endpointData.domain + fileUploads.endpointData.download);
        /*
        const options = {};
        fileUploads.options.forEach(option => {
            const optionKeys = Object.keys(option);
            optionKeys.forEach(key => {
                options[key] = option[key];
            });
        });
        */
        formData.append('options', JSON.stringify(fileUploads.options));
        formData.append('optionsSnapshot', JSON.stringify(fileUploads.optionsSnapshot));

        return this.http.request<FileProcessResponse>(
            url,
            'POST',
            formData,
            { headers: this.helperService.getCommonHttpHeaders(), timeout: 30000 })
            .response$
            .pipe(
                tap(() => {
                    this.logger.trace7({ message: 'File process success: ', data: FileUpload.toLoggableObject(fileUploads) });
                    this.gaService.send(
                        GAContants.HitType.EVENT,
                        GAContants.EventCategory.BUTTON,
                        GAContants.EventAction['FILE-CONVERT-END'],
                        GAContants.EventLabel.CONVERT,
                        '',
                    );
                }),
                retryWhen(errors => errors.pipe(delay(1000), take(2))),
                catchError((err) => {
                    // console.log('HPY =========== processFile catchError');
                    this.logger.error({ message: `Error processing file: ${err.message}`, error: err });
                    this.gaService.send(
                        GAContants.HitType.EVENT,
                        GAContants.EventCategory.BUTTON,
                        GAContants.EventAction['FILE-CONVERT-FAILED'],
                        GAContants.EventLabel.CONVERT,
                        '',
                    );
                    return throwError(() => err);
                }),
            );
    }
    /**
     * Blocking call
     */
    async createZip(fileUpload: FileUpload): Promise<any> {
        this.logger.trace7({ message: `Creating zip file.`, data: FileUpload.toLoggableObject(fileUpload) });
        const processorMetadata = fileUpload.endpointData.processorMetadataList[0];
        return new Promise(async (resolve, reject) => {
            if (!processorMetadata.processDomain) {
                throw new Error('File is not processed!');
            }
            const url = ProcessorMetadataUtils.getZipEndpoint(processorMetadata);
            const formData: FormData = new FormData();

            let result = fileUpload.result;
            if (isString(result)) {
                result = JSON.parse(fileUpload.result);
            }
            const zipFile = result.zip;
            const outputFiles = castArray(result.output);

            formData.append('id', fileUpload.id);
            formData.append('wsId', this.wsService.getId());
            formData.append('wsDomain', this.wsService.getDomain());
            formData.append('zipFile', zipFile);
            (outputFiles || []).forEach(file => {
                formData.append('outputFiles', file);
            });

            await this.http.request(url, 'POST', formData, { headers: this.helperService.getCommonHttpHeaders() })
                .response$
                .toPromise()
                .then(resp => {
                    this.logger.trace7({ message: `Creating zip file success.`, data: resp });
                    resolve(fileUpload);
                })
                .catch(err => {
                    this.logger.error({ message: `Error creating zip file.`, data: FileUpload.toLoggableObject(fileUpload), error: err });
                    reject(fileUpload);
                });
        });
    }

    async probeProcessForInfo(fileUploads: FileUpload, probeDuration: number): Promise<any> {
        this.fileManagerService.saveFileUploadToStorage(fileUploads);
        this.logger.trace7({ message: `Creating zip file.`, data: FileUpload.toLoggableObject(fileUploads) });
        const processorMetadata = fileUploads.endpointData.processorMetadataList[0];
        return new Promise((resolve, reject) => {
            const url = ApiConfig.suffixDomainWithHttpsEdgeEndpoint(processorMetadata.processDomain) + '/files/input/probe';
            const formData: FormData = new FormData();
            const i = 0;
            if (fileUploads.file && !FileUpload.isUploadedFile(fileUploads)) {
                fileUploads.file.forEach(file => {
                    formData.append('files', file, file.name); // [' + (i++) + ']
                });
            } else {
                // this is a re add event
                if (fileUploads.originalInputFiles) {
                    formData.append('originalInputFiles', JSON.stringify(fileUploads.originalInputFiles));
                }
            }

            formData.append('probeDuration', probeDuration + '');
            formData.append('id', fileUploads.id);
            formData.append('processType', fileUploads.processType);
            formData.append('fromType', fileUploads.fromType.toString());
            formData.append('toType', fileUploads.toType);
            formData.append('wsId', this.wsService.getId());
            formData.append('wsDomain', this.wsService.getDomain());
            formData.append('email', fileUploads.settings.email);
            // HPY IMP this is needed to dispatch the email. it is used in the email
            // formData.append('downloadURLPrefix', fileUploads.endpointData.domain + fileUploads.endpointData.download);
            /*
            const options = {};
            fileUploads.options.forEach(option => {
                const optionKeys = Object.keys(option);
                optionKeys.forEach(key => {
                    options[key] = option[key];
                });
            });
            */
            formData.append('options', JSON.stringify(fileUploads.options));

            this.http.request(url, 'POST', formData, { headers: this.helperService.getCommonHttpHeaders() })
                .response$
                .toPromise()
                .then(data => {
                    this.logger.trace7({ message: `Success probing file info.`, data });
                    resolve((data as any).body);
                })
                .catch(err => {
                    this.logger.error({ message: `Error probing file info.`, data: FileUpload.toLoggableObject(fileUploads), error: err });
                    reject(fileUploads);
                });
        });
    }
}
