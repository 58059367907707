import { inject, injectable } from 'inversify';
import { ReplaySubject, interval } from 'rxjs';
import {
    flatten,
    uniq
} from '../utils/lodash-min';

import { FileUpload } from '../models/file-upload';
import { FileUploadStatusTracker } from '../models/file-upload-status-tracker';
import { ProcessStageSubStateTypes, ProcessStageTypes, WebsocketResponse } from '../types/types';
import { JSONUtils } from '../utils/json.utils';
import { ApiConfig } from './api-config';
import { LightHttp } from './light-http.service';
import { ProcessCompleteCheckService } from './process-complete-check.service';
import { IFileManagerService } from './service-interfaces';
import { StorageKeyPrefixeTypes, StorageService } from './storage.service';
import { UIContext } from './ui-context';
import { WebSocketService } from './websocket.service';

const FILE_DELETE_ENDPOINT = '/file-manager/delete/';

@injectable()
export class FileManagerService implements IFileManagerService {
    logger = {
        error: (...args) => console.log,
        trace7: (...args) => console.log,
    };

    private fileUploadTrackers: FileUploadStatusTracker[] = [];

    private _fileUploadsListChanged: ReplaySubject<FileUpload[]> = new ReplaySubject(1);
    get fileUploadsListChanged() {
        return this._fileUploadsListChanged.asObservable();
    }

    private _fileUploadChanged: ReplaySubject<FileUpload> = new ReplaySubject(1);
    get fileUploadChanged() {
        return this._fileUploadChanged.asObservable();
    }

    private fileTrackerInitialized = false;

    constructor(
        @inject(LightHttp) private http: LightHttp,
        @inject(ApiConfig) private apiConfig: ApiConfig,
        @inject(StorageService) private storageService: StorageService,
        @inject(UIContext) private uiContext: UIContext,
        @inject(ProcessCompleteCheckService) private processCompleteCheckService: ProcessCompleteCheckService,
        @inject(WebSocketService) private wsService: WebSocketService,
    ) {
        // enable for WebSocket.
        this.initFileTrackingUsingWebSocket();
    }

    private initFileTrackingUsingWebSocket() {
        // this.wsService.getWebsocketMessageObserver().subscribe({
        //     next: message => {
        //         this.applyHistory(null, message);
        //     },
        //     error: err => this.uiContext.handleError(err),
        // });
    }

    private initFileTracker() {
        if (!this.fileTrackerInitialized) {
            this.fileTrackerInitialized = true;
            let isRequestInProgress = false;
            interval(1000).subscribe(
                async () => {
                    if (!isRequestInProgress) {
                        isRequestInProgress = true;

                        const promiseArr = [];
                        if (this.fileUploadTrackers.length > 0) {
                            for (const tracker of this.fileUploadTrackers) {
                                promiseArr.push(tracker.replayHistory(
                                    this.http,
                                    (fileUpload: FileUpload, websocketResponse: WebsocketResponse) => {
                                        this.applyHistory(fileUpload, websocketResponse)
                                    }),
                                );
                            }
                        }
                        await Promise.all(promiseArr);
                        isRequestInProgress = false;
                    }
                }
            );
        }
    }

    public async removeFile(fileUpload: FileUpload) {
        const tracker = this.getFileUploadTracker(fileUpload);
        if (tracker) {
            this.removeFileUploadFromTracker(tracker, fileUpload.id);
        } else {
            console.error('File doesnot exist on Tracker');
        }
        this.notifyFileUploadsListChanged();
        this.removeStoredFileUpload(fileUpload);
        this.removeRemoteFileAsync(fileUpload);
    }

    private removeStoredFileUpload(fileUpload: FileUpload) {
        this.storageService.remove(StorageKeyPrefixeTypes.FILE_UPLOAD_KEY_PREFIX, fileUpload.id);
    }

    private removeRemoteFileAsync(fileUpload: FileUpload): void {
        (async() => {
            try {
                try {
                    const urls = this.getFileDeleteUrls(fileUpload);
                    for (const url of urls) {
                        try {
                            await this.http.get(url).toPromise();
                        } catch (e) {
                            this.logger.error({
                                message: 'Error while removing file from remote server',
                                error: e,
                                data: FileUpload.toLoggableObject(fileUpload),
                            });
                        }
                    }
                } catch (e) {
                    this.logger.error({
                        message: 'Error while getting URL for removing file from storage',
                        error: e,
                        data: FileUpload.toLoggableObject(fileUpload),
                    });
                }
                
            } catch (e) {
                this.logger.error({
                    message: 'Error while removing file from local storage',
                    error: e,
                    data: FileUpload.toLoggableObject(fileUpload),
                });
            }
        })();
    }

    private removeFileUploadFromTracker(tracker: FileUploadStatusTracker, fileUploadId: string) {
        tracker.removeFileUpload(fileUploadId);
        this.notifyFileUploadsListChanged();
    }

    private notifyFileUploadsListChanged() {
        const fileUploads = this.getFileUploads();
        this._fileUploadsListChanged.next(fileUploads);
    }

    private notifyFileUploadChanged(fileUpload: FileUpload) {
        this._fileUploadChanged.next(fileUpload);
    }

    public async removeAllFiles(fileUploads: FileUpload[]) {
        for (const fileUpload of fileUploads) {
            await this.removeFile(fileUpload);
        }
    }

    private getFileDeleteUrls(fileUpload: FileUpload): string[] {
        const metadata = fileUpload.endpointData.processorMetadataList[0];
        const domains = [
            metadata.downloadDomain,
            metadata.uploadDomain,
            metadata.processDomain,
        ];
        return uniq(domains).map(domain => {
            return ApiConfig.suffixDomainWithHttpsEdgeEndpoint(domain) + FILE_DELETE_ENDPOINT + fileUpload.id;
        });
    }

    public getFileUploads(): FileUpload[] {
        return flatten(this.fileUploadTrackers.map(t => t.getFileUploads())).filter(t => t);
    }

    private getFileUploadsById(fileUploadId: string): FileUpload[] {
        return this.getFileUploads().filter(f => f.id === fileUploadId);
    }

    /** Add FileUploads. We are not tracking UploadInProgress files */
    public addFileUpload(fileUpload: FileUpload) {
        this.addFileUploads([fileUpload]);
    }

    public addFileUploads(fileUploads: FileUpload[]) {
        this.initFileTracker();
        fileUploads.forEach((fileUpload, index) => {
            let tracker = this.getFileUploadTracker(fileUpload);
            if (!tracker) {
                tracker = FileUploadStatusTracker.createFromFileUpload(fileUpload);
                // Prevent adding null trackers
                if (tracker) {
                    this.fileUploadTrackers.push(tracker);
                }
            }
            // Tracker could be null until processData is ready
            if (tracker) {
                tracker.addFileUpload(fileUpload);
            }
        });
        this.notifyFileUploadsListChanged();
    }

    private getFileUploadTracker(fileUpload: FileUpload) {
        return this.fileUploadTrackers.find(t => t.sameAs(fileUpload));
    }

    saveFileUploadToStorage(fileUpload: FileUpload) {
        this.storageService.store(StorageKeyPrefixeTypes.FILE_UPLOAD_KEY_PREFIX, fileUpload.id, fileUpload);
        const untractedTracker = this.fileUploadTrackers.find(t => !t.canTrack);
        if (untractedTracker) {
            const fileUploads = untractedTracker.getFileUploads();
            (fileUploads || []).forEach(fileUpload => {
                let canCreate = FileUploadStatusTracker.canCreateTrackedFileUploadTracker(fileUpload);
                if (canCreate) {
                    this.removeFileUploadFromTracker(untractedTracker, fileUpload.id);
                    this.addFileUpload(fileUpload);
                }
            });
        }
    }

    private saveFileUploadToStorageWithoutRecomputingUntrackedFileUploads(fileUpload: FileUpload) {
        this.storageService.store(StorageKeyPrefixeTypes.FILE_UPLOAD_KEY_PREFIX, fileUpload.id, fileUpload);
    }

    checkStatus(fileInfo: FileUpload) {
        return this.processCompleteCheckService.requestToSendFileCompleteDataThroughWS(
            fileInfo.endpointData.processorMetadataList[0].processDomain,
            fileInfo.id,
        )
            .then(data => this.logger.trace7({ message: `Check fileUpload status.`, data }))
            .catch(err => this.logger.error({ message: 'Error checking fileUpload status.', error: err }));
    }

    /**
     *
     * @param _fileUpload to apply ws reply to a fileUpload on this session, fileUpload should be null.
     * There is a recovered version of same fileUpload on recoveredFileUploads, sending to that wouldnt update the UI
     * @param websocketResponse
     */
    public applyHistory(_fileUpload: FileUpload, websocketResponse: WebsocketResponse) {
        const fileUploads = _fileUpload && [_fileUpload] || this.getFileUploadsById(websocketResponse.uuid);
        if (fileUploads && fileUploads.length > 0) {
            fileUploads.forEach(fileUpload => {
                // only update result with ws message it we are allowed to
                if (!fileUpload.discartWSReply) {
                    if (!fileUpload.result || fileUpload.result.error) {
                        // this condition required to prevent messages from overriding complete result from
                        // messages from FakeProgress (Runs on an indipendent thread)
                        fileUpload.result = websocketResponse.result && JSON.parse(websocketResponse.result);
                        // this also need to prevent displaying "Waiting for server to reply" after "completed".
                        // Which is caused by recieving empty JSON or Object from server after complete message
                        fileUpload.ws = websocketResponse;
                        this.saveFileUploadToStorageWithoutRecomputingUntrackedFileUploads(fileUpload);

                    } else {
                        // Update file process finished time
                        if (!fileUpload.processEndTime) {
                            fileUpload.processEndTime = new Date();
                        }
                    }
                }

                if (fileUpload && fileUpload.result) {
                    const result = fileUpload.result;
                    if (websocketResponse.processStage === ProcessStageTypes.COMPLETED && ProcessStageSubStateTypes.COMPLETED) {
                        fileUpload.processEndTime = result.end;
                        fileUpload.processStartTime = result.start || fileUpload.processStartTime;
                        this.saveFileUploadToStorage(fileUpload);
                    }

                    if (websocketResponse.processStage === ProcessStageTypes.ZIP &&
                        (!fileUpload.zipResult || (fileUpload.zipResult && fileUpload.zipResult.processStageSubState !== ProcessStageSubStateTypes.COMPLETED))
                    ) {
                        fileUpload.zipResult = websocketResponse;
                        if (websocketResponse.processStageSubState === ProcessStageSubStateTypes.COMPLETED) {
                            this.saveFileUploadToStorageWithoutRecomputingUntrackedFileUploads(fileUpload);
                        }
                    }
                }
                this.notifyFileUploadChanged(fileUpload);
            });
        }
    }

    public stringifyAllUploadedFileUploads() {
        try {
            let fileArray = [];
            (this.getFileUploads() || []).forEach(fileUpload => {
                try {
                    fileArray.push(FileUpload.toLoggableObject(fileUpload));
                } catch (e) {
                    this.logger.error({
                        message: 'Failed to serialize a file upload',
                        error: e,
                    });
                }
            });
            return JSONUtils.safeStringify(fileArray);
        } catch (e) {
            this.logger.error({
                message: 'Failed to serialize file uploads',
                error: e,
            });
        }
    }
}
