import { Observable, Subject } from 'rxjs';

import { Logger } from '../models/logger';
import { GenericMouseEvent } from '../types/types';
import { BrowserUtils } from '../ui-utils/browser-utils';
import { UIUtils } from './dom-utils';

export namespace WindowSize {
    export const XS = 1;
    export const SM = XS << 1;
    export const MD = XS << 2;
    export const LG = XS << 3;
    export const XL = XS << 4;
    export const XXL = XS << 5;
}

export class WindowUtils {


    private static get logger() {
        return Logger.getLogger('WindowUtils');
    };

    static dispatchResizeEvent(delayMillis: number = 0) {
        BrowserUtils.runIfWindowExists(() => {
            setTimeout(() => window.dispatchEvent(new Event("resize")), 0);
        });
    }

    /** TODO will not work with browsers without ResizeObserver  */
    static resizeObserver(element): Observable<void> {
        const subject = new Subject<void>();
        var ro = new globalThis['ResizeObserver'](entries => {
            return subject.next(null);
        });
        ro.observe(element);
        return subject.asObservable();
    }

    static runWhenIdeal(fn: () => void) {
        BrowserUtils.runIfWindowExists(() => {
            const requestIdleOrAnimationFrame = window.requestIdleCallback || window.requestAnimationFrame || window['mozRequestAnimationFrame'] ||
                (window as any).webkitRequestAnimationFrame || window['msRequestAnimationFrame'];
            if (!requestIdleOrAnimationFrame) {
                return fn();
            }
            return requestIdleOrAnimationFrame(fn);
        });
    }

    static addMoveMoveHandlerToWindow(
        fn: ($event: GenericMouseEvent) => void,
        autoRemoveOnMouseUp = true,
        onRemoveFn?: ($event: GenericMouseEvent) => void,
    ): (e?: any) => void {
        if (!BrowserUtils.hasWindow()) {
            return;
        }
        const handler = ($event) => {
            const genericEvent = UIUtils.toGenericMouseEvent($event);
            fn(genericEvent as any);
        };

        window.addEventListener('mousemove', handler, { passive: false });
        window.addEventListener('touchmove', handler, { passive: false });

        const removeFn = (e) => {
            window.removeEventListener('mousemove', handler);
            window.removeEventListener('touchmove', handler);
            if (onRemoveFn) {
                onRemoveFn(UIUtils.toGenericMouseEvent(e));
            }
            if (autoRemoveOnMouseUp) {
                window.removeEventListener('mouseup', removeFn);
                window.removeEventListener('touchend', removeFn);
            }
            WindowUtils.logger.trace1({ message: 'Removed mouse move event' });
        }
        if (autoRemoveOnMouseUp) {
            window.addEventListener('mouseup', removeFn, { passive: false });
            window.addEventListener('touchend', removeFn, { passive: false });

        }
        WindowUtils.logger.trace1({ message: 'Added mouse move event' });
        return removeFn; // Why return this?
    }

    static addMoveUpHandlerToWindow(fn: ($event: GenericMouseEvent) => void, autoRemoveOnMouseUp = true): ($event) => void {
        if (!BrowserUtils.hasWindow()) {
            return;
        }
        const handler = ($event) => {
            const genericEvent = UIUtils.toGenericMouseEvent($event);
            fn(genericEvent as any);
            window.removeEventListener('mouseup', handler);
            window.removeEventListener('touchend', handler);
        };

        window.addEventListener('mouseup', handler, { passive: false });
        window.addEventListener('touchend', handler, { passive: false });

        WindowUtils.logger.trace1({ message: 'Added mouse up event' });
        return handler;
    }

    static getWindowSize(): number {
        const width = window.innerWidth;
        if(width < 576) {
            return WindowSize.XS;
        }
        if(width < 768) {
            return WindowSize.SM;
        }
        if(width < 992) {
            return WindowSize.MD;
        }
        if(width < 1200) {
            return WindowSize.LG;
        }
        if(width < 1400) {
            return WindowSize.XL;
        }
        return WindowSize.XXL;
    }
}
