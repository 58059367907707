import { Component, } from 'react';

import { FormControl } from '../../../../models/forms';
import { untilDestroyed } from '../../../../utils/until-destroyed';
import { OptionalChildrenProps } from '../../../../types/react.types';

const defaultProps: any = {
    type: 'text',
}

export interface FormInputComponentProps extends OptionalChildrenProps {
    id?: string;
    type: 'text' | 'email' | 'number';
    control: FormControl;
    placeholder?: string;
    className?: string;
}

export interface FormInputComponentState {
    value: any,
}

export default class FormInputComponent extends Component<FormInputComponentProps, FormInputComponentState> {
    mergedProps: FormInputComponentProps = defaultProps;

    readonly state: Readonly<FormInputComponentState> = {
        value: this.props?.control?.value || null,
    };
    constructor(props: FormInputComponentProps) {
        super(props);
        this.mergedProps = Object.assign({}, defaultProps, props);
        props.control.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (value) => {
                    this.setState({ value })
                }
            });
    }

    render() {
        return <input
            id={this.mergedProps.id}
            type={this.mergedProps.type}
            placeholder={this.mergedProps.placeholder}
            className={this.mergedProps.className}
            value={this.state.value} onInput={(e: any) => this.props.control.setValue(e.target.value)}
        />
    }
}