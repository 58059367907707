import { inject, injectable } from 'inversify';

import { FileUpload } from '../models/file-upload';
import { Logger } from '../models/logger';
import { GAContants } from '../types/ga.types';
import { ProcessorMetadataUtils } from '../utils/processor-metadata.utils';
import { GAService } from './analytics.service';
import { HelperService } from './helper.service';
import { LightHttp } from './light-http.service';


@injectable()
export class FileShareService {
    private readonly logger = Logger.getLogger('FileShareService');

    constructor(
        @inject(LightHttp) private http: LightHttp,
        @inject(GAService) private gaService: GAService,
        @inject(HelperService) private helperService: HelperService,
    ) {
    }

    shareGCSPermenentFile(fileInfo: FileUpload, localFilename: string, text: string, isInputFile: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            const domain = fileInfo.endpointData.processorMetadataList[0].processDomain || fileInfo.endpointData.processorMetadataList[0].uploadDomain;
            const shareURL = domain +
                fileInfo.endpointData.processorMetadataList[0].imageShareEndpoint;

            const formData = new FormData();
            formData.append('gcName', fileInfo.endpointData.processorMetadataList[0].fileMetadata.gcName);
            formData.append('localFilename', localFilename);
            formData.append('isInputFile', isInputFile ? 'true' : 'false');
            formData.append('text', text);

            this.http.request<{ id: string, slug: any }>(
                shareURL, 'POST', formData, { headers: this.helperService.getCommonHttpHeaders() }
            ).response$.toPromise()
                .then((response) => {
                    resolve({
                        pageUrl: `https://www.imgsock.com/meme/${response.id}/${response.slug}`,
                        imgUrl: `https://www.imgsock.com/api/image/${response.id}`,
                    });
                }).catch(err => reject(err));
        });
    }

    downloadMemeImageFile(image_url: string) {
        const _window: Window = window.open('/download?url=' + encodeURIComponent(image_url));
        this.gaService.send(
            GAContants.HitType.EVENT,
            GAContants.EventCategory.BUTTON,
            GAContants.EventAction['FILE-DOWNLOAD'],
            GAContants.EventLabel.DOWNLOAD,
            '',
        );
    }

    getDownloadURL(fileInfo: FileUpload, file_name: String) {
        const domain = fileInfo.endpointData.processorMetadataList[0].downloadDomain;
        const downloadUrl = ProcessorMetadataUtils.getDownloadEndpoint(fileInfo.endpointData.processorMetadataList[0]) +
            ((domain.indexOf('googleapis') !== -1) ? '' : '/') + file_name;
        return downloadUrl;
    }
}
