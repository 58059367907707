import { of } from 'rxjs';
import { catchError, mergeMap, retry } from 'rxjs/operators';

import { ApiConfig } from '../services/api-config';
import { ILightHttpService } from '../services/service-interfaces';
import { WebsocketResponse } from '../types/types';
import { cloneDeep, flatten, get, isEqual, uniq } from '../utils/lodash-min';
import { FileUpload } from './file-upload';

interface ExistingFileIdsResponse {
    existingFileIds: string[];
}

interface FileHistoryResponse {
    processCompleteData: WebsocketResponse,
    history: WebsocketResponse[],
}

interface FilesHistoryResponse {
    [fileId: string]: FileHistoryResponse;
}

interface CommEndpoints {
    commHistoryEndpoint: string | null;
    commInputExistEndpoint: string | null;
}


type MessageIds = Set<number>;

interface ProcessedMessageCache {
    [uuid: string]: MessageIds;
}

/**
 * This does not delete files. If it were to be implemented then functionality should be added to checkForExistance
 */
export class FileUploadStatusTracker {
    private _canTrack = true;
    get canTrack() {
        return this._canTrack;
    }
    private commHistoryEndpoint: string = null;
    private commInputExistEndpoint: string = null;
    private fileUploads: Map<string, FileUpload[]> = new Map();

    private messageCache: ProcessedMessageCache = {};
    private fileExistsCheckPassed = false;
    private fileExistsResponse: ExistingFileIdsResponse = null;

    static getCommEndpoints(fileUpload: FileUpload): CommEndpoints {
        const processDomain = get(fileUpload, 'endpointData.processorMetadataList[0].processDomain', null);
        const commEndpoint = get(fileUpload, 'endpointData.processorMetadataList[0].commEndpoint', null);
        if (!processDomain) {
            return {
                commHistoryEndpoint: null,
                commInputExistEndpoint: null,
            }
        }
        return {
            commHistoryEndpoint: ApiConfig.suffixDomainWithHttpsEdgeEndpoint(processDomain) + `/workflows/replay-history`,
            commInputExistEndpoint: ApiConfig.suffixDomainWithHttpsEdgeEndpoint(processDomain) + `/files/input/exists`,
        };
    }

    private constructor(fileUpload: FileUpload) {
        if (!fileUpload) {
            this._canTrack = false;
            this.commHistoryEndpoint = null;
            this.commInputExistEndpoint = null;
        } else {
            const commEndpoints = FileUploadStatusTracker.getCommEndpoints(fileUpload);
            this.commHistoryEndpoint = commEndpoints.commHistoryEndpoint;
            this.commInputExistEndpoint = commEndpoints.commInputExistEndpoint;
        }
    }

    static createFromFileUpload(fileUpload: FileUpload) {
        const processDomain = get(fileUpload, 'endpointData.processorMetadataList[0].processDomain', null) || null;
        if (!processDomain) {
            return new FileUploadStatusTracker(null);
        }
        return new FileUploadStatusTracker(fileUpload);
    }

    static canCreateTrackedFileUploadTracker(fileUpload: FileUpload) {
        const processDomain = get(fileUpload, 'endpointData.processorMetadataList[0].processDomain', null) || null;
        return !!processDomain;
    }

    sameAs(fileUpload: FileUpload) {
        const commEndpoints = FileUploadStatusTracker.getCommEndpoints(fileUpload);
        return this.commHistoryEndpoint === commEndpoints.commHistoryEndpoint;
    }

    hasFileUpload(fileUploadId: string): boolean {
        return this.fileUploads.has(fileUploadId);
    }

    addFileUpload(fileUpload: FileUpload): void {
        const newFileUploads = (this.fileUploads.get(fileUpload.id) || []);
        newFileUploads.push(fileUpload);
        this.fileUploads.set(fileUpload.id, newFileUploads);
        this.messageCache[fileUpload.id] = new Set();
    }

    removeFileUpload(fileUploadId: string): void {
        this.fileUploads.delete(fileUploadId);
    }

    getFileUploads(): FileUpload[] {
        return flatten(Array.from(this.fileUploads.values()));
    }

    private getFileUploadsUniqIds(): string[] {
        return uniq(this.getFileUploads().map(f => f.id));
    }

    private getIncompletedFileUploadsUniqueIds(): string[] {
        return uniq(this.getFileUploads().filter(f => !(f.isCompleted() || f.isFailed())).map(f => f.id));
    }

    private checkForExistence(http: ILightHttpService) {
        const fileIds = this.getIncompletedFileUploadsUniqueIds();
        if(this.fileExistsCheckPassed && this.fileExistsResponse != null) {
            const sameFileIds = isEqual(cloneDeep(this.fileExistsResponse.existingFileIds), cloneDeep(fileIds));
            if(sameFileIds) {
                return of(this.fileExistsResponse);
            }
        }
        if (fileIds && fileIds.length > 0) {
            return http.post<ExistingFileIdsResponse>(this.commInputExistEndpoint, {
                fileIds: fileIds,
            }).pipe(
                mergeMap((resp: ExistingFileIdsResponse) => {
                    const filesExists = isEqual(cloneDeep(resp.existingFileIds), cloneDeep(fileIds));
                    if(filesExists) {
                        this.fileExistsCheckPassed = true;
                        this.fileExistsResponse = resp;
                    }
                    return of(resp);
                }),
            );
        }
        return of({ existingFileIds: [] });
    }

    replayHistory(http: ILightHttpService, applyHistory: (fileUpload: FileUpload, websocketResponse: WebsocketResponse) => void) {
        // console.log(`Can track ${this.canTrack}`);
        if (!this.canTrack) {
            return Promise.resolve();
        }
        return new Promise(
            resolve => {
                this.checkForExistence(http)
                    .pipe(
                        mergeMap((resp: ExistingFileIdsResponse) => {
                            if (resp.existingFileIds && resp.existingFileIds.length > 0) {
                                return http.post(
                                    this.commHistoryEndpoint,
                                    {
                                        fileIds: resp.existingFileIds
                                    }
                                );
                            }
                            return of([]);
                        }),
                        retry(1),
                        catchError(() => {
                            return of([]);
                        })
                    ).subscribe({
                        next: (resp: FilesHistoryResponse) => {
                            Object.keys(resp).forEach(r => {
                                const fileHistoryResponse = resp[r];
                                const fileUploads = this.fileUploads.get(r);
                                if (fileHistoryResponse.processCompleteData) {
                                    fileUploads.forEach(fileUpload => {
                                        applyHistory(fileUpload, fileHistoryResponse.processCompleteData);
                                    });
                                } else {
                                    fileHistoryResponse.history.forEach(h => {
                                        if (this.messageCache[h.uuid].has(h.eventId)) {
                                            return;
                                        }
                                        this.messageCache[h.uuid].add(h.eventId);
                                        fileUploads.forEach(fileUpload => {
                                            applyHistory(fileUpload, h);
                                        });
                                    });
                                }
                            });
                            resolve({});
                        }
                    });
            }
        );
    }
}