import { Component, } from 'react';

export async function getInitialProps({ res, err }: { res: any; err: any }) {
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
    return { statusCode };
  }

export default class ErrorPage extends Component {
    statusCode;
    constructor(props) {
        super(props);
        this.statusCode = props.statusCode;
    }
    render() {
        return <div style={{ textAlign: 'center', width: '100%' }}>
            <div>
                {this.statusCode
                    ? `An error ${this.statusCode} occurred on server`
                    : <img style={{ width: '100%', maxWidth: '500px', maxHeight: '400px' }} src="/img/404.svg" />
                }
            </div>
            <div>
                <h4>The page you're looking for can't be found.</h4>
            </div>
            <div>
                <a className={`btn btn-primary`} href="/">Navigate To Hope Page</a>
            </div>
        </div>
    }
}