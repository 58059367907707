import { Component } from 'react';
import { createRoot } from 'react-dom/client';

import { BrowserUtils } from '../../../ui-utils/browser-utils';
import { StringUtils } from '../../../utils/string-utils';
import styles from './spinner.modal.module.scss';

interface SpinnerModalProps {
    message: string;
}

interface SpinnerModalState {
    message: string;
}

export default class SpinnerModal extends Component<SpinnerModalProps, SpinnerModalState> {
    static message: string;

    private static get generateId() {
        return StringUtils.getRandomString('id_');
    }

    static show(props: any): Promise<SpinnerModal> {
        if (!BrowserUtils.hasDocument()) {
            console.error("Requested execution aborted since document was not available");
            return;
        }
        const id = this.generateId;
        const modalPortal = document.createElement('div');
        modalPortal.id = id;
        modalPortal.className = `spinner ${Date.now()}`;
        document.body.appendChild(modalPortal);
        props.id = id;
        return new Promise(resolve => {
            const child = <SpinnerModal {...props} instantCreateCallback={(instant) => {
                resolve(instant);
            }}></SpinnerModal>;
            const root = createRoot(modalPortal!);
            root.render(child);
        });
    }

    public updateMessage(message: string) {
        this.setState({
            message: message,
        });
    }

    readonly state: Readonly<SpinnerModalState> = {
        message: this.props.message,
    };

    constructor(props: SpinnerModalProps) {
        super(props);
        (this.props as any).instantCreateCallback(this);
    }

    getContainerId() {
        return (this.props as any).id;
    }

    render() {
        return <div className={styles["spinner-container"]}>
            <div className={styles["spinner-inner"]}>
                <div className={styles["spinner-border"]} role="status">
                    <span className={styles["sr-only"]}>Loading...</span>
                </div>
                <div className={styles["message"]} style={{ color: 'white', textAlign: 'center' }}>{this.state.message}</div>
            </div>
        </div>
    }
}