import { injectable } from 'inversify';

const defaultEnvironment = {
    production: true,
    debug: false,
    debugLevel: 'warn',
    templatePrefix: 'xconvert',
    id: 'xconvert',
    sitename: 'xconvert',
    siteDisplayName: 'XConvert',
    siteSupportEmail: 'support@xconvert.com',
    domainname: 'xconvert.com',
    apiDomainname: 'xconvert.com',
    podDomainname: 'xconvert.com',
    dev: false,
    qa: false,
    localhostSiteDomain: 'www.xconvert.com',
    localhostAPIDomain: 'api.xconvert.com',
    localhostPODDomain: 'pod.xconvert.com',
    gadId: 'ca-pub-5479498347583905',
    showHistory: false,
    constants: {
        imageresizerId: 'imageresizer',
        ps2pdfId: 'ps2pdf',
        xconvertId: 'xconvert'
    },
};

@injectable()
export class EnvironmentService {


    environment = defaultEnvironment;
    loadEnvironmentFromCLIData(cliData: { pageProps: { environment: any } }) {
        try {
            this.environment = cliData.pageProps.environment;
        } catch (e) {
            console.error(e);
        }
    }
    get env() {
        return this.environment;
    }

    get xconvert() {
        return this.environment.sitename === 'xconvert';
    }

    get imageresizer() {
        return this.environment.sitename === 'imageresizer';
    }

    get sitename() {
        return this.environment.sitename;
    }

    get domainname() {
        return this.environment.domainname;
    }

    get email() {
        return this.environment.siteSupportEmail;
    }

    getSiteName() {
        return this.environment.sitename;
    }

    getDomainName() {
        return this.environment.domainname;
    }

    isXConvert() {
        return this.getSiteName() === 'xconvert';
    }

    apiDomainname() {
        return this.environment && this.environment.apiDomainname;
    }

    podDomainname() {
        return this.environment && this.environment.podDomainname;
    }
}

export namespace EnvironmentService {
    export function getBoolEnv(variableName: string,): boolean {
        return EnvironmentService.getEnv(variableName) === 'true';
    }

    export function getEnv(variableName: string,): string {
        return process.env[variableName];
    }
}