import axios, { AxiosRequestConfig } from 'axios';
import { inject, injectable } from "inversify";
import { get } from 'lodash-es';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import { HttpRequestBody, HttpRequestOptions, HttpRequestOptionsInternal } from "../types/http.types";
import { ClientError, ServiceError, UnknownHTTPError } from '../utils/request.utils';
import { ApiConfig } from './api-config';
import { DOMStorage } from './dom-storage.service';
import { EnvironmentService } from './environment.service';
import { HttpProgressEvent, UNAUTHENTICATED_URLS } from './light-http.service';
import { ILightHttpService } from "./service-interfaces";


/***
 * Try removing LightHttp after implementing this
 */
@injectable()
export class HttpClient implements ILightHttpService {
    private static wsDataProvider = {
        getOrCreateStoredWSId: (): string | null => null,
        getStoredWSDomain: (): string | null => null,
    };
    static loginUserDetailsProvider: { userIpAddress: string | null, userContry: string | null } = { userIpAddress: null, userContry: null };
    static commonHttpHeadersProvider: { getCommonHttpHeaders: () => any }
        = { getCommonHttpHeaders: () => { return {}; } };
    static authTokenProvider: { getToken: () => string | null } = { getToken: () => null };

    constructor(
        @inject(ApiConfig) private apiConfig: ApiConfig,
        @inject(EnvironmentService) private env: EnvironmentService,
        @inject(DOMStorage) private domStorage: DOMStorage,
    ) {
    }

    get<T>(url: string, options?: HttpRequestOptions) {
        let config: AxiosRequestConfig = this.convertHttpRequestOptionsToAxiosRequestConfig(options)
        return axios.get(url, config)
    }
    request<T>(url: string, method: "GET" | "POST" | "PUT" | "DELETE" | "HEAD" | "PATCH", body: HttpRequestBody,
        options?: HttpRequestOptions) {
        switch (method) {
            case 'GET':
                return this.get(url, options)
            case 'POST':
                return this.post(url, body, options)
            case 'PUT':
                return this.put(url, body, options)
            case 'PATCH':
                return this.patch(url, body, options)
            case 'DELETE':
                return this.delete(url, options)
            case 'HEAD':
                throw Error("Not supported")
        }
    }
    post<T>(url: string, body: HttpRequestBody, options?: HttpRequestOptions) {
        let config: AxiosRequestConfig = this.convertHttpRequestOptionsToAxiosRequestConfig(options)
        return axios.post(url, body, config)
    }
    put<T>(url: string, body: HttpRequestBody, options?: HttpRequestOptions) {
        let config: AxiosRequestConfig = this.convertHttpRequestOptionsToAxiosRequestConfig(options)
        return axios.put(url, body, config)
    }
    patch<T>(url: string, body: HttpRequestBody, options?: HttpRequestOptions) {
        let config: AxiosRequestConfig = this.convertHttpRequestOptionsToAxiosRequestConfig(options)
        return axios.patch(url, body, config)
    }
    delete<T>(url: string, options?: HttpRequestOptions) {
        let config: AxiosRequestConfig = this.convertHttpRequestOptionsToAxiosRequestConfig(options)
        return axios.delete(url, config)
    }

    private convertHttpRequestOptionsToAxiosRequestConfig(options?: HttpRequestOptions) {
        let config: AxiosRequestConfig = {}
        if(options){
            config.headers = options.headers;
            config.withCredentials = options.withCredentials;
            config.params = options.params;
            config.responseType = options.responseType;
            config.timeout = options.timeout
        }
        return config;
    }


    private convertToProgressEvent(event: AjaxResponse<any>): HttpProgressEvent {
        if (event.type === 'upload_progress' || event.type === 'download_progress') {
            return {
                event: {
                    loaded: event.loaded,
                    total: event.total,
                },
                type: event.type === 'upload_progress' ? 'upload_progress' : 'download_progress',
            }
        }

        return {
            event,
            type: event.type,
        };
    }

    private convertErrorResponse(error: AjaxError, options?: { requestUrl: string }) {
        if (error.status == 0) {
            let url = get(error || {}, 'xhr.responseURL', options?.requestUrl);
            if ((!url || url.length == 0) && options?.requestUrl) {
                url = options?.requestUrl;
            }
            return new UnknownHTTPError(`Unable to reach the server. Possible causes could be server is offline, your connection to the server is blocked or you are offline. ${url}`);
        }

        const status = parseInt(error.status / 100 + '');
        if (status === 4) {
            return new ClientError(this.getErrorMessage(error, options));
        } else if (status === 5) {
            return new ServiceError(this.getErrorMessage(error, options));
        } else {
            return new UnknownHTTPError(this.getErrorMessage(error, options));
        }
    }

    private getErrorMessage(error: AjaxError, options?: { requestUrl?: string }): string {
        let message = get(error, 'response.message');
        const url = get(error, 'xhr.responseURL');
        if (message) {
            return message;
        }
        if (error.status || error.status === 0) {
            if (error.response) {
                return `${error.status} - ${error.response}`;
            }
            if (url) {
                return `${error.status} - unknow error while connecting to ${url}`;
            }
            return `${error.status} - unknow error`;
        }
        var resolvedUrl = url || options?.requestUrl;
        if (resolvedUrl) {
            return `Unknow error while connecting to ${resolvedUrl}`;
        }
        return `Unknow error`;
    }

    private mergeUserRequestedHeadersWithDefaultHeaders(url: string, options: HttpRequestOptionsInternal) {
        if (!options.headers) {
            options.headers = {};
        }
        const commonHeaders = this.getCommonHeaders(url)
        let allHeaders = Object.assign({}, commonHeaders, options.headers);
        options.headers = allHeaders;
    }

    private getCommonHeaders(url: string) {
        let headers: any = {};
        const token = HttpClient.authTokenProvider.getToken();
        if (!UNAUTHENTICATED_URLS.find(regex => regex.test(url))) {
            headers = HttpClient.commonHttpHeadersProvider.getCommonHttpHeaders();
            // IMPORTANT: Need to add GCP, blob://, sockjs-node to skip url list
            headers = Object.assign(
                {},
                headers,
                {
                    /** API Domain name.
                     * //TODO please rename sitename to apiEndpointDomain
                     */
                    sitename: `${this.apiConfig.apiDomainName}`,
                    /** Domain name of the requesting website  */
                    domainname: `${this.env.domainname}`,
                    ...(HttpClient.loginUserDetailsProvider.userIpAddress ? { 'ip-address': HttpClient.loginUserDetailsProvider.userIpAddress } : null),
                    ...(HttpClient.loginUserDetailsProvider.userContry ? { 'country': HttpClient.loginUserDetailsProvider.userContry } : null),
                    wsId: `${HttpClient.wsDataProvider.getOrCreateStoredWSId()}`,
                    wsDomain: `${HttpClient.wsDataProvider.getStoredWSDomain()}`,
                }
            );

            if (token) {
                headers.Authorization = `Bearer ${token}`;
            }
        }
        return headers;
    }

    public static registerWSDetailProvider(provider: {
        getOrCreateStoredWSId: () => string | null,
        getStoredWSDomain: () => string | null,
    }) {
        HttpClient.wsDataProvider = provider;
    }

    public static registerLoginUserDetailsProvider(provider: { userIpAddress: string, userContry: string }) {
        HttpClient.loginUserDetailsProvider = provider;
    }

    public static registerCommonHttpHeadersProvider(provider: { getCommonHttpHeaders: () => any }) {
        HttpClient.commonHttpHeadersProvider = provider;
    }

    public static registerAuthTokenProvider(provider: { getToken: () => string }) {
        HttpClient.authTokenProvider = provider;
    }
}